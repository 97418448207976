import * as React from 'react';
import { IDocument } from 'common/schema/Document';
import { useClientConfig } from 'client/components/config/ClientConfigModule';
import { useFieldSchema } from './useFieldSchema';
import { MetadataText } from 'client/ui/metadata/MetadataText';
import { MetadataDate } from 'client/components/metadata/fields/MetadataDate';
import { IDossier } from 'common/schema/Dossier';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { Icon } from 'antd';
import { MetadataBoolean } from '../fields/MetadataBoolean';

export interface ISchemaMetadataProps {
  /**
   * Specifica se è per il documento o per il fascicolo
   */
  ns: SchemaConfigNamespace;
  /**
   * Campo da visualizzare
   */
  field: string;
  /**
   * Documento o Fascicolo da cui prendere il dato
   */
  source?: IDocument | IDossier;
  /**
   * Dato fornito direttamente.
   */
  value?: any;
  /**
   * Label di default se non sovrascritto
   */
  label: string;
  /**
   * Tipo di dato
   */
  type?: 'string' | 'date' | 'number' | 'boolean';
}

/**
 * Permette di visualizzare un metadato in base alla configurazione specificata
 * a livello applicativo.
 *
 * E' possibile utilizzarlo a livello _automatico_, in cui estrae dal documento
 * o dal fascicolo il valore in base al campo `field`, oppure in maniera
 * _manuale_, passando oltre al `field` anche un valore `value`
 * (necessario per gli Array i.e. `attachments.fileName`)
 */
// TODO: Gestire meglio i numeri
export function SchemaMetadata(props: ISchemaMetadataProps) {
  const { field, source } = props;
  const fieldSchema = useFieldSchema(props.ns, field);

  if (!fieldSchema?.enable) {
    return null;
  }

  // Warning se c'è `source` ma anche `value`. È ambiguo.
  if ('value' in props && 'source' in props) {
    process.env.DEV && console.warn(`SchemaMetadata: campo gestito sia come "value" che come "source" - ${props.ns}/${props.field}`); // prettier-ignore
  }

  // Se il campo è stato indicato _specificatamente_ ma non ha valore , evitiamo il rendering
  if ('value' in props && !props.value) {
    return null;
  }

  const originalValue = props.value ?? (source as any)?.[field];

  const option = fieldSchema?.options?.find(o => o.value === originalValue);

  const label = fieldSchema?.label ?? props.label;
  const value = option?.label ?? originalValue;
  const type = props.type ?? typeof value;

  if (value == null) return null;

  switch (type) {
    case 'date':
      return (
        <MetadataDate label={label} date={value} format={fieldSchema?.format} />
      );
    case 'boolean':
      return <MetadataBoolean label={label} value={value} />;
    case 'number':
    case 'string':
    default:
      return <MetadataText label={label} text={value} />;
  }
}
