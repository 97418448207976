import { yup } from '../initYup';
import { createSwaggerSchema } from '../swagger/createSwaggerSchema';
import { SchemaObject } from '@nestjs/swagger/dist/interfaces/open-api-spec.interface';

export interface DataTransferObject<T extends object, TDto> {
  validator: yup.ObjectSchema<T>;
  swaggerSchema: SchemaObject;
  Type: TDto;
}

export function createDataTransferObject<T extends object>(
  schema: yup.ObjectSchema<T>
) {
  return {
    validator: schema,
    swaggerSchema: createSwaggerSchema(schema)
  } as DataTransferObject<T, yup.InferType<typeof schema>>;
}
