export enum MetadataType {
  String = 'String',
  Number = 'Number',
  Date = 'Date',
  Currency = 'Currency',
  User = 'User'
}

export interface IMetadata {
  name: string;
  type: MetadataType;
  list?: boolean;
  label?: string;
  value: any;
}
