import * as React from 'react';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { ICancellation } from 'common/schema/Cancellation';

export interface CancellationViewProps {
  cancellation: ICancellation;
}

/**
 * Dati dettaglio eventuale annullamento del Protocollo
 */
export function CancellationView(props: CancellationViewProps) {
  const { cancellation } = props;

  return (
    <>
      <SchemaMetadata
        ns="document"
        field="cancellation.canceled"
        label="Protocollo annullato"
        value={cancellation.canceled}
        type="boolean"
      />
      <SchemaMetadata
        ns="document"
        field="cancellation.author"
        label="Utente autore dell’annullamento"
        value={cancellation.author}
      />
      <SchemaMetadata
        ns="document"
        field="cancellation.date"
        label="Data annullamento"
        value={cancellation.date}
        type="date"
      />
      <SchemaMetadata
        ns="document"
        field="cancellation.reason"
        label="Motivo annullamento"
        value={cancellation.reason}
      />
      <SchemaMetadata
        ns="document"
        field="cancellation.order"
        label="Provvedimento di annullamento"
        value={cancellation.order}
      />
    </>
  );
}
