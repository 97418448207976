import * as React from 'react';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { IDossierStandardMetadataSubject } from 'common/schema/DossierStandardMetadata';
import { SchemaSection } from '../schema/SchemaSection';
import { Divider } from 'antd';
import { SchemaLabel } from '../schema/SchemaLabel';

export interface SubjectsViewProps {
  subjects?: IDossierStandardMetadataSubject[];
}

/**
 * Dati dettaglio Soggetti procedimento da linee guida AgID
 */
export function SubjectsView(props: SubjectsViewProps) {
  const { subjects } = props;
  if (!subjects) return null;

  return (
    <SchemaSection ns="dossier" field="standardMetadata.subjects">
      {subjects.length > 0 && (
        <Divider orientation="left" className="divider-small">
          <SchemaLabel
            ns="dossier"
            field="standardMetadata.subjects"
            label="Soggetti"
          />
        </Divider>
      )}
      {subjects.map((subject, i) => (
        <div key={i} style={{ paddingLeft: '24px' }}>
          {i > 0 && <Divider />}
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.subjects.type"
            label="Tipo"
            value={subject.type}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.subjects.code"
            label="Codice"
            value={subject.code}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.subjects.description"
            label="Descrizione"
            value={subject.description}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.subjects.owner"
            label="Titolare"
            value={subject.owner}
            type="boolean"
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.subjects.ipaCode"
            label="Codice IPA"
            value={subject.ipaCode}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.subjects.organization"
            label="Amministrazione"
            value={subject.organization}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.subjects.uoCode"
            label="Codice UO"
            value={subject.uoCode}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.subjects.administrativeUnit"
            label="Unita Amministrativa"
            value={subject.administrativeUnit}
          />
        </div>
      ))}
    </SchemaSection>
  );
}
