import React from 'react';
import { Alert } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { AlertProps } from 'antd/lib/alert';
import { Scrollable } from '../scroller/Scrollable';

export interface AlertPageProps extends AlertProps {
  hasMenu?: boolean;
}

export function AlertPage(props: AlertPageProps) {
  const { hasMenu, ...otherProps } = props;

  return (
    <PageLayout hasMenu={hasMenu ?? true} hasScrollable>
      <Scrollable spacing="inside">
        <Alert {...otherProps} />
      </Scrollable>
    </PageLayout>
  );
}
