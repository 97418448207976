import { ObjectSchema, Schema } from 'yup';
import { SchemaObject } from '@nestjs/swagger/dist/interfaces/open-api-spec.interface';
import { toPairs, map, mapValues } from 'lodash';
import { isArraySchema } from './yupSchemaCheck';
import { inspect } from 'util';

declare module 'yup' {
  interface Schema<T> {
    _subType: Schema<any>;
  }
}

/**
 * Genera uno schema per Swagger a partire dai validatori di yup.
 */
export function createSwaggerSchema<T extends object>(
  schema: ObjectSchema<T>,
  nested?: boolean
): SchemaObject {
  const description = schema.describe();
  const swaggerSchema = {
    type: 'object',
    description: description.label,
    properties: mapValues(schema.fields, (field, key) =>
      createSwaggerProperty(key, field)
    )
  };

  // if (!nested) {
  //   console.log('schema is', inspect(swaggerSchema));
  // }
  return swaggerSchema;
}

function createSwaggerProperty<T>(key: string, field: Schema<T>): SchemaObject {
  const description = field.describe();

  if (description.type === 'object') {
    return createSwaggerSchema(field as any);
  }

  if (isArraySchema(field)) {
    return {
      type: 'array',
      description: description.label,
      items: createSwaggerProperty(key, field._subType)
    };
  }

  if (description.type === 'date') {
    return {
      type: 'string',
      format: 'date-time',
      description: description.label,
      required: (field as any)._exclusive.required ?? false
    };
  }

  return {
    type: description.type,
    description: description.label,
    enum: hasMeta(description.meta, 'oneOf')
      ? description.meta.oneOf
      : undefined,
    required: (field as any)._exclusive.required ?? false
  };
}

function hasMeta<T extends object, K extends string>(
  meta: T,
  key: K
): meta is T & { [Key in K]: any } {
  return meta?.hasOwnProperty(key) ?? false;
}
