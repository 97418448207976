import { IAttachment } from 'common/schema/Attachment';
import { IAttachmentVersion } from 'common/schema/AttachmentVersion';

/**
 * Controlla se un allegato (o il documento principale) sono disponibili
 * come documenti fisici.
 */
export function isAttachmentAvailable(
  attachment: IAttachment | IAttachmentVersion | undefined
): attachment is IAttachment | IAttachmentVersion {
  if (!attachment) return false;
  if (!attachment.hasFile) return false;
  // if (!attachment.path) return false;

  return true;
}
