import * as React from 'react';
import { Button, Icon, Divider, message, Popconfirm } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DiscardPolicyApi } from '../DiscardPolicyApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { IDiscardPolicy } from 'common/schema/DiscardPolicy';
import { TableScroll } from 'client/ui/table/TableScroll';
import { IDiscardPoliciesQueryDto } from 'common/dto/DiscardPoliciesQueryDto';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

export interface DiscardPoliciesListPageProps {}

/**
 * Pagina di elenco delle Policy di Scarto
 */
export function DiscardPoliciesListPage(props: DiscardPoliciesListPageProps) {
  const [query, setQuery] = useState<IDiscardPoliciesQueryDto>({
    page: 1,
    pageSize: 10
  });
  const { response, loading, error } = useApiQuery(DiscardPolicyApi.list, {
    data: { query }
  });

  const deleteOne = useApiMutation(DiscardPolicyApi.delete, {
    invalidates: [DiscardPolicyApi.list]
  });

  const handleDelete = (id: number) => async (e: any) => {
    try {
      await deleteOne({ data: { id } });
      message.success('Eliminata correttamente.');
    } catch (err) {
      console.error(err);
      message.error(`Impossibile eliminare la voce.`);
    }
  };

  const columns: ColumnProps<IDiscardPolicy>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '100px',
      sorter: true
    },
    {
      title: 'Policy',
      /** TODO: Sostituire con calcolo  */
      dataIndex: 'name',
      className: 'bold-column',
      ...createColumnFilter(FilterInputType.Text),
      sorter: true
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: date => <ISODate date={date} />,
      ...createColumnFilter(FilterInputType.Date),
      sorter: true
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 260,
      render: (_, record) => (
        <div className="table-actions-column">
          <Link to={`/app/discard-policies/edit/${record.id}`}>
            <Button size="small">
              <Icon type="eye" /> <TableButtonDetailText />
            </Button>
          </Link>
          <ButtonDeleteConfirm
            title="Sei sicuro di voler eliminare questa policy?"
            onConfirm={handleDelete(record.id)}
          />
        </div>
      )
    }
  ];

  return (
    <PageLayout
      hasScrollable
      hasMenu
      fullTable
      title={
        <CustomCardTitle
          image="discard"
          title="Elenco delle Policy di Scarto"
        />
      }
      extra={
        <Link to={`/app/discard-policies/edit/create`}>
          <Button type="primary" icon="plus">
            Nuova Policy di Scarto
          </Button>
        </Link>
      }
    >
      <TableScroll
        columns={columns}
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data.items ?? []}
        onChange={(pagination, filters, sorter) => {
          const description =
            filters.description && filters.description.length > 0
              ? filters.description[0].toString()
              : undefined;
          setQuery({
            ...query,
            description,
            page: pagination.current || query.page,
            pageSize: pagination.pageSize || query.pageSize,
            name:
              filters.name && filters.name.length > 0
                ? filters.name[0]
                : undefined,
            createdAt:
              filters.createdAt && filters.createdAt.length > 0
                ? filters.createdAt[0]
                : undefined,
            orderBy: sorter.field,
            orderByDirection: sorter.order
              ? ColumnSortDirections[sorter.order]
              : undefined
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
