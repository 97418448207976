import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { TextAreaInput, TextAreaInputProps } from './TextAreaInput';
import './TextAreaInputItem.scss';

interface Props<T extends FormikValues> extends TextAreaInputProps<T> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
}

/**
 * Form Item per input testuale.
 */
export function TextAreaInputItem<T extends FormikValues>(props: Props<T>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem
      {...itemProps}
      name={name}
      label={label}
      className="text-area-imput-item"
    >
      <TextAreaInput {...otherProps} name={name} />
    </FormItem>
  );
}
