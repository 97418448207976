import * as React from 'react';
import { Row, Col, Divider, Tabs } from 'antd';
const { TabPane } = Tabs;
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { DepositReport } from '../detail/DepositReport';
import { TableDepositDocumentList } from './relations/TableDepositDocumentList';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositApi } from '../DepositApi';
import { RouteComponentProps } from 'react-router';
import { useState } from 'react';
import { DepositContentType } from './content-type/DepositContentType';
import { DepositContentTypeSelectTable } from './content-type/DepositContentTypeSelectTable';
import { TableDepositDossierList } from './relations/TableDepositDossierList';
import { TableDepositAttachmentsList } from './relations/TableDepositAttachmentsList';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { DepositStateTag } from '../detail/DepositStateTag';
import { ISODate } from 'client/ui/display/date/ISODate';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import { LabeledTextGroup } from 'client/ui/labeled-text/LabeledTextGroup';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

interface Params {
  id: string;
}

export interface DepositPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina del singolo versamento
 */
export function DepositPage(props: DepositPageProps) {
  const id = parseInt(props.match.params.id, 10);
  const { response, loading, error } = useApiQuery(DepositApi.find, {
    data: { id }
  });
  const [contentType, setContentType] = useState(DepositContentType.Documents);

  const deposit = response?.data;

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare il Versamento"
        description={getNetworkErrorMessage(error)}
      />
    );

  if (!deposit) return <LoadingPage />;

  return (
    <PageLayout
      className="deposit-page"
      hasScrollable
      hasMenu
      loading={loading}
      title={
        <CustomCardTitle
          image="deposit"
          titleLabel="Versamento:"
          title={
            deposit.description ?? (
              <>
                Versamento del <ISODate date={deposit && deposit.createdAt} />
              </>
            )
          }
        />
      }
    >
      <RowScroller>
        <ColScroller span={8} side="left" spacing="inside">
          <LabeledTextGroup inline>
            <LabeledText
              label="Stato"
              text={<DepositStateTag state={deposit.state} />}
              style={{ minWidth: '180px' }}
            />
            <LabeledText label="ID" text={deposit.id.toString()} />
            <LabeledText
              label="Data"
              text={<ISODate date={deposit.createdAt} />}
            />
          </LabeledTextGroup>
          <Row type="flex">
            <LabeledText
              ellipsis={false}
              label="Descrizione"
              text={deposit.description}
            />
          </Row>
          <Divider />
          <DepositReport deposit={deposit} />
          <Divider />
          <DepositContentTypeSelectTable
            deposit={deposit}
            selected={contentType}
            onChange={setContentType}
          />
        </ColScroller>
        <ColScroller span={16} forceHeight hasTableScroll>
          <Tabs type="card" className="tabs-content-scrollable">
            <TabPane
              className="spacing-tablescroll full-table"
              key="dossiers"
              tab="Elenco Fascicoli"
            >
              <TableDepositDossierList deposit={deposit} />
            </TabPane>
            <TabPane
              className="spacing-tablescroll full-table"
              key="documents"
              tab="Elenco Documenti"
            >
              <TableDepositDocumentList deposit={deposit} />
            </TabPane>
            <TabPane
              className="spacing-tablescroll full-table"
              key="files"
              tab="Elenco File"
            >
              <TableDepositAttachmentsList deposit={deposit} />
            </TabPane>
          </Tabs>
        </ColScroller>
      </RowScroller>
    </PageLayout>
  );
}
