import { IUser } from './User';
import { IDossier } from './Dossier';
import { IDocument } from './Document';
import { PermissionsType } from 'common/permissions/Permissions';
import { IGrantRequest } from './GrantRequest';

export interface IGrant {
  id: number;
  user: IUser;
  userId: number;
  author?: IUser;
  authorId?: number | null;
  dossiers: IDossierGrant[];
  documents: IDocumentGrant[];
  requests: IGrantRequest[];
  status: GrantStatus;
  description?: string | null;
  beginsAt?: Date | null;
  expiresAt?: Date | null;
  approvedAt?: Date | null;
  declinedAt?: Date | null;
  createdAt: Date;
  updatedAt: Date;
  permission: PermissionsType;
}

export interface IDossierGrant {
  id: number;
  dossier: IDossier;
  dossierId: number;
  grant: IGrant;
  grantId: number;
}

export interface IDocumentGrant {
  id: number;
  document: IDocument;
  documentId: number;
  grant: IGrant;
  grantId: number;
}

export enum GrantStatus {
  Request = 'request',
  Draft = 'draft',
  Active = 'active',
  Expired = 'expired',
  Declined = 'declined'
}

export enum OrderableGrantFields {
  Id = 'id',
  CreatedAt = 'createdAt'
}

export enum GrantRequestDomain {
  Specific = 'specific',
  Generic = 'generic'
}
