import React, { useState } from 'react';
import clx from 'classnames';
import { Typography, Row } from 'antd';
const { Text, Paragraph } = Typography;
import './SelectionRule.scss';

export interface SelectionRuleProps {
  label: string;
  rule: string;
  parameter?: string | string[];
}

/**
 * Elemento per mostrare una regola di selezione in formato testo
 * Una Riga
 * (Regole di selezione dei versamenti o degli scarti)
 */
export function SelectionRule(props: SelectionRuleProps) {
  return (
    <Paragraph className="selection-rule">
      <Text className="rule-and">e</Text>
      <Text className="rule-label" type="secondary">
        {props.label}
      </Text>
      <Text className="rule-column">:</Text>
      <Text className="rule-rule">{props.rule}</Text>
      {props.parameter && (
        <>
          <Text className="rule-column">:</Text>
          {!Array.isArray(props.parameter) ? (
            <Text className="rule-parameter">{props.parameter}</Text>
          ) : (
            <>
              {props.parameter.map((single, i) => (
                <Text key={i} className="rule-parameter">
                  {single}
                  {i + 1 !== props.parameter?.length && `, `}
                </Text>
              ))}
            </>
          )}
        </>
      )}
    </Paragraph>
  );
}
