import React from 'react';
import { Icon, Button } from 'antd';
import { ISODate } from 'client/ui/display/date/ISODate';
import { ITransmission } from 'common/schema/Transmission';
import { TableScroll } from 'client/ui/table/TableScroll';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { useFieldSchemaColumns } from '../schema/useFieldSchemaColumn';

export interface ITransmissionsTableProps {
  ns: SchemaConfigNamespace;
  selectedTransmission: number | null;
  setSelectedTransmission: React.Dispatch<React.SetStateAction<number | null>>;
  transmissions: ITransmission[];
}

export function TransmissionsTable(props: ITransmissionsTableProps) {
  const columns = useFieldSchemaColumns<ITransmission>(props.ns, [
    {
      title: 'Data Invio',
      dataIndex: 'sentAt',
      field: 'transmissions.sentAt',
      width: 100,
      render: sentAt => <ISODate date={sentAt} />
    },
    {
      title: 'Utente Mittente / Ruolo',
      dataIndex: 'sender',
      field: 'transmissions.sender',
      className: 'bold-column',
      ellipsis: true
    },
    {
      title: 'Tipo',
      dataIndex: 'kind',
      field: 'transmissions.kind',
      width: 110
    },
    {
      title: 'Diritti',
      dataIndex: 'rights',
      field: 'transmissions.rights',
      width: 50,
      render: (_, record) =>
        record.rights ? (
          <Icon
            type="check"
            className="success-color"
            style={{ fontSize: '18px', color: 'green' }}
          />
        ) : null
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      className: 'arrow',
      width: 40,
      render: (_, record, i) => (
        <Button
          className="td-button-link"
          type="link"
          icon="right-circle"
          onClick={e => props.setSelectedTransmission(i)}
        />
      )
    }
  ]);
  return (
    <div className="table-container">
      <TableScroll
        // bottomSpace={54}
        // label="Trasmissioni"
        columns={columns}
        dataSource={props.transmissions}
        size="middle"
        pagination={false}
        rowClassName={(row, i) =>
          i === props.selectedTransmission ? 'ant-table-row-selected' : ''
        }
      />
    </div>
  );
}
