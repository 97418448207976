import * as React from 'react';
import { FormItem } from 'client/ui/form/FormItem';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { OrganizationalAreaApi } from 'client/components/admin/organizations/OrganizationalAreaApi';
import { SelectProps } from 'antd/lib/select';
import { IOrganizationalArea } from 'common/schema/OrganizationalArea';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';

export interface IOrganizationalAreaInputProps extends SelectProps {
  name: string;
  resetOnChange?: string[];
}

export function OrganizationalAreaInput(props: IOrganizationalAreaInputProps) {
  const { name, resetOnChange, ...otherProps } = props;

  useFormikFieldChange(
    formik => {
      if (resetOnChange) {
        resetOnChange.forEach(field => formik.setFieldValue(field, undefined));
      }
    },
    [name]
  );

  return (
    <FormItem name={name} label="A.O.O.">
      <AsyncSelectInput
        placeholder="Cerca A.O.O..."
        name={name}
        query={{
          apiFn: OrganizationalAreaApi.listSelectable,
          options: (formik, value) => ({
            data: { query: { name: value, page: 1, pageSize: 30 } }
          })
        }}
        optionTransform={(area: IOrganizationalArea) => ({
          value: area.uid,
          label: `${area.uid} - ${area.name}`
        })}
        allowClear
        showSearch
        refreshOnSearch
        responseTransform={data => data.items}
        responseMeta={data => ({
          shown: data.items.length,
          total: data.meta.total
        })}
        {...otherProps}
      />
    </FormItem>
  );
}
