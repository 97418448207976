import * as React from 'react';
import { Tree } from 'antd';
import {
  getDossierStructureNode,
  IDossierStructureSubdossierNode
} from './getDossierStructureNode';
import { IDossier } from 'common/schema/Dossier';
import { SchemaLabel } from 'client/components/metadata/schema/SchemaLabel';
import { AntTreeNode } from 'antd/lib/tree';
import { IDossierStructureDocumentNode } from './getDocumentStructureNode';
import { useHistory } from 'react-router';
const { DirectoryTree, TreeNode } = Tree;

export interface IDossierStructureProps {
  dossier: IDossier;
}

/**
 * Componente che si occupa di decodificare e visualizzare la struttura di
 * fascicoli e sotto-fascicoli.
 */
export function DossierStructure(props: IDossierStructureProps) {
  const { dossier } = props;
  const history = useHistory();

  if (!dossier.subdossiers || dossier.subdossiers.length < 1) return null;
  const roots = dossier.subdossiers.filter(node => node.parentUid == null);

  const handleOpen = (e: React.MouseEvent<HTMLElement>, node: AntTreeNode) => {
    const data = node.props.data as
      | IDossierStructureDocumentNode
      | IDossierStructureSubdossierNode;

    switch (data?.type) {
      // Navigazione verso il documento
      case 'document':
        history.push(`/app/documents/${dossier.areaUid}/${data.uid}`);
        return;

      // Sottofascicolo - il doppioclick non ha un comportamento particolare.
      case 'subdossier':
        return;
    }
  };

  return (
    <>
      <SchemaLabel
        ns="dossier"
        field="subdossiers"
        label="Struttura"
        labelLikeMetadata
      />
      <DirectoryTree multiple defaultExpandAll onDoubleClick={handleOpen}>
        {roots.map(root => getDossierStructureNode(dossier, root.uid))}
      </DirectoryTree>
    </>
  );
}
