import { yup } from 'common/validation/initYup';

export type IDocumentsQueryDto = yup.InferType<typeof documentsQueryValidator>;

export const documentsQueryValidator = yup.object({
  page: yup.number().integer().min(1).default(1),
  pageSize: yup.number().integer().min(5).max(20).default(10),
  subject: yup.string().nullable().notRequired(),
  registerYear: yup.number().allowEmptyString().notRequired(),
  registerNumber: yup.number().allowEmptyString().notRequired(),
  registerNumberRangeFrom: yup.number().allowEmptyString().notRequired(),
  registerNumberRangeTo: yup.number().allowEmptyString().notRequired(),
  registeredAt: yup.date().notRequired(),
  registeredAtRangeFrom: yup.date().notRequired(),
  registeredAtRangeTo: yup.date().notRequired(),
  register: yup.string().nullable().notRequired(),
  typology: yup.string().nullable().notRequired(),
  categoryIndexId: yup.number().nullable().notRequired(),
  categoryAnyIndex: yup
    .boolean()
    .default(false)
    .label('Cerca la voce su tutti i titolari'),
  category: yup.string().nullable().notRequired(),
  createdAt: yup.date().notRequired(),
  createdAtRangeFrom: yup.date().notRequired(),
  createdAtRangeTo: yup.date().notRequired(),
  area: yup.string().nullable().notRequired(),
  metadataValue: yup.string().nullable().notRequired()
});
