import React from 'react';
import clx from 'classnames';
import { Typography } from 'antd';
const { Paragraph } = Typography;
import './LabeledText.scss';

export interface LabeledTextProps {
  text?: string | React.ReactNode;
  label: string;
  style?: React.CSSProperties;
  /**
   * ellipsis? -> Se props.text è una stringa aggiunge
   * la funzionalità ellipsis al testo (di default è True)
   */
  ellipsis?: boolean;
}

/**
 * Elemento per mostrare testo o elemento con una label superiore
 * (simile a quella degli imput nei Form)
 *
 */
export function LabeledText(props: LabeledTextProps) {
  const isString = typeof props.text === 'string';
  // rendo ellipsis = true di default
  const ellipsis = props.ellipsis === false ? false : true;

  return (
    <div style={props.style} className={clx('labeled-text', {})}>
      <Paragraph type="secondary">{props.label}</Paragraph>
      {isString ? (
        ellipsis ? (
          <Paragraph strong ellipsis={{ rows: 1, expandable: true }}>
            {props.text}
          </Paragraph>
        ) : (
          <Paragraph strong>{props.text}</Paragraph>
        )
      ) : (
        <div className="type-react-node">{props.text}</div>
      )}
    </div>
  );
}
