import * as React from 'react';
import './CcTooltip.scss';
import clx from 'classnames';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip/index';

/**
 * Tooltip stilizzato come quello di C-Prot
 */
export function CcTooltip(props: TooltipProps) {
  return (
    <Tooltip
      {...props}
      overlayClassName={clx('cc-tooltip', props.overlayClassName)}
    />
  );
}
