import React from 'react';
import { Row, Col } from 'antd';
import { RouteComponentProps } from 'react-router';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { DashboardSystemDetail } from './detail/DashboardSystemDetail';
import { DashboardDepositDetail } from './detail/DashboardDepositDetail';
import { DashboardDiscardDetail } from './detail/DashboardDiscardDetail';
import { useModulesConfig } from '../config/ClientConfigModule';
import { NotFoundPage } from '../not-found/NotFoundPage';
import { DashboardApi } from './DashboardApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { AlertPage } from 'client/ui/alert/AlertPage';

export interface DashboardPageProps
  extends RouteComponentProps<{ instanceId: string }> {}

/**
 * Pagina Dashboard dell'Archivio di Deposito
 */
export function DashboardPage(props: DashboardPageProps) {
  const modules = useModulesConfig();

  const { response, error, loading } = useApiQuery(DashboardApi.getData, {
    data: {}
  });

  const data = response?.data;

  if (!modules.dashboard) return <NotFoundPage />;

  if (error) {
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare la Dashboard. Riprovare"
        description={error.message}
      />
    );
  }

  if (loading || !data) return <LoadingPage />;

  return (
    <PageLayout
      hasScrollable
      hasMenu
      loading={loading || !response}
      // title={<CustomCardTitle image="home" title="Dashboard" />}
    >
      <Scrollable spacing="inside">
        <div style={{ padding: '24px' }}>
          <Row gutter={36}>
            <Col span={24}>
              <DashboardSystemDetail data={data.system} />
            </Col>
          </Row>
          <Row gutter={36} style={{ marginTop: '36px' }}>
            <Col span={24}>
              <DashboardDepositDetail data={data.deposits} />
            </Col>
          </Row>
          <Row gutter={36} style={{ marginTop: '36px' }}>
            <Col span={24}>
              <DashboardDiscardDetail data={data.discards} />
            </Col>
          </Row>
        </div>
      </Scrollable>
    </PageLayout>
  );
}
