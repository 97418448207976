import * as React from 'react';
import { Tabs, Col } from 'antd';
const { TabPane } = Tabs;
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { TableGrantsDocumentList } from '../edit/TableGrantsDocumentList';
import { TableGrantsDossierList } from '../edit/TableGrantsDossierList';
import { GrantExtra } from '../common/GrantExtra';
import { RouteComponentProps } from 'react-router';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { GrantApi } from '../GrantApi';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import { GrantStatusTag } from '../detail/GrantStatusTag';
import { ISODate } from 'client/ui/display/date/ISODate';
import { LabeledTextGroup } from 'client/ui/labeled-text/LabeledTextGroup';
import './GrantViewPage.scss';
import { GrantRequestsTable } from '../relations/GrantRequestsTable';

interface Params {
  id: string;
}

export interface GrantViewPageProps extends RouteComponentProps<Params> {}

export function GrantViewPage(props: GrantViewPageProps) {
  const id = parseInt(props.match.params.id, 10);

  const { response, error, loading } = useApiQuery(GrantApi.findGrant, {
    data: { id }
  });

  if (error) return <AlertPage type="error" message={error.message} showIcon />;

  // if (loading || !response || !response.data)
  //   return <LoaderSpin full transparent />;

  return (
    <PageLayout
      hasScrollable
      hasMenu
      loading={loading || !response}
      title={
        <CustomCardTitle
          ellipses={true}
          ellipsesWidth="75%"
          image="grant"
          titleLabel="Dettaglio Permesso:"
          title={
            id +
            (response?.data.description
              ? ' | ' + response.data.description
              : '')
          }
        />
      }
      extra={
        <GrantExtra
          grant={response?.data}
          edit={true}
          loading={loading || !response}
        />
      }
    >
      <RowScroller>
        <ColScroller span={8} side="left">
          <div className="grant-summary">
            <LabeledTextGroup inline>
              <LabeledText
                label="Data Richiesta"
                text={<ISODate date={response?.data.createdAt} />}
              />
              <LabeledText label="ID" text={id} />
            </LabeledTextGroup>
            <LabeledTextGroup inline>
              <LabeledText
                label="Stato"
                text={<GrantStatusTag grant={response?.data} />}
                style={{ minWidth: '150px' }}
              />
              {response?.data.approvedAt && (
                <LabeledText
                  label="Data Approvazione"
                  text={<ISODate date={response?.data.approvedAt} />}
                />
              )}
              {response?.data.declinedAt && (
                <LabeledText
                  label="Data Respingimento"
                  text={<ISODate date={response?.data.declinedAt} />}
                />
              )}
            </LabeledTextGroup>
            <LabeledTextGroup inline>
              <LabeledText
                label="Valido dal"
                text={
                  <ISODate date={response?.data.beginsAt} defaultValue={'--'} />
                }
                style={{ minWidth: '150px' }}
              />
              <LabeledText
                label="Valido fino al"
                text={
                  <ISODate
                    date={response?.data.expiresAt}
                    defaultValue={'--'}
                  />
                }
              />
            </LabeledTextGroup>
            <LabeledText label="Concesso a" text={response?.data.user.name} />
            <LabeledText
              ellipsis={false}
              label="Descrizione"
              text={response?.data.description}
            />
          </div>
          {/* Campi imput e pulsanti per approvazione richiesta vive solo a richiesta aperta */}
          {/* <GrantsPageForm /> */}
        </ColScroller>
        <ColScroller span={16} forceHeight hasTableScroll>
          <Tabs type="card" className="tabs-content-scrollable">
            {/* Elenco dei fascicoli */}
            <TabPane
              className="spacing-tablescroll full-table"
              key="dossiers"
              tab="Elenco Fascicoli"
            >
              <TableGrantsDossierList
                dossiers={response?.data.dossiers ?? []}
                bottomSpace={58}
              />
            </TabPane>
            {/* Elenco dei documenti */}
            <TabPane
              className="spacing-tablescroll full-table"
              key="document"
              tab="Elenco Documenti"
            >
              <TableGrantsDocumentList
                documents={response?.data.documents ?? []}
                bottomSpace={58}
              />
            </TabPane>
            {/* Dettaglio Richiesta */}
            <TabPane
              className="spacing-tablescroll full-table"
              key="request"
              tab="Dettaglio Richiesta"
            >
              <GrantRequestsTable
                requests={response?.data.requests ?? []}
                bottomSpace={58}
              />
            </TabPane>
          </Tabs>
        </ColScroller>
      </RowScroller>
    </PageLayout>
  );
}
