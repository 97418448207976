import { IDossier } from './Dossier';
import { IDocument } from './Document';

export enum DocumentClassificationType {
  /** Fascicolo generale della voce di titolario */
  Category = 'Category',
  /** Specifico fascicolo */
  Dossier = 'Dossier'
}

/**
 * Specifica la struttura di un documento contenuto in un fascicolo.
 */
export interface IDocumentClassification {
  uid?: number;
  type: DocumentClassificationType;
  /** Codice del fascicolo / titolario */
  code: string;
  /** Descrizione del fascicolo / titolario */
  title: string;
  insertedAt?: Date;

  primary?: boolean;
  register?: string;
  registerUid?: number;

  document?: IDocument;

  // Speciali MIUR
  repertory?: string;
  number?: number;
  description?: string;
  /** UID del titolario */
  indexUid?: number;
}
