import * as React from 'react';
import { EventTable } from 'client/components/metadata/tabs/EventTable';
import { IDossier } from 'common/schema/Dossier';
import { Scrollable } from 'client/ui/scroller/Scrollable';

export interface IDossierEventTabProps {
  dossier: IDossier;
}

export function DossierEventTab(props: IDossierEventTabProps) {
  const { dossier } = props;

  if (!dossier) return null;

  return (
    <Scrollable hasTableScroll>
      <EventTable ns="dossier" events={dossier.events} />
    </Scrollable>
  );
}
