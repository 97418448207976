import * as React from 'react';
import { attachmentExtension } from './attachmentExtension';
import { Empty, Button } from 'antd';
import { isAttachmentAvailable } from './isAttachmentAvailable';
import { IAttachment } from 'common/schema/Attachment';
import { getAttachmentUrl } from './getAttachmentUrl';
import { AttachmentPreview } from './AttachmentPreview';

export interface IAttachmentPreviewFrameProps {
  attachment: AttachmentPreview | null;
}

/**
 * Si occupa di visualizzare l'allegato di un documento.
 */
export function AttachmentPreviewFrame(props: IAttachmentPreviewFrameProps) {
  const { attachment } = props;

  // Non c'è l'allegato.
  if (!attachment) {
    return <Empty description={`Documento non disponibile`} />;
  }

  // Documento fisico non disponibile.
  if (!isAttachmentAvailable(attachment.item)) {
    return <Empty description={`Documento non acquisito.`} />;
  }

  const attachmentUrl = getAttachmentUrl(attachment);

  const extension = attachmentExtension(attachment.item.fileName);
  if (extension != 'pdf') {
    return (
      <Empty
        description={`Scarica il file ${
          attachment.item.title ? `"${attachment.item.title}"` : ''
        } ${attachment.item.fileName ? `(${attachment.item.fileName})` : ''}`}
      >
        <a href={attachmentUrl} target="_blank">
          <Button type="primary">Scarica</Button>
        </a>
      </Empty>
    );
  }

  return (
    <iframe
      src={`${attachmentUrl}?preview=true#navpanes=0&toolbar=0`}
      frameBorder={0}
      allowFullScreen
      width="100%"
      height="100%"
    />
  );
}
