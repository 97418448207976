import { ObjectID } from 'bson';
import { yup } from 'common/validation/initYup';
import { IDocument } from './Document';
import { IAttachmentVersion } from './AttachmentVersion';

export enum AttachmentKind {
  Principal = 'Principal',
  Attachment = 'Attachment'
}

/**
 * Allegato (documento fisico) a un Documento (logico).
 */
export interface IAttachment {
  id: number;
  /** Specifica se è il documento principale o un semplice allegato */
  kind: AttachmentKind;
  uid: number;

  fileName?: string;
  title: string;
  createdAt?: Date;
  hasFile?: boolean;
  pagesCount?: number;
  hash?: string;
  bytes?: number;

  // Path visualizzabile dal client
  path?: string;

  depositedAt: Date;

  // Speciali CC
  documentId?: number;
  document: IDocument;
  areaUid: string;
  organizationUid: string;
  code?: string;

  // Speciali MIUR
  detailType?: string;
  collocation?: string;
  versions?: IAttachmentVersion[];
}

export const AttachmentValidator = yup.object({
  documentId: yup.number().required(),
  uid: yup.number().integer().positive().required(),
  kind: yup.string().required().oneOfEnum(AttachmentKind) as yup.StringSchema<
    AttachmentKind
  >,
  fileName: yup.string().notRequired(),
  areaUid: yup.string().required(),
  organizationUid: yup.string().required(),
  // TODO: Dovrebbe essere obbligatorio, segnalare e rimuovere il default
  title: yup.string().default('File'),
  code: yup.string().notRequired(),
  createdAt: yup.date().italianFormat().notRequired(),
  hasFile: yup.boolean().default(false),
  pagesCount: yup.number().integer().min(0).notRequired(),
  hash: yup.string().notRequired()
});
