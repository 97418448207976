import * as React from 'react';
import { Input, Row, Col, Select } from 'antd';
import { TextInput } from 'client/ui/form/input/TextInput';
import { useState } from 'react';
import { FormItem } from 'client/ui/form/FormItem';
import { useFormikContext, useField } from 'formik';
import { useTypeField } from 'client/ui/form/hooks/useTypeField';
import { SchemaLabel } from 'client/components/metadata/schema/SchemaLabel';

export interface IDossierCodeSearchProps {}

const DossierCodeSearchTypes = [
  { value: 'exact', label: 'Esatto', inputName: 'code' },
  {
    value: 'begins',
    label: 'Inizia con',
    inputName: 'codeBegins'
  }
];

export function DossierCodeSearch(props: IDossierCodeSearchProps) {
  const [searchTypeValue, setSearchTypeValue] = useTypeField('code', 'exact');
  const formik = useFormikContext<any>();
  const searchType = DossierCodeSearchTypes.find(
    t => t.value === searchTypeValue
  )!;

  const changeSearchType = (value: string) => {
    formik.setFieldValue(searchType.inputName, undefined);
    setSearchTypeValue(value);
  };

  return (
    <FormItem
      name={searchType.inputName}
      label={<SchemaLabel ns="dossier" field="code" label="Codice Fascicolo" />}
    >
      <Input.Group compact>
        <Select
          style={{ width: '30%' }}
          value={searchTypeValue}
          onChange={changeSearchType}
        >
          {DossierCodeSearchTypes.map(type => (
            <Select.Option value={type.value}>{type.label}</Select.Option>
          ))}
        </Select>

        <TextInput style={{ width: '70%' }} name={searchType.inputName} />
      </Input.Group>
    </FormItem>
  );
}
