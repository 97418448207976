import * as React from 'react';
import { Tree } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { RouteComponentProps } from 'react-router-dom';
import { useCategoryTree } from './category-tree/useCategoryTree';
import { ICategory } from 'common/schema/Category';
import { AlertPage } from 'client/ui/alert/AlertPage';
const { TreeNode } = Tree;

interface Params {
  id: string;
}

export interface ICategoryIndexDetailProps
  extends RouteComponentProps<Params> {}

export function CategoryIndexDetail(props: ICategoryIndexDetailProps) {
  const id = parseInt(props.match.params.id, 10);
  // const { response, error, loading } = useApiQuery(CategoryIndexApi.findIndex, {
  //   data: { id }
  // });

  const { index, error, loading, loadCategory } = useCategoryTree(id);

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare il Titolario"
        showIcon
        type="error"
      />
    );

  const onLoadData = (treeNode: any) => {
    return loadCategory(treeNode.props.dataRef);
  };

  const renderTreeNode = (data: ICategory[]) =>
    data.map(category => {
      if (category.children) {
        return (
          <TreeNode
            title={`${category.code} - ${category.name}`}
            dataRef={category}
          >
            {renderTreeNode(category.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          isLeaf={category.isLeaf}
          title={`${category.code} - ${category.name}`}
          dataRef={category}
        />
      );
    });

  return (
    <PageLayout
      hasMenu
      hasScrollable
      loading={loading || !index}
      title={
        <CustomCardTitle
          icon="ordered-list"
          titleLabel="Titolario:"
          title={index?.title}
        />
      }
    >
      <Scrollable spacing="inside">
        <Tree showLine loadData={onLoadData}>
          {index?.categories && renderTreeNode(index?.categories)}
        </Tree>
      </Scrollable>
    </PageLayout>
  );
}
