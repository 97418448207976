export enum DepositState {
  Progress = 'progress',
  Complete = 'complete',
  Fail = 'fail'
}

export interface IDeposit {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  description?: string;
  state: DepositState;
  dossiersCount: number;
  documentsCount?: number;
  dossiersReceived: number;
  documentsReceived: number;
  // attachmentsReceived: number;
}

export enum OrderableDepositFields {
  Id = 'id',
  CreatedAt = 'createdAt'
}
