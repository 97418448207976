import { Roles } from 'common/permissions/Roles';
import { toPairs } from 'lodash';
import { IUser } from 'common/schema/User';
import { PermissionLogic } from './PermissionLogic';
import { IAuthDomain } from 'common/schema/AuthDomain';
import { IRoleBinding } from 'common/schema/RoleBinding';
import { PermissionsType } from 'common/permissions/Permissions';
import { AuthDomainLogic } from './AuthDomainLogic';

export class RolesLogic {
  static availableRolesOptions(user: IUser | null, modulesFeature?: string[]) {
    if (!user) return [];

    let roles = { ...Roles };

    if (modulesFeature && !modulesFeature.some(f => f === 'grant')) {
      delete roles.Consulter;
    }

    if (!PermissionLogic.hasRootRole(user, 'Admin')) {
      delete roles.Admin;
      delete roles.System;
    }

    const options = toPairs(roles).map(([key, value]) => ({
      value: value.name,
      label: value.displayName
    }));

    return options;
  }

  /**
   * Ritorna la lista di ruoli su cui si ha autorizzazione
   * in base al dominio.
   * E.G un utente Admin X e Archivista Y, potrà creare un Archivista X, Admin X, e Archivista Y, ma non un Admin Y.
   */
  static availableRolesOptionsForDomain(
    user: IUser | null,
    domain: IAuthDomain,
    modulesFeature?: string[]
  ) {
    if (!user || !user.bindings) return [];

    let roles = { ...Roles };

    // Seleziono tutti i ruoli che un utente ha sul dominio X
    const userDomainRoles = user.bindings
      .filter(bind => AuthDomainLogic.isSubDomain(bind.domain, domain))
      .map(bind => bind.role);

    if (userDomainRoles.length === 0) {
      return [];
    }

    if (modulesFeature && !modulesFeature.some(f => f === 'grant')) {
      delete roles.Consulter;
    }

    if (!userDomainRoles.includes(Roles.Admin.name)) {
      delete roles.Admin;
      delete roles.System;
    }

    if (
      !userDomainRoles.includes(Roles.Archivist.name) &&
      !userDomainRoles.includes(Roles.Admin.name)
    ) {
      delete roles.Archivist;
    }

    const options = toPairs(roles).map(([key, value]) => ({
      value: value.name,
      label: value.displayName
    }));

    return options;
  }
}
