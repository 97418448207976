import * as React from 'react';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { IDocumentStandardMetadata } from 'common/schema/DocumentStandardMetadata';
import { SchemaSection } from '../schema/SchemaSection';

export interface DocumentStandardMetadataViewProps {
  metadata?: IDocumentStandardMetadata;
}

/**
 * Dati dettaglio metadati del protocollo (linee guida AgID)
 */
export function DocumentStandardMetadataView(
  props: DocumentStandardMetadataViewProps
) {
  const { metadata } = props;
  if (!metadata) return null;

  return (
    <SchemaSection ns="document" field="standardMetadata">
      <SchemaMetadata
        ns="document"
        field="standardMetadata.format"
        label="Formato"
        value={metadata.format}
      />
      <SchemaMetadata
        ns="document"
        field="standardMetadata.making"
        label="Formazione"
        value={metadata.making}
      />
      <SchemaMetadata
        ns="document"
        field="standardMetadata.makingSubject"
        label="Soggetto Produttore"
        value={metadata.makingSubject}
      />
    </SchemaSection>
  );
}
