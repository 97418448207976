import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { message, Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { UserApi } from './UserApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Formik } from 'formik';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { CheckboxInputItem } from 'client/ui/form/input/CheckboxInputItem';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import {
  userCreateValidator,
  userEditValidator,
  IUserCreateDto
} from 'common/dto/UserDto';
import { PageCard } from 'client/ui/layout/PageCard';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { useThemeConfig } from 'client/components/config/ClientConfigModule';
import { LoginLabelText } from 'client/ui/label/LoginLabelText';

interface Params {
  id: string;
}

export interface IUserEditProps extends RouteComponentProps<Params> {}

export function UserEdit(props: IUserEditProps) {
  // Otteniamo l'ID dell'utente (o `create` per un nuovo utente)
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const theme = useThemeConfig();

  const { response, error, loading, refetch } = useApiQuery(UserApi.findUser, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  // Validazione
  const validator = id == null ? userCreateValidator : userEditValidator;

  // Modifica
  const create = useApiMutation(UserApi.createUser, {
    invalidates: [UserApi.findUser]
  });
  const update = useApiMutation(UserApi.updateUser, {
    invalidates: [UserApi.findUser]
  });

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare l'Utente"
        description={error.message}
      />
    );

  const userName = response?.data.name;

  // if (id && !response?.data) return <LoadingPage />;

  return (
    <PageLayout hasMenu hasScrollable excludeCard>
      <Formik<IUserCreateDto>
        initialValues={
          response && response.data
            ? response.data
            : ({ email: '', name: '' } as any)
        }
        enableReinitialize
        validate={schemaToValidator(validator)}
        onSubmit={async (rawValues, helpers) => {
          try {
            const values = await validator.validate(rawValues);

            if (id) {
              await update({
                data: { id: id!, input: values }
              });
            } else {
              const result = await create({
                data: { input: { ...values } as any }
              });
              props.history.push(`/app/admin/users/edit/${result.data!.id}`);
            }
            refetch();
            message.success('Utente aggiornato con successo. ');
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante l'aggiornamento dell'utente."
            );
          }
        }}
      >
        {({ dirty, submitForm, isSubmitting }) => (
          <PageCard
            hasScrollable
            loading={loading || isSubmitting}
            title={
              <CustomCardTitle
                icon="user"
                titleLabel={id ? 'Modifica Utente:' : 'Utente:'}
                title={id ? userName : 'Nuovo Utente'}
              />
            }
            extra={
              <CustomCardExtra
                right={
                  <>
                    {id && (
                      <Link to={`/app/admin/users/${id}`}>
                        <Button disabled={loading || isSubmitting}>
                          Vai al dettaglio
                        </Button>
                      </Link>
                    )}
                    <Button
                      htmlType="submit"
                      onClick={submitForm}
                      disabled={loading || isSubmitting}
                      type="primary"
                    >
                      Salva
                    </Button>
                  </>
                }
              />
            }
          >
            <Scrollable spacing="inside">
              <FormikForm layout="vertical" autoComplete="off">
                <Row gutter={16}>
                  <Col span={12}>
                    <TextInputItem name="login" label={<LoginLabelText />} />
                    <TextInputItem
                      name="name"
                      label="Nome"
                      placeholder="Nome"
                    />
                    <TextInputItem
                      name="serial"
                      label={theme.labels.userSerial ?? 'Identificativo IAM'}
                      itemProps={{
                        extra: theme.labels.userSerialHelp
                      }}
                    />
                    <TextInputItem
                      name="email"
                      label="E-Mail"
                      placeholder="Indirizzo e-mail utente"
                    />
                  </Col>
                  <Col span={12}>
                    <CheckboxInputItem
                      name="enableAccessKey"
                      title="Abilita login con Access Key"
                      itemProps={{
                        extra:
                          'Disabilitare questa opzione per forzare il login integrato di Windows'
                      }}
                    />
                    <TextInputItem
                      name="accessKey"
                      type={'pa' + 'ssword'}
                      label="Access Key"
                      autoComplete="new-password"
                      itemProps={{
                        dependsOnChecked: 'enableAccessKey'
                      }}
                    />
                  </Col>
                </Row>
              </FormikForm>
            </Scrollable>
          </PageCard>
        )}
      </Formik>
    </PageLayout>
  );
}
