import * as React from 'react';
import clx from 'classnames';
import { Row, Col, Select, Button } from 'antd';
import { FieldArray, useField } from 'formik';
import {
  DiscardPolicyRule,
  getDiscardPolicyRuleField
} from 'common/schema/DiscardPolicyRule';
import { DiscardPolicyRuleOperatorInput } from './DiscardPolicyRuleOperatorInput';
import { DiscardPolicyRuleValueInput } from './DiscardPolicyRuleValueInput';
import './DiscardPolicyRuleInput.scss';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { DiscardPolicyRuleFieldInput } from './DiscardPolicyRuleFieldInput';
import { DiscardRuleLogic } from 'common/logic/DiscardRuleLogic';

/**
 * Singola riga per l'input di una regola di una policy di scarto.
 */
export function DiscardPolicyRuleInput() {
  const [field, ,] = useField<DiscardPolicyRule[]>('rules');

  const user = useCurrentUser();

  // Quando cambia il `target`, resettiamo le regole di selezione.
  useFormikFieldChange(
    formik => {
      const defaultField = DiscardRuleLogic.getDefault(user);
      formik.setFieldValue('rules', defaultField);
    },
    ['target']
  );

  return (
    <FieldArray
      name="rules"
      render={helpers => (
        <>
          {field.value.map((rule: DiscardPolicyRule, index: number) => {
            const ruleField = getDiscardPolicyRuleField(rule.field);

            const operator = rule.operator;
            const disabled = DiscardRuleLogic.isFieldDisabled(user, rule);

            return (
              <Row
                key={index}
                type="flex"
                justify="center"
                gutter={4}
                align="top"
                className="discard-policy-rule-imput-row"
              >
                <Col className="col-rule-metadata">
                  <DiscardPolicyRuleFieldInput
                    index={index}
                    rule={rule}
                    disabled={disabled}
                  />
                </Col>
                <Col className="col-rule-operator">
                  <DiscardPolicyRuleOperatorInput
                    rule={rule}
                    index={index}
                    ruleField={ruleField}
                    disabled={disabled}
                  />
                </Col>
                <Col className="col-rule-value">
                  <DiscardPolicyRuleValueInput
                    name={`rules.${index}.value`}
                    index={index}
                    operator={operator}
                    ruleField={ruleField}
                  />
                </Col>
                <Col
                  className={clx('col-rule-action', 'rule-delete', {
                    'first-row': index === 0
                  })}
                >
                  <Button
                    shape="circle"
                    icon="minus"
                    disabled={
                      field.value.length === 1 ||
                      DiscardRuleLogic.isFieldDisabled(user, rule)
                    }
                    onClick={e => helpers.remove(index)}
                  />
                </Col>
                <Col
                  className={clx('col-rule-action', 'rule-add', {
                    'first-row': index === 0
                  })}
                >
                  <Button
                    shape="circle"
                    icon="plus"
                    onClick={e => helpers.insert(index + 1, {})}
                  />
                </Col>
              </Row>
            );
          })}
        </>
      )}
    />
  );
}
