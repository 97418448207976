import { IActor } from './Actor';

export enum VisibilityActorType {
  User = 'User',
  Role = 'Role'
}

export enum VisibilityPermission {
  Read = 'Read',
  ReadWrite = 'ReadWrite'
}

/**
 * Metadato di singola Visibilità.
 * Descrive i permessi (precedenti all'archiviazione di deposito) di un singolo
 * documento o fascicolo.
 *
 * TODO: Questo potrebbe essere diverso... vedi IVisibilityMaybe
 * perchè permissions deve legato al singolo utente
 */
export interface IVisibility {
  permissions?: string;

  // Speciali CC
  actor?: string;
  type?: VisibilityActorType;
  reason?: string;
  createdAt?: Date;
  expiresAt?: Date;
  notes?: string;

  // Speciali MIUR
  aclName?: string;
  actors: IActor[];
}

export interface IVisibilityMaybe {
  actor?: string;
  // VisibilityActorType: -> probabilmente con modifica per intergrare valori Miur
  type?: VisibilityActorType;
  permissions?: string;

  // Speciali CC
  reason?: string;
  createdAt?: Date;
  expiresAt?: Date;
  notes?: string;

  // Speciali MIUR
  aclName?: string;
}
