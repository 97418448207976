import * as React from 'react';
import { Tag } from 'antd';
import './AttachmentTypeLabel.scss';
import { isAttachmentAvailable } from './isAttachmentAvailable';
import { AttachmentKind, IAttachment } from 'common/schema/Attachment';
import { AttachmentPreview } from './AttachmentPreview';

export interface IAttachmentTypeLabelProps {
  attachment?: AttachmentPreview | null;
}

/**
 * Label che indica il tipo di Allegato visualizzato nell'iFrame.
 *
 */
export function AttachmentTypeLabel(props: IAttachmentTypeLabelProps) {
  const { attachment } = props;

  // Se non c'è l'allegato o non è disponibile un file non mostriamo la label
  if (!attachment || !isAttachmentAvailable(attachment.item)) {
    return null;
  }

  // Per capire se è un allegato o un documento principale facciamo riferimento,
  // anche per le versioni, all'allegato padre.
  const reference =
    attachment.type === 'attachment' ? attachment.item : attachment.attachment;

  return reference.kind === AttachmentKind.Principal ? (
    <Tag className="attachment-type-label principal" color="#f50">
      Documento Principale
    </Tag>
  ) : (
    <Tag className="attachment-type-label" color="#999">
      Allegato
    </Tag>
  );
}
