import * as React from 'react';
import { Row, Col, Button } from 'antd';
import { IGrant } from 'common/schema/Grant';
import { Link } from 'react-router-dom';

export interface GrantExtraProps {
  grant?: IGrant;
  edit?: boolean;
  submit?: () => Promise<any>;
  // isSubmitting?: boolean;
  loading?: boolean;
}

/**
 * Intestazione della Pagina Richeste
 * contiene i metadati della richiesta di consultazione
 * Comune sia per GrantEditPage che GrantViewPage
 */
export function GrantExtra(props: GrantExtraProps) {
  return (
    <Row type="flex" align="middle">
      {props.edit && (
        <Col>
          <Link to={`/app/grants/edit/${props.grant?.id}`}>
            <Button type="primary" disabled={props.loading}>
              Modifica
            </Button>
          </Link>
        </Col>
      )}
      {props.submit && (
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            onClick={props.submit}
            disabled={props.loading}
          >
            Salva
          </Button>
        </Col>
      )}
    </Row>
  );
}
