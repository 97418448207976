import { apiClient } from 'client/core/network/apiClient';
import { IDocument } from 'common/schema/Document';
import { IDocumentsQueryDto } from 'common/dto/DocumentsQueryDto';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';

export const DocumentApi = {
  findDocument: (options: { id: string }) =>
    apiClient.get<IDocument>(`/documents/${options.id}`),
  findUidDocument: (options: { uid: string; areaUid: string }) =>
    apiClient.get<IDocument>(
      `/documents/uid/${options.areaUid}/${options.uid}`
    ),
  findDocuments: (options: { params: IDocumentsQueryDto }) =>
    apiClient.get<PaginatedResult<IDocument>>('/documents', {
      params: options.params
    })
};
