import { apiClient } from 'client/core/network/apiClient';
import { IDossier } from 'common/schema/Dossier';
import { IDossiersQueryDto } from 'common/dto/DossiersQueryDto';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';

export const DossierApi = {
  findDossier: (options: { id: string }) =>
    apiClient.get<IDossier>(`/dossiers/${options.id}`),
  findUidDossier: (options: { areaUid: string; uid: string }) =>
    apiClient.get<IDossier>(`/dossiers/uid/${options.areaUid}/${options.uid}`),
  findDossiers: (options: { params: IDossiersQueryDto }) =>
    apiClient.get<PaginatedResult<IDossier>>('/dossiers', {
      params: options.params
    })
};
