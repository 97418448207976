import { apiClient } from 'client/core/network/apiClient';
import { IGrant } from 'common/schema/Grant';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IGrantsQueryDto } from 'common/dto/GrantsQueryDto';
import { IGrantDto, IGrantCreateDto } from 'common/dto/GrantDto';
import { IUserGrantsQueryDto } from 'common/dto/UserGrantsQueryDto';

export const GrantApi = {
  findGrants: (opts: { query: IGrantsQueryDto }) =>
    apiClient.get<PaginatedResult<IGrant>>(`/grants`, {
      params: opts.query
    }),
  findGrant: (opts: { id: number }) =>
    apiClient.get<IGrant>(`/grants/${opts.id}`),
  createGrant: (opts: { input: IGrantCreateDto }) =>
    apiClient.post<IGrant>(`/grants`, opts.input),
  updateGrant: (opts: { id: number; input: IGrantDto }) =>
    apiClient.put<IGrant>(`/grants/${opts.id}`, opts.input),
  deleteGrant: (opts: { id: number }) => apiClient.delete(`/grants/${opts.id}`),

  // Utenti
  listOwned: (opts: { userId: number; query: IUserGrantsQueryDto }) =>
    apiClient.get<PaginatedResult<IGrant>>(`/users/${opts.userId}/grants`, {
      params: opts.query
    }),
  findOwned: (opts: { userId: number; id: number }) =>
    apiClient.get<IGrant>(`/users/${opts.userId}/grants/${opts.id}`)
};
