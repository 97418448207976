import React from 'react';
import { Popconfirm, Button, Icon } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { ButtonProps } from 'antd/lib/button';

export interface ButtonConfirmProps extends PopconfirmProps {
  loading?: boolean;
  size?: 'small' | 'default' | 'large';
  icon?: ButtonProps['icon'];
  type?: ButtonProps['type'];
  children?: React.ReactNode;
}

export function ButtonConfirm(props: ButtonConfirmProps) {
  const {
    placement,
    loading,
    size,
    type,
    children,
    icon,
    ...otherProps
  } = props;

  return (
    <Popconfirm
      placement={placement ? placement : 'topRight'}
      {...otherProps}
      okButtonProps={{ type: 'danger' }}
    >
      <Button type={type} icon={icon} size={size ?? 'small'} loading={loading}>
        {children}
      </Button>
    </Popconfirm>
  );
}
