import { useField } from 'formik';

/**
 * Hook per gestire una select, con un tipo dinamico, ad esempio `single` o
 * `interval` per `DateSearch`.
 */
export function useTypeField(name: string, defaultValue: any) {
  const fieldName = `${name}__type`;
  const [field, meta, helper] = useField(fieldName);
  return [field.value ?? defaultValue, helper.setValue];
}
