import React from 'react';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { AuthDomainApi } from '../../auth-domains/AuthDomainApi';
import { displayAuthDomain } from '../../auth-domains/AuthDomainDisplay';
import { IAuthDomain } from 'common/schema/AuthDomain';
import { useFormikContext } from 'formik';
import { FormItem } from 'client/ui/form/FormItem';

export function DomainSelectInput() {
  const formik = useFormikContext();
  return (
    <FormItem name="domainId" label="Organizzazione / A.O.O.">
      <AsyncSelectInput
        style={{ width: '100%' }}
        name="domainId"
        query={{
          apiFn: AuthDomainApi.findDomains,
          options: { data: {} }
        }}
        optionTransform={domain => ({
          value: domain.id,
          label: displayAuthDomain(domain)
        })}
        afterChange={(data: IAuthDomain[] | undefined, value: number) => {
          formik.setFieldValue(
            'domain',
            data?.find(domain => domain.id === value) ?? null
          );
        }}
      />
    </FormItem>
  );
}
