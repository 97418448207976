import * as React from 'react';
import clx from 'classnames';
import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import './Table.scss';

export interface ITableProps<T> extends TableProps<T> {
  label?: React.ReactNode;
  containerClassName?: string;
  bottomLeft?: React.ReactNode;
}

/**
 * Tabella già stilizzata per essere utilizzata nell'archivio di deposito.
 */
export function Table<T>(props: ITableProps<T>) {
  const {
    label,
    containerClassName,
    pagination,
    size,
    bottomLeft,
    ...otherProps
  } = props;
  return (
    <div className={clx('table-container', containerClassName)}>
      {label && <div className="table-title">{label}</div>}
      <AntTable<T>
        {...otherProps}
        size={size || 'middle'}
        pagination={pagination || false}
      />
      {bottomLeft && (
        <div
          className={clx('table-bottom-left', {
            'has-pagination': pagination
          })}
        >
          {bottomLeft}
        </div>
      )}
    </div>
  );
}
