export enum CorrespondingType {
  Sender = 'M', // Mittente
  OriginalSender = 'MO', // Mittente Originario
  Recipient = 'D', // Destinatario
  Copy = 'C', // Copia Conoscenza
  Forward = 'I', // Inoltro / Interoperabilità?
  Other = 'F' // Altro
}

export interface ICorresponding {
  type: CorrespondingType;
  desc?: string;

  // Speciali MIUR
  shippingAt?: Date;
  shippingMethod?: string;
  receivedAt?: Date;
}
