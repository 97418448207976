import * as React from 'react';
import { useFieldSchema } from './useFieldSchema';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';

export interface ISchemaSectionProps {
  /**
   * Specifica se è per il documento o per il fascicolo
   */
  ns: SchemaConfigNamespace;
  /**
   * Campo che dev'essere abilitato per mostrare il contenuto
   */
  field: string;
  /**
   * Contenuto di default se disabilitato
   */
  whenDisabled?: React.ReactNode;
  /**
   * Contenuto se abilitato
   */
  children: React.ReactNode;
}

/**
 * Permette di visualizzare un contenuto relativo a un metadato solo se è
 * abilitato dal valore specificato nel file di configurazione.
 */
export function SchemaSection(props: ISchemaSectionProps) {
  const { field } = props;
  const fieldSchema = useFieldSchema(props.ns, field);

  if (!fieldSchema?.enable) {
    return props.whenDisabled ? <>{props.whenDisabled}</> : null;
  }

  return <>{props.children}</>;
}
