export enum GrantRequestType {
  Dossier = 'dossier',
  Document = 'document'
}

export interface IGrantRequest {
  id: number;
  type: GrantRequestType;
  grantId: number;
  found?: boolean;

  areaUid: string;
  register?: string;

  // Documento
  registerYear?: number;
  registerNumber?: number;

  // Fascicolo
  code?: string;
}
