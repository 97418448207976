import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    /**
     * Effettua l'analisi di una categoria per ottenere il codice.
     */
    parseCategory(): StringSchema;
  }
}

yup.addMethod(yup.string, 'parseCategory', function () {
  return this.transform(function (value: string, originalValue) {
    if (!this.isType(value) || value == null) return value;

    value = value.trim();

    const spaceIdx = value.indexOf(' ');
    if (spaceIdx < 0) return value;

    const code = value.substring(0, spaceIdx) as string;
    if (code.endsWith('.')) return code.substr(0, code.length - 1);
    return code;
  });
});
