import * as React from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { Icon, message } from 'antd';
import { resolveApiPath } from 'client/core/appConfig';
import { IDiscard } from 'common/schema/Discard';
import { DiscardApi } from '../DiscardApi';
import { useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';

export interface IDiscardAttachmentUploadInputProps {
  discard: IDiscard;
}

function discardToFilesList(discard: IDiscard) {
  return discard.attachmentPath
    ? [
        {
          uid: discard.attachmentPath,
          url: resolveApiPath(`/discards/${discard.id}/attachment`),
          size: 0,
          type: 'any',
          name: discard.attachmentFileName!,
          fileName: discard.attachmentFileName!
        }
      ]
    : [];
}

export function DiscardAttachmentUploadInput(
  props: IDiscardAttachmentUploadInputProps
) {
  const { discard } = props;
  const [files, setFiles] = useState<UploadFile<any>[]>(
    discardToFilesList(discard)
  );

  return (
    <Dragger
      fileList={files}
      onRemove={async file => {
        const nextDiscard = await DiscardApi.removeAttachment({
          id: discard.id
        });
        setFiles(discardToFilesList(nextDiscard.data));
      }}
      showUploadList={{
        showDownloadIcon: false,
        showPreviewIcon: false,
        showRemoveIcon: true
      }}
      // action={resolveApiPath(`/discards/${discard.id}/attachment`)}
      customRequest={options => {
        const { onSuccess, onError, file, action } = options;
        const formData = new FormData();
        formData.append('attachment', file);
        DiscardApi.uploadAttachment({ id: discard.id, data: formData })
          .then(res => {
            onSuccess(res.data, file);
            setFiles(discardToFilesList(res.data));
          })
          .catch(err => {
            onError(err);
          });
      }}
      onChange={info => {
        setFiles([...info.fileList]);
        const { status } = info.file;
        if (status === 'done') {
          message.success('Allegato caricato correttamente');
        } else if (status === 'error') {
          message.error(
            "Si è verificato un errore durante l'upload dell'allegato, si prega di riprovare."
          );
        }
      }}
    >
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-hint">
        Fai click o trascina in quest'area per allegare un file allo Scarto
      </p>
    </Dragger>
  );
}
