import * as React from 'react';
import './PageCard.scss';
import clx from 'classnames';
import { Card, Row, Col } from 'antd';
import { CardProps } from 'antd/lib/card';
import { LoaderSpin } from '../loader/LoaderSpin';

export interface PageCardProps extends CardProps {
  fullTable?: boolean;
  hasFooterBar?: boolean;
  hasScrollable?: boolean;
}

/**
 * Card stilizzata come contenitore di pagina
 */
export function PageCard(props: PageCardProps) {
  const {
    hasFooterBar,
    fullTable,
    hasScrollable,
    loading,
    ...othersProps
  } = props;

  // <Spin /> considera undefined come true, quindi
  // controlliamo la variabile prima di passarla al componente
  const isLoading = loading ? true : false;

  return (
    <Card
      size="small"
      {...othersProps}
      className={clx('page-card', props.className, {
        'full-table': props.fullTable,
        'has-scrollable': props.hasScrollable
      })}
      bordered={false}
    >
      <Row type="flex" className="full-height">
        <Col span={24}>
          <LoaderSpin
            spinning={isLoading}
            hasScroll={hasScrollable}
            transparent
          >
            {props.children}
          </LoaderSpin>
        </Col>
      </Row>
    </Card>
  );
}
