import * as React from 'react';
import { Input } from 'antd';
import { TextInput } from 'client/ui/form/input/TextInput';
import { FormItem } from 'client/ui/form/FormItem';

export interface IRegisterYearSearchProps {}

export function RegisterYearSearch(props: IRegisterYearSearchProps) {
  return (
    <FormItem name="registerYear" label="Anno di Protocollo">
      <TextInput
        name="registerYear"
        placeholder="Cerca Anno di Protocollo..."
      />
    </FormItem>
  );
}
