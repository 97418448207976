import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    /**
     * Richiede che la stringa, una volta rimossi i tag html, non sia vuota.
     */
    notEmptyHtml(): StringSchema;
  }
}

yup.addMethod(yup.string, 'notEmptyHtml', function (arrayRef) {
  return this.test({
    message: 'Il campo ${path} è un campo richiesto.',
    name: 'notEmptyHtml',
    exclusive: true,
    test(value) {
      if (!value || typeof value !== 'string') return false;
      return value.replace(/<[^>]+>/g, '').trim() !== '';
    }
  });
});
