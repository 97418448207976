import * as React from 'react';
import { SpecificMetadataView } from './SpecificMetadataView';
import { IMetadata } from 'common/schema/Metadata';

export interface ISpecificMetadatasViewProps {
  metadata: IMetadata[];
}

export function SpecificMetadatasView(props: ISpecificMetadatasViewProps) {
  const { metadata } = props;
  return (
    <div className="specific-metadata--container">
      {metadata.map((item, key) => (
        <SpecificMetadataView metadata={item} key={key} />
      ))}
    </div>
  );
}
