import * as React from 'react';
import { Icon, Row, Tooltip } from 'antd';
import clx from 'classnames';
import './DossierHeader.scss';
import { IDossier } from 'common/schema/Dossier';
import { SchemaMetadata } from 'client/components/metadata/schema/SchemaMetadata';
import { useHeaderSchema } from 'client/components/metadata/schema/useHeaderSchema';
import { DossierLogic } from 'common/logic/DossierLogic';

export interface DossierHeaderProps {
  dossier: IDossier;
}

/**
 * Intestazione del fascicolo.
 */

export function DossierHeader(props: DossierHeaderProps) {
  const { dossier } = props;

  const header = useHeaderSchema('dossier');

  if (!dossier) return null;

  const isDossierComplete = DossierLogic.isDossierComplete(dossier);

  return (
    <Row type="flex" align="middle" className="dossier-header metadata-inline">
      <div className={clx('dossier-type', { incomplete: !isDossierComplete })}>
        <Icon type="folder-open" theme="filled" />
        {!isDossierComplete && (
          <Tooltip
            placement="right"
            title={
              'Attenzione, Fascicolo parziale. Alcuni documenti di questo fascicolo non sono stati versati.'
            }
          >
            <Icon type="info-circle" theme="filled" />
          </Tooltip>
        )}
      </div>
      <Row type="flex" className="scrollable-header">
        {header?.map((field, key) => (
          <SchemaMetadata
            key={key}
            ns="dossier"
            source={dossier}
            field={field.name}
            label="field.name"
          />
        ))}
      </Row>
    </Row>
  );
}
