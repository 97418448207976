import * as React from 'react';
import {
  AsyncSelectInput,
  AsyncSelectInputProps
} from 'client/ui/form/input/AsyncSelectInput';
import { FormikValues, useFormikContext } from 'formik';
import { UserApi } from '../UserApi';
import { SelectProps } from 'antd/lib/select';
import { IUser } from 'common/schema/User';
import { IGrant } from 'common/schema/Grant';

export interface IUserSelectInputProps extends SelectProps {
  name: string;
  user: IUser | undefined;
}

export function UserSelectInput(props: IUserSelectInputProps) {
  const { user, name, ...otherProps } = props;

  return (
    <AsyncSelectInput
      current={user}
      name={name}
      query={{
        apiFn: UserApi.findUsers,
        options: (formik, search) => ({
          data: {
            query: {
              name: search,
              page: 1,
              pageSize: 30
            }
          }
        })
      }}
      allowClear
      showSearch
      refreshOnSearch
      responseTransform={data => data.items}
      responseMeta={data => ({
        shown: data.items.length,
        total: data.meta.total
      })}
      optionTransform={(user: IUser) => ({
        value: user.id,
        label: `${user.name} - ${user.login}`
      })}
      {...otherProps}
    />
  );
}
