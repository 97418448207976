import * as React from 'react';
import clx from 'classnames';
import { Icon } from 'antd';
import { attachmentExtension } from './attachmentExtension';
import { AttachmentKind } from 'common/schema/Attachment';

export interface IAttachmentIconProps {
  fileName?: string;
  kind?: AttachmentKind;
}

function typeToIcon(fileName: string | undefined) {
  const extension = attachmentExtension(fileName);
  if (!extension) return 'file-exclamation';

  switch (extension) {
    case 'pdf':
      return 'file-pdf';

    case 'ppt':
    case 'pptx':
      return 'file-ppt';
    case 'xls':
    case 'xlsx':
      return 'file-excel';

    case 'doc':
    case 'docx':
      return 'file-word';

    case 'jpg':
    case 'png':
    case 'gif':
      return 'file-image';

    default:
      return 'file';
  }
}
/**
 * Mostra l'icona di un allegato.
 */
export function AttachmentIcon(props: IAttachmentIconProps) {
  return (
    <Icon
      type={typeToIcon(props.fileName)}
      className={clx('attachment-icon', {
        principal: props.kind == AttachmentKind.Principal
      })}
    />
  );
}
