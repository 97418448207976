import * as React from 'react';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { TableDepositsList } from '../table/TableDepositsList';

export interface DepositsListProps {}

/**
 * Pagina di elenco dei versamenti
 */
export function DepositsList(props: DepositsListProps) {
  return (
    <PageLayout
      hasScrollable
      hasMenu
      fullTable
      title={<CustomCardTitle image="deposit" title="Elenco Versamenti" />}
    >
      <TableDepositsList />
    </PageLayout>
  );
}
