import * as React from 'react';
import clx from 'classnames';
import { Col, Typography } from 'antd';
const { Text } = Typography;
import { MetadataNote } from './MetadataNote';
import { IUserNote } from 'common/schema/UserNote';

export interface MetadataNotesProps {
  label?: string | React.ReactNode;
  notes: Maybe<IUserNote[]>;
  className?: string;
  removeColon?: boolean;
}

export function MetadataNotes(props: MetadataNotesProps) {
  return (
    <Col className={clx('metadata-notes', props.className)}>
      {props.label && (
        <Text strong className="label">
          {props.label}
          {props.removeColon ? '' : ':'}
        </Text>
      )}
      {props.notes &&
        props.notes.map((note, key) => <MetadataNote key={key} note={note} />)}
    </Col>
  );
}
