import * as React from 'react';
import { Icon } from 'antd';
import { SpinProps } from 'antd/lib/spin/index';

export const tableLoaderSpin = (loading: boolean) => {
  const spin: SpinProps = {
    size: 'large',
    indicator: <Icon type="loading-3-quarters" spin />,
    spinning: loading
  };
  return spin;
};
