import * as React from 'react';
import { Divider } from 'antd';
import { MetadataText } from 'client/ui/metadata/MetadataText';
import { ICorresponding, CorrespondingType } from 'common/schema/Corresponding';
import { SchemaMetadata } from '../schema/SchemaMetadata';

export interface ICorrespondingsViewProps {
  correspondings: ICorresponding[];
}

function correspondingTypeLabel(type: CorrespondingType) {
  switch (type) {
    case CorrespondingType.Sender:
      return 'Mittente';
    case CorrespondingType.OriginalSender:
      return 'Mittente Originario';
    case CorrespondingType.Recipient:
      return 'Destinatario';
    case CorrespondingType.Copy:
      return 'Conoscenza';
    default:
      return '';
  }
}

/**
 * Elenco dei corrispondenti.
 */
export function CorrespondingsView(props: ICorrespondingsViewProps) {
  return (
    <>
      {props.correspondings.map((corresponding, key) => (
        <>
          <MetadataText
            label={correspondingTypeLabel(corresponding.type)}
            text={corresponding.desc}
            key={key}
          />
          <SchemaMetadata
            ns="document"
            field="correspondings.shippingAt"
            label="Data spedizione"
            value={corresponding.shippingAt}
            type="date"
          />
          <SchemaMetadata
            ns="document"
            field="correspondings.shippingMethod"
            label="Mezzo di spedizione"
            value={corresponding.shippingMethod}
          />
          <SchemaMetadata
            ns="document"
            field="correspondings.receivedAt"
            label="Data ricezione"
            value={corresponding.receivedAt}
            type="date"
          />
          {key + 1 != props.correspondings.length && <Divider />}
        </>
      ))}
    </>
  );
}
