import * as React from 'react';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { RouteComponentProps } from 'react-router-dom';
import { DocumentSearch } from './DocumentSearch';

export interface IDocumentSearchPageProps extends RouteComponentProps {}

/**
 * Pagina di ricerca per i documenti
 *
 * TODO: Al momento la ricerca non funziona per i campi normalizzati.
 * Dovremmo introdurre dei "normalizer" per gestire i campi
 * nullabili.
 */
export function DocumentSearchPage(props: IDocumentSearchPageProps) {
  return (
    <PageLayout
      hasScrollable
      hasMenu
      title={<CustomCardTitle icon="file" title="Ricerca Documenti" />}
    >
      <DocumentSearch />
    </PageLayout>
  );
}
