import * as React from 'react';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { IDocumentStandardMetadata } from 'common/schema/DocumentStandardMetadata';
import { SchemaSection } from '../schema/SchemaSection';
import { IDossierStandardMetadata } from 'common/schema/DossierStandardMetadata';
import { PhasesView } from './PhasesView';
import { SubjectsView } from './SubjectsView';

export interface DossierStandardMetadataViewProps {
  metadata?: IDossierStandardMetadata;
}

/**
 * Dati dettaglio metadati del protocollo (linee guida AgID)
 */
export function DossierStandardMetadataView(
  props: DossierStandardMetadataViewProps
) {
  const { metadata } = props;
  if (!metadata) return null;

  return (
    <SchemaSection ns="dossier" field="standardMetadata">
      <SchemaMetadata
        ns="dossier"
        field="standardMetadata.mainType"
        label="Tipo principale"
        value={metadata.mainType}
      />
      <SchemaMetadata
        ns="dossier"
        field="standardMetadata.notes"
        label="Note"
        value={metadata.notes}
      />
      <SchemaMetadata
        ns="dossier"
        field="standardMetadata.procedureName"
        label="Nome Procedimento"
        value={metadata.procedureName}
      />
      <SchemaMetadata
        ns="dossier"
        field="standardMetadata.procedureTopic"
        label="Materia/Argomento"
        value={metadata.procedureTopic}
      />
      <SchemaMetadata
        ns="dossier"
        field="standardMetadata.procedureOfficeStructure"
        label="Ufficio/Struttura"
        value={metadata.procedureOfficeStructure}
      />
      <SchemaMetadata
        ns="dossier"
        field="standardMetadata.procedureOfficeResponsible"
        label="Ufficio Responsabile"
        value={metadata.procedureOfficeResponsible}
      />
      <SchemaMetadata
        ns="dossier"
        field="standardMetadata.procedureResponsible"
        label="Responsabile Unico"
        value={metadata.procedureResponsible}
      />
      <SchemaMetadata
        ns="dossier"
        field="standardMetadata.applicantType"
        label="Tipo Richiedente"
        value={metadata.applicantType}
      />
      <PhasesView phases={metadata.phases} />
      <SubjectsView subjects={metadata.subjects} />
    </SchemaSection>
  );
}
