import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Tabs, Empty } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { DocumentHeader } from './sections/DocumentHeader';
import { DocumentProfileTab } from './tabs/DocumentProfileTab';
import { DocumentCategoryTab } from './tabs/DocumentCategoryTab';
import { DocumentEventTab } from './tabs/DocumentEventTab';
import { DocumentVisibilityTab } from './tabs/DocumentVisibilityTab';
import { PageTabsContainer } from 'client/ui/layout/PageTabsContainer';
import { DocumentTransmissionTab } from './tabs/DocumentTransmissionTab';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DocumentApi } from './DocumentApi';
import { DocumentAttachmentTab } from './tabs/DocumentAttachmentTab';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { useFieldSchema } from '../metadata/schema/useFieldSchema';
import { SchemaLabel } from '../metadata/schema/SchemaLabel';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { AttachmentKind, IAttachment } from 'common/schema/Attachment';
import { isAttachmentAvailable } from './attachment/isAttachmentAvailable';
import {
  AttachmentPreview,
  previewAttachment
} from './attachment/AttachmentPreview';
const { TabPane } = Tabs;

type Params = {
  areaUid: string;
  uid: string;
};

export interface DocumentPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di visualizzazione del singolo documento. Contiene i dati generali
 * mostrati come intestazione e la navigazione fra i tab di dettaglio come
 * "Profilo", ecc.
 *
 * Qui effettuiamo la query alle API REST per ottenere i dettagli del documento.
 */
export function DocumentPage(props: DocumentPageProps) {
  const [attachment, setAttachment] = useState(
    null as AttachmentPreview | null
  );

  const { uid, areaUid } = props.match.params;

  const { response, error, loading } = useApiQuery(
    DocumentApi.findUidDocument,
    {
      data: { uid, areaUid },
      onLoad: response => {
        // Cerchiamo il documento principale
        const primary = (response.data.attachments ?? []).find(
          a => a.kind === AttachmentKind.Principal
        );
        // Se il documento principale è disponibile lo impostiamo
        if (isAttachmentAvailable(primary)) {
          setAttachment(previewAttachment(primary));
        }
      }
    }
  );

  const showDocumentVisibilities = useFieldSchema('document', 'visibilities');

  if (error)
    return <AlertPage type="error" message={getNetworkErrorMessage(error)} />;
  if (loading || !response?.data) return <LoadingPage />;

  const document = response.data;

  return (
    <PageLayout hasMenu hasScrollable excludeCard>
      <PageTabsContainer header={<DocumentHeader document={document} />}>
        {/* Tab di dettaglio profilo del documento */}
        <TabPane
          tab={<SchemaLabel ns="document" field="profile" label="Profilo" />}
          key="profile"
          className="page-tabs-panel"
        >
          <DocumentProfileTab document={document} attachment={attachment} />
        </TabPane>

        {/* Tab di dettaglio classificazione del documento */}
        <TabPane
          tab={
            <SchemaLabel
              ns="document"
              field="classifications"
              label="Classifica"
            />
          }
          key="classifications"
          className="page-tabs-panel"
        >
          <DocumentCategoryTab
            document={document}
            ns="document"
            attachment={attachment}
          />
        </TabPane>

        {/* Allegati */}
        <TabPane
          tab={
            <SchemaLabel ns="document" field="attachments" label="Allegati" />
          }
          key="attachments"
          className="page-tabs-panel"
        >
          <DocumentAttachmentTab
            document={document}
            attachment={attachment}
            setAttachment={setAttachment}
          />
        </TabPane>

        {/* Tab di dettaglio delle trasmissioni del documento */}
        <TabPane
          tab={
            <SchemaLabel
              ns="document"
              field="transmissions"
              label="Trasmissioni"
            />
          }
          key="transmissions"
          className="page-tabs-panel"
        >
          <DocumentTransmissionTab document={document} />
        </TabPane>

        {/* Tab di dettagli visibilità del documento */}
        {showDocumentVisibilities && (
          <TabPane
            tab={
              <SchemaLabel
                ns="document"
                field="visibilities"
                label="Visibilità"
              />
            }
            key="visibilities"
            className="page-tabs-panel"
          >
            <DocumentVisibilityTab document={document} />
          </TabPane>
        )}

        {/* Tab di dettaglio eventi di modifica occorsi sul documento */}
        <TabPane
          tab={<SchemaLabel ns="document" field="events" label="Eventi" />}
          key="events"
          className="page-tabs-panel"
        >
          <DocumentEventTab document={document} />
        </TabPane>
      </PageTabsContainer>
    </PageLayout>
  );
}
