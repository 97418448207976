import * as React from 'react';
import { Col, Row } from 'antd';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { DatePickerInputItem } from 'client/ui/form/input/DatePickerInputItem';
import { FormItem } from 'client/ui/form/FormItem';
import { UserSelectInput } from 'client/components/admin/users/input/UserSelectInput';
import { GrantLogic } from 'common/logic/grant/GrantLogic';
import { IGrant } from 'common/schema/Grant';
import { GrantStatusTag } from '../detail/GrantStatusTag';
import { TextAreaInputItem } from 'client/ui/form/input/TextAreaInputItem';

export interface GrantInputProps {
  grant: IGrant | undefined;
}

export function GrantInput(props: GrantInputProps) {
  const stateOptions = GrantLogic.getTransitions(props.grant).map(status => ({
    value: status,
    label: <GrantStatusTag status={status} />
  }));

  return (
    <div className="grant-user-form">
      <SelectInputItem name="status" options={stateOptions} label="Stato:" />
      <FormItem name="userId" label="Concesso a:">
        <UserSelectInput
          name="userId"
          placeholder="Nome Utente"
          user={props.grant?.user}
        />
      </FormItem>
      <TextAreaInputItem
        name="description"
        label="Descrizione"
        placeholder="Aggiungi una descrizione al Permesso..."
        rows={3}
      />
      <Row gutter={12}>
        <Col span={12}>
          <DatePickerInputItem
            type="from"
            name="beginsAt"
            label="Decorrenza Permesso"
            placeholder="Decorrenza"
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={12}>
          <DatePickerInputItem
            type="to"
            name="expiresAt"
            label="Scadenza Permesso"
            placeholder="Scadenza"
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
    </div>
  );
}
