import * as React from 'react';
import clx from 'classnames';
import { Card, Row, Tooltip } from 'antd';
import { CardProps } from 'antd/lib/card';
import './CardDashboard.scss';

export interface CardDashboardProps extends CardProps {}

/**
 * Card Pesonalizzata per la dashboard
 */
export function CardDashboard(props: CardDashboardProps) {
  const { children, className, ...otherProps } = props;
  return (
    <Card
      size="small"
      className={clx('card-dashboard', className)}
      {...otherProps}
    >
      {children}
    </Card>
  );
}
