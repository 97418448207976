import * as React from 'react';
import { useState } from 'react';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { TransmissionsTable } from 'client/components/metadata/tabs/TransmissionsTable';
import { TransmissionsDetailsTable } from 'client/components/metadata/tabs/TransmissionDetailsTable';
import { IDossier } from 'common/schema/Dossier';
import { RowScroller } from 'client/ui/scroller/RowScroller';

export interface IDossierTransmissionTabProps {
  dossier: IDossier;
}

export function DossierTransmissionTab(props: IDossierTransmissionTabProps) {
  const { dossier } = props;
  const [selectedTransmission, setSelectedTransmission] = useState(
    null as number | null
  );

  if (!dossier) return null;

  return (
    <RowScroller>
      <ColScroller span={10} side="left" hasTableScroll>
        <TransmissionsTable
          ns="dossier"
          selectedTransmission={selectedTransmission}
          setSelectedTransmission={setSelectedTransmission}
          transmissions={dossier.transmissions}
        />
      </ColScroller>
      <ColScroller isCard span={14} side="right">
        <TransmissionsDetailsTable
          ns="dossier"
          selectedTransmission={selectedTransmission}
          transmissions={dossier.transmissions}
        />
      </ColScroller>
    </RowScroller>
  );
}
