import { combineReducers, Store } from 'redux';
import { AuthReducer } from 'client/components/auth/AuthModule';
import { SearchReducer } from 'client/components/search/SearchModule';
import { ClientConfigReducer } from 'client/components/config/ClientConfigModule';

/**
 * Reducer principale dell'applicazione, che contiene lo stato di ogni
 * reducer parziale.
 * Ogni nuovo `Module` deve essere aggiunto qui.
 */
export const RootReducer = combineReducers({
  auth: AuthReducer,
  search: SearchReducer,
  config: ClientConfigReducer
});

export type RootState = ReducerState<typeof RootReducer>;
export type RootStore = Store<RootState>;
