import * as React from 'react';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { IDocumentGrant } from 'common/schema/Grant';
import { TableScroll } from 'client/ui/table/TableScroll';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';

export interface TableGrantsDocumentListProps {
  documents: IDocumentGrant[];
  onDelete?: (documentId: number) => void;
  tableButton?: React.ReactNode;
  hideLink?: boolean;
  bottomSpace?: number;
}

/**
 * Tabella che mostra i documenti richiesti
 * con la CheckBox iniziale per concedere l'autorizzazione
 *
 * TODO: da implementare versione solo visualizzazione
 */
export function TableGrantsDocumentList(props: TableGrantsDocumentListProps) {
  const columns: ColumnProps<IDocumentGrant>[] = [
    {
      title: 'A.O.O.',
      dataIndex: 'document.areaName',
      key: 'document.areaName',
      width: 120,
      ellipsis: true
    },
    {
      title: 'Anno',
      dataIndex: 'document.registerYear',
      key: 'document.registerYear',
      width: 60,
      ellipsis: true
    },
    {
      title: 'Protocollo',
      dataIndex: 'document.registerNumber',
      key: 'document.registerNumber',
      width: 80,
      ellipsis: true
    },
    {
      title: 'Oggetto',
      dataIndex: 'document.subject',
      key: 'document.subject',
      ellipsis: true
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 80,
      render: (_, record) => (
        <div className="table-actions-column">
          {props.onDelete && (
            <ButtonDeleteConfirm
              iconOnlyButton
              title="Sei sicuro di voler eliminare questo Documento dalla lista?"
              onConfirm={e => {
                props.onDelete!(record.document.id);
              }}
            />
          )}
          {!props.hideLink && (
            <Link
              to={`/app/documents/${record.document.areaUid}/${record.document.uid}`}
            >
              <Button icon="eye" size="small" />
            </Link>
          )}
        </div>
      )
    }
  ];

  return (
    <TableScroll
      columns={columns}
      dataSource={props.documents}
      bottomSpace={props.bottomSpace}
      size="middle"
      bottomLeft={props.tableButton}
      pagination={false}
    />
  );
}
