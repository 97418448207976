import * as React from 'react';
import { MetadataCollapse } from '../fields/MetadataCollapse';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { IDocument } from 'common/schema/Document';
import { Divider } from 'antd';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { SchemaSection } from '../schema/SchemaSection';

export interface DocumentSubjectHistoryProps {
  document: IDocument;
}

/**
 * Oggetto del documento
 *
 * permette di visualizzare anche la storia delle modifiche
 */
export function DocumentSubjectHistory(props: DocumentSubjectHistoryProps) {
  const { document } = props;
  const historyLength = document.subjectHistory
    ? document.subjectHistory.length
    : 0;

  return (
    <SchemaSection ns="document" field="subject">
      <MetadataCollapse
        field="subject"
        ns="document"
        header={
          <SchemaMetadata
            ns="document"
            source={document}
            field="subject"
            label="Oggetto"
          />
        }
      >
        {historyLength > 0 &&
          document.subjectHistory &&
          document.subjectHistory.map((history, key) => (
            <div key={key}>
              <div className="metadata-inline">
                <SchemaMetadata
                  ns="document"
                  field="subjectHistory.author"
                  label="Autore modifica Oggetto:"
                  value={history.author}
                />
                <SchemaMetadata
                  ns="document"
                  field="subjectHistory.date"
                  label="Data:"
                  value={history.date}
                  type="date"
                />
              </div>
              <SchemaMetadata
                ns="document"
                field="subjectHistory.previous"
                label="Oggetto originario:"
                value={history.previous}
              />
              {key + 1 != historyLength && <Divider />}
            </div>
          ))}
      </MetadataCollapse>
    </SchemaSection>
  );
}
