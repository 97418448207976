import React from 'react';
import clx from 'classnames';
import './LabeledTextGroup.scss';

export interface LabeledTextGroupProps {
  inline?: boolean;
  children: React.ReactNode;
}

/**
 * Elemento Contenitore per una serie di elementi LabeledText
 * al momento permette solo la gestione inline degli elementi interni
 */
export function LabeledTextGroup(props: LabeledTextGroupProps) {
  return (
    <div
      className={clx('labeled-text-group', {
        'group-inline': props.inline
      })}
    >
      {props.children}
    </div>
  );
}
