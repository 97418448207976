import * as React from 'react';
import { Icon, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { DepositStateTag } from '../detail/DepositStateTag';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositApi } from '../DepositApi';
import { useState } from 'react';
import { ISODate } from 'client/ui/display/date/ISODate';
import { IDeposit } from 'common/schema/Deposit';
import { TableScroll } from 'client/ui/table/TableScroll';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { IDepositsQueryDto } from 'common/dto/DepositsQueryDto';
import { DepositStateOptions } from '../detail/DepositOptions';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

export interface TableDepositsListProps {}

/**
 * Tabella che mostra l'elenco dei versamenti
 *
 * TODO: Aggiungere alert per gli errori.
 */
export function TableDepositsList(props: TableDepositsListProps) {
  const [query, setQuery] = useState<IDepositsQueryDto>({
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    orderByDirection: ColumnSortDirections.descend
  });

  const { response, loading, error } = useApiQuery(DepositApi.list, {
    data: { query }
  });

  const columns: ColumnProps<IDeposit>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sorter: true
    },
    {
      title: 'Stato',
      dataIndex: 'state',
      key: 'state',
      width: 120,
      render: (_, record) => <DepositStateTag state={record.state} />,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: DepositStateOptions
      })
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 140,
      render: date => <ISODate date={date} />,
      sorter: true,
      ...createColumnFilter(FilterInputType.Date)
    },
    {
      title: 'Modifica',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 140,
      render: date => <ISODate date={date} />
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 120,
      render: (_, record) => (
        <Link to={`/app/deposits/single/${record.id}`}>
          <Button size="small">
            <Icon type="eye" /> <TableButtonDetailText />
          </Button>
        </Link>
      )
    }
  ];

  return (
    <TableScroll
      columns={columns}
      loading={tableLoaderSpin(loading)}
      dataSource={response?.data.items ?? []}
      size="middle"
      onChange={(pagination, filters, sorter) => {
        setQuery({
          ...query,
          page: pagination.current || query.page,
          pageSize: pagination.pageSize || query.pageSize,
          states: filters.state ?? [],
          description:
            filters.description && filters.description.length > 0
              ? filters.description[0]
              : undefined,
          createdAt:
            filters.createdAt && filters.createdAt.length > 0
              ? filters.createdAt[0]
              : undefined,
          orderBy: sorter.field,
          orderByDirection: sorter.order
            ? ColumnSortDirections[sorter.order]
            : undefined
        });
      }}
      pagination={{
        size: 'small',
        position: 'bottom',
        showSizeChanger: true,
        total: response?.data.meta.total,
        pageSize: query.pageSize,
        current: query.page,
        pageSizeOptions: ['5', '10', '20', '30', '40']
      }}
    />
  );
}
