import * as React from 'react';
import { Tree } from 'antd';
import { IDossier } from 'common/schema/Dossier';
import { getDocumentStructureNode } from './getDocumentStructureNode';
const { TreeNode } = Tree;

export interface IDossierStructureChildrenProps {
  dossier: IDossier;
  nodeUid: number;
}

export interface IDossierStructureSubdossierNode {
  type: 'subdossier';
  uid: number;
}

/**
 * Rappresenta un singolo nodo della struttura dei sottofascicoli.
 * Si occupa di visualizzare, ricorsivamente, i sottofascicoli.
 */
export function getDossierStructureNode(dossier: IDossier, nodeUid: number) {
  const node = dossier.subdossiers.find(node => node.uid === nodeUid)!;
  const children = dossier.subdossiers.filter(
    node => node.parentUid === nodeUid
  );

  return (
    <TreeNode
      title={node.title}
      key={`${node.parentUid || 'root'}-${node.uid}`}
      data={
        { type: 'subdossier', uid: node.uid } as IDossierStructureSubdossierNode
      }
    >
      {children.map(child => getDossierStructureNode(dossier, child.uid))}
      {node.documentUids &&
        node.documentUids?.map(uid => getDocumentStructureNode(dossier, uid))}
    </TreeNode>
  );
}
