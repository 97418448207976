import { Role } from './Role';

const Admin = new Role('Admin', 'Amministratore', [
  'documents.add',
  'discards.read',
  'discards.write',
  'discards.start',
  'deposits.read',
  'organigram.read',
  'organigram.read-selectable',
  'organigram.write',
  'users.read',
  'users.read-selectable',
  'users.write',
  'domains.read',
  'users.documental-import',
  'dashboard.read'
]);

const Archivist = new Role('Archivist', 'Archivista', [
  'documents.read',
  'discards.read',
  'discards.write',
  'discards.start',
  'deposits.read',
  'organigram.read',
  'organigram.read-selectable',
  'organigram.write', // Valutare se rimuovere
  'users.read',
  'users.read-selectable',
  'users.write', // Necessario?
  'grants.read',
  'grants.write',
  'domains.read',
  'dashboard.read'
]);

const Consulter = new Role('Consulter', 'Consultatore', [
  'documents.read-granted',
  'grants.read-own',
  'grants.write-own',
  'organigram.read-selectable', // Necessario? Solo per la ricerca
  'dashboard.read'
]);

const System = new Role('System', 'Utente di Sistema', [
  // Versamenti
  'documents.add',
  'deposits.read',
  // AOO e Titolari
  'organigram.read',
  'organigram.write',
  // Monitoraggio
  'monitoring.read',
  'documents.get-meta',
  // Permessi
  'grants.read',
  'grants.write',
  // Utenti
  'users.read',
  'users.write',
  // Dashboard
  'dashboard.read'
]);

export const Roles = {
  Archivist,
  Consulter,
  Admin,
  System
};

export type RoleName = keyof typeof Roles;
