import { yup } from 'common/validation/initYup';
import { yupToFormErrors } from 'formik';

/**
 * Crea un validatore di uno schema per i form che non abbia solamente
 * `abortEarly`.
 */
export function schemaToValidator<T extends object>(
  schema: yup.ObjectSchema<T>,
  options?: yup.ValidateOptions
) {
  return async (values: any) => {
    try {
      const res = await schema.validate(values, {
        ...options,
        abortEarly: false
      });
      return;
    } catch (err) {
      console.log('err', err, values);
      return yupToFormErrors(err);
    }
  };
}
