import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router';
import { LoginRoute } from 'client/components/auth/login/LoginRoute';
import { AuthCurrentLoader } from 'client/components/auth/current-loader/AuthCurrentLoader';
import { useAuth, usePermissions } from 'client/components/auth/AuthModule';
import { NotFoundPage } from 'client/components/not-found/NotFoundPage';
import { AdminRoute } from 'client/components/admin/AdminRoute';
import { GrantViewPage } from 'client/components/grant/view/GrantViewPage';
import { GrantEditPage } from 'client/components/grant/edit/GrantEditPage';
import { GrantsListPage } from 'client/components/grant/list/GrantsListPage';
import { DepositPage } from 'client/components/deposit/single/DepositPage';
import { DiscardsListPage } from 'client/components/discard/list/DiscardsListPage';
import { DepositsList } from 'client/components/deposit/list/DepositsList';
import { DiscardPolicyEditPage } from 'client/components/discard-policy/edit/DiscardPolicyEditPage';
import { DiscardPoliciesListPage } from 'client/components/discard-policy/list/DiscardPoliciesListPage';
import { DocumentSearchPage } from 'client/components/search/DocumentSearchPage';
import { DossierSearchPage } from 'client/components/search/DossierSearchPage';
import { DocumentPage } from 'client/components/document/DocumentPage';
import { DossierPage } from 'client/components/dossier/DossierPage';
import { ClientConfigLoader } from 'client/components/config/ClientConfigLoader';
import { DiscardEditPage } from 'client/components/discard/edit/DiscardEditPage';
import { GrantRequestViewPage } from 'client/components/grant/view/GrantRequestViewPage';
import { GrantRequestsListPage } from 'client/components/grant/list/GrantRequestsListPage';
import { Modal } from 'antd';
import { DashboardPage } from 'client/components/dashboard/DashboardPage';
import { useModulesConfig } from 'client/components/config/ClientConfigModule';

export const Routes = () => {
  const { logged } = useAuth();
  const history = useHistory();
  const permissions = usePermissions();
  const modules = useModulesConfig();

  useEffect(() => {
    const unlisten = history.listen(() => {
      Modal.destroyAll();
    });
    return () => {
      unlisten();
    };
  }, []);

  const getHome = () => {
    if (modules.dashboard) return '/app/home';
    if (permissions.has(['documents.read', 'documents.read-granted']))
      return '/app/search/documents';
    else if (permissions.has('organigram.read'))
      return '/app/admin/organizations';
    else return '/app/grants/owned';
  };

  return (
    <ClientConfigLoader>
      <AuthCurrentLoader>
        {!logged ? (
          <Switch>
            <Route path="/login" component={LoginRoute} />
            <Redirect to={{ pathname: '/login' }} />
          </Switch>
        ) : (
          <Switch>
            {/** TODO:: Rimuovere il prefisso "app" */}
            <Route path="/app/admin" component={AdminRoute} />
            {/* <Route path="/app" component={AppRoutes} /> */}
            <Redirect from="/login" exact to="/app" />

            {/* Home */}
            <Route path="/app/home" component={DashboardPage} />

            {/** Permessi di visualizzazione lato Richiedente */}
            <Route
              path="/app/grants/owned/view/:id"
              component={GrantRequestViewPage}
            />
            <Route path="/app/grants/owned" component={GrantRequestsListPage} />

            {/** Permessi di visualizzazione */}
            <Route path="/app/grants/view/:id" component={GrantViewPage} />
            <Route path="/app/grants/edit/:id" component={GrantEditPage} />
            <Route path="/app/grants" component={GrantsListPage} />

            {/** Versamenti */}
            <Route path="/app/deposits/single/:id" component={DepositPage} />
            <Route path="/app/deposits" component={DepositsList} />

            {/** Scarti */}
            <Route path="/app/discards/edit/:id" component={DiscardEditPage} />
            <Route path="/app/discards/list" component={DiscardsListPage} />

            {/** Policy di Scarto */}
            <Route
              path="/app/discard-policies/edit/:id"
              component={DiscardPolicyEditPage}
            />
            <Route
              path="/app/discard-policies"
              component={DiscardPoliciesListPage}
            />

            {/** Ricerca */}
            <Route
              path="/app/search/documents"
              component={DocumentSearchPage}
            />
            <Route path="/app/search/dossiers" component={DossierSearchPage} />
            {/** Accesso ai documenti / fascicoli */}
            <Route
              path="/app/documents/:areaUid/:uid"
              component={DocumentPage}
            />
            <Route path="/app/dossiers/:areaUid/:uid" component={DossierPage} />

            {/** Generali */}
            <Redirect from="/app/" to={getHome()} />
            <Redirect from="/" exact to={getHome()} />
            <Route component={NotFoundPage} />
          </Switch>
        )}
      </AuthCurrentLoader>
    </ClientConfigLoader>
  );
};
