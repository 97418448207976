import * as React from 'react';
import Select, { OptionProps, SelectProps } from 'antd/lib/select';
import {
  FormikValues,
  useField,
  useFormikContext,
  FieldInputProps,
  FieldHelperProps,
  FormikContextType
} from 'formik';
import {
  ApiGet,
  ApiQueryOptions,
  useApiQuery
} from 'client/core/network/hooks/useApiQuery';
import { Spin } from 'antd';
import { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { AsyncSelectInputMeta } from './AsyncSelectInputMeta';

type Item<R extends Array<any>> = R extends Array<infer I> ? I : never;

type AsyncSelectQueryFn<T extends FormikValues, A, R> = (
  formik: FormikContextType<T>,
  search?: string | undefined
) => ApiQueryOptions<A, R>;

interface SelectOption extends OptionProps {
  label: React.ReactNode;
}

/**
 * Dati aggiuntivi visualizzabili nella select.
 */
export type AsyncSelectMeta = {
  shown: number;
  total: number;
};

export interface AsyncSelectInputProps<
  T extends FormikValues,
  A,
  R,
  O extends Array<any>
> extends SelectProps {
  name: string;
  /** Permette di caricare con una query le opzioni. */
  query: {
    apiFn: ApiGet<A, R>;
    options: ApiQueryOptions<A, R> | AsyncSelectQueryFn<T, A, R>;
  };
  refreshOnSearch?: boolean;
  /** Aggiunge sempre l'oggetto passato alla lista delle opzioni, così che possa essere renderizzata la label */
  current?: Item<O>;
  responseTransform?: (response: R) => O;
  responseMeta?: (response: R) => AsyncSelectMeta;
  optionTransform: (option: Item<O>) => SelectOption;
  afterChange?: (data: R | undefined, value: any) => void;
}

type InferArray<T> = T extends any[] ? T : never;

/**
 * Select collegata direttamente a Formik.
 */
// TODO: Gestire errori
export function AsyncSelectInput<
  T extends FormikValues,
  A,
  R,
  O extends Array<any>
>(props: AsyncSelectInputProps<T, A, R, O>) {
  const {
    name,
    query,
    responseTransform,
    responseMeta,
    optionTransform,
    refreshOnSearch,
    afterChange,
    current,
    ...otherProps
  } = props;
  const [field, , helpers] = useField<T>(name);
  const [search, setSearch] = useState(undefined as string | undefined);
  const formik = useFormikContext<T>();
  const { response, loading, error } = useApiQuery(
    query.apiFn,
    typeof query.options === 'function'
      ? query.options(formik, search)
      : query.options
  );

  const responseTransformFn = responseTransform ?? (i => (i as unknown) as O);

  // Genero le opzioni della select sui dati recuperati via API
  const items = response?.data
    ? responseTransformFn(response?.data).map(optionTransform)
    : [];
  // Genero l'opzione del current se fornito
  const currentItem = current ? optionTransform(current) : null;
  const meta = response ? responseMeta?.(response.data) : undefined;

  // Se il current esiste e _non_ è presente nel risultato della query,
  // aggiungo il current alla lista e aggiorno i meta
  if (currentItem && !items.some(item => item.value === currentItem.value)) {
    items.push(currentItem);
    if (meta) meta.shown += 1;
  }

  // Ricerca
  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 200),
    [refreshOnSearch]
  );

  // Informo che esistono altri dati
  return (
    <Select<any>
      {...otherProps}
      loading={loading}
      notFoundContent={
        loading ? <Spin size="small" /> : 'Nessun elemento trovato.'
      }
      value={field.value}
      filterOption={!refreshOnSearch}
      onSearch={refreshOnSearch ? handleSearch : undefined}
      onChange={value => {
        helpers.setValue(value == undefined ? null : value);
        afterChange?.(response?.data, value);
      }}
      dropdownRender={menu => (
        <div>
          {menu}
          <AsyncSelectInputMeta meta={meta} />
        </div>
      )}
    >
      {items.map(item => (
        <Select.Option key={item.value} {...item}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
}
