import * as React from 'react';
import clx from 'classnames';
import { Card, Row, Col, Tabs } from 'antd';
import './PageTabsContainer.scss';

export interface PageTabsContainerProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  isDossier?: boolean;
  hasFooterBar?: boolean;
  // hasScrollable?: boolean;
}

/**
 * Contenitore Pagina con Tabs (pagina Documento e Fascicoli)
 *
 * Disegna la Card bianca principale e contiene gli stili per i Tab
 * I children devono essere <TabPane>
 * La header è obbligatoria
 */

export function PageTabsContainer(props: PageTabsContainerProps) {
  const className = clx('card-shadow', 'panel-container', {
    'is-dossier': props.isDossier,
    'has-footer-bar': props.hasFooterBar
    // 'tabs-content-scrollable': props.hasScrollable,
  });

  return (
    <div className="page-tabs-container">
      <Card size="small" className={className} bordered={false}>
        {props.header}
        <Tabs type="card" className="page-tabs">
          {props.children}
        </Tabs>
      </Card>
    </div>
  );
}
