import * as React from 'react';
import { Result } from 'antd';
import './NotFoundPage.scss';
import { PageLayout } from 'client/ui/layout/PageLayout';

export interface INotFoundPageProps {}

export function NotFoundPage(props: INotFoundPageProps) {
  return (
    <PageLayout hasMenu hasScrollable className="not-found-page">
      <Result status="error" title="404" subTitle="Pagina Non Trovata." />
    </PageLayout>
  );
}
