import * as yup from 'yup';

type Enum<E> = Record<keyof E, string>;

declare module 'yup' {
  interface StringSchema {
    /**
     * Permette di fornire un ref a un array esterno (i.e. context) per validare
     * se il valore è contenuto in quell'array.
     */
    oneOfEnum<E extends string, T, EV extends { [key in E]: T }>(
      enumValue: EV
    ): StringSchema<string>;
  }
}

yup.addMethod(yup.mixed, 'oneOfEnum', function (enumValue) {
  return this.test({
    message:
      'Il campo ${path} non rientra fra le opzioni disponibili: ${enumValue}.',
    name: 'oneOfEnum',
    exclusive: true,
    params: { enumValue: Object.values(enumValue) },
    test(value) {
      return Object.values(enumValue).includes(value);
    }
  });
});
