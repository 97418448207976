import React from 'react';
import clx from 'classnames';
import './MetadataCollapse.scss';
import { Collapse, Icon } from 'antd';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { useFieldSchema } from '../schema/useFieldSchema';
const { Panel } = Collapse;

export interface MetadataCollapseProps {
  /**
   * Riga con funzione di pulsante per aprire il collapse
   * normalmente: <SchemaMetadata ... /> || <MetadadaText ... />
   */
  header: any; //string | React.ReactNode | null;
  /**
   * Contenuto che viene mostrato al click del pulsante
   *
   * TODO: attenzione ad utilizzare come wrapper dei children
   * i Fragment <></> || <SchemaSection> || <SchemaMetadata>
   * vengono considerati elementi da renderizzare anche se all'interno non c'e nulla
   * inserirli in un if, es: {somethingToShow && <></>}
   * oppure utilizzare la proprietà seguente: hideCollapse?: boolean
   */
  children?: React.ReactNode;
  /**
   * vedi sopra
   */
  hideCollapse?: boolean;
  className?: string;

  field: string;
  ns: SchemaConfigNamespace;
}

/**
 * Permette di visualizzare dati aggiuntivi ad un metadato ad esempio le versioni dell'oggetto
 */
export function MetadataCollapse(props: MetadataCollapseProps) {
  const { ns, field } = props;
  const fieldSchema = useFieldSchema(ns, field);
  if (fieldSchema?.enable === false) return props.header;
  if (props.header == null) return null;
  if (props.hideCollapse) return props.header;
  if (!props.children) return props.header;

  return (
    <Collapse
      className={clx('metadata-collapse', props.className, {})}
      expandIcon={({ isActive }) => (
        <Icon type={isActive ? 'minus-circle' : 'history'} />
      )}
      expandIconPosition="right"
    >
      <Panel header={props.header} key="1">
        {props.children}
      </Panel>
    </Collapse>
  );
}
