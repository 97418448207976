import * as React from 'react';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { TableScroll } from 'client/ui/table/TableScroll';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { GrantApi } from '../GrantApi';
import { ColumnProps } from 'antd/lib/table';
import { IGrant } from 'common/schema/Grant';
import { ISODate } from 'client/ui/display/date/ISODate';
import { GrantStatusTag } from '../detail/GrantStatusTag';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { useState } from 'react';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { IUserGrantsQueryDto } from 'common/dto/UserGrantsQueryDto';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

export interface GrantRequestsListPageProps {}

/**
 * Elenco dei permessi per un utente
 */
export function GrantRequestsListPage(props: GrantRequestsListPageProps) {
  const user = useCurrentUser();

  const [query, setQuery] = useState<IUserGrantsQueryDto>({
    page: 1,
    pageSize: 20
  });
  const { response, error, loading } = useApiQuery(GrantApi.listOwned, {
    skip: !user,
    data: { query, userId: user?.id! }
  });

  const columns: ColumnProps<IGrant>[] = [
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (_, record) => <ISODate date={record.createdAt.toString()} />
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'Stato',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (_, record) => <GrantStatusTag grant={record} />
    },
    {
      title: 'Inizio',
      dataIndex: 'beginsAt',
      key: 'beginsAt',
      width: 100,
      render: (_, record) => <ISODate date={record.createdAt.toString()} />
    },
    {
      title: 'Scadenza',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      width: 100,
      render: (_, record) => <ISODate date={record.createdAt.toString()} />
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 120,
      render: (_, record) => (
        <Link to={`/app/grants/owned/view/${record.id}`}>
          <Button size="small">
            <Icon type="eye" /> <TableButtonDetailText />
          </Button>
        </Link>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco delle richieste di permesso temporaneo"
        description={getNetworkErrorMessage(error.message)}
        type="error"
        showIcon
      />
    );

  return (
    <PageLayout
      hasScrollable
      fullTable
      hasMenu
      title={
        <CustomCardTitle
          image="grant"
          title="Le mie Richieste di Consultazione"
        />
      }
    >
      <TableScroll
        loading={tableLoaderSpin(loading || !response)}
        columns={columns}
        dataSource={response?.data.items ?? []}
        size="middle"
        className="table-grant-list"
        onChange={(pagination, filters, sorter) => {
          setQuery({
            ...query,
            page: pagination.current || query.page,
            pageSize: pagination.pageSize || query.pageSize
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
