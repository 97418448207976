import * as React from 'react';
import { DiscardItemState } from 'common/schema/Discard';
import Tag, { TagProps } from 'antd/lib/tag';

export interface IDiscardItemBadgeProps {
  state: DiscardItemState;
}

function mapDiscardItemStateToTagProps(state: DiscardItemState): TagProps {
  switch (state) {
    case DiscardItemState.Prepared:
      return { color: 'purple', children: 'Proposto' };
    case DiscardItemState.Running:
      return { color: 'geekblue', children: 'In Corso' };
    case DiscardItemState.Discarded:
      return { color: 'volcano', children: 'Scartato' };
  }
}

/**
 * Mostra lo stato di un elemento dello scarto
 */
export function DiscardItemBadge(props: IDiscardItemBadgeProps) {
  return <Tag {...mapDiscardItemStateToTagProps(props.state)} />;
}
