import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import clx from 'classnames';
const R = require('ramda');
import { Table as AntTable, Pagination } from 'antd';
import { TableProps } from 'antd/lib/table';
import './Table.scss';
import { throttle } from 'lodash';
import { number } from 'yup';

export interface TableScrollProps<T> extends TableProps<T> {
  label?: React.ReactNode;
  hasFooterMenu?: boolean;
  /**
   * Permette di sovrascrivere il dato calcolato dinamicamenre
   * della distanza tra la fine della tabella (inclusa l'eventuale paginazione)
   * e la fine della pagina.
   *
   * Utile per fissare errori di calcolo (TODO: trovare e corregere)
   * quando la tabella è annidata in più elementi
   */
  bottomSpace?: number;
  /**
   * Permette di sovrascrivere il dato calcolato dinamicamenre.
   *
   * Per fissare errori di calcolo nei Drawer che vengono disegnati fuori della pagina
   */
  topSpace?: number;
  /**
   * Permette di inserire un elemento (es: <Button size="small" />)
   * sotto la tabella e a fianco della paginazione.
   */
  bottomLeft?: React.ReactNode;
}

/**
 * Tabella già stilizzata per essere utilizzata nell'archivio di deposito.
 *
 * TODO: al momento calcoliamo le dimensioni in maniera dinamica al momento del primo render
 * e al 'resize' solo quella relativa allo spazio da lasciare in fondo alla pagina per
 * evitare troppi rerender.
 * Se ci saranno problemi bisognerà calcolare con useLayoutEffect
 * anche gli elementi calcolati ora con useCallback.
 */
export function TableScroll<T>(props: TableScrollProps<T>) {
  const {
    label,
    pagination,
    size,
    columns,
    bottomSpace,
    topSpace,
    bottomLeft,
    ...otherProps
  } = props;

  /**
   * Calcolo dinamico altezza massima disponibile per la Tabella Ant
   */
  const [tableRect, setTableRect] = useState({
    top: 194
  });
  const measuredTableRect = useCallback(node => {
    if (node !== null) {
      setTableRect(node.getBoundingClientRect());
    }
  }, []);

  /**
   * Calcolo dinamico altezza Pagina (windows) per calcolare il footer della pagina.
   *
   * Per ottenere i dati necessari in combinazione con i css abbiamo:
   * altezza della finestra (100vh): windowRect.width
   * altezza utile fino alla fine della Pagination: windowRect.top
   *     include footer fisso, eventuale footerMenu e il padding della Card
   */
  const divWindow = useRef<HTMLDivElement>(null);
  const [divWindowSize, setDivWindowSize] = useState({
    top: 200,
    width: 254
  });
  const updateSize = () => {
    if (divWindow.current) {
      setDivWindowSize(divWindow.current.getBoundingClientRect());
    }
  };
  useLayoutEffect(() => {
    updateSize();
    const updateSizeThrottled = throttle(updateSize, 100);
    window.addEventListener('resize', updateSizeThrottled);
    return () => window.removeEventListener('resize', updateSizeThrottled);
  }, []);

  /**
   * Calcolo dinamico altezza Header della Tabella
   *
   * Per effettuare il calcolo aggiungiamo una colonna alla tabella
   * in cui inseriamo l'elemento da misurare
   */
  const [headerRect, setHeaderRect] = useState({
    height: 34
  });
  const measuredHeader = useCallback(node => {
    if (node !== null) {
      setHeaderRect(node.getBoundingClientRect());
    }
  }, []);
  // creazione elemento div misurabile
  const customColumn = {
    title: <div ref={measuredHeader} className="measure-header-ref" />,
    key: '$$measuredColumn',
    width: 6,
    className: 'measure-header-cell'
  };
  // aggiunta elemento elemento div misurabile alle colonne della tabella
  const measurableColumn = R.prepend(customColumn, columns);

  /**
   * Non facciamo il Calcolo dinamico altezza Pagination
   * Per complicazioni nel  passaggio delle informazione tra Table e Pagination
   */
  const paginationHeight = pagination ? 36 : bottomLeft ? 36 : 0;
  // socrascriviamo anche la proprietà passata al componente per
  // assicurarci che sia della giusta dimensione
  const fixedSizePagination = pagination
    ? R.assoc('size', 'small', pagination)
    : pagination;

  /**
   * Calcoli Dinamici
   */
  const dinamicBottomSpace = props.bottomSpace
    ? props.bottomSpace
    : divWindowSize
    ? divWindowSize.width - divWindowSize.top
    : 54;
  const dinamicHeightToRemove = headerRect
    ? dinamicBottomSpace + headerRect.height + paginationHeight
    : 172;
  const dinamicSubtractHeight = topSpace
    ? topSpace + dinamicHeightToRemove
    : tableRect
    ? tableRect.top + dinamicHeightToRemove
    : 366;
  const dinamicTableHeight = divWindowSize
    ? divWindowSize.width - dinamicSubtractHeight
    : 50;

  // console.log('Verifica  Dati  ||||||||||||||||||||||||||||||||||||');
  // console.log('Esiste divWindowSize:           ', divWindowSize);
  // console.log('EpaginationHeight:   ', paginationHeight);
  // console.log('Esiste tableRect:               ', tableRect);
  // console.log('> dinamicBottomSpace:    (54) ', dinamicBottomSpace);
  // console.log('> BottomSpace:           (54) ', props.bottomSpace);
  // console.log('> dinamicHeightToRemove:(172)', dinamicHeightToRemove);
  // console.log('> tableRect.top              ', tableRect.top);
  // console.log('> dinamicSubtractHeight:(366)', dinamicSubtractHeight);
  // console.log('> dinamicTableHeight:    (50) ', dinamicTableHeight);

  return (
    <>
      <div ref={divWindow} className="measure-windows-ref" />
      <div className="table-container table-scrool">
        {label && <div className="table-title">{label}</div>}
        <div ref={measuredTableRect} className="measure-table-rect-ref">
          <AntTable<T>
            {...otherProps}
            columns={measurableColumn}
            bodyStyle={{
              maxHeight: `calc(100vh - ${dinamicSubtractHeight}px)`
            }}
            scroll={{ y: dinamicTableHeight }}
            size={'middle'}
            pagination={fixedSizePagination || false}
          />
          {bottomLeft && (
            <div
              className={clx('table-bottom-left', {
                'has-pagination': pagination
              })}
            >
              {bottomLeft}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
