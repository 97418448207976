import { IDossier } from 'common/schema/Dossier';

export class DossierLogic {
  static isDossierComplete(dossier: IDossier) {
    for (const document of dossier.documents) {
      const isDocumentPresent = dossier.classifications.some(
        c => c.document?.uid === document.uid
      );

      if (!isDocumentPresent) {
        return false;
      }
    }

    return true;
  }
}
