import React from 'react';
import './MetadataBoolean.scss';
import { Icon } from 'antd';
import { MetadataText } from 'client/ui/metadata/MetadataText';

export interface MetadataBooleanProps {
  label?: string;
  value?: boolean;
  className?: string;
  hideIfEmpty?: boolean;
}

export function MetadataBoolean(props: MetadataBooleanProps) {
  return (
    <MetadataText
      hideIfEmpty={props.hideIfEmpty}
      label={props.label}
      className={props.className}
      text={
        props.value ? (
          <>
            <Icon type="check-circle" className="value-true" /> Si
          </>
        ) : (
          <>
            <Icon type="close-circle" className="value-false" /> No
          </>
        )
      }
    />
  );
}
