import * as React from 'react';
import clx from 'classnames';
import { Tag, Icon } from 'antd';
import { TagProps } from 'antd/lib/tag';
import './DepositStateTag.scss';
import { DepositState } from 'common/schema/Deposit';
import { assertNever } from 'server/utils/typings/assertNever';

function mapDepositToTagProps(state: DepositState): TagProps {
  switch (state) {
    case DepositState.Progress:
      return { children: 'Esecuzione', color: 'blue' };
    case DepositState.Complete:
      return { children: 'Completato', color: 'green' };
    case DepositState.Fail:
      return { children: 'Errore', color: 'red' };
  }
  assertNever(state);
}

function stateIcon(status: DepositState) {
  switch (status) {
    case DepositState.Progress:
      return <Icon type="sync" spin className="deposit-state-icon progress" />;
    case DepositState.Complete:
      return <Icon type="check" className="deposit-state-icon complete" />;
    case DepositState.Fail:
      return <Icon type="warning" className="deposit-state-icon fail" />;
    // case 'queue':
    //   return <Icon type="clock-circle" className="deposit-state-icon queue" />;

    default:
      return <Icon type="question-circle" className="deposit-state-icon" />;
  }
}

export interface DepositsStateTagProps {
  state: DepositState;
  compact?: boolean;
}

/**
 * Mostra lo Stato di un versamento già stilizzato
 */
export function DepositStateTag(props: DepositsStateTagProps) {
  const tagProps = mapDepositToTagProps(props.state);
  const icon = stateIcon(props.state);

  return props.compact ? (
    icon
  ) : (
    <Tag {...tagProps} className={clx('deposit-state-tag')} />
  );
}
