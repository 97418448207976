import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { DatePickerInputProps, DatePickerInput } from './DatePickerInput';

interface Props<T extends FormikValues> extends DatePickerInputProps<T> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
}

/**
 * Form Item per input di tipo data.
 */
export function DatePickerInputItem<T extends FormikValues>(props: Props<T>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem {...itemProps} name={name} label={label}>
      <DatePickerInput {...otherProps} name={name} />
    </FormItem>
  );
}
