import { apiClient } from 'client/core/network/apiClient';
import {
  IOrganizationalAreasQueryDto,
  IOrganizationalAreasSelectableQueryDto
} from 'common/dto/OrganizationalAreasQueryDto';
import { IOrganizationalArea } from 'common/schema/OrganizationalArea';
import { CreateOrganizationalAreaDto } from 'common/dto/OrganizationalAreaDto';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';

export const OrganizationalAreaApi = {
  findOrganizationalAreas: (options: { query: IOrganizationalAreasQueryDto }) =>
    apiClient.get<PaginatedResult<IOrganizationalArea>>(
      '/organizational-areas',
      { params: options.query }
    ),
  listSelectable: (options: {
    query: IOrganizationalAreasSelectableQueryDto;
  }) =>
    apiClient.get<PaginatedResult<IOrganizationalArea>>(
      '/organizational-areas/projections/selectable',
      { params: options.query }
    ),
  findOrganizationalArea: (options: { id: number }) =>
    apiClient.get<IOrganizationalArea>(`/organizational-areas/${options.id}`),
  createOrganizationalArea: (options: { data: CreateOrganizationalAreaDto }) =>
    apiClient.post<IOrganizationalArea>(`/organizational-areas`, options.data),
  updateOrganizationalArea: (options: {
    id: number;
    data: IOrganizationalArea;
  }) =>
    apiClient.put<IOrganizationalArea>(
      `/organizational-areas/${options.id}`,
      options.data
    )
};
