import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { FormikValues, useField } from 'formik';

export interface TextInputProps<T extends FormikValues>
  extends Omit<InputProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input testuale.
 */
export function TextInput<T extends FormikValues>(props: TextInputProps<T>) {
  const { name } = props;
  const [field, meta] = useField(name);

  return <Input {...props} {...field} />;
}
