import React, { useCallback } from 'react';
import { Checkbox } from 'antd';
import { FormikValues, useField, useFormikContext } from 'formik';
import { CheckboxProps, CheckboxChangeEvent } from 'antd/lib/checkbox';

export interface CheckboxInputProps<T extends FormikValues>
  extends Omit<CheckboxProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input di tipo checkbox.
 */
export function CheckboxInput<T extends FormikValues>(
  props: CheckboxInputProps<T>
) {
  const { name } = props;
  const [field, meta] = useField<boolean>(name);
  const formik = useFormikContext<T>();
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => formik.setFieldValue(name, e.target.checked),
    [name]
  );

  return (
    <Checkbox
      {...props}
      {...field}
      checked={field.value}
      onChange={handleChange}
    />
  );
}
