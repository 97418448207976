import * as React from 'react';
import { Divider } from 'antd';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { MetadataNotes } from 'client/components/metadata/fields/MetadataNotes';
import { SpecificMetadatasView } from 'client/components/metadata/specific/SpecificMetadatasView';
import { CorrespondingsView } from 'client/components/metadata/profile/CorrespondingsView';
import { IDocument } from 'common/schema/Document';
import { SchemaMetadata } from 'client/components/metadata/schema/SchemaMetadata';
import { SchemaSection } from 'client/components/metadata/schema/SchemaSection';
import { DocumentSubjectHistory } from 'client/components/metadata/profile/DocumentSubjectHistory';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { CancellationView } from 'client/components/metadata/profile/CancellationView';
import { CorrespondingsHistoryView } from 'client/components/metadata/profile/CorrespondingsHistoryView';
import { SchemaLabel } from 'client/components/metadata/schema/SchemaLabel';
import { IAttachment } from 'common/schema/Attachment';
import { AttachmentColumn } from '../sections/AttachmentColumn';
import { AttachmentPreview } from '../attachment/AttachmentPreview';
import { DocumentStandardMetadataView } from 'client/components/metadata/profile/DocumentStandardMetadataView';

export interface DocumentProfileTabProps {
  document: IDocument;
  attachment: AttachmentPreview | null;
}

/**
 * Mostra il dettaglio dei metadati di PROFILO di un documento
 */
export function DocumentProfileTab(props: DocumentProfileTabProps) {
  const { document, attachment } = props;

  return (
    <RowScroller>
      <ColScroller span={10} side="left">
        {/* Oggetto documento */}
        <DocumentSubjectHistory document={document} />
        <Divider />
        {/* Dati Identificativi Arma */}
        <SchemaMetadata
          ns="document"
          source={document}
          field="protocolloArma"
          label="Protocollo Arma on Page"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="numeroPratica"
          label="Numero Pratica"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="uoName"
          label="Unità Organizzativa"
        />
        <Divider />
        {/* Dati Organizzazione e AOO */}
        <SchemaMetadata
          ns="document"
          source={document}
          field="organizationUid"
          label="Codice Organizzazione"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="organizationName"
          label="Organizzazione"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="areaUid"
          label="Codice AOO"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="areaName"
          label="AOO"
        />
        <Divider />
        {/* Dati Identificativi */}
        <SchemaMetadata
          ns="document"
          source={document}
          field="signature"
          label="Segnatura"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="registerUid"
          label="ID del registro di protocollo"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="register"
          label="Registro di Protocollo"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="registerName"
          label="Nome Registro"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="registerNumber"
          label="Numero di protocollo"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="registerYear"
          label="Anno di protocollo"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="registerType"
          label="Tipo di documento nel registro"
        />
        <Divider />
        {/* dati classificazione */}
        <SchemaMetadata
          ns="document"
          source={document}
          field="mainCategoryIndexUid"
          label="UID del Titolario"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="mainCategory"
          label="Classificazione"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="mainCategoryTitle"
          label="Descrizione della classificazione"
        />
        <Divider />
        {/* Dati relativi alle date del documento */}
        <SchemaMetadata
          ns="document"
          source={document}
          field="createdAt"
          label="Data Creazione"
          type="date"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="registeredAt"
          label="Data Protocollo"
          type="date"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="expiresAt"
          label="Data Scadenza"
          type="date"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="emergencyRegister"
          label="Segnatura di emergenza"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="emergencyRegisterDate"
          label="Data protocollo emergenza"
          type="date"
        />
        <Divider />
        {/* Dati mittente/destinatario */}
        <SchemaSection ns="document" field="correspondings">
          <CorrespondingsView correspondings={document.correspondings} />
        </SchemaSection>
        <Divider />
        <SchemaSection ns="document" field="correspondingHistory">
          <CorrespondingsHistoryView
            correspondings={document.correspondingHistory}
          />
        </SchemaSection>
        <Divider />
        <SchemaMetadata
          ns="document"
          source={document}
          field="senderRegisteredAt"
          label="Data di protocollo del mittente"
          type="date"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="senderRegisterNumber"
          label="Registro di protocollo del mittente"
        />
        <Divider />
        {/* catatteristiche ducumento */}
        <SchemaMetadata
          ns="document"
          source={document}
          field="hasSensitiveData"
          label="Contiene dati sensibili"
          type="boolean"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="isConfidential"
          label="Specifica se il documento è Riservato"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="confidentialLevel"
          label="Livello di riservatezza"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="preservationStatus"
          label="Stato conservazione"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="connectedRegister"
          label="Protocollo collegato"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="connectingRegister"
          label="Protocollo collegante"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="collocation"
          label="Collocazione"
        />
        <Divider />
        <SchemaMetadata
          ns="document"
          source={document}
          field="registerAuthor"
          label="Operatore di protocollo che ha efettuato la registrazione"
        />
        {/* dati relativi a storia documento */}
        <SchemaSection ns="document" field="cancellation">
          {document.cancellation && (
            <CancellationView cancellation={document.cancellation} />
          )}
        </SchemaSection>
        <DocumentStandardMetadataView metadata={document.standardMetadata} />
        <Divider />
        {/* Note */}
        <SchemaSection ns="document" field="notes">
          {document.notes && document.notes.length > 0 && (
            <MetadataNotes
              label={<SchemaLabel ns="document" field="notes" label="Note" />}
              notes={document.notes}
            />
          )}
        </SchemaSection>
        <Divider />
        {/* Tipologia documento e metadati specifici */}
        <SchemaMetadata
          ns="document"
          source={document}
          field="typologyUid"
          label="UID della tipologia"
        />
        <SchemaMetadata
          ns="document"
          source={document}
          field="typology"
          label="Tipologia del documento"
        />
        <SchemaSection ns="document" field="metadata">
          {document.metadata && document.metadata.length > 0 && (
            <>
              <SchemaLabel
                ns="document"
                field="metadata"
                label="Metadata Specifici"
              />
              <SpecificMetadatasView metadata={document.metadata} />
            </>
          )}
        </SchemaSection>
        <Divider />
        {/* Dati relativi all'archivio di deposito */}
        <SchemaSection ns="document" field="depositData">
          <SchemaMetadata
            ns="document"
            source={document}
            field="id"
            label="ID"
          />
          <SchemaMetadata
            ns="document"
            source={document}
            field="depositId"
            label="ID Deposito"
          />
          <SchemaMetadata
            ns="document"
            source={document}
            field="depositedAt"
            label="Data Deposito"
            type="date"
          />
          <SchemaMetadata
            ns="document"
            source={document}
            field="depositSentAt"
            label="Data invio a Deposito"
            type="date"
          />
          <SchemaMetadata
            ns="document"
            source={document}
            field="uid"
            label="ID Archivio Corrente"
          />
        </SchemaSection>
      </ColScroller>

      <AttachmentColumn attachment={attachment} />
    </RowScroller>
  );
}
