import React, { useState } from 'react';
import { Icon, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { OrganizationalAreaApi } from '../organizations/OrganizationalAreaApi';
import { IOrganizationalArea } from 'common/schema/OrganizationalArea';
import { TableScroll } from 'client/ui/table/TableScroll';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

export interface OrganizationalAreasListProps {}

export function OrganizationalAreasList(props: OrganizationalAreasListProps) {
  const [query, setQuery] = useState({
    page: 1,
    pageSize: 20
  });

  const { response, error, loading } = useApiQuery(
    OrganizationalAreaApi.findOrganizationalAreas,
    {
      data: {
        query
      }
    }
  );

  const columns: ColumnProps<IOrganizationalArea>[] = [
    // {
    //   key: 'id',
    //   dataIndex: 'id',
    //   title: 'ID',
    //   width: 60
    // },
    {
      key: 'uid',
      dataIndex: 'uid',
      title: 'Codice (UID)',
      ellipsis: true,
      width: 220,
      render: uid => <code>{uid}</code>
    },
    {
      key: 'name',
      dataIndex: 'name',
      ellipsis: true,
      title: 'Nome'
    },
    {
      key: 'actions',
      align: 'right',
      width: 120,
      render: (text, area) => (
        <Link to={`/app/admin/organizational-areas/${area.id}`}>
          <Button size="small">
            <Icon type="eye" /> <TableButtonDetailText />
          </Button>
        </Link>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco delle Aree Organizzative"
        description={error.message}
        type="error"
        showIcon
      />
    );

  return (
    <PageLayout
      fullTable
      hasMenu
      hasScrollable
      title={<CustomCardTitle icon="bank" title="Aree Organizzative" />}
    >
      <TableScroll<IOrganizationalArea>
        rowKey="id"
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data.items ?? []}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          setQuery({
            ...query,
            page: pagination.current ?? query.page,
            pageSize: pagination.pageSize ?? query.pageSize
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
