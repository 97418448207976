import * as React from 'react';
import { Button, Icon, Alert, message } from 'antd';
import { Formik } from 'formik';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DossierApi } from '../dossier/DossierApi';
import { OrganizationalAreaInput } from './form/organizational-area-input/OrganizationalAreaInput';
import { useDossierSearchState, SearchActions } from './SearchModule';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { DossierCodeSearch } from './form/dossier-code-search/DossierCodeSearch';
import { DateSearch } from './form/date-search/DateSearch';
import { useDispatch } from 'react-redux';
import { IDossier } from 'common/schema/Dossier';
import {
  IDossiersQueryDto,
  dossiersQueryDtoValidator
} from 'common/dto/DossiersQueryDto';
import { FormikForm } from 'client/ui/form/FormikForm';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { TableScroll } from 'client/ui/table/TableScroll';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { useFieldSchemaColumns } from '../metadata/schema/useFieldSchemaColumn';
import { SchemaLabel } from '../metadata/schema/SchemaLabel';

export interface IDossierSearchProps {
  isSelectable?: boolean;
  onAdd?: (dossier: IDossier) => void;
  isAdded?: (dossierId: number) => boolean;
}

/**
 * Ricerca sui fascicoli
 */
export function DossierSearch(props: IDossierSearchProps) {
  const dispatch = useDispatch();
  const search = useDossierSearchState();

  const { response, error, loading } = useApiQuery(DossierApi.findDossiers, {
    skip: !search.searchedAt,
    dependsOn: {
      // Ricarichiamo la ricerca se si ripreme su "Cerca".
      searchedAt: search.searchedAt
    },
    data: {
      params: search.search
    }
  });

  // TODO: Provvisorio per mostrare la checkbox di selezione nella tabella per la pagina Permessi di Visualizzazione
  const rowSelection = props.isSelectable ? {} : undefined;

  // Fix per calcolo altezza Tablescroll nel Drawer (pagina Permesso Temporaneo)
  const tableScrollProps = props.onAdd ? { topSpace: 100 } : {};

  const handleReset = () => {
    dispatch(
      SearchActions.dossierSearched({
        page: 1,
        pageSize: search.search.pageSize
      })
    );
  };

  // Colonne
  const columns: ColumnProps<IDossier>[] = useFieldSchemaColumns('dossier', [
    {
      key: 'area',
      dataIndex: 'areaName',
      title: 'A.O.O.',
      ellipsis: true,
      width: 120
    },
    {
      dataIndex: 'code',
      field: 'code',
      title: 'Codice',
      width: 120,
      ellipsis: true
    },
    {
      key: 'category',
      dataIndex: 'category',
      title: 'Class.',
      width: 100,
      ellipsis: true
    },
    {
      field: 'title',
      dataIndex: 'title',
      title: 'Descrizione',
      ellipsis: true
    },
    {
      key: 'actions',
      title: '',
      align: 'center',
      width: 60,
      render: (_, row) =>
        props.onAdd ? (
          props.isAdded && props.isAdded(row.id) ? (
            <Icon style={{ fontSize: '21px', color: '#389e0d' }} type="check" />
          ) : (
            <Button
              type="primary"
              size="small"
              onClick={e => {
                props.onAdd?.(row);
              }}
              icon="plus"
            />
          )
        ) : (
          <Link to={`/app/dossiers/${row.areaUid}/${row.uid}`}>
            <Button
              className="td-button-link"
              type="link"
              icon="right-circle"
            />
          </Link>
        )
    }
  ]);

  return (
    <RowScroller>
      <ColScroller span={8} side="left" spacing="inside">
        {/* Form di ricerca */}
        <Formik<IDossiersQueryDto>
          initialValues={search.search}
          enableReinitialize
          validate={schemaToValidator(dossiersQueryDtoValidator)}
          onSubmit={async (values, formik) => {
            try {
              const parsed = await dossiersQueryDtoValidator.validate(values);
              dispatch(
                SearchActions.dossierSearched({
                  ...parsed,
                  page: 1,
                  pageSize: search.search.pageSize
                })
              );
            } catch (err) {
              message.error('Impossibile avviare la ricerca');
            }
          }}
        >
          <FormikForm layout="vertical">
            <OrganizationalAreaInput name="area" />
            <DossierCodeSearch />
            <TextInputItem
              name="title"
              label={<SchemaLabel ns="dossier" field="title" label="Titolo" />}
            />
            <DateSearch fieldPrefix="createdAt" label="Data Creazione" />
            <DateSearch fieldPrefix="openedAt" label="Data Apertura" />
            <DateSearch fieldPrefix="closedAt" label="Data Chiusura" />
            <Button htmlType="submit" type={'primary' as any}>
              Cerca
            </Button>{' '}
            <Button onClick={handleReset} icon="close-circle">
              Annulla
            </Button>
          </FormikForm>
        </Formik>
      </ColScroller>
      <ColScroller
        hasTableScroll
        forceHeight
        isCard
        span={16}
        side="right"
        spacing="outside"
        className="full-table"
      >
        {/* Tabella dei risultati */}
        {error && <Alert type="error" message={error.message} />}
        <TableScroll
          bottomSpace={64}
          {...tableScrollProps}
          rowSelection={rowSelection}
          // label="Risultati della Ricerca"
          dataSource={response ? response.data.items : []}
          columns={columns}
          loading={tableLoaderSpin(loading)}
          onChange={(pagination, filters, sorter) => {
            dispatch(
              SearchActions.dossierSearched({
                ...search.search,
                page: pagination.current ?? search.search.page,
                pageSize: pagination.pageSize ?? search.search.pageSize
              })
            );
          }}
          pagination={{
            size: 'small',
            position: 'bottom',
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: search.search.pageSize,
            current: search.search.page,
            pageSizeOptions: ['5', '10', '20']
          }}
        />
      </ColScroller>
    </RowScroller>
  );
}
