import * as React from 'react';
import { IUser } from 'common/schema/User';
import { Button, Alert } from 'antd';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { UserApi } from '../UserApi';
import './UserCreateServiceToken.scss';
import { PermissionLogic } from 'common/logic/PermissionLogic';

export interface IUserCreateServiceTokenProps {
  user: IUser;
}

/**
 * Componente per creare il service token di un account di tipo "Utente di Sistema".
 * Una volta generato il token lo mostra.
 */
export function UserCreateServiceToken(props: IUserCreateServiceTokenProps) {
  const [
    runCreateServiceToken,
    { loading, response, error }
  ] = useApiMutationState(UserApi.createServiceToken, {
    data: { id: props.user.id }
  });

  if (!PermissionLogic.hasRootRole(props.user, 'System')) return null;

  return (
    <div>
      {(!response || error) && (
        <Button
          onClick={e => runCreateServiceToken({})}
          loading={loading}
          icon="code"
        >
          Crea Service Token
        </Button>
      )}
      {response && (
        <Alert
          showIcon
          type="success"
          message="Service Token creato"
          description={
            <div>
              Il token è stato creato. Copiarlo e salvarlo in un luogo sicuro.
              Al ricaricamento della pagina non sarà possibile ottenerlo
              nuovamente (a meno di rigenerarne uno nuovo, invalidando il
              precedente):
              <pre className="create-service-token-code">
                {response.data.serviceTokenJwt}
              </pre>
            </div>
          }
        />
      )}
    </div>
  );
}
