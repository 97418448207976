import React from 'react';

export const managementSvg = (
  <svg
    id="icon-svg-management"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>management</title>
    <g>
      <path d="M245.73,203.63l-41.06-41a53.1,53.1,0,0,0,2-14.16,56.08,56.08,0,0,0-70.33-54.76l-4.18,1,5.88,5.75,25.37,25.38-7.59,28.34-28.35,7.6-25.37-25.37-5.73-5.91-1.06,4.2a55.83,55.83,0,0,0-1.85,13.73A56.67,56.67,0,0,0,150,205a53.43,53.43,0,0,0,14.15-2l41.05,41.05a28.63,28.63,0,0,0,40.49-40.49Z" />
      <path d="M266.66,154.18l-9.5-6.35a5.62,5.62,0,0,1-2.44-4.33,105.44,105.44,0,0,0-3.87-22.28,5.52,5.52,0,0,1,.7-4.66l6.47-9.5c1.85-2.73,1.94-5,.9-6.87L252,87.73a5.67,5.67,0,0,0-5.17-2.92l-11.41.44a5.56,5.56,0,0,1-4.53-2.07,104,104,0,0,0-16.23-15.91,5.51,5.51,0,0,1-2.17-4.18l-.35-11.34c-.11-3.28-1.37-5.17-3.31-6.07l-12.91-6a5.68,5.68,0,0,0-5.9.68l-9,7.1a5.58,5.58,0,0,1-4.88,1,103.7,103.7,0,0,0-22.38-3.29,5.47,5.47,0,0,1-4.2-2.1l-7-9c-2-2.61-4.14-3.39-6.24-3l-14,2.72a5.66,5.66,0,0,0-4.37,4l-3.09,10.91a5.58,5.58,0,0,1-3.35,3.66A105.56,105.56,0,0,0,91.4,63a5.53,5.53,0,0,1-4.65.77l-11-3.19c-3.16-.92-5.34-.3-6.8,1.26L59.25,72.22a5.67,5.67,0,0,0-1.17,5.83l4,10.73a5.64,5.64,0,0,1-.57,5,101.56,101.56,0,0,0-6.62,11.85c-1.29,2.77-2.42,5.57-3.44,8.39a5.55,5.55,0,0,1-3.34,3.39l-10.7,3.84c-3.09,1.12-4.5,2.9-4.76,5l-1.73,14.14a5.66,5.66,0,0,0,2.47,5.4l9.5,6.35a5.59,5.59,0,0,1,2.44,4.32,105.22,105.22,0,0,0,3.87,22.28,5.54,5.54,0,0,1-.7,4.67L42,192.94c-1.85,2.73-1.94,5-.9,6.87L48,212.27a5.67,5.67,0,0,0,5.17,2.92l11.41-.44a5.56,5.56,0,0,1,4.53,2.07,104.47,104.47,0,0,0,16.23,15.91,5.51,5.51,0,0,1,2.17,4.18l.36,11.34c.1,3.28,1.36,5.16,3.3,6.07l12.91,6a5.68,5.68,0,0,0,5.9-.68l9-7.1a5.58,5.58,0,0,1,4.88-1,103.7,103.7,0,0,0,22.38,3.29,5.5,5.5,0,0,1,4.21,2.1l7,9c2,2.61,4.14,3.39,6.24,3l14-2.72a5.66,5.66,0,0,0,4.37-4l3.09-10.91V246l-22.43-22.43a73.69,73.69,0,1,1,61.41-63.46l22.48,22.48H252l11.72-4.21a5.68,5.68,0,0,0,3.71-4.65l1.73-14.14A5.66,5.66,0,0,0,266.66,154.18Z" />
    </g>
  </svg>
);
export const discardSvg = (
  <svg
    id="icon-svg-discard"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>discard</title>
    <path d="M229.2,63.11H198.88C197.76,46.23,183.37,30,165.84,30H134.16c-17.53,0-31.92,16.23-33,33.11H70.8A10.7,10.7,0,0,0,60,73.68v9.39A10.7,10.7,0,0,0,70.8,93.64H229.2A10.7,10.7,0,0,0,240,83.07V73.68A10.7,10.7,0,0,0,229.2,63.11Zm-106.35,0c1-5.2,5.7-13.85,11.31-13.85h31.68c5.61,0,10.29,8.65,11.31,13.85Zm99.27,49.08H77.4a10.53,10.53,0,0,0-7.59,3.16,10.05,10.05,0,0,0-2.87,7.57L73.47,259.6A11.16,11.16,0,0,0,84.6,270H214.92a11.17,11.17,0,0,0,11.13-10.4l6.53-136.68a10.09,10.09,0,0,0-2.87-7.57A10.53,10.53,0,0,0,222.12,112.19ZM117.84,227.73c0,5.84-4.3,10.57-9.6,10.57s-9.6-4.73-9.6-10.57V150.94c0-5.84,4.3-10.57,9.6-10.57s9.6,4.73,9.6,10.57Zm41.76,0c0,5.84-4.3,10.57-9.6,10.57s-9.6-4.73-9.6-10.57V150.94c0-5.84,4.3-10.57,9.6-10.57s9.6,4.73,9.6,10.57Zm41.76,0c0,5.84-4.3,10.57-9.6,10.57s-9.6-4.73-9.6-10.57V150.94c0-5.84,4.3-10.57,9.6-10.57s9.6,4.73,9.6,10.57Z" />
  </svg>
);
export const instanceSvg = (
  <svg
    id="icon-svg-instance"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>instance</title>
    <g>
      <path d="M260,60c0,16.56-49.25,30-110,30S40,76.56,40,60,89.25,30,150,30,260,43.43,260,60Zm0,0" />
      <path d="M150,110C89.25,110,40,96.57,40,80v40c0,16.57,49.25,30,110,30s110-13.43,110-30V80c0,16.57-49.25,30-110,30Zm0,0" />
      <path d="M150,170c-60.75,0-110-13.43-110-30v40c0,16.57,49.25,30,110,30s110-13.43,110-30V140c0,16.57-49.25,30-110,30Zm0,0" />
      <path d="M150,230c-60.75,0-110-13.43-110-30v40c0,16.57,49.25,30,110,30s110-13.43,110-30V200c0,16.57-49.25,30-110,30Zm0,0" />
    </g>
  </svg>
);
export const conservationSvg = (
  <svg
    id="icon-svg-conservation"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>conservation</title>
    <path d="M203.68,30V65.48h36Zm-6,49.54a8.05,8.05,0,0,1-8.06-8V30H84.22a23.79,23.79,0,0,0-24,23.53v193c0,12.94,10.78,23.48,24,23.48H215.77c13.22,0,24-10.54,24-23.48v-167Zm-6.32,107.82h-.05a28.77,28.77,0,0,1-28.73,28.74H137.42a28.77,28.77,0,0,1-28.73-28.74V161.44a15.17,15.17,0,0,1,12-14.81V131.2A29.38,29.38,0,0,1,150,101.86h.09a29.38,29.38,0,0,1,29.34,29.34v15.43a15.18,15.18,0,0,1,12,14.81ZM150,115.92h-.09a15.28,15.28,0,0,0-15.28,15.28v15.05H165.3V131.2A15.28,15.28,0,0,0,150,115.92Z" />
  </svg>
);
export const depositSvg = (
  <svg
    id="icon-svg-deposit"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>deposit</title>
    <path d="M264.15,182.83H203.4a5.86,5.86,0,0,0-5.86,5.87v30.37a5.86,5.86,0,0,1-5.85,5.87H108.31a5.86,5.86,0,0,1-5.85-5.87V188.7a5.86,5.86,0,0,0-5.86-5.87H35.85A5.86,5.86,0,0,0,30,188.7v72.49A8.8,8.8,0,0,0,38.78,270H261.22a8.8,8.8,0,0,0,8.78-8.81V188.7A5.86,5.86,0,0,0,264.15,182.83ZM145.81,211.45a5.91,5.91,0,0,0,8.38,0l82.1-82.4a6,6,0,0,0-4.2-10.16H186.25V38.47A8.45,8.45,0,0,0,177.81,30H122.19a8.45,8.45,0,0,0-8.44,8.47v80.42H67.91a6,6,0,0,0-4.2,10.16Z" />
  </svg>
);
export const requestSvg = (
  <svg
    id="icon-svg-request"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>request</title>
    <path d="M69,129.53V115.08a14.72,14.72,0,0,1,14.6-14.84H216.4A14.71,14.71,0,0,1,231,115.08v14.45l18.71-19a4,4,0,0,0-.36-5.9L159,33.12a14.43,14.43,0,0,0-17.94,0L50.65,104.61a4,4,0,0,0-.37,5.9Zm198.61-7.41a3.82,3.82,0,0,0-4.22.86L164.27,223.73a19.95,19.95,0,0,1-28.54,0L36.62,123a3.82,3.82,0,0,0-4.23-.85A3.93,3.93,0,0,0,30,125.76v128A16.14,16.14,0,0,0,46,270H254a16.14,16.14,0,0,0,16-16.27v-128A4,4,0,0,0,267.61,122.12Zm-73.27.82H105.67a8.14,8.14,0,0,0,0,16.27h88.67a8.14,8.14,0,0,0,0-16.27Zm0,32.54H105.67a8.13,8.13,0,0,0,0,16.26h88.67a8.13,8.13,0,0,0,0-16.26Z" />
  </svg>
);
export const grantSvg = (
  <svg
    id="icon-svg-grant"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>grant</title>
    <path d="M69,129.53V115.08a14.72,14.72,0,0,1,14.6-14.84H216.4A14.71,14.71,0,0,1,231,115.08v14.45l18.71-19a4,4,0,0,0-.36-5.9L159,33.12a14.43,14.43,0,0,0-17.94,0L50.65,104.61a4,4,0,0,0-.37,5.9Zm198.61-7.41a3.82,3.82,0,0,0-4.22.86L164.27,223.73a19.95,19.95,0,0,1-28.54,0L36.62,123a3.82,3.82,0,0,0-4.23-.85A3.93,3.93,0,0,0,30,125.76v128A16.14,16.14,0,0,0,46,270H254a16.14,16.14,0,0,0,16-16.27v-128A4,4,0,0,0,267.61,122.12Zm-73.27.82H105.67a8.14,8.14,0,0,0,0,16.27h88.67a8.14,8.14,0,0,0,0-16.27Zm0,32.54H105.67a8.13,8.13,0,0,0,0,16.26h88.67a8.13,8.13,0,0,0,0-16.26Z" />
  </svg>
);
export const searchSvg = (
  <svg
    id="icon-svg-icone"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>search</title>
    <path d="M240.82,59.51a99.82,99.82,0,0,0-157.88,119l-45,45A27,27,0,0,0,76.16,261.8L121,217A99.85,99.85,0,0,0,240.82,59.51ZM219.75,179.62a70,70,0,1,1,0-99.05A69.58,69.58,0,0,1,219.75,179.62Z" />
  </svg>
);

export const documentSvg = (
  <svg
    id="icon-svg-document"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>document</title>
    <g>
      <polygon points="203.68 30 203.68 65.48 239.72 65.48 203.68 30" />
      <path d="M197.68,79.55a8.05,8.05,0,0,1-8.06-8V30H84.23a23.8,23.8,0,0,0-24,23.53v193c0,12.94,10.78,23.49,24,23.49H215.77c13.22,0,24-10.55,24-23.49v-167Zm-3.35,125.72H105.67a8.14,8.14,0,0,1,0-16.27h88.66a8.14,8.14,0,0,1,0,16.27Zm0-41H105.67a8.13,8.13,0,0,1,0-16.26h88.66a8.13,8.13,0,0,1,0,16.26Zm0-38.88H105.67a8.13,8.13,0,0,1,0-16.26h88.66a8.13,8.13,0,0,1,0,16.26Z" />
    </g>
  </svg>
);
export const helpSvg = (
  <svg
    id="icon-svg-help"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>help</title>
    <path d="M150,30a120,120,0,1,0,120,120A120,120,0,0,0,150,30Zm8.55,186.73a14.14,14.14,0,0,1-9.68,3.72,14.59,14.59,0,0,1-9.91-3.68c-2.82-2.44-4.23-5.88-4.23-10.28a13.22,13.22,0,0,1,4.09-9.86,13.82,13.82,0,0,1,10-4,13.66,13.66,0,0,1,13.87,13.86q0,6.51-4.19,10.24Zm34.75-86.17a42.24,42.24,0,0,1-7.63,10.28q-4.41,4.33-15.87,14.57a65,65,0,0,0-5.07,5.06,20.46,20.46,0,0,0-2.84,4,19.57,19.57,0,0,0-1.44,3.63c-.34,1.2-.85,3.33-1.54,6.37q-1.75,9.67-11.06,9.68a11.34,11.34,0,0,1-8.15-3.16q-3.3-3.17-3.3-9.4a34.7,34.7,0,0,1,2.42-13.54A34.26,34.26,0,0,1,145.25,148,148.17,148.17,0,0,1,156,137.71c4-3.47,6.84-6.09,8.61-7.85a26.44,26.44,0,0,0,4.46-5.92,14.15,14.15,0,0,0,1.82-7.06,16.4,16.4,0,0,0-5.54-12.56q-5.53-5.13-14.28-5.12-10.25,0-15.08,5.16t-8.19,15.21q-3.16,10.52-12,10.52a11.84,11.84,0,0,1-8.8-3.67,11.26,11.26,0,0,1-3.57-8q0-8.83,5.67-17.91a43.75,43.75,0,0,1,16.57-15q10.88-6,25.4-6,13.48,0,23.81,5a38.52,38.52,0,0,1,16,13.54,33.18,33.18,0,0,1,5.63,18.6,28.77,28.77,0,0,1-3.21,13.86Zm0,0" />
  </svg>
);
export const homeSvg = (
  <svg
    id="icon-svg-home"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>home</title>
    <path d="M266.27,132.6,158.68,33.35a12.92,12.92,0,0,0-17.36,0L33.73,132.6a11.49,11.49,0,0,0-.52,16.45,12.51,12.51,0,0,0,8.84,3.9H52.8V258.22A11.74,11.74,0,0,0,64.5,270h171a11.74,11.74,0,0,0,11.7-11.78V153H258a12.51,12.51,0,0,0,8.84-3.9A11.49,11.49,0,0,0,266.27,132.6ZM150,177.74h24.56v75.9H125.44v-75.9Z" />
  </svg>
);
export const logoutSvg = (
  <svg
    id="icon-svg-logout"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>logout</title>
    <g>
      <path d="M43.28,150.4c0-32.52,16.45-60.81,41.12-79.25,5.83-4.35,17.49-6.28,22.18-.72a18.25,18.25,0,0,1,4.07,11.5c.22,7.26-7.31,16.11-12.49,21.2A66,66,0,0,0,78,150.49c0,39.06,31.66,68.66,70.71,68.66s70.74-29.6,70.74-68.66c0-19.9-7.6-37.38-20.22-49.74C194,95.66,187,86.68,188.1,79.49a16.84,16.84,0,0,1,6.37-11.21c5.8-4.38,17.41-1.25,23.15,3.21,23.68,18.43,39.1,46.6,39.1,78.91C256.72,207.63,206,252,148.7,252S43.28,207.63,43.28,150.4Zm0,0" />
      <path d="M166.81,46.07c0-12.29-9.41-16-16.55-16-16.39,0-16.56,15-16.56,16v99.36c0,12.29,9.41,16,16.55,16,16.39,0,16.56-15,16.56-16Z" />
    </g>
  </svg>
);
export const navigation_backSvg = (
  <svg
    id="icon-svg-navigation_back"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>navigation_back</title>
    <path d="M150,30A120,120,0,1,0,270,150,120,120,0,0,0,150,30Zm27.75,182.93A13.25,13.25,0,1,1,159,231.66L87.36,160a13.25,13.25,0,0,1,0-18.73l71.75-71.75a13.25,13.25,0,1,1,18.73,18.73l-62.39,62.38Z" />
  </svg>
);
export const folderSvg = (
  <svg
    id="icon-svg-folder"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
  >
    <title>folder</title>
    <g>
      <path d="M250.07,111.69H82.94a19.8,19.8,0,0,0-19.33,15.15s0,.07,0,.11L34.4,225.45a5,5,0,0,0,.16,3.27A19.83,19.83,0,0,0,53,241.25H220.17a19.87,19.87,0,0,0,19.13-14.46l.12-.32L269,137.78a5.14,5.14,0,0,0,.21-.86,19.36,19.36,0,0,0,.77-5.3,20,20,0,0,0-19.93-19.93Zm0,0" />
      <path d="M34.26,162.18a4.43,4.43,0,0,0,.72,0,5,5,0,0,0,4.78-3.57l11.1-37.5s0-.11,0-.17A29.86,29.86,0,0,1,79.83,98.61H231.1A5,5,0,0,0,235.79,92a20,20,0,0,0-18.73-13.28H126.74L108.26,60.21a4.93,4.93,0,0,0-3.52-1.46H49.93A20,20,0,0,0,30,78.68v78.57a5,5,0,0,0,4.26,4.93Z" />
    </g>
  </svg>
);
