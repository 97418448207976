import {
  DiscardPolicyRuleField,
  DiscardPolicyRuleOperators,
  DiscardPolicyRuleType,
  DiscardPolicyRuleOperator,
  DiscardPolicyRule,
  DiscardPolicyRuleFieldName,
  AvailableDiscardPolicyRuleFields
} from 'common/schema/DiscardPolicyRule';
import { IUser } from 'common/schema/User';
import { PermissionLogic } from './PermissionLogic';
import { Roles } from 'common/permissions/Roles';
import {
  IDiscardPolicy,
  DiscardPolicyTarget
} from 'common/schema/DiscardPolicy';
import { IDiscardPolicyCreateDto } from 'common/dto/DiscardPolicyDto';

export class DiscardRuleLogic {
  /** Non permettere l'uso dell'IN agli utenti non-root sulle regole AOO */
  static getOperators(
    ruleField: DiscardPolicyRuleField | undefined,
    user: IUser | null
  ) {
    if (!ruleField) return [];

    let operators = DiscardPolicyRuleOperators.filter(op =>
      ruleField.operators.includes(op.value)
    );

    const isUserRoot = PermissionLogic.has(user, 'discards.write', {
      forRoot: true
    });

    if (!isUserRoot && ruleField.type === DiscardPolicyRuleType.Area) {
      operators = operators.filter(
        op => op.value !== DiscardPolicyRuleOperator.In
      );
    }

    return operators;
  }

  /**
   * Gli utenti specifici avranno, di default, una regola per filtrare i documenti
   * appartenenti a una delle AOO a loro disponibili.
   * Gli utenti generali, invece, non avranno questa restrizione.
   */
  static getDefault(user: IUser | null): DiscardPolicyRule[] {
    const isUserRoot = PermissionLogic.has(user, 'discards.write', {
      forRoot: true
    });

    if (isUserRoot) return [{}] as any;

    return [
      {
        field: DiscardPolicyRuleFieldName.areaUid,
        operator: DiscardPolicyRuleOperator.Equals,
        value: undefined
      }
    ];
  }

  /**
   * Genera gli initialValues per il formik in base all'utente.
   */
  static getDiscardPolicyInitialValue(
    policy: IDiscardPolicy | null | undefined,
    user: IUser | null
  ): IDiscardPolicyCreateDto {
    // Se la policy esiste, ritorna l'originale
    if (policy) return policy as IDiscardPolicyCreateDto;
    return {
      name: '',
      laws: '',
      description: '',
      rules: this.getDefault(user),
      target: DiscardPolicyTarget.Dossiers
    } as any;
  }

  /**
   * Ritorna vero se la rule deve essere bloccata.
   * Gli utenti specifici devono avere una rule di tipo "[AOO][uguale a]" non modificabile.
   */
  static isFieldDisabled(user: IUser | null, rule: DiscardPolicyRule) {
    const isUserRoot = PermissionLogic.has(user, 'discards.write', {
      forRoot: true
    });

    if (isUserRoot) return false;

    return rule.field === DiscardPolicyRuleFieldName.areaUid;
  }

  /**
   * Gli utenti specifici non possono aggiungere rule per filtrare via AOO
   * oltre a quella di default.
   */
  static canAddAooField(user: IUser | null) {
    return PermissionLogic.has(user, 'discards.write', {
      forRoot: true
    });
  }

  /**
   * Ritorna i field selezionabili delle rules.
   * Le rules di tipo AOO appaiono nelle opzioni solo se sono già presenti come valore (e.g. dal formik)
   * o se l'utente è un utente generale.
   * Gli utenti specifici non possono aggiungere altre regole di tipo AOO (oltre a quella di default già presente).
   */
  static getAvaiableFields(
    user: IUser | null,
    targetField: DiscardPolicyTarget,
    rule: DiscardPolicyRule
  ) {
    return (
      // Seleziona i ruleField in base al target
      AvailableDiscardPolicyRuleFields.filter(f => f.target === targetField)
        // Se la regola contiene già il field AOO oppure si ha l'autorizzazione, non filtrare
        // altrimenti, rimuovi i ruleField di tipo AOO
        .filter(f =>
          this.canAddAooField(user) ||
          rule.field === DiscardPolicyRuleFieldName.areaUid
            ? true
            : f.field !== DiscardPolicyRuleFieldName.areaUid
        )
    );
  }
}
