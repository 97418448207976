import { useCurrentUser } from 'client/components/auth/AuthModule';
import React, { useState, useCallback } from 'react';
import { Button, Modal, message, Typography } from 'antd';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { UserApi } from '../UserApi';
import { UserLogic } from 'common/logic/UserLogic';
import { openMassiveImportBagModal } from './openMassiveImportBagModal';
import { useClientConfig } from 'client/components/config/ClientConfigModule';

const { Text } = Typography;

export interface DocumentalImportButtonProps {}

export function DocumentalImportButton(props: DocumentalImportButtonProps) {
  const currentUser = useCurrentUser();
  const config = useClientConfig();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const massiveImport = useApiMutation(UserApi.userImport, {
    invalidates: [UserApi.findUsers]
  });

  const handleOk = useCallback(async () => {
    setLoading(true);
    try {
      const importResponse = await massiveImport({});
      if (importResponse.data.importBag.errors.length > 0) {
        openMassiveImportBagModal(importResponse.data.importBag.errors);
      }
      setVisible(false);
      message.success(`Utenti importati con successo`);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCancel = useCallback(() => {
    if (loading) {
      return;
    }
    setVisible(false);
  }, []);

  // TODO [MED] -> _tutti_ gli admin o solo quello generale?
  if (!UserLogic.isAdmin(currentUser)) {
    return null;
  }

  // Se la configurazione del documentale non permette l'import massivo, non mostrare il pulsante.
  if (!config || !config.documental.features.massiveUsersImport) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setVisible(true)}>Importa Utenti</Button>
      <Modal
        title="Importazione massiva utenti"
        visible={visible}
        onCancel={handleCancel}
        onOk={async () => await handleOk()}
        cancelText={'Annulla'}
        okText={'Conferma'}
        cancelButtonProps={{ disabled: loading }}
        maskClosable={!loading}
        okButtonProps={{ loading: loading }}
        closable={false}
      >
        Sei sicuro di voler importare gli utenti dal documentale? Questa
        operazione aggiornerà tutti gli utenti e non potrà essere annullata.
      </Modal>
    </>
  );
}
