import { IAttachment } from 'common/schema/Attachment';
import { IAttachmentVersion } from 'common/schema/AttachmentVersion';

/**
 * Dobbiamo gestire l'anteprima sull'allegato e sulle sue eventuali
 * versioni. Per questo definiamo un tipo "Preview" che permette di gestirli
 * entrambi
 */
export type AttachmentPreview =
  | { type: 'attachment'; item: IAttachment }
  | { type: 'version'; item: IAttachmentVersion; attachment: IAttachment };

export function previewAttachment(attachment: IAttachment): AttachmentPreview {
  return {
    type: 'attachment',
    item: attachment
  };
}

export function previewAttachmentVersion(
  attachment: IAttachment,
  version: IAttachmentVersion
): AttachmentPreview {
  return {
    type: 'version',
    item: version,
    attachment
  };
}
