import React from 'react';
import moment from 'moment';
import { useState } from 'react';
import { useInterval } from 'client/core/hooks/useInterval';

const FORMAT = 'dddd, D MMMM YYYY, HH:mm:ss';

export interface ILiveDateProps {}

export function LiveDate(props: ILiveDateProps) {
  const [date, setDate] = useState(moment().format(FORMAT));
  useInterval(() => {
    setDate(moment().format(FORMAT));
  }, 1000);

  return <span>{date}</span>;
}
