import React from 'react';
import { Row, Col, Alert, Card } from 'antd';
import { StatisticCC, StatisticCCProps } from 'client/ui/statistic/StatisticCC';

export interface ColCardStatisticCCProps extends StatisticCCProps {
  span: number;
}

/**
 * Elemento per mostrare i dati dell'archivio di deposito nella dashboard
 *
 */
export function ColCardStatisticCC(props: ColCardStatisticCCProps) {
  const { span, ...othersProps } = props;
  return (
    <Col span={span}>
      <Card size="small" style={{ margin: '12px 0' }}>
        <StatisticCC {...othersProps} />
      </Card>
    </Col>
  );
}
