import * as yup from 'yup';

declare module 'yup' {
  interface ObjectSchema {
    /**
     * Richiede che l'oggetto sia uno di quelli specificati
     */
    oneOfType<T1 extends object, T2 extends object>(types: [ObjectSchema<T1>, ObjectSchema<T2>], discriminator?: keyof T1 & keyof T2): ObjectSchema<T1 | T2>; // prettier-ignore
    oneOfType<T1 extends object, T2 extends object, T3 extends object>(types: [ObjectSchema<T1>, ObjectSchema<T2>, ObjectSchema<T3>], discriminator?: keyof T1 & keyof T2 & keyof T3): ObjectSchema<T1 | T2 | T3>; // prettier-ignore
  }
}

/**
 * Estrae il tipo in base ai metadatai salvati con `exact`
 */
function getTypeValue(schema: any, discriminator: string) {
  return schema.fields[discriminator]._meta.literalValue;
}

yup.addMethod(yup.object, 'oneOfType', function (
  types: Array<yup.ObjectSchema<any>>,
  discriminator = 'type'
) {
  return this.test({
    message: 'Il campo ${path} deve rispettare uno degli schemi disponibili.',
    name: 'oneOfType',
    exclusive: true,
    test(value) {
      if (!value || typeof value !== 'object') return false;
      for (let type of types) {
        if (getTypeValue(type, discriminator) === value[discriminator]) {
          try {
            type.validateSync(value);
            return true;
          } catch (e) {
            return e;
          }
        }
      }
      return false;
    }
  });
});
