import { DiscardPolicyTarget } from './DiscardPolicy';

export enum DiscardPolicyRuleOperator {
  Equals = 'Eq',
  StartsWith = 'Sta',
  NotEquals = 'Neq',
  GreaterThan = 'Gt',
  LessThan = 'Lt',
  In = 'In',
  Before = 'Bef',
  After = 'Aft'
}

export enum DiscardPolicyRuleType {
  Area = 'Area',
  String = 'String',
  Number = 'Number',
  Date = 'Date'
}

export enum DiscardPolicyRuleFieldName {
  areaUid = 'areaUid',
  register = 'register',
  typology = 'typology',
  createdAt = 'createdAt',
  closedAt = 'closedAt',
  registeredAt = 'registeredAt',
  category = 'category'
}

export const DiscardPolicyRuleOperators: {
  value: DiscardPolicyRuleOperator;
  label: string;
}[] = [
  { value: DiscardPolicyRuleOperator.In, label: 'in' },
  { value: DiscardPolicyRuleOperator.Equals, label: 'uguale a' },
  { value: DiscardPolicyRuleOperator.NotEquals, label: 'diverso da' },
  { value: DiscardPolicyRuleOperator.GreaterThan, label: 'maggiore di' },
  { value: DiscardPolicyRuleOperator.LessThan, label: 'minore di' },
  { value: DiscardPolicyRuleOperator.Before, label: 'fino al' },
  { value: DiscardPolicyRuleOperator.After, label: 'dal' },
  { value: DiscardPolicyRuleOperator.StartsWith, label: 'inizia con' }
];

export function getDiscardPolicyRuleOperator(
  operator: DiscardPolicyRuleOperator
) {
  return DiscardPolicyRuleOperators.find(o => o.value === operator)!;
}

export interface DiscardPolicyRuleField {
  group: string;
  target: DiscardPolicyTarget;
  field: DiscardPolicyRuleFieldName;
  title: string;
  type: DiscardPolicyRuleType;
  operators: DiscardPolicyRuleOperator[];
}

export const AvailableDiscardPolicyRuleFields: DiscardPolicyRuleField[] = [
  // Fascicoli
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Dossiers,
    field: DiscardPolicyRuleFieldName.areaUid,
    title: 'AOO',
    type: DiscardPolicyRuleType.Area,
    operators: [DiscardPolicyRuleOperator.Equals, DiscardPolicyRuleOperator.In]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Dossiers,
    field: DiscardPolicyRuleFieldName.category,
    title: 'Codice di classificazione',
    type: DiscardPolicyRuleType.String, // TODO: Select titolari?
    operators: [
      DiscardPolicyRuleOperator.Equals,
      DiscardPolicyRuleOperator.StartsWith
    ]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Dossiers,
    field: DiscardPolicyRuleFieldName.typology,
    title: 'Tipo fascicolo',
    type: DiscardPolicyRuleType.String,
    operators: [DiscardPolicyRuleOperator.Equals]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Dossiers,
    field: DiscardPolicyRuleFieldName.createdAt,
    title: 'Data di creazione',
    type: DiscardPolicyRuleType.Date,
    operators: [
      DiscardPolicyRuleOperator.Before,
      DiscardPolicyRuleOperator.After
    ]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Dossiers,
    field: DiscardPolicyRuleFieldName.closedAt,
    title: 'Data di chiusura',
    type: DiscardPolicyRuleType.Date,
    operators: [
      DiscardPolicyRuleOperator.Before,
      DiscardPolicyRuleOperator.After
    ]
  },
  // Documenti
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Documents,
    field: DiscardPolicyRuleFieldName.areaUid,
    title: 'AOO',
    type: DiscardPolicyRuleType.Area,
    operators: [DiscardPolicyRuleOperator.Equals, DiscardPolicyRuleOperator.In]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Documents,
    field: DiscardPolicyRuleFieldName.register,
    title: 'Registro',
    type: DiscardPolicyRuleType.String,
    operators: [DiscardPolicyRuleOperator.Equals]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Documents,
    field: DiscardPolicyRuleFieldName.category,
    title: 'Codice di classificazione',
    type: DiscardPolicyRuleType.String,
    operators: [
      DiscardPolicyRuleOperator.Equals,
      DiscardPolicyRuleOperator.StartsWith
    ]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Documents,
    field: DiscardPolicyRuleFieldName.typology,
    title: 'Tipo documento',
    type: DiscardPolicyRuleType.String,
    operators: [DiscardPolicyRuleOperator.Equals]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Documents,
    field: DiscardPolicyRuleFieldName.createdAt,
    title: 'Data di creazione',
    type: DiscardPolicyRuleType.Date,
    operators: [
      DiscardPolicyRuleOperator.Before,
      DiscardPolicyRuleOperator.After
    ]
  },
  {
    group: 'Generali',
    target: DiscardPolicyTarget.Documents,
    field: DiscardPolicyRuleFieldName.registeredAt,
    title: 'Data di protocollo',
    type: DiscardPolicyRuleType.Date,
    operators: [
      DiscardPolicyRuleOperator.Before,
      DiscardPolicyRuleOperator.After
    ]
  }
];

export function getDiscardPolicyRuleField(field: DiscardPolicyRuleFieldName) {
  return AvailableDiscardPolicyRuleFields.find(o => o.field === field)!;
}

/**
 * Singola regola di scarto.
 */
export interface DiscardPolicyRule {
  field: DiscardPolicyRuleFieldName;
  operator: DiscardPolicyRuleOperator;
  value: any;
  /**
   * Utilizzato solo al rendering.
   */
  displayValue?: any;
}
