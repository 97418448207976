import * as React from 'react';
import { Modal, Button, Icon } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { TableScroll } from 'client/ui/table/TableScroll';
import { useState } from 'react';
import { IDiscardQueryDto } from 'common/dto/DiscardQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DiscardApi } from '../DiscardApi';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { IDiscard } from 'common/schema/Discard';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  DiscardStatusTag,
  DiscardStateOptions
} from '../detail/status/DiscardStatusTag';
import { ISODate } from 'client/ui/display/date/ISODate';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';
import {
  createColumnFilter,
  FilterInputType,
  getFilter,
  getDateRange
} from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';

export interface DiscardsListPageProps {}

/**
 * Pagina di elenco degli Scarti
 */
export function DiscardsListPage(props: DiscardsListPageProps) {
  const history = useHistory();

  function infoNewDiscard() {
    Modal.info({
      autoFocusButton: null,
      maskClosable: true,
      title: 'Nuovo Scarto',
      okText: 'Vai alle Policy di Scarto',
      content: (
        <div>
          <p>
            Per Creare un nuovo Scarto selezionare una{' '}
            <strong>Policy di Scarto</strong> dalla pagina dedicata (oppure
            crearne una nuova) e fare click sul pulsante{' '}
            <strong>Crea proposta di Scarto</strong>.
          </p>
        </div>
      ),
      onOk() {
        history.push('/app/discard-policies');
      }
    });
  }

  const [query, setQuery] = useState<IDiscardQueryDto>({
    page: 1,
    pageSize: 10,
    orderBy: 'createdAt',
    orderDirection: ColumnSortDirections.descend
  });
  const { response, loading, error } = useApiQuery(DiscardApi.list, {
    data: { query }
  });

  const columns: ColumnProps<IDiscard>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      sorter: true
    },
    {
      title: 'Stato',
      dataIndex: 'state',
      key: 'state',
      width: 200,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: DiscardStateOptions
      }),
      render: (state, discard) => <DiscardStatusTag discard={discard} />
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      width: 120,
      sorter: true,
      render: date => <ISODate date={date} />
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 110,
      render: (_, record) => (
        <Link to={`/app/discards/edit/${record.id}`}>
          <Button size="small">
            <Icon type="eye" /> <TableButtonDetailText />
          </Button>
        </Link>
      )
    }
  ];

  if (error) {
    return (
      <AlertPage
        message="Impossibile caricare l'elenco degli scarti"
        description={getNetworkErrorMessage(error)}
      />
    );
  }

  return (
    <PageLayout
      hasScrollable
      fullTable
      hasMenu
      title={
        <CustomCardTitle
          image="discard"
          title="Elenco Scarti Archivio di Deposito"
        />
      }
      extra={
        <div className="page-card-extra">
          <Button type="primary" icon="plus" onClick={infoNewDiscard}>
            Nuovo Scarto
          </Button>
        </div>
      }
    >
      <TableScroll
        columns={columns}
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data.items ?? []}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<IDiscard>;
          const description = getFilter<string>(filters, 'description');

          setQuery({
            ...query,
            description,
            states: filters.state,
            page: pagination.current || query.page,
            pageSize: pagination.pageSize || query.pageSize,
            orderBy: order ? field.toString() : 'createdAt',
            orderDirection: order
              ? ColumnSortDirections[order]
              : ColumnSortDirections.descend
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
