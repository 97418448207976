import * as yup from 'yup';

const originalOneOf = yup.mixed.prototype.oneOf as Function;

yup.mixed.prototype.oneOf = function (
  this: yup.Schema<any>,
  arrayOfValues: any[],
  ...args: any[]
) {
  return originalOneOf.call(
    this.meta({ oneOf: arrayOfValues }),
    arrayOfValues,
    ...args
  );
};
