import * as React from 'react';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { IDossierStandardMetadataPhase } from 'common/schema/DossierStandardMetadata';
import { SchemaSection } from '../schema/SchemaSection';
import { Divider } from 'antd';
import { SchemaLabel } from '../schema/SchemaLabel';

export interface PhasesViewProps {
  phases?: IDossierStandardMetadataPhase[];
}

/**
 * Dati dettaglio fasi procedimento da linee guida AgID
 */
export function PhasesView(props: PhasesViewProps) {
  const { phases } = props;
  if (!phases) return null;

  return (
    <SchemaSection ns="dossier" field="standardMetadata.phases">
      {phases.length > 0 && (
        <Divider orientation="left" className="divider-small">
          <SchemaLabel
            ns="dossier"
            field="standardMetadata.phases"
            label="Fase"
          />
        </Divider>
      )}

      {phases.map((phase, i) => (
        <div key={i} style={{ paddingLeft: '24px' }}>
          {i > 0 && <Divider />}
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.phases.name"
            label="Protocollo annullato"
            value={phase.name}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.phases.description"
            label="Descrizione"
            value={phase.description}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.phases.note"
            label="Nota"
            value={phase.note}
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.phases.startDate"
            label="Data Inizio"
            value={phase.startDate}
            type="date"
          />
          <SchemaMetadata
            ns="dossier"
            field="standardMetadata.phases.endDate"
            label="Data Fine"
            value={phase.endDate}
            type="date"
          />
        </div>
      ))}
    </SchemaSection>
  );
}
