import * as React from 'react';
import { IDiscardPolicy } from 'common/schema/DiscardPolicy';
import { Button, Modal, message } from 'antd';
import { DiscardPolicyApi } from '../DiscardPolicyApi';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export interface IDiscardPolicyStartButtonProps {
  policy: IDiscardPolicy | undefined;
  disabled?: boolean;
}

/**
 * Pulsante per avvio dello scarto a partire da una policy di scarto
 */
export function DiscardPolicyStartButton(
  props: IDiscardPolicyStartButtonProps
) {
  const { policy, disabled } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleStart = async () => {
    if (!policy || loading) return;
    setLoading(true);
    try {
      const result = await DiscardPolicyApi.start({ id: policy.id });
      setLoading(false);

      Modal.info({
        title: 'Scarto creato con successo',
        cancelText: 'Chiudi',
        okText: 'Vai allo Scarto',
        content: (
          <div>
            Lo scarto è stato creato con successo a partire dalla policy.
          </div>
        ),
        onOk: () => {
          history.push(`/app/discards/edit/${result.data!.id}`);
        }
      });
    } catch (e) {
      setLoading(false);
      message.error(
        getNetworkErrorMessage(
          e,
          'Si è verificato un errore durante la creazione dello scarto. '
        )
      );
      console.error(e);
    }
  };

  return (
    <>
      <Button
        loading={loading}
        disabled={!policy || disabled}
        type="primary"
        onClick={handleStart}
      >
        Crea Proposta di Scarto
      </Button>
    </>
  );
}
