import * as React from 'react';
import { AttachmentsTable } from 'client/components/metadata/tabs/AttachmentsTable';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { IDocument } from 'common/schema/Document';
import { IAttachment } from 'common/schema/Attachment';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { AttachmentColumn } from '../sections/AttachmentColumn';
import './DocumentAttachmentTab.scss';
import { AttachmentPreview } from '../attachment/AttachmentPreview';

export interface IDocumentAttachmentTabProps {
  document: IDocument;
  attachment: AttachmentPreview | null;
  setAttachment: (attachment: AttachmentPreview) => void;
}

/**
 * Tab degli allegati.
 *
 */
export function DocumentAttachmentTab(props: IDocumentAttachmentTabProps) {
  const { document, attachment, setAttachment } = props;

  return (
    <RowScroller>
      <ColScroller span={10} side="left" hasTableScroll>
        <AttachmentsTable
          ns="document"
          selected={attachment}
          onSelect={setAttachment}
          attachments={document.attachments ?? []}
        />
      </ColScroller>

      <AttachmentColumn attachment={attachment} />
    </RowScroller>
  );
}
