import * as React from 'react';
import { IDiscard } from 'common/schema/Discard';
import { DiscardLogic } from 'common/logic/DiscardLogic';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DiscardApi } from '../../DiscardApi';
import { Button, message } from 'antd';
import { useHistory } from 'react-router';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';

export interface IDiscardRemoveButtonProps {
  discard: IDiscard;
}

export function DiscardRemoveButton(props: IDiscardRemoveButtonProps) {
  const { discard } = props;

  const [remove, { loading }] = useApiMutationState(DiscardApi.remove, {
    data: { id: discard.id },
    invalidates: [DiscardApi.list]
  });
  const history = useHistory();

  const handleRemove = async () => {
    await remove({ data: { id: discard.id } });
    message.success('Lo scarto è stato eliminato correttamente');
    history.push(`/app/discards/list`);
  };

  if (!DiscardLogic.isEditableOrRemovable(discard)) return null;

  return (
    <ButtonDeleteConfirm
      okText="Elimina Scarto"
      title="Confermi di voler eliminare lo scarto?"
      size="default"
      loading={loading}
      onConfirm={handleRemove}
    />
  );
}
