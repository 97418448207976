import React from 'react';
import { Popconfirm, Button, Icon } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';

export interface ButtonDeleteConfirmProps extends PopconfirmProps {
  loading?: boolean;
  size?: 'small' | 'default' | 'large';
  iconOnlyButton?: boolean;
}

export function ButtonDeleteConfirm(props: ButtonDeleteConfirmProps) {
  const {
    placement,
    okText,
    cancelText,
    loading,
    size,
    iconOnlyButton,
    ...otherProps
  } = props;

  return (
    <Popconfirm
      placement={placement ? placement : 'topRight'}
      okText={okText ? okText : 'Elimina'}
      cancelText={cancelText ? cancelText : 'Annulla'}
      {...otherProps}
      okButtonProps={{ type: 'danger' }}
    >
      <Button
        type="danger"
        icon="delete"
        size={size ?? 'small'}
        loading={loading}
      >
        {iconOnlyButton ? '' : okText ? okText : 'Elimina'}
      </Button>
    </Popconfirm>
  );
}
