import * as React from 'react';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { IDossierGrant } from 'common/schema/Grant';
import { TableScroll } from 'client/ui/table/TableScroll';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';

export interface TableGrantsDossierListProps {
  dossiers: IDossierGrant[];
  onDelete?: (dossierId: number) => void;
  tableButton?: React.ReactNode;
  hideLink?: boolean;
  bottomSpace?: number;
}

/**
 * Tabella che mostra i fascicoli richiesti
 * con la CheckBox iniziale per concedere l'autorizzazione
 */
// TODO: da implementare versione solo visualizzazione
export function TableGrantsDossierList(props: TableGrantsDossierListProps) {
  const columns: ColumnProps<IDossierGrant>[] = [
    {
      title: 'Registro',
      dataIndex: 'dossier.register',
      key: 'dossier.register',
      width: '20%',
      ellipsis: true
    },
    {
      title: 'Codice',
      dataIndex: 'dossier.code',
      key: 'dossier.code',
      width: '20%',
      ellipsis: true
    },
    {
      title: 'Descrizione',
      dataIndex: 'dossier.title',
      key: 'dossier.title',
      ellipsis: true
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 80,
      render: (_, record) => (
        <div className="table-actions-column">
          {props.onDelete && (
            <ButtonDeleteConfirm
              iconOnlyButton
              title="Sei sicuro di voler eliminare questo Fascicolo dalla lista?"
              onConfirm={e => {
                props.onDelete!(record.dossier.id);
              }}
            />
          )}
          {!props.hideLink && (
            <Link
              to={`/app/dossiers/${record.dossier.areaUid}/${record.dossier.uid}`}
            >
              <Button icon="eye" size="small" />
            </Link>
          )}
        </div>
      )
    }
  ];

  return (
    <TableScroll
      columns={columns}
      dataSource={props.dossiers}
      bottomSpace={props.bottomSpace}
      size="middle"
      pagination={false}
      bottomLeft={props.tableButton}
    />
  );
}
