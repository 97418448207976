import * as React from 'react';
import { Icon } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { TableScroll } from 'client/ui/table/TableScroll';
import { IGrantRequest, GrantRequestType } from 'common/schema/GrantRequest';

export interface GrantRequestsTableProps {
  requests: IGrantRequest[];
  bottomSpace?: number;
}

/**
 * Tabella che mostra il dettaglio dei documenti/fascicoli richiesti
 */
export function GrantRequestsTable(props: GrantRequestsTableProps) {
  const columns: ColumnProps<IGrantRequest>[] = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      width: 40,
      render: (_, record) =>
        record.type === GrantRequestType.Document ? (
          <Icon type="file" style={{ fontSize: '18px' }} />
        ) : record.type === GrantRequestType.Dossier ? (
          <Icon type="folder" style={{ fontSize: '18px' }} />
        ) : null
    },
    {
      title: '',
      dataIndex: 'found',
      key: 'found',
      width: 40,
      render: (_, record) =>
        record.found === true ? (
          <Icon type="check" style={{ fontSize: '18px', color: '#389e0d' }} />
        ) : record.found === false ? (
          <Icon type="warning" style={{ fontSize: '18px', color: '#cf1322' }} />
        ) : null
    },
    {
      title: 'AOO',
      dataIndex: 'areaUid',
      key: 'areaUid',
      ellipsis: true
    },
    // {
    //   title: 'ID',
    //   dataIndex: 'uid',
    //   key: 'uid',
    //   ellipsis: true
    // },
    {
      title: 'Registro',
      dataIndex: 'register',
      key: 'register',
      ellipsis: true
    },
    {
      title: 'Protocollo',
      dataIndex: 'registerNumber',
      key: 'registerNumber',
      ellipsis: true
    },
    {
      title: 'Codice',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true
    }
    // {
    //   title: 'Classifica',
    //   dataIndex: 'category',
    //   key: 'category',
    //   ellipsis: true
    // }
  ];

  return (
    <TableScroll
      columns={columns}
      dataSource={props?.requests ?? []}
      bottomSpace={props.bottomSpace}
      size="middle"
      pagination={false}
      rowClassName={(record, i) => (record.found === false ? 'not-found' : '')}
    />
  );
}
