import React from 'react';
import { Icon, Button, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { OrganizationApi } from './OrganizationApi';
import { IOrganization } from 'common/schema/Organization';
import { TableScroll } from 'client/ui/table/TableScroll';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { usePermissions } from 'client/components/auth/AuthModule';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

export interface OrganizationsListProps {}

export function OrganizationsList(props: OrganizationsListProps) {
  const {
    response,
    error,
    loading,
    refetch
  } = useApiQuery(OrganizationApi.findOrganizations, { data: {} });

  const permissions = usePermissions();
  const deleteOne = useApiMutation(OrganizationApi.deleteOrganization, {});

  // const deleteOne = useOrganizationDeleteMutation({
  //   refetchQueries: [{ query: OrganizationsListDocument }]
  // });

  const handleDelete = (id: number) => async (e: any) => {
    try {
      const result = await deleteOne({ data: { id } });
      refetch();
      message.success('Eliminata correttamente.');
    } catch (err) {
      console.error(err);
      message.error(`Impossibile eliminare la voce.`);
      return;
    }
  };

  const columns: ColumnProps<IOrganization>[] = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 100
    },
    {
      key: 'uid',
      dataIndex: 'uid',
      title: 'Codice (UID)',
      width: 160,
      render: uid => <code>{uid}</code>
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nome'
    },
    // {
    //   key: 'areasCount',
    //   title: 'A.O.O.',
    //   render: (_, row) =>
    //     row.areasCount > 0 ? (
    //       <Icon type="check-circle" style={{ color: '#2ed573' }} />
    //     ) : (
    //       <Icon type="close-circle" style={{ color: '#ff4757' }} />
    //     )
    // },
    {
      key: 'actions',
      title: 'Azioni',
      align: 'right',
      width: 200,
      render: (text, org) => (
        <div className="table-actions-column">
          <Link to={`/app/admin/organizations/${org.id}`}>
            <Button size="small">
              <Icon type="eye" /> <TableButtonDetailText />
            </Button>
          </Link>
          {permissions.domain('organigram.write', org.domain) && (
            <ButtonDeleteConfirm
              title="Sei sicuro di voler eliminare questa Organizzazione?"
              onConfirm={handleDelete(org.id)}
            />
          )}
        </div>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco delle Organizzazioni"
        description={error.message}
        type="error"
        showIcon
      />
    );

  return (
    <PageLayout
      hasMenu
      fullTable
      hasScrollable
      title={<CustomCardTitle icon="cluster" title="Organizzazioni" />}
      extra={
        <>
          {permissions.has('organigram.write', { forRoot: true }) && (
            <Link to="/app/admin/organizations/create">
              <Button
                type="primary"
                icon="plus"
                disabled={loading || !response || !response.data}
              >
                Aggiungi
              </Button>
            </Link>
          )}
        </>
      }
    >
      <TableScroll<IOrganization>
        pagination={false}
        rowKey="id"
        loading={tableLoaderSpin(loading || !response)}
        dataSource={response?.data ?? []}
        columns={columns}
      />
    </PageLayout>
  );
}
