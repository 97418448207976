import { FormItem } from 'client/ui/form/FormItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import React from 'react';
import {
  AvailableDiscardPolicyRuleFields,
  DiscardPolicyRule,
  DiscardPolicyRuleFieldName
} from 'common/schema/DiscardPolicyRule';
import { useField } from 'formik';
import { DiscardPolicyTarget } from 'common/schema/DiscardPolicy';
import { Select } from 'antd';
import { DiscardRuleLogic } from 'common/logic/DiscardRuleLogic';
import { useCurrentUser } from 'client/components/auth/AuthModule';

const { Option } = Select;

export interface IDiscardPolicyRuleFieldInputProps {
  index: number;
  rule: DiscardPolicyRule;
  disabled?: boolean;
}

/**
 * Componente per la gestione delle righe di input
 * delle regole di una discard policy
 */
export function DiscardPolicyRuleFieldInput(
  props: IDiscardPolicyRuleFieldInputProps
) {
  const { index, disabled, rule } = props;
  const user = useCurrentUser();
  const [targetField] = useField<DiscardPolicyTarget>('target');

  return (
    <FormItem
      name={`rules.${index}.field`}
      label={index === 0 && 'Metadato da valutare'}
      colon={false}
    >
      <SelectInput
        disabled={disabled}
        name={`rules.${index}.field`}
        options={DiscardRuleLogic.getAvaiableFields(
          user,
          targetField.value,
          rule
        ).map((ruleField, key) => ({
          value: ruleField.field,
          key: key,
          label: ruleField.title
        }))}
        // style={{ width: 200 }}
      />
    </FormItem>
  );
}
