import { apiClient } from 'client/core/network/apiClient';
import { ICategoryIndexesQueryDto } from 'common/dto/CategoryIndexesQueryDto';
import { ICategoryIndex } from 'common/schema/CategoryIndex';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';

export const CategoryIndexApi = {
  findIndexes: (opts: { query: ICategoryIndexesQueryDto }) =>
    apiClient.get<PaginatedResult<ICategoryIndex>>(`/category-indexes`, {
      params: opts.query
    }),
  findIndex: (opts: { id: number }) =>
    apiClient.get<ICategoryIndex>(`/category-indexes/${opts.id}`)
};
