import * as React from 'react';
import clx from 'classnames';
import { Row, Icon } from 'antd';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { Link } from 'react-router-dom';
import { IDocument } from 'common/schema/Document';
import {
  IDocumentClassification,
  DocumentClassificationType
} from 'common/schema/DocumentClassification';
import { SchemaMetadata } from 'client/components/metadata/schema/SchemaMetadata';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { TableScroll } from 'client/ui/table/TableScroll';
import { useFieldSchemaColumns } from 'client/components/metadata/schema/useFieldSchemaColumn';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { IAttachment } from 'common/schema/Attachment';
import { AttachmentColumn } from '../sections/AttachmentColumn';
import './DocumentCategoryTab.scss';
import { orderBy } from 'lodash';
import { AttachmentPreview } from '../attachment/AttachmentPreview';

export interface IDocumentCategoryTabProps {
  ns: SchemaConfigNamespace;
  document: IDocument;
  attachment: AttachmentPreview | null;
}

export function DocumentCategoryTab(props: IDocumentCategoryTabProps) {
  const { document, attachment } = props;

  if (!document) return null;

  const classifications = orderBy(
    document.classifications,
    ['primary', 'uid'],
    ['desc']
  );

  const columns = useFieldSchemaColumns<IDocumentClassification>(props.ns, [
    {
      field: 'classifications.type',
      dataIndex: 'title',
      title: 'Tipo',
      width: 45,
      render: (_, record) => (
        <Icon
          className={clx('category-icon', {
            principal: record.primary
          })}
          // style={
          //   record.primary
          //     ? { fontSize: '18px', color: '#569bc4' }
          //     : { fontSize: '18px' }
          // }
          type={
            record.type === DocumentClassificationType.Dossier
              ? 'folder'
              : 'ordered-list'
          }
        />
      )
    },
    {
      field: 'classifications.code',
      dataIndex: 'code',
      title: 'Codice',
      width: 120,
      ellipsis: true,
      render: (code, row) => (
        <span
          className={clx('category-code', {
            principal: row.primary
          })}
        >
          {row.type === DocumentClassificationType.Dossier &&
          row.uid != null ? (
            <Link to={`/app/dossiers/${document.areaUid}/${row.uid}`}>
              {code}
            </Link>
          ) : (
            code
          )}
        </span>
      )
    },
    {
      field: 'classifications.title',
      dataIndex: 'title',
      title: 'Descrizione',
      ellipsis: true
    }
    // {
    //   field: 'classifications.register',
    //   dataIndex: 'register',
    //   title: 'Registro',
    //   width: 100
    // },
    // {
    //   field: 'classifications.insertedAt',
    //   dataIndex: 'insertedAt',
    //   title: 'Data Class.',
    //   width: 100,
    //   render: date => <ISODate date={date} />
    // }
  ]);

  return (
    <RowScroller>
      <ColScroller span={10} side="left" hasTableScroll>
        <TableScroll
          // bottomSpace={54}
          // label="Classifica/Fascicoli Contenenti il Documento"
          columns={columns}
          dataSource={classifications}
          expandedRowRender={record => (
            <Row type="flex" className="metadata-inline">
              <SchemaMetadata
                ns="document"
                field="classifications.insertedAt"
                value={record.insertedAt}
                label="Data Registrazione"
                type="date"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.title"
                value={record.title}
                label="Descrizione"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.primary"
                value={record.primary}
                label="Indica se è la Classificazione Primaria"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.uid"
                value={record.uid}
                label="ID del fascicolo, se è un fascicolo su Archivio Corrente"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.type"
                value={record.type}
                label="Tipo (Fascicolo o Categoria)"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.registerUid"
                value={record.registerUid}
                label="ID del registro Archivio Corrente"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.register"
                value={record.register}
                label="Registro in cui è contenuto il fascicolo"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.indexUid"
                value={record.indexUid}
                label="ID d’istanza del titolario di classificazione utilizzato."
              />
              <SchemaMetadata
                ns="document"
                field="classifications.repertory"
                value={record.repertory}
                label="Repertorio del fascicolo"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.number"
                value={record.number}
                label="Numero fascicolo"
              />
              <SchemaMetadata
                ns="document"
                field="classifications.description"
                value={record.description}
                label="Descrizione fascicolo"
              />
            </Row>
          )}
        />
      </ColScroller>

      <AttachmentColumn attachment={attachment} />
    </RowScroller>
  );
}
