import React, { useState } from 'react';
import { Button, Icon, Alert } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { OrganizationalAreaApi } from './OrganizationalAreaApi';
import { IOrganizationalArea } from 'common/schema/OrganizationalArea';
import { Table } from 'client/ui/table/Table';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

export interface OrganizationAreasProps {
  organizationId: number | null;
}

/**
 * Elenco delle aree organizzative contenute in una organizzazione.
 */
export function OrganizationAreas(props: OrganizationAreasProps) {
  const { organizationId } = props;
  const [query, setQuery] = useState({
    page: 1,
    pageSize: 20
  });

  const { response, error, loading } = useApiQuery(
    OrganizationalAreaApi.findOrganizationalAreas,
    {
      skip: !organizationId,
      data: { query: { ...query, organizationId: organizationId! } }
    }
  );

  if (!organizationId) return null;

  if (error)
    return <Alert type="error" message="Impossibile caricare le A.O.O." />;

  const columns: ColumnProps<IOrganizationalArea>[] = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID'
    },
    {
      key: 'uid',
      dataIndex: 'uid',
      title: 'Codice (UID)',
      render: uid => <code>{uid}</code>
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nome'
    },
    {
      key: 'actions',
      title: 'Azioni',
      align: 'right',
      render: (_, row) => (
        <div className="table-actions-column">
          <Link to={`/app/admin/organizational-areas/${row.id}`}>
            <Button size="small">
              <Icon type="eye" /> <TableButtonDetailText />
            </Button>
          </Link>
          {/* <ButtonDeleteConfirm
            title="Sei sicuro di voler eliminare questa Area Organizzativa?"
            //onConfirm={}
          /> */}
        </div>
      )
    }
  ];

  return (
    <Table<IOrganizationalArea>
      label="Elenco AOO dell'Organizzazione"
      rowKey="id"
      loading={tableLoaderSpin(loading || !response)}
      dataSource={response?.data.items ?? []}
      columns={columns}
      onChange={(pagination, filters, sorter) => {
        setQuery({
          ...query,
          page: pagination.current ?? query.page,
          pageSize: pagination.pageSize ?? query.pageSize
        });
      }}
      pagination={{
        size: 'small',
        position: 'bottom',
        showSizeChanger: true,
        total: response?.data.meta.total,
        pageSize: query.pageSize,
        current: query.page,
        pageSizeOptions: ['5', '10', '20', '30', '40']
      }}
    />
  );
}
