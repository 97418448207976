import React from 'react';
import clx from 'classnames';
import './TimelineCustomItem.scss';
import { Col, Typography, Timeline, Tag } from 'antd';
import { ISODate } from '../display/date/ISODate';
const { Text } = Typography;

export interface TimelineCustomItemProps {
  id: string | number;
  type?: string;
  author?: string;
  description: string;
  createdAt: Date;
  mode?: 'column' | 'row';
}

export function TimelineCustomItem(props: TimelineCustomItemProps) {
  const history = props;
  const mode = history.mode == 'row' ? 'row' : 'column';
  return (
    <Timeline.Item
      key={history.id}
      color={
        history.type == 'error'
          ? 'red'
          : history.type === 'success'
          ? 'green'
          : 'blue'
      }
      className={clx('timeline-custom-item', `mode-${mode}`, {})}
    >
      <ISODate date={history.createdAt} format="DD/MM/YYYY HH:mm:ss" />{' '}
      {history.author && mode == 'row' && <Tag>{history.author}</Tag>}
      <span className={clx('history-desc', `type-${history.type}`, {})}>
        {history.author && mode == 'column' && <Tag>{history.author}</Tag>}
        {history.description}
      </span>
    </Timeline.Item>
  );
}
