import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CategoryIndexApi } from './CategoryIndexApi';
import { ColumnProps } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import { ICategoryIndex } from 'common/schema/CategoryIndex';
import { TableScroll } from 'client/ui/table/TableScroll';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { useState } from 'react';
import { ICategoryIndexesQueryDto } from 'common/dto/CategoryIndexesQueryDto';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

export interface ICategoryIndexesListProps {}

/**
 * Elenco dei titolari
 */
export function CategoryIndexesList(props: ICategoryIndexesListProps) {
  const [query, setQuery] = useState<ICategoryIndexesQueryDto>({
    page: 1,
    pageSize: 20
  });

  const {
    response,
    error,
    loading,
    refetch
  } = useApiQuery(CategoryIndexApi.findIndexes, { data: { query } });

  const columns: ColumnProps<ICategoryIndex>[] = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 80,
      sorter: true
    },
    {
      key: 'uid',
      dataIndex: 'uid',
      title: 'UID',
      width: 80,
      ellipsis: true,
      sorter: true
    },
    {
      key: 'title',
      dataIndex: 'title',
      title: 'Nome',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'area.name',
      dataIndex: 'area.name',
      title: 'A.O.O.',
      ellipsis: true
    },
    {
      key: 'beginsAt',
      dataIndex: 'beginsAt',
      title: 'Valido Dal',
      sorter: true,
      width: 100,
      render: date => <ISODate date={date} />
    },
    {
      key: 'endsAt',
      dataIndex: 'endsAt',
      title: 'Valido Al',
      sorter: true,
      width: 100,
      render: date => <ISODate date={date} />
    },
    {
      key: 'actions',
      title: 'Azioni',
      align: 'right',
      width: 120,
      render: (text, index) => (
        <div>
          <Link to={`/app/admin/category-indexes/${index.id}`}>
            <Button size="small">
              <Icon type="eye" /> <TableButtonDetailText />
            </Button>
          </Link>
        </div>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco del Titolario"
        description={error.message}
        type="error"
        showIcon
      />
    );

  if (!response?.data) return <LoadingPage />;

  return (
    <PageLayout
      hasMenu
      fullTable
      hasScrollable
      title={<CustomCardTitle icon="ordered-list" title="Titolari" />}
    >
      <TableScroll<ICategoryIndex>
        loading={tableLoaderSpin(loading)}
        rowKey="id"
        dataSource={response.data.items}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          setQuery({
            ...query,
            page: pagination.current || query.page,
            pageSize: pagination.pageSize || query.pageSize,
            title:
              filters.title && filters.title.length > 0
                ? filters.title[0]
                : undefined,
            orderBy: sorter.field,
            orderByDirection: sorter.order
              ? ColumnSortDirections[sorter.order]
              : undefined
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response.data.meta.total,
          pageSize: query.pageSize,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </PageLayout>
  );
}
