import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'client/core/store/RootReducer';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { fromPairs } from 'lodash';

/**
 * Configurazione di un campo a partire dal file di configurazione
 */
export const useHeaderSchema = (ns: SchemaConfigNamespace) =>
  useSelector(
    (state: RootState) => state.config.config?.schema[ns].header,
    shallowEqual
  );
