import * as React from 'react';
import { SelectionRule } from '../selection-rule/SelectionRule';
import { IDiscardPolicy } from 'common/schema/DiscardPolicy';
import {
  DiscardPolicyRuleOperators,
  getDiscardPolicyRuleOperator,
  AvailableDiscardPolicyRuleFields,
  getDiscardPolicyRuleField
} from 'common/schema/DiscardPolicyRule';

export interface IDiscardSelectionRules {
  policy: IDiscardPolicy;
}

export function DiscardSelectionRules(props: IDiscardSelectionRules) {
  const { rules } = props.policy;
  return (
    <div>
      {rules.map(rule => {
        const operator = getDiscardPolicyRuleOperator(rule.operator);
        const ruleField = getDiscardPolicyRuleField(rule.field);

        return (
          <SelectionRule
            label={ruleField.title}
            rule={operator.label}
            parameter={rule.displayValue ?? rule.value}
          />
        );
      })}
    </div>
  );
}
