import moment from 'moment';

export const dateInputUtils = {
  toMoment(
    type: 'from' | 'to',
    raw: string | undefined
  ): moment.Moment | undefined {
    if (!raw) return undefined;

    const date = moment.tz(raw, moment.ISO_8601, 'Europe/Rome');
    return date;
  },
  toString(
    type: 'from' | 'to',
    raw: moment.Moment | undefined | null
  ): string | undefined {
    if (!raw) return undefined;

    // Convertiamo la data nella TimeZone di interesse per l'applicativo
    const date = moment.tz(
      raw.format('DD/MM/YYYY'),
      'DD/MM/YYYY',
      'Europe/Rome'
    );

    // console.log('tz', date.tz());
    // console.log(
    //   'date',
    //   raw.format('DD/MM/YYYY'),
    //   raw.toISOString(),
    //   type === 'start'
    //     ? date.startOf('day').toISOString()
    //     : date.endOf('day').toISOString()
    // );

    return type === 'from'
      ? date.startOf('day').toISOString()
      : date.endOf('day').toISOString();
  }
};
