import { apiClient } from 'client/core/network/apiClient';
import { IUser } from 'common/schema/User';
import { IRoleBinding } from 'common/schema/RoleBinding';
import { IUserRolesAddDto } from 'common/dto/UserRolesDto';
import {
  IUserCreateDto,
  IUserEditDto,
  IUsersQueryDto
} from 'common/dto/UserDto';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IUsersMassiveImportBag } from 'common/schema/UsersMassiveImportBag';

export interface CreateServiceTokenResult {
  serviceToken: string;
  serviceTokenJwt: string;
}

export const UserApi = {
  findUser: (opts: { id: number }) => apiClient.get<IUser>(`/users/${opts.id}`),
  findCurrentUser: (opts: {}) => apiClient.get<IUser>(`/users/current`),
  findUserRoles: (opts: { id: number }) =>
    apiClient.get<IRoleBinding[]>(`/users/${opts.id}/roles`),
  addUserRole: (opts: { userId: number; role: string; domainId: number }) =>
    apiClient.post<IUserRolesAddDto>(`/users/${opts.userId}/roles`, {
      role: opts.role,
      domainId: opts.domainId
    }),
  createUser: (opts: { input: IUserCreateDto }) =>
    apiClient.post<IUser>(`/users`, opts.input),
  updateUser: (opts: { id: number; input: IUserEditDto }) =>
    apiClient.put<IUser>(`/users/${opts.id}`, opts.input),
  deleteUserRole: (opts: { userId: number; domainId: number }) =>
    apiClient.delete(`/users/${opts.userId}/roles`, {
      params: { domainId: opts.domainId }
    }),
  findUsers: (opts: { query: IUsersQueryDto }) =>
    apiClient.get<PaginatedResult<IUser>>(`/users`, { params: opts.query }),
  /** Elenco di utenti per le select */
  listSelectable: (opts: { query: IUsersQueryDto }) =>
    apiClient.get<PaginatedResult<IUser>>(`/users/projections/selectable`, {
      params: opts.query
    }),
  deleteUser: (opts: { id: number }) => apiClient.delete(`/users/${opts.id}`),
  /** Crea un service token */
  createServiceToken: (opts: { id: number }) =>
    apiClient.post<CreateServiceTokenResult>(
      `/users/${opts.id}/actions/create-service-token`
    ),

  /** Importazione massiva da documentale */
  userImport: () =>
    apiClient.post<{ importBag: IUsersMassiveImportBag }>(
      `/users/actions/massive-import-documental`
    )
};
