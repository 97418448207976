import { yup } from 'common/validation/initYup';
import { GrantRequestType } from 'common/schema/GrantRequest';
import { GrantStatus } from 'common/schema/Grant';

export const dossierGrantValidator = yup.object({
  dossierId: yup.number().integer().required()
});

export const documentGrantValidator = yup.object({
  documentId: yup.number().integer().required()
});

export const grantValidator = yup
  .object({
    userId: yup.number().integer(),
    status: yup
      .string<GrantStatus>()
      .oneOf(Object.values(GrantStatus) as any)
      .notRequired(),
    areaUid: yup.string().notRequired().nullable().default(null),
    dossiers: yup.array(dossierGrantValidator).default([]),
    documents: yup.array(documentGrantValidator).default([]),
    description: yup.string().nullable().notRequired(),
    // TODO: Controllare il messaggio di errore
    beginsAt: yup.date().when('status', {
      is: GrantStatus.Active,
      then: yup.date().required(),
      otherwise: yup.date().nullable().notRequired()
    }),
    expiresAt: yup.date().when('status', {
      is: GrantStatus.Active,
      then: yup.date().required(),
      otherwise: yup.date().nullable().notRequired()
    })
  })
  .noUnknown();

export type IGrantDto = yup.InferType<typeof grantValidator>;

export const grantUserValidator = yup
  .object({
    login: yup.string().required(),
    serial: yup.string().required(),
    name: yup.string().required(),
    email: yup.string().email().notRequired()
  })
  .default(null)
  .nullable();

export const grantRequestValidator = yup
  .object({
    type: yup
      .string<GrantRequestType>()
      .oneOf(Object.values(GrantRequestType) as any)
      .required(),
    areaUid: yup.string().required(),
    register: yup.string().notRequired(),

    // Documento
    registerYear: yup.number().integer().positive().notRequired(),
    registerNumber: yup.number().integer().positive().notRequired(),

    // Fascicolo
    code: yup.string().notRequired()
  })
  .noUnknown();

export const grantCreateValidator = grantValidator.clone().shape({
  userId: yup.number().integer().notRequired(),
  user: grantUserValidator.notRequired(),
  requests: yup.array(grantRequestValidator).notRequired()
});

export type IGrantCreateDto = yup.InferType<typeof grantCreateValidator>;
