import * as React from 'react';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositApi } from '../../DepositApi';
import { useState } from 'react';
import { IDeposit } from 'common/schema/Deposit';
import { IDocument } from 'common/schema/Document';
import { TableScroll } from 'client/ui/table/TableScroll';
import { IDepositDocumentsQueryDto } from 'common/dto/DepositDocumentsQueryDto';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  createColumnFilter,
  FilterInputType,
  getFilter
} from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';

export interface TableDepositDocumentListProps {
  deposit: IDeposit;
  label?: React.ReactNode;
}

/**
 * Tabella che mostra l'elenco dei versamenti
 * TODO: Gestire errori
 */
export function TableDepositDocumentList(props: TableDepositDocumentListProps) {
  const { deposit } = props;

  const [query, setQuery] = useState<IDepositDocumentsQueryDto>({
    page: 1,
    pageSize: 20
  });

  const { response, loading, error } = useApiQuery(DepositApi.listDocuments, {
    data: {
      id: deposit.id,
      query
    }
  });

  const columns: ColumnProps<IDocument>[] = [
    {
      title: 'UID',
      dataIndex: 'uid',
      width: 80,
      ellipsis: true,
      sorter: true
    },
    {
      title: 'A.O.O.',
      dataIndex: 'areaName',
      width: 160,
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    // {
    //   title: 'Registro',
    //   dataIndex: 'register'
    // },
    {
      title: 'Protocollo',
      dataIndex: 'registerNumber',
      width: 130,
      sorter: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    // {
    //   title: 'Classe',
    //   dataIndex: 'category'
    // },
    {
      title: 'Oggetto',
      dataIndex: 'subject',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    }
  ];

  return (
    <TableScroll
      label={props.label}
      bottomSpace={64}
      loading={tableLoaderSpin(loading || !response)}
      columns={columns}
      dataSource={response?.data.items || []}
      onChange={(pagination, filters, sorter) => {
        const { field, order } = sorter as SorterResult<IDocument>;

        const areaName = getFilter<string>(filters, 'areaName');
        const registerNumber = getFilter<string>(filters, 'registerNumber');
        const subject = getFilter<string>(filters, 'subject');

        setQuery({
          ...query,
          areaName,
          registerNumber,
          subject,
          orderBy: order ? field?.toString() : 'id',
          orderByDirection: order
            ? ColumnSortDirections[order]
            : ColumnSortDirections.descend,
          page: pagination.current || query.page,
          pageSize: pagination.pageSize || query.pageSize
        });
      }}
      pagination={{
        pageSize: query.pageSize,
        current: query.page,
        total: response?.data.meta.total
      }}
    />
  );
}
