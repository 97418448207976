import * as React from 'react';
import { Menu } from 'antd';
import { useRouterCurrent } from 'client/routes/useRouterCurrent';
import { Link } from 'react-router-dom';
import './MainMenu.scss';
import { IconVector } from '../icon/IconVector';
import { CcTooltip } from '../tooltip/CcTooltip';
import { TopHeaderMenu } from '../layout/TopHeaderMenu';
import { PermissionLogic } from 'common/logic/PermissionLogic';
import {
  useCurrentUser,
  usePermissions
} from 'client/components/auth/AuthModule';
import {
  useModulesConfig,
  useThemeConfig
} from 'client/components/config/ClientConfigModule';

export interface MainMenuProps {}

/**
 * Menu principale dell'applicazione
 */
export function MainMenu(props: MainMenuProps) {
  const currentPathKey = useRouterCurrent();
  const user = useCurrentUser();
  const permissions = usePermissions();
  const modules = useModulesConfig();
  const theme = useThemeConfig();

  // console.log('currentPathKey', currentPathKey);

  return (
    <TopHeaderMenu hasLogout>
      <Menu
        theme="dark"
        mode="horizontal"
        className="main-menu"
        selectedKeys={currentPathKey}
        style={{ lineHeight: '34px' }}
      >
        {/* Menu Home  */}
        {modules.dashboard && (
          <Menu.Item key="home">
            <CcTooltip placement="top" title="Home">
              <Link to="/app/home">
                <IconVector image="home" />
              </Link>
            </CcTooltip>
          </Menu.Item>
        )}

        {modules.grant && permissions.has(['grants.read', 'grants.read-own']) && (
          <Menu.SubMenu
            key="grant"
            title={
              <CcTooltip placement="top" title="Permessi di Consultazione">
                <div>
                  <IconVector image="grant" />
                </div>
              </CcTooltip>
            }
          >
            <Menu.Item
              key="grants"
              hidden={!modules.grant || !permissions.has('grants.read')}
            >
              <Link to="/app/grants">Gestione Permessi</Link>
            </Menu.Item>

            <Menu.Item
              key="grant-requests"
              hidden={!modules.grant || !permissions.has('grants.read-own')}
            >
              <Link to="/app/grants/owned">Le mie Richieste</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        {permissions.has(['documents.read', 'documents.read-granted']) && (
          <Menu.SubMenu
            key="search"
            title={
              <CcTooltip placement="top" title="Ricerca">
                <div>
                  <IconVector image="search" />
                </div>
              </CcTooltip>
            }
          >
            <Menu.Item key="search/documents">
              <Link to="/app/search/documents">Ricerca Documenti</Link>
            </Menu.Item>
            <Menu.Item key="search/dossiers">
              <Link to="/app/search/dossiers">Ricerca Fascicoli</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        {permissions.has('deposits.read', { forRoot: true }) && (
          <Menu.SubMenu
            key="deposits"
            title={
              <CcTooltip placement="top" title="Versamenti">
                <div>
                  <IconVector image="deposit" />
                </div>
              </CcTooltip>
            }
          >
            <Menu.Item key="deposits">
              <Link to="/app/deposits">Elenco Versamenti</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        {permissions.has(['discards.read']) && (
          <Menu.SubMenu
            key="discards"
            title={
              <CcTooltip placement="top" title="Scarti">
                <div>
                  <IconVector image="discard" />
                </div>
              </CcTooltip>
            }
          >
            <Menu.Item key="discards">
              <Link to="/app/discards/list">Elenco Scarti</Link>
            </Menu.Item>
            <Menu.Item key="discard-policies">
              <Link to="/app/discard-policies">Policy di Scarto</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {permissions.has(['organigram.read', 'users.read']) && (
          <Menu.SubMenu
            key="admin"
            title={
              <CcTooltip placement="top" title="Gestione">
                <div>
                  <IconVector image="management" />
                </div>
              </CcTooltip>
            }
          >
            {theme.options.showOrganizationMenu && (
              <Menu.Item
                key="admin/organizations"
                hidden={!permissions.has('organigram.read')}
              >
                <Link to="/app/admin/organizations">Organizzazioni</Link>
              </Menu.Item>
            )}
            {theme.options.showAooMenu && (
              <Menu.Item
                key="admin/organizational-areas"
                hidden={!permissions.has('organigram.read')}
              >
                <Link to="/app/admin/organizational-areas">
                  Aree Organizzative
                </Link>
              </Menu.Item>
            )}
            <Menu.Item
              key="admin/users"
              hidden={!permissions.has('users.read')}
            >
              <Link to="/app/admin/users">Utenti</Link>
            </Menu.Item>
            {theme.options.showCategoryMenu && (
              <Menu.Item
                key="admin/category-indexes"
                hidden={!permissions.has('organigram.read')}
              >
                <Link to="/app/admin/category-indexes">Titolari</Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
      </Menu>
    </TopHeaderMenu>
  );
}
