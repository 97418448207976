import * as React from 'react';
import { IDiscard } from 'common/schema/Discard';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DiscardApi } from '../../DiscardApi';
import { useHistory } from 'react-router';
import { message, Button } from 'antd';
import { DiscardLogic } from 'common/logic/DiscardLogic';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';

export interface IDiscardRetryButtonProps {
  discard: IDiscard;
}

export function DiscardRetryButton(props: IDiscardRetryButtonProps) {
  const { discard } = props;
  const [retry, { loading }] = useApiMutationState(DiscardApi.retry, {
    data: { id: discard.id },
    invalidates: [DiscardApi.find]
  });

  const handleRetry = async () => {
    await retry({});
    message.success('Lo scarto è stato riavviato correttamente');
  };

  if (!DiscardLogic.isRetryable(discard)) return null;

  return (
    <Button
      type="primary"
      icon="reload"
      loading={loading}
      onClick={handleRetry}
    >
      Riavvia
    </Button>
  );
}
