import * as React from 'react';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { AuthApi } from '../AuthApi';
import { useAuth, AuthActions, useCurrentUser } from '../AuthModule';
import { Button, Icon } from 'antd';
import { useDispatch } from 'react-redux';
import { useClientConfig } from 'client/components/config/ClientConfigModule';
import { UserLogic } from 'common/logic/UserLogic';

export interface ILogoutButtonProps {}

/**
 * Gestisce il logout dell'utente corrente, sia dalla sessione remota che dai
 * dati locali.
 */
export function LogoutButton(props: ILogoutButtonProps) {
  const auth = useAuth();
  const user = useCurrentUser();
  const config = useClientConfig();
  const dispatch = useDispatch();
  const logout = useApiMutation(AuthApi.logout, {});

  if (!auth.logged) return null;
  if (!UserLogic.canLogout(user)) return null;

  const handleLogout = async () => {
    await logout({});

    switch (user?.originalStrategy) {
      // In caso di IAM, è necessario chiamare l'API di logout, ma non dobbiamo
      // reimpostare `isLogged` altrimenti verrebbe generato un nuovo accessToken
      // automaticamente.
      case 'openam':
        dispatch(AuthActions.logout({ isRedirect: true }));
        const strategy = config?.security.strategies.find(
          s => s.type === 'openam'
        );
        if (strategy?.logoutUrl) {
          window.location.href = strategy.logoutUrl;
        }
        break;

      // Tranne nel caso di IAM OpenAM, dobbiamo effettuare il reload della
      // pagina impostando `isLogged` a false.
      default:
        dispatch(AuthActions.logout());
        break;
    }
  };

  return (
    <a onClick={handleLogout} className="main-menu-button-link">
      <Icon type="logout" /> Logout
    </a>
  );
}
