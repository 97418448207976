import { apiClient } from 'client/core/network/apiClient';
import { IOrganization } from 'common/schema/Organization';

export const OrganizationApi = {
  findOrganizations: () => apiClient.get<IOrganization[]>('/organizations'),
  findOrganization: (options: { id: number }) =>
    apiClient.get<IOrganization>(`/organizations/${options.id}`),
  createOrganization: (options: { data: IOrganization }) =>
    apiClient.post<IOrganization>('/organizations', options.data),
  updateOrganization: (options: { id: number; data: IOrganization }) =>
    apiClient.put<IOrganization>(`/organizations/${options.id}`, options.data),
  deleteOrganization: (options: { id: number }) =>
    apiClient.delete(`/organizations/${options.id}`)
};
