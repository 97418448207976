import * as React from 'react';
import { VisibilityTable } from 'client/components/metadata/tabs/VisibilityTable';
import { IDossier } from 'common/schema/Dossier';
import { Scrollable } from 'client/ui/scroller/Scrollable';

export interface DossierVisibilityTabProps {
  dossier: IDossier;
}

/**
 * Mostra il dettaglio dei metadati di VISIBILITA di un fascicolo
 */
export function DossierVisibilityTab(props: DossierVisibilityTabProps) {
  const { dossier } = props;

  if (!dossier) return null;

  return (
    <Scrollable hasTableScroll>
      <VisibilityTable visibilities={dossier.visibilities} ns="dossier" />
    </Scrollable>
  );
}
