import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { FormItem } from 'client/ui/form/FormItem';
import { useFormikContext } from 'formik';
import { IRoleBinding } from 'common/schema/RoleBinding';
import { PermissionLogic } from 'common/logic/PermissionLogic';
import { RolesLogic } from 'common/logic/RolesLogic';
import { useCurrentUser } from 'client/components/auth/AuthModule';

interface IRolesOption {
  value: 'Archivist' | 'Consulter' | 'Admin' | 'System';
  label: string;
}

export function UserRoleSelectInput() {
  const formik = useFormikContext<IRoleBinding>();
  const [options, setOptions] = useState<IRolesOption[]>([]);

  const user = useCurrentUser();

  useEffect(() => {
    if (!formik.values.domain) return;
    formik.setFieldValue('role', undefined);
    setOptions(
      RolesLogic.availableRolesOptionsForDomain(user, formik.values.domain)
    );
  }, [formik.values.domain]);

  return (
    <FormItem name="role" label="Aggiungi Ruolo:">
      <SelectInput
        disabled={!formik.values.domainId}
        style={{ width: '100%' }}
        name="role"
        options={options}
      />
    </FormItem>
  );
}
