import { yup } from 'common/validation/initYup';
import {
  DiscardPolicyRuleOperator,
  DiscardPolicyRuleFieldName
} from 'common/schema/DiscardPolicyRule';
import { DiscardPolicyTarget } from 'common/schema/DiscardPolicy';

export const discardPolicyValidator = yup
  .object({
    name: yup.string().required(),
    description: yup.string().notRequired(),
    laws: yup.string().notRequired(),
    target: yup
      .string<DiscardPolicyTarget>()
      .oneOf(Object.values(DiscardPolicyTarget) as any),
    rules: yup
      .array(
        yup
          .object({
            field: yup
              .string<DiscardPolicyRuleFieldName>()
              .oneOf(Object.values(DiscardPolicyRuleFieldName) as any)
              .required('Il metadato è un campo richiesto'),
            operator: yup
              .string<DiscardPolicyRuleOperator>()
              .oneOf(Object.values(DiscardPolicyRuleOperator) as any)
              .required(`L'operatore è un campo richiesto`),
            value: yup
              .mixed()
              .transform((v, o) =>
                Array.isArray(o) && o.length === 0 ? undefined : o
              )
              .required('Il valore è un campo richiesto')
          })
          .noUnknown()
      )
      .default([])
  })
  .noUnknown();

export type IDiscardPolicyCreateDto = yup.InferType<
  typeof discardPolicyValidator
>;
