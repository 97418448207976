import * as React from 'react';
import { useField, FieldArray } from 'formik';
import { IDossierGrant } from 'common/schema/Grant';
import { Drawer, Row, Col, Button } from 'antd';
import { useState } from 'react';
import { DossierSearch } from 'client/components/search/DossierSearch';
import { TableGrantsDossierList } from './TableGrantsDossierList';

export interface IGrantDossierInputProps {}

export function GrantDossierInput(props: IGrantDossierInputProps) {
  const [field] = useField<IDossierGrant[]>('dossiers');
  const [dossierSearch, setDossierSearch] = useState(false);

  return (
    <FieldArray
      name="dossiers"
      render={helpers => (
        <>
          <TableGrantsDossierList
            dossiers={field.value ? field.value : []}
            onDelete={dossierId => {
              const index = field.value.findIndex(
                d => d.dossierId === dossierId
              );
              helpers.remove(index);
            }}
            bottomSpace={58}
            tableButton={
              <Button
                size="small"
                ghost
                type="primary"
                onClick={() => {
                  setDossierSearch(true);
                }}
              >
                Aggiungi Fascicoli
              </Button>
            }
          />
          <Drawer
            className="search-drawer"
            title={
              <Row type="flex" justify="space-between">
                <Col>
                  Aggiungi Fascicoli al Permesso Temporaneo di Visualizzazione
                </Col>
                <Col>
                  <Button
                    type="primary"
                    ghost
                    size="small"
                    onClick={() => {
                      setDossierSearch(false);
                    }}
                  >
                    Termina Inserimento Fascicoli
                  </Button>
                </Col>
              </Row>
            }
            placement="bottom"
            closable={false}
            visible={dossierSearch}
          >
            <DossierSearch
              onAdd={dossier => {
                const grantDossier = {
                  dossierId: dossier.id,
                  dossier: dossier
                };
                helpers.push(grantDossier);
              }}
              isAdded={dossierId =>
                field.value?.some(d => d.dossierId === dossierId)
              }
            />
          </Drawer>
        </>
      )}
    />
  );
}
