import React from 'react';
import { Col, Card } from 'antd';
import './ColScroller.scss';
import { ColProps } from 'antd/lib/col';
import clx from 'classnames';

export interface ColScrollerProps extends ColProps {
  /**
   * side? - Specifica se la colonna è a destra
   * in questo caso verifica se lasciare uno spazio ulteriore per
   * la barra dello scroll
   *
   * Utilizzato per il multi colonna in un Row scrollabile
   */
  side?: 'left' | 'right';
  /**
   * isCard? - Crea una <Card> intorno all'elemento scrollabile
   */
  isCard?: boolean;
  /**
   * pdfViewer? - Specifica che l'elemento nello scroll è pdfViewer
   */
  pdfViewer?: boolean;
  /**
   * spacing? - "inside"  Inserisce un padding sopra e sotto (12px) all'INTERNO dello Scroll
   * spacing? - "outside" Inserisce un padding sopra e sotto (12px) all'ESTERNO dello Scroll
   *                      Utile se il contenuto è una Card (isCard)
   */
  spacing?: 'inside' | 'outside';
  /**
   * imposta al 100% l'altezza di .scrollable-content-wrapper
   *       Utilizzato per Tabs dopo Fix Internet Explorer
   */
  forceHeight?: boolean;
  /**
   * Elimina lo scroll-y su scrollable
   *       Fix Internet Explorer
   */
  hasTableScroll?: boolean;
}

/**
 * Wrapper per lo scrolling dell'elemento Col di Ant.
 * ATTENZIONE
 * Va inserito in una Row con > type="flex" className="scrollable-row"
 */
export function ColScroller(props: ColScrollerProps) {
  const {
    children,
    className,
    side,
    isCard,
    pdfViewer,
    spacing,
    forceHeight,
    hasTableScroll,
    ...otherProps
  } = props;
  const cssSide = side ? 'col-' + side : '';
  return (
    <Col
      className={clx('scrollable-col', className, cssSide, {
        'pdf-viewer': pdfViewer,
        'has-card': isCard,
        'has-tablescroll': hasTableScroll,
        'force-height': forceHeight,
        'padding-inside': spacing == 'inside',
        'padding-outside': spacing == 'outside'
      })}
      {...otherProps}
    >
      {isCard ? (
        <Card>
          <div className="scrollable">
            <div className="scrollable-content-wrapper">{children}</div>
          </div>
        </Card>
      ) : (
        <div className="scrollable">
          <div className="scrollable-content-wrapper">{children}</div>
        </div>
      )}
    </Col>
  );
}
