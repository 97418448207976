import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import './TopHeaderMenu.scss';

import { CcTooltip } from '../tooltip/CcTooltip';
import { LogoutButton } from 'client/components/auth/logout/LogoutButton';
import { IconVector } from '../icon/IconVector';

export interface TopHeaderMenuProps {
  children: React.ReactNode;
  hasBack?: boolean;
  hasHelp?: boolean;
  hasLogout?: boolean;
}

/**
 * Container del Menu principale dell'applicazione
 */
export function TopHeaderMenu(props: TopHeaderMenuProps) {
  const hasSome = props.hasBack || props.hasHelp || props.hasLogout;

  if (!hasSome) {
    return (
      <Row className="main-menu-wrapper">
        <Col span={24} className="menu-center-column">
          {props.children}
        </Col>
      </Row>
    );
  }

  return (
    <Row className="main-menu-wrapper">
      <Col span={8} className="menu-left-column">
        {props.hasBack && (
          <div className="main-menu-button not-inverse">
            <div className="main-menu-button-link">
              <IconVector image="navigation_back" /> Home
            </div>
          </div>
        )}
      </Col>

      <Col span={8} className="menu-center-column">
        {props.children}
      </Col>

      <Col span={8} className="menu-right-column">
        {props.hasHelp && (
          <div className="main-menu-button">
            <CcTooltip placement="topRight" title="Aiuto">
              <Link to="/app/admin/demo-ui" className="main-menu-button-link">
                <IconVector image="help" />
              </Link>
            </CcTooltip>
          </div>
        )}
        {props.hasLogout && (
          <div className="main-menu-button">
            <LogoutButton />
          </div>
        )}
      </Col>
    </Row>
  );
}
