import * as React from 'react';
import { useClientConfig } from 'client/components/config/ClientConfigModule';
import { Button } from 'antd';

export interface IOpenAMLoginButtonProps {}

/**
 * Permette di effettuare il login con OpenAM (IAM)
 */
export function OpenAMLoginButton(props: IOpenAMLoginButtonProps) {
  const config = useClientConfig();
  const strategy = config?.security.strategies.find(s => s.type === 'openam');
  if (!strategy) return null;

  const handleLogin = () => {
    // Redirect
    window.location.href =
      strategy.loginUrl + encodeURIComponent(window.location.href);
  };

  return (
    <Button type="primary" onClick={handleLogin} style={{ width: '100%' }}>
      Accedi tramite IAM
    </Button>
  );
}
