import { IDiscard } from 'common/schema/Discard';
import { createDataTransferObject } from 'common/validation/dto/createDataTransferObject';
import { yup } from 'common/validation/initYup';

export interface IDiscardFindResult {
  discard: IDiscard;
  meta?: IDiscardFindResultMeta;
}

export interface IDiscardFindResultMeta {
  isLoading: boolean;
  documentsCount: number;
  dossiersCount: number;
  documentsDeletedCount: number;
  dossiersDeletedCount: number;
}

// Modifica
export const updateDiscardDto = createDataTransferObject(
  yup
    .object({
      description: yup.string().required().label('Descrizione')
    })
    .noUnknown()
);

export type IUpdateDiscardDto = typeof updateDiscardDto.Type;
