import { RootState } from 'client/core/store/RootReducer';
import { Reducer } from 'redux';
import { createAction, ActionTypes } from 'client/core/store/actions';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  IDocumentsQueryDto,
  documentsQueryValidator
} from 'common/dto/DocumentsQueryDto';
import {
  IDossiersQueryDto,
  dossiersQueryDtoValidator
} from 'common/dto/DossiersQueryDto';

export const SearchActions = {
  documentSearched: createAction(
    '@search/documentSearched',
    (search: IDocumentsQueryDto) => ({
      search
    })
  ),
  dossierSearched: createAction(
    '@search/dossierSearched',
    (search: IDossiersQueryDto) => ({
      search
    })
  )
};

export interface SearchState {
  documentSearch: {
    searchedAt: Date | null;
    search: IDocumentsQueryDto;
  };
  dossierSearch: {
    searchedAt: Date | null;
    search: IDossiersQueryDto;
  };
}

export const SearchReducer: Reducer<
  SearchState,
  ActionTypes<typeof SearchActions>
> = (
  state = {
    documentSearch: {
      searchedAt: null,
      search: documentsQueryValidator.cast()
    },
    dossierSearch: {
      searchedAt: null,
      search: dossiersQueryDtoValidator.cast()
    }
  },
  action
) => {
  switch (action.type) {
    /**
     * Registriamo la ricerca del documento
     */
    case '@search/documentSearched': {
      return {
        ...state,
        documentSearch: {
          search: action.payload.search,
          searchedAt: new Date()
        }
      };
    }

    /**
     * Registriamo la ricerca del fascicolo
     */
    case '@search/dossierSearched':
      return {
        ...state,
        dossierSearch: {
          search: action.payload.search,
          searchedAt: new Date()
        }
      };

    default:
      return state;
  }
};

export const useDocumentSearchState = () =>
  useSelector(
    (state: RootState) =>
      state.search.documentSearch as SearchState['documentSearch']
  );

export const useDossierSearchState = () =>
  useSelector(
    (state: RootState) =>
      state.search.dossierSearch as SearchState['dossierSearch']
  );
