export enum HistoryActionType {
  Deleted = 'Eliminato',
  Added = 'Aggiunto'
}

export interface ICorrespondingHistory {
  author?: string;
  date?: Date;
  previous?: string;
  action?: HistoryActionType | string;
}
