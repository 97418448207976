import * as React from 'react';
import { useField, FieldArray } from 'formik';
import { TableGrantsDocumentList } from './TableGrantsDocumentList';
import { IDocumentGrant } from 'common/schema/Grant';
import { Drawer, Row, Col, Button } from 'antd';
import { DocumentSearch } from 'client/components/search/DocumentSearch';
import { useState } from 'react';

export interface IGrantDocumentInputProps {}

export function GrantDocumentInput(props: IGrantDocumentInputProps) {
  const [field] = useField<IDocumentGrant[]>('documents');
  const [documentSearch, setDocumentSearch] = useState(false);

  return (
    <FieldArray
      name="documents"
      render={helpers => (
        <>
          <TableGrantsDocumentList
            documents={field.value ? field.value : []}
            onDelete={documentId => {
              const index = field.value.findIndex(
                d => d.documentId === documentId
              );
              helpers.remove(index);
            }}
            bottomSpace={58}
            tableButton={
              <Button
                ghost
                size="small"
                type="primary"
                onClick={() => {
                  setDocumentSearch(true);
                }}
              >
                Aggiungi Documenti
              </Button>
            }
          />
          <Drawer
            className="search-drawer"
            title={
              <Row type="flex" justify="space-between">
                <Col>
                  Aggiungi Documenti al Permesso Temporaneo di Visualizzazione
                </Col>
                <Col>
                  <Button
                    type="primary"
                    ghost
                    size="small"
                    onClick={() => {
                      setDocumentSearch(false);
                    }}
                  >
                    Termina Inserimento Documenti
                  </Button>
                </Col>
              </Row>
            }
            closable={false}
            placement="bottom"
            visible={documentSearch}
          >
            <DocumentSearch
              onAdd={document => {
                const grantDocument = {
                  documentId: document.id,
                  document: document
                };
                helpers.push(grantDocument);
              }}
              isAdded={documentId =>
                field.value?.some(d => d.documentId === documentId)
              }
            />
          </Drawer>
        </>
      )}
    />
  );
}
