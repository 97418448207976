import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { UsersList } from './users/UsersList';
import { UserDetail } from './users/UserDetail';
import { OrganizationalAreasList } from './organizational-areas/OrganizationalAreasList';
import { OrganizationalAreaEdit } from './organizational-areas/OrganizationalAreaEdit';
import { OrganizationsList } from './organizations/OrganizationsList';
import { OrganizationEdit } from './organizations/OrganizationEdit';
import { CategoryIndexesList } from '../category-index/CategoryIndexesList';
import { CategoryIndexDetail } from '../category-index/CategoryIndexDetail';
import { UserEdit } from './users/UserEdit';
import { Switch } from 'react-router';

export interface AdminRouteProps extends RouteComponentProps {}

export function AdminRoute(props: AdminRouteProps) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/users`} exact component={UsersList} />
      <Route path={`${match.url}/users/edit/:id`} component={UserEdit} />
      <Route path={`${match.url}/users/:id`} component={UserDetail} />

      <Route
        path={`${match.url}/organizational-areas`}
        exact
        component={OrganizationalAreasList}
      />
      <Route
        path={`${match.url}/organizational-areas/:id`}
        component={OrganizationalAreaEdit}
      />
      <Route
        path={`${match.url}/organizations`}
        exact
        component={OrganizationsList}
      />
      <Route
        path={`${match.url}/organizations/:id`}
        exact
        component={OrganizationEdit}
      />
      <Route
        path={`${match.url}/organizations/:organizationId/areas/:id`}
        component={OrganizationalAreaEdit}
      />

      {/** Titolario */}
      <Route
        path={`${match.url}/category-indexes`}
        exact
        component={CategoryIndexesList}
      />
      <Route
        path={`${match.url}/category-indexes/:id`}
        component={CategoryIndexDetail}
      />
    </Switch>
  );
}
