import * as React from 'react';
import { IAuthDomain } from 'common/schema/AuthDomain';

export interface IAuthDomainDisplayProps {
  domain: IAuthDomain;
}

export function displayAuthDomain(domain: IAuthDomain) {
  if (domain.area) return <span>A.O.O.: {domain.area.name}</span>;
  if (domain.organization)
    return <span>Organizzazione: {domain.organization.name}</span>;
  return domain.descriptor;
}

export function AuthDomainDisplay(props: IAuthDomainDisplayProps) {
  const { domain } = props;
  return (
    <div>
      {domain.area && <span>A.O.O.: {domain.area.name}</span>}
      {domain.organization && (
        <span>Organizzazione: {domain.organization.name}</span>
      )}
    </div>
  );
}
