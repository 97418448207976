import React from 'react';
import { useThemeConfig } from 'client/components/config/ClientConfigModule';

/**
 * Label personalizzata da Config per il pulsante di link ai dettagli delle tabelle
 * Inserito per l'arma dei carabinieri per mostrare "Dettaglio",
 * se non viene inserita in Config ritorna "Vedi" > MIUR
 */
export function TableButtonDetailText() {
  const theme = useThemeConfig();
  return <span>{theme.labels.tableButtonDetailText ?? 'Vedi'}</span>;
}
