import * as React from 'react';
import { MetadataText } from 'client/ui/metadata/MetadataText';
import moment from 'moment';

export interface IMetadataDateProps {
  label: string;
  date: Date | string | null | undefined;
  format?: string;
  hideIfEmpty?: boolean;
}

export function MetadataDate(props: IMetadataDateProps) {
  const formattedDate = props.date
    ? moment(props.date).format(props.format ?? 'DD/MM/YYYY HH:mm:ss')
    : null;
  return (
    <MetadataText
      hideIfEmpty={props.hideIfEmpty}
      label={props.label}
      text={formattedDate}
    />
  );
}
