import React, { useState } from 'react';
import { Button, Icon, message, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { RouteComponentProps } from 'react-router';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { UserApi } from './UserApi';
import { IUser } from 'common/schema/User';
import { TableScroll } from 'client/ui/table/TableScroll';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { IUsersQueryDto } from 'common/dto/UserDto';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { useThemeConfig } from 'client/components/config/ClientConfigModule';
import { usePermissions } from 'client/components/auth/AuthModule';
import { DocumentalImportButton } from './documental/DocumentalImportButton';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { LoginLabelText } from 'client/ui/label/LoginLabelText';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

interface Params {}
export interface UsersListProps extends RouteComponentProps<Params> {}

/**
 * Elenco degli utenti a sistema.
 */
export function UsersList(props: UsersListProps) {
  const theme = useThemeConfig();
  const [query, setQuery] = useState<IUsersQueryDto>({
    page: 1,
    pageSize: 20
  });

  const permissions = usePermissions();

  const { response, error, loading } = useApiQuery(UserApi.findUsers, {
    data: { query }
  });

  const deleteOne = useApiMutation(UserApi.deleteUser, {
    invalidates: [UserApi.findUsers]
  });

  const handleDelete = (id: number) => async (e: any) => {
    try {
      await deleteOne({ data: { id } });
      message.success(`L'utente è stato eliminato.`);
    } catch (err) {
      console.error(err);
      message.error(
        `Si è verificato un errore durante l'eliminazione dell'utente.`
      );
    }
  };

  const userColumns: ColumnProps<IUser>[] = [
    {
      key: 'imported',
      dataIndex: 'imported',
      title: '',
      width: 24,
      render: imported => {
        return imported ? (
          <Tooltip
            placement="right"
            title={'Utente importato da sistema documentale'}
          >
            <div
              style={{
                borderRadius: '8px',
                width: '8px',
                height: '8px',
                backgroundColor: '#9fa3a9'
              }}
            />
          </Tooltip>
        ) : null;
      }
    },
    {
      key: 'login',
      dataIndex: 'login',
      title: <LoginLabelText />,
      ...createColumnFilter(FilterInputType.Text),
      sorter: true
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nome',
      sorter: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'serial',
      dataIndex: 'serial',
      title: theme.labels.userSerial ?? 'Identificativo IAM',
      sorter: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      key: 'actions',
      align: 'right',
      render: (text, user) => (
        <div className="table-actions-column">
          <Link to={`/app/admin/users/${user.id}`}>
            <Button size="small">
              <Icon type="eye" /> <TableButtonDetailText />
            </Button>
          </Link>
          {permissions.canEditUser(user) && (
            <ButtonDeleteConfirm
              title="Sei sicuro di voler eliminare l'utente?"
              onConfirm={handleDelete(user.id)}
            />
          )}
        </div>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco degli Utenti"
        description={error.message}
        type="error"
        showIcon
      />
    );

  return (
    <PageLayout
      hasMenu
      fullTable
      hasScrollable
      title={<CustomCardTitle icon="user" title="Utenti" />}
      extra={
        <CustomCardExtra
          right={
            <>
              <Link to={`/app/admin/users/edit/create`}>
                <Button
                  type="primary"
                  icon="plus"
                  disabled={loading || !response || !response.data}
                >
                  Aggiungi
                </Button>
              </Link>
              <DocumentalImportButton />
            </>
          }
        />
      }
    >
      <TableScroll<IUser>
        rowKey="id"
        loading={tableLoaderSpin(loading)}
        dataSource={response?.data.items || []}
        columns={userColumns}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<IUser>;

          const login =
            filters.login && filters.login.length > 0
              ? filters.login[0].toString()
              : undefined;

          setQuery({
            ...query,
            login,
            orderBy: field?.toString(),
            orderDirection: order
              ? ColumnSortDirections[order]
              : ColumnSortDirections.ascend,
            page: pagination.current || query.page,
            pageSize: pagination.pageSize || query.pageSize,
            name:
              filters.name && filters.name.length > 0
                ? filters.name[0]
                : undefined,
            serial:
              filters.serial && filters.serial.length > 0
                ? filters.serial[0]
                : undefined
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.page,
          pageSizeOptions: ['5', '10', '20', '30']
        }}
      />
    </PageLayout>
  );
}
