import * as React from 'react';
import { Icon, Row, Col, Tabs, Tag, Divider, Card } from 'antd';
import { MetadataText } from 'client/ui/metadata/MetadataText';
import { DocumentRegisterTypeBanner } from '../detail/document-type/DocumentRegisterTypeBanner';
import './DocumentHeader.scss';
import { IDocument } from 'common/schema/Document';
import { SchemaMetadata } from 'client/components/metadata/schema/SchemaMetadata';
import { SchemaSection } from 'client/components/metadata/schema/SchemaSection';
import { useHeaderSchema } from 'client/components/metadata/schema/useHeaderSchema';

export interface DocumentHeaderProps {
  document: IDocument;
}

/**
 * Intestazione del documento.
 */
export function DocumentHeader(props: DocumentHeaderProps) {
  const { document } = props;

  const header = useHeaderSchema('document');

  if (!document) return null;

  return (
    <Row type="flex" align="middle" className="document-header metadata-inline">
      {document.registerType && (
        <DocumentRegisterTypeBanner type={document.registerType} />
      )}
      <Row type="flex" className="scrollable-header">
        {header?.map((field, key) => (
          <SchemaMetadata
            key={key}
            ns="document"
            source={document}
            field={field.name}
            label="field.name"
          />
        ))}
      </Row>
    </Row>
  );
}
