import * as React from 'react';
import { Divider, Tag } from 'antd';
import { MetadataText } from 'client/ui/metadata/MetadataText';
import { ICorresponding, CorrespondingType } from 'common/schema/Corresponding';
import {
  HistoryActionType,
  ICorrespondingHistory
} from 'common/schema/CorrespondingHistory';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { MetadataCollapse } from '../fields/MetadataCollapse';
import { SchemaLabel } from '../schema/SchemaLabel';

export interface ICorrespondingsHistoryViewProps {
  correspondings: ICorrespondingHistory[] | undefined;
}

/**
 * Elenco Modifiche dei corrispondenti.
 */
export function CorrespondingsHistoryView(
  props: ICorrespondingsHistoryViewProps
) {
  const { correspondings } = props;
  if (correspondings && correspondings.length < 1) return null;

  return (
    <MetadataCollapse
      ns="document"
      field="correspondingHistory"
      header={
        <MetadataText
          label={
            <SchemaLabel
              ns="document"
              field="correspondingHistory"
              label="Modifiche ai Mittenti/Destinatari"
            />
          }
        />
      }
    >
      {correspondings &&
        correspondings.map((history, index) => (
          <div key={index}>
            <div className="metadata-inline">
              {history.action && (
                <Tag
                  color={
                    history.action == HistoryActionType.Added
                      ? 'green'
                      : 'orange'
                  }
                >
                  {history.action}
                </Tag>
              )}
              <SchemaMetadata
                ns="document"
                field="correspondingHistory.previous"
                label="Mittente/Destinatario"
                value={history.previous}
              />
            </div>
            <div className="metadata-inline">
              <SchemaMetadata
                ns="document"
                field="correspondingHistory.author"
                label="Autore modifica"
                value={history.author}
              />
              <SchemaMetadata
                ns="document"
                field="correspondingHistory.date"
                label="Data"
                value={history.date}
                type="date"
              />
            </div>
            {index + 1 != correspondings.length && <Divider />}
          </div>
        ))}
    </MetadataCollapse>
  );
}
