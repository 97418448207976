import * as React from 'react';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { UserApi } from '../UserApi';
import { message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Table } from 'client/ui/table/Table';
import { UserRoleDialog } from './UserRoleDialog';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { displayAuthDomain } from '../../auth-domains/AuthDomainDisplay';
import { IUser } from 'common/schema/User';
import { IRoleBinding } from 'common/schema/RoleBinding';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { Roles } from 'common/permissions/Roles';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { usePermissions } from 'client/components/auth/AuthModule';

export interface IUserDetailRolesProps {
  user: IUser;
  id: number;
}

export function UserDetailRoles(props: IUserDetailRolesProps) {
  const { id, user } = props;
  const { response, loading, error } = useApiQuery(UserApi.findUserRoles, {
    data: { id }
  });

  const permissions = usePermissions();

  const deleteUserRole = useApiMutation(UserApi.deleteUserRole, {
    data: { userId: user.id },
    invalidates: [UserApi.findUserRoles, UserApi.findUser]
  });

  if (!response) return <LoaderSpin full transparent />;

  const columns: ColumnProps<IRoleBinding>[] = [
    {
      key: 'role',
      dataIndex: 'role',
      title: 'Ruolo',
      render: (_, rb) => Roles[rb.role].displayName
    },
    {
      key: 'area',
      title: 'Dominio',
      render: (_, row) => displayAuthDomain(row.domain)
    },
    {
      key: 'actions',
      title: 'Azioni',
      align: 'right',
      render: (_, row) => {
        // Se un utente non ha accesso al dominio
        // oppure se non può modificare quel ruolo
        // allora non mostrare il pulsante elimina
        if (!permissions.canEditBinding(row)) {
          return null;
        }

        return (
          <ButtonDeleteConfirm
            title="Sei sicuro di voler eliminare questo Ruolo?"
            onConfirm={async e => {
              await deleteUserRole({ data: { domainId: row.domainId } });
              message.success('Il ruolo è stato rimosso correttamente.');
            }}
          />
        );
      }
    }
  ];

  return (
    <div>
      <Table
        loading={tableLoaderSpin(loading)}
        // label="Ruoli associati all'utente"
        columns={columns}
        dataSource={response.data}
        pagination={false}
      />
      <UserRoleDialog binding={undefined} user={user} />
    </div>
  );
}
