import * as React from 'react';
import { Input, Row, Col, Select } from 'antd';
import { TextInput } from 'client/ui/form/input/TextInput';
import { useState } from 'react';
import { FormItem } from 'client/ui/form/FormItem';
import { useFormikContext, useField } from 'formik';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { useTypeField } from 'client/ui/form/hooks/useTypeField';

export interface IDateSearchProps {
  fieldPrefix: string;
  label: React.ReactNode;
}

const RegisterDateSearchTypes = [
  { value: 'single', label: 'Singolo', inputNames: [''] },
  {
    value: 'range',
    label: 'Intervallo',
    inputNames: ['RangeFrom', 'RangeTo']
  }
];

export function DateSearch(props: IDateSearchProps) {
  const { fieldPrefix, label } = props;
  const [searchTypeValue, setSearchTypeValue] = useTypeField(
    fieldPrefix,
    'single'
  );
  const formik = useFormikContext<any>();
  const searchType = RegisterDateSearchTypes.find(
    t => t.value === searchTypeValue
  )!;

  const changeSearchType = (value: string) => {
    searchType.inputNames.forEach(name =>
      formik.setFieldValue(fieldPrefix + name, undefined)
    );
    setSearchTypeValue(value);
  };

  return (
    <FormItem
      names={searchType.inputNames.map(name => fieldPrefix + name)}
      label={label}
    >
      <Input.Group compact>
        <Select
          style={{ width: '30%' }}
          value={searchTypeValue}
          onChange={changeSearchType}
        >
          {RegisterDateSearchTypes.map(type => (
            <Select.Option key={type.value} value={type.value}>
              {type.label}
            </Select.Option>
          ))}
        </Select>

        {searchType.value === 'single' && (
          <DatePickerInput
            className="input-item-date-range"
            name={fieldPrefix}
            type="from"
            style={{ width: '70%' }}
            placeholder="DD/MM/YYYY"
            format="DD/MM/YYYY"
          />
        )}
        {searchType.value === 'range' && (
          <>
            <DatePickerInput
              className="input-item-date-range"
              name={fieldPrefix + 'RangeFrom'}
              type="from"
              style={{ width: 125 }}
              placeholder="Dal (DD/MM/YYYY)"
              format="DD/MM/YYYY"
            />
            <DatePickerInput
              className="input-item-date-range"
              name={fieldPrefix + 'RangeTo'}
              type="to"
              style={{ width: 125 }}
              placeholder="Fino al (DD/MM/YYYY)"
              format="DD/MM/YYYY"
            />
          </>
        )}
      </Input.Group>
    </FormItem>
  );
}
