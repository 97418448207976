import { AxiosPromise, AxiosResponse } from 'axios';
import merge from 'lodash/merge';
import { ApiGet } from './useApiQuery';
import { useNetworkContext } from '../context/NetworkContext';
import { useState, useCallback } from 'react';
import { objToKey } from 'client/core/hooks/objToKey';

/**
 * Simile ad `useApiQuery`, ma al posto di essere chiamata automaticamente
 * al cambiamento delle options permette di fare la chiamata solamente quando
 * viene chiamato un **metodo specifico**.
 *
 * In questo modo possiamo effettuare operazioni creative e distruttive
 * senza doverci preoccupare di effetti di re-render di React.
 */
export function useApiMutation<T extends {}, R>(
  apiFn: ApiMutation<T, R>,
  baseOptions: ApiMutationOptions<T>
) {
  const network = useNetworkContext();

  return async (options: ApiMutationOptions<T>) => {
    const result = await apiFn(merge(options.data, baseOptions.data) as any);

    // Invalida la query associate in caso ve ne siano.
    if (baseOptions.invalidates) {
      network.invalidator.next({ queries: baseOptions.invalidates });
    }

    return result;
  };
}

/**
 * Versione della mutation che gestisce anche lo stato
 */
export function useApiMutationState<T extends {}, R>(
  apiFn: ApiMutation<T, R>,
  baseOptions: ApiMutationOptions<T>
) {
  const network = useNetworkContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [response, setResponse] = useState(
    undefined as AxiosResponse<R> | undefined
  );

  const mutation = useCallback(
    async (options: ApiMutationOptions<T>) => {
      setLoading(true);
      setError(null);
      try {
        const result = await apiFn(
          merge(options.data, baseOptions.data) as any
        );

        // Invalida la query associate in caso ve ne siano.
        if (baseOptions.invalidates) {
          network.invalidator.next({ queries: baseOptions.invalidates });
        }

        setResponse(result);
        setLoading(false);
        return result;
      } catch (e) {
        setError(e);
        setLoading(false);
        throw e;
      }
    },
    [apiFn, objToKey(baseOptions)]
  );

  const reset = useCallback(() => {
    setError(null);
    setResponse(undefined);
    setLoading(false);
  }, []);

  return [mutation, { loading, error, response, reset }] as [
    RunApiMutation<T, R>,
    {
      loading: boolean;
      error: any;
      response: AxiosResponse<R>;
      reset: ResetApiMutation;
    }
  ];
}

export interface ApiMutationOptions<T extends {}> {
  data?: Partial<T>;
  /** Invalida un insieme di Query al successo della mutazione */
  invalidates?: ApiGet<any, any>[];
}

export type RunApiMutation<T extends {}, R> = (
  options: ApiMutationOptions<T>
) => AxiosPromise<R>;

export type ApiMutation<T extends {}, R> = (data: T) => AxiosPromise<R>;

export type ResetApiMutation = () => void;
