import * as React from 'react';
import { useState } from 'react';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { TransmissionsTable } from 'client/components/metadata/tabs/TransmissionsTable';
import { TransmissionsDetailsTable } from 'client/components/metadata/tabs/TransmissionDetailsTable';
import { IDocument } from 'common/schema/Document';
import { RowScroller } from 'client/ui/scroller/RowScroller';

export interface IDocumentTransmissionTabProps {
  document: IDocument;
}

export function DocumentTransmissionTab(props: IDocumentTransmissionTabProps) {
  const { document } = props;
  const [selectedTransmission, setSelectedTransmission] = useState(
    null as number | null
  );

  if (!document) return null;

  return (
    <RowScroller>
      <ColScroller span={10} side="left" hasTableScroll>
        <TransmissionsTable
          ns="document"
          selectedTransmission={selectedTransmission}
          setSelectedTransmission={setSelectedTransmission}
          transmissions={document.transmissions}
        />
      </ColScroller>
      <ColScroller isCard span={14} side="right">
        <TransmissionsDetailsTable
          selectedTransmission={selectedTransmission}
          transmissions={document.transmissions}
          ns="document"
        />
      </ColScroller>
    </RowScroller>
  );
}
