import * as React from 'react';
import { IDiscard } from 'common/schema/Discard';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DiscardApi } from '../../DiscardApi';
import { useHistory } from 'react-router';
import { message, Button } from 'antd';
import { DiscardLogic } from 'common/logic/DiscardLogic';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';

export interface IDiscardStartButtonProps {
  discard: IDiscard;
}

export function DiscardStartButton(props: IDiscardStartButtonProps) {
  const { discard } = props;
  const [start, { loading }] = useApiMutationState(DiscardApi.start, {
    data: { id: discard.id },
    invalidates: [DiscardApi.find]
  });

  const handleStart = async () => {
    await start({});
    message.success('Lo scarto è stato avviato correttamente');
  };

  if (!DiscardLogic.isStartable(discard)) return null;

  return (
    <>
      <ButtonConfirm
        loading={loading}
        type="primary"
        size="default"
        icon="play-circle"
        title={
          <p style={{ maxWidth: '400px' }}>
            Confermi di voler avviare lo scarto? Questa operazione non può
            essere annullata e porterà all'eliminazione definitiva dei fascicoli
            e documenti presenti.
          </p>
        }
        okText="Avvia Scarto"
        onConfirm={handleStart}
      >
        Avvia Scarto
      </ButtonConfirm>
    </>
  );
}
