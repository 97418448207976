import * as React from 'react';
import { useFieldSchema } from './useFieldSchema';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { MetadataText } from 'client/ui/metadata/MetadataText';

export interface ISchemaLabelProps {
  /**
   * Specifica se è per il documento o per il fascicolo
   */
  ns: SchemaConfigNamespace;
  /**
   * Campo di cui visualizzare l'etichetta
   */
  field: string;
  /**
   * Label di default se non sovrascritto
   */
  label: React.ReactNode;
  /**
   * Per renderizzare MetadataText Label
   */
  labelLikeMetadata?: boolean;
}

/**
 * Permette di visualizzare l'etichetta di un metadato di un documento in base
 * al valore specificato nel file di configurazione.
 */
export function SchemaLabel(props: ISchemaLabelProps) {
  const { field } = props;
  const fieldSchema = useFieldSchema(props.ns, field);

  if (!fieldSchema?.enable) {
    return null;
  }

  const label = fieldSchema?.label ?? props.label;
  if (props.labelLikeMetadata) {
    return <MetadataText label={label} />;
  }
  return <span>{label}</span>;
}
