import React from 'react';
import clx from 'classnames';
import './IconVector.scss';
import {
  managementSvg,
  discardSvg,
  instanceSvg,
  conservationSvg,
  depositSvg,
  requestSvg,
  searchSvg,
  documentSvg,
  helpSvg,
  homeSvg,
  logoutSvg,
  navigation_backSvg,
  folderSvg
} from './iconsSvg';
import { LoginLayout } from 'client/components/auth/login/LoginLayout';

export type IconVectorType =
  | 'management'
  | 'discard'
  | 'instance'
  | 'conservation'
  | 'deposit'
  | 'grant'
  | 'search'
  | 'document'
  | 'help'
  | 'home'
  | 'logout'
  | 'navigation_back'
  | 'folder';

export interface IconVectorProps {
  size?: number;
  static?: boolean;
  image: IconVectorType;
}

/**
 * Ritorna un icona Svg
 *
 */
export function IconVector(props: IconVectorProps) {
  const size = props.size ? props.size + 'px' : '30px';
  const icon = (vector: string) => {
    switch (vector) {
      case 'management':
        return managementSvg;
      case 'discard':
        return discardSvg;
      case 'instance':
        return instanceSvg;
      case 'conservation':
        return conservationSvg;
      case 'deposit':
        return depositSvg;
      case 'grant':
        return requestSvg;
      case 'search':
        return searchSvg;
      case 'document':
        return documentSvg;
      case 'help':
        return helpSvg;
      case 'home':
        return homeSvg;
      case 'logout':
        return logoutSvg;
      case 'navigation_back':
        return navigation_backSvg;
      case 'folder':
        return folderSvg;
    }
  };

  return (
    <span
      className={clx('IconVector', props.image, { dinamic: !props.static })}
      style={{ width: size, height: size }}
    >
      {icon(props.image)}
    </span>
  );
}
