import { yup } from 'common/validation/initYup';

export type IDossiersQueryDto = yup.InferType<typeof dossiersQueryDtoValidator>;

export const dossiersQueryDtoValidator = yup.object({
  page: yup.number().integer().min(1).default(1),
  pageSize: yup.number().integer().min(5).max(20).default(20),
  area: yup.string().nullable().notRequired(),
  title: yup.string().nullable().notRequired(),
  code: yup.string().nullable().notRequired(),
  codeBegins: yup.string().nullable().notRequired(),
  category: yup.string().nullable().notRequired(),
  register: yup.string().nullable().notRequired(),
  createdAt: yup.date().notRequired(),
  createdAtRangeFrom: yup.date().notRequired(),
  createdAtRangeTo: yup.date().notRequired(),
  closedAt: yup.date().notRequired(),
  closedAtRangeFrom: yup.date().notRequired(),
  closedAtRangeTo: yup.date().notRequired(),
  openedAt: yup.date().notRequired(),
  openedAtRangeFrom: yup.date().notRequired(),
  openedAtRangeTo: yup.date().notRequired()
});
