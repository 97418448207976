import React, { useContext } from 'react';

export interface IFormikFormContext {
  /** Permette di nascondere il `success` dai FormItem */
  hideSuccess?: boolean;
  /** Specifica se mostrare gli errori di validazione in un tooltip */
  helpInTooltips?: boolean;
}

export const FormikFormContext = React.createContext<IFormikFormContext>({
  hideSuccess: false,
  helpInTooltips: false
});

export const useFormikFormContext = () => useContext(FormikFormContext);
