import * as React from 'react';
import clx from 'classnames';
import './DocumentRegisterTypeBanner.scss';
import { RegisterType } from 'common/schema/Register';
import { resolvePublicPath } from 'client/core/appConfig';

const DocumentTypeSpace = require('./image/document-type-space.png');

export interface DocumentRegisterTypeBannerProps {
  type: RegisterType;
}

/**
 * Visualizza un'icona e il simbolo associato per il documento in base
 * al tipo del registro.
 *
 * TODO: Verificare altre visualizzazioni
 */
export function DocumentRegisterTypeBanner(
  props: DocumentRegisterTypeBannerProps
) {
  return (
    <div
      className={clx('document-register-type', {
        incoming: props.type == RegisterType.Incoming,
        outgoing: props.type == RegisterType.Outgoing,
        internal: props.type == RegisterType.Internal,
        none: props.type == RegisterType.None
      })}
    >
      <img src={resolvePublicPath(DocumentTypeSpace)} />
    </div>
  );
}
