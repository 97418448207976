import * as React from 'react';
import { useCategoryTree } from 'client/components/category-index/category-tree/useCategoryTree';
import { TreeSelect, Spin, Icon } from 'antd';
import { useState } from 'react';
import { TreeNode } from 'antd/lib/tree-select';
import { useField, useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import { ICategory } from 'common/schema/Category';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';

export interface ICategorySelectProps {
  indexId: number | undefined;
  name: string;
}

function mapCategories(categories: ICategory[]): TreeNode[] {
  return sortBy(categories, ['code']).map(category => ({
    dataRef: category,
    title: `${category.code} - ${category.name}`,
    value: category.code,
    id: category.id,
    key: `${category.id}-${category.uid}`,
    isLeaf: category.isLeaf,
    children:
      !category.isLeaf && category.children
        ? mapCategories(category.children)
        : undefined
  }));
}

export function CategorySelect(props: ICategorySelectProps) {
  const { indexId, name } = props;
  const { index, error, loading, loadCategory } = useCategoryTree(indexId);
  const [field, meta] = useField(name);
  const formik = useFormikContext<any>();

  if (loading)
    return (
      <Spin
        indicator={<Icon type="loading-3-quarters" spin />}
        style={{ height: '32px', width: '100%', paddingTop: '5px' }}
      />
    );
  if (!index || !indexId) return null;

  // console.log('field value is', field.value);

  return (
    <TreeSelect
      key={indexId /* Forziamo il re-rendering */}
      loading={loading}
      allowClear
      loadData={node => {
        console.log('node', node);
        return loadCategory(node.props.dataRef);
      }}
      placeholder="Classifica..."
      treeData={mapCategories(index.categories || [])}
      value={field.value}
      onChange={value => formik.setFieldValue(name, value)}
    />
  );
}
