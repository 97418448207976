import React from 'react';
import './StaticFooter.scss';

import { Layout } from 'antd';
import { SchemaSection } from 'client/components/metadata/schema/SchemaSection';
import { useThemeConfig } from 'client/components/config/ClientConfigModule';
import moment from 'moment';
const { Footer } = Layout;

/**
 * Footer fisso per l'arma dei carabinieri.
 */
export function StaticFooter() {
  const theme = useThemeConfig();

  return (
    <Footer className="static-footer">
      <div>
        Copyright © {moment().format('YYYY')} Ver. {process.env.VERSION}
      </div>
      {/* Vale anche come esempio di utilizzo di opzioni e label dal file di configurazione */}
      {theme.options.showStaticFooter && (
        <div>{theme.labels.staticFooterText}</div>
      )}
    </Footer>
  );
}
