import React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { isEqual, sortBy } from 'lodash';

interface IBreadcrumbItem {
  path?: string;
  /** Viene utilizzato al posto di title per confrontare se è cambiato */
  key?: string;
  title: React.ReactNode;
}

interface IBreadcrumbContext {
  items: IBreadcrumbItem[];
  setItems: (update: (items: IBreadcrumbItem[]) => IBreadcrumbItem[]) => void;
}

export const BreadcrumbContext = createContext<IBreadcrumbContext>({
  items: [],
  setItems: (() => {}) as any
});

export function BreadcrumbProvider(props: React.PropsWithChildren<{}>) {
  const [items, setItems] = useState([] as IBreadcrumbItem[]);

  return (
    <BreadcrumbContext.Provider value={{ items, setItems }}>
      {props.children}
    </BreadcrumbContext.Provider>
  );
}

function areItemsEqual(a: IBreadcrumbItem, b: IBreadcrumbItem) {
  const aKey = a.key == null ? a.title : a.key;
  const bKey = b.key == null ? b.title : b.key;
  if (aKey !== bKey) return false;
  if (a.path !== b.path) return false;
  return true;
}

/**
 * Registra un elemento di navigazione nei breadcrumb.
 */
export const useBreadcrumbItem = (item: IBreadcrumbItem) => {
  const ctx = useContext(BreadcrumbContext);
  const compareKey = item.key == null ? item.title : item.key;
  useEffect(() => {
    // console.log('pushing', item);
    ctx.setItems(items => {
      return sortBy([...items, item], i => (i.path ? i.path.length : 0));
    });

    // Cleanup
    return () => {
      ctx.setItems(items => {
        return items.filter(i => {
          return !areItemsEqual(i, item);
        });
      });
    };
  }, [item.path, compareKey]);
};

export const useBreadcrumbs = () => {
  const ctx = useContext(BreadcrumbContext);
  return ctx.items;
};
