import * as React from 'react';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CategoryIndexApi } from './CategoryIndexApi';
import { SchemaMetadata } from '../metadata/schema/SchemaMetadata';

export interface ICategoryIndexLabelProps {
  uid?: number;
  areaUid?: string;
}

export function CategoryIndexLabel(props: ICategoryIndexLabelProps) {
  const { response, error, loading } = useApiQuery(
    CategoryIndexApi.findIndexes,
    {
      skip: !props.uid,
      data: {
        query: {
          uid: props.uid,
          areaUid: props.areaUid,
          pageSize: 5,
          page: 1
        }
      }
    }
  );

  if (!props.uid || loading || !response?.data.items.length) return null;
  if (error) return null;

  return (
    <SchemaMetadata
      ns="dossier"
      field="categoryIndexTitle"
      label="Titolario"
      value={response?.data.items[0].title}
    />
  );
}
