import * as React from 'react';
import { useState } from 'react';
import { CategorySelect } from '../category-select/CategorySelect';
import { useField, useFormikContext } from 'formik';
import { FormItem } from 'client/ui/form/FormItem';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { CategoryIndexApi } from 'client/components/category-index/CategoryIndexApi';
import { ICategoryIndex } from 'common/schema/CategoryIndex';
import { Checkbox } from 'antd';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { CheckboxInputItem } from 'client/ui/form/input/CheckboxInputItem';
import moment from 'moment';
import { useThemeConfig } from 'client/components/config/ClientConfigModule';

export interface ICategoryGeneralSelectProps {
  areaName: string;
  categoryIndexName: string;
  categoryName: string;
  categoryAnyIndexName: string;
}

export function CategoryGeneralSelect(props: ICategoryGeneralSelectProps) {
  const [field, meta, helpers] = useField<number>(props.categoryIndexName);
  const theme = useThemeConfig();

  return (
    <>
      <FormItem
        name={props.categoryIndexName}
        label={theme.labels.categoryIndexLabel ?? 'Titolario'}
      >
        <AsyncSelectInput
          placeholder="Cerca Titolario..."
          name={props.categoryIndexName}
          query={{
            apiFn: CategoryIndexApi.findIndexes,
            options: (formik, search) => ({
              data: {
                query: {
                  title: search,
                  areaUid: formik.values[props.areaName],
                  pageSize: 20
                }
              }
            })
          }}
          allowClear
          showSearch
          refreshOnSearch
          responseTransform={data => data.items}
          responseMeta={data => ({
            shown: data.items.length,
            total: data.meta.total
          })}
          optionTransform={(index: ICategoryIndex) => ({
            label: `${index.area?.uid ?? ''} - ${index.title}`,
            value: index.id
          })}
        />
      </FormItem>
      <FormItem name={props.categoryName}>
        <CategorySelect name={props.categoryName} indexId={field.value} />
      </FormItem>
      {field.value && (
        <CheckboxInputItem
          name={props.categoryAnyIndexName}
          title="Cerca la classificazione su tutti i titolari"
        />
      )}
    </>
  );
}
