import * as React from 'react';
import { Button } from 'antd';
import { IAttachment } from 'common/schema/Attachment';
import './AttachmentDownloadButton.scss';
import { getAttachmentUrl } from './getAttachmentUrl';
import { isAttachmentAvailable } from './isAttachmentAvailable';
import { AttachmentPreview } from './AttachmentPreview';

export interface IAttachmentDownloadButtonProps {
  attachment?: AttachmentPreview | null;
}

/**
 * Pulsante per scaricare un File.
 *
 */
export function AttachmentDownloadButton(
  props: IAttachmentDownloadButtonProps
) {
  const { attachment } = props;

  // Se non c'è l'allegato o non è disponibile un file non mostriamo il pulsante
  if (!attachment || !isAttachmentAvailable(attachment.item)) {
    return null;
  }

  return (
    <a
      href={getAttachmentUrl(attachment)}
      target="_blank"
      className="attachment-download-button"
    >
      <Button size="small" type="primary" icon="download" shape="round">
        Download
      </Button>
    </a>
  );
}
