import * as React from 'react';
import { EventTable } from 'client/components/metadata/tabs/EventTable';
import { IDocument } from 'common/schema/Document';
import { Scrollable } from 'client/ui/scroller/Scrollable';

export interface IDocumentEventTabProps {
  document: IDocument;
}

export function DocumentEventTab(props: IDocumentEventTabProps) {
  const { document } = props;

  if (!document) return null;

  return (
    <Scrollable hasTableScroll>
      <EventTable ns="document" events={document.events} />
    </Scrollable>
  );
}
