import * as React from 'react';
import { Button, Icon, Alert, Input as AntInput, message } from 'antd';
import { Formik, ErrorMessage, yupToFormErrors } from 'formik';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { useDocumentSearchState, SearchActions } from './SearchModule';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DocumentApi } from '../document/DocumentApi';
import { RegisterNumberSearch } from './form/register-number-search/RegisterNumberSearch';
import { DateSearch } from './form/date-search/DateSearch';
import { CategoryGeneralSelect } from '../category/general-select/CategoryGeneralSelect';
import { OrganizationalAreaInput } from './form/organizational-area-input/OrganizationalAreaInput';
import { useDispatch } from 'react-redux';
import { IDocument } from 'common/schema/Document';
import {
  IDocumentsQueryDto,
  documentsQueryValidator
} from 'common/dto/DocumentsQueryDto';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CcTooltip } from 'client/ui/tooltip/CcTooltip';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { TableScroll } from 'client/ui/table/TableScroll';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { RegisterYearSearch } from './form/register-year-search/RegisterYearSearch';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';

export interface IDocumentSearchProps {
  isSelectable?: boolean;
  onAdd?: (document: IDocument) => void;
  isAdded?: (documentId: number) => boolean;
}

/**
 * Card per ricerca per i documenti
 */
export function DocumentSearch(props: IDocumentSearchProps) {
  const dispatch = useDispatch();
  const search = useDocumentSearchState();

  const { response, error, loading } = useApiQuery(DocumentApi.findDocuments, {
    skip: !search.searchedAt,
    dependsOn: { searchedAt: search.searchedAt },
    data: { params: search.search }
  });

  /**
   * TODO: Provvisorio per mostrare la checkbox di selezione nella tabella
   * per la pagina Permessi di Visualizzazione
   */
  const rowSelection = props.isSelectable ? {} : undefined;

  // Fix per calcolo altezza Tablescroll nel Drawer (pagina Permesso Temporaneo)
  const tableScrollProps = props.onAdd ? { topSpace: 100 } : {};

  const handleReset = () => {
    dispatch(
      SearchActions.documentSearched({
        page: 1,
        pageSize: search.search.pageSize
      } as any)
    );
  };

  // Colonne
  const columns: ColumnProps<IDocument>[] = [
    // {
    //   key: 'uid',
    //   dataIndex: 'uid',
    //   title: 'UID',
    //   width: 100
    // },
    {
      key: 'areaName',
      dataIndex: 'areaName',
      title: 'A.O.O.',
      width: 120,
      ellipsis: true
    },

    {
      key: 'registerYear',
      dataIndex: 'registerYear',
      title: 'Anno',
      width: 60,
      ellipsis: true
    },
    {
      key: 'registerNumber',
      dataIndex: 'registerNumber',
      title: 'Protocollo',
      width: 80,
      ellipsis: true
    },
    {
      key: 'subject',
      dataIndex: 'subject',
      title: 'Oggetto',
      ellipsis: true
    },
    {
      key: 'actions',
      title: '',
      align: 'center',
      width: 60,
      render: (_, row) =>
        props.onAdd ? (
          props.isAdded && props.isAdded(row.id) ? (
            <Icon style={{ fontSize: '21px', color: '#389e0d' }} type="check" />
          ) : (
            <Button
              type="primary"
              size="small"
              onClick={e => {
                props.onAdd?.(row);
              }}
              icon="plus"
            />
          )
        ) : (
          <Link to={`/app/documents/${row.areaUid}/${row.uid}`}>
            <Button
              className="td-button-link"
              type="link"
              icon="right-circle"
            />
          </Link>
        )
    }
  ];

  return (
    <RowScroller>
      <ColScroller span={8} side="left" spacing="inside">
        {/* Form di ricerca */}
        <Formik<IDocumentsQueryDto>
          initialValues={search.search}
          enableReinitialize
          validate={schemaToValidator(documentsQueryValidator)}
          onSubmit={async (values, formik) => {
            try {
              const parsed = await documentsQueryValidator.validate(values);
              dispatch(
                SearchActions.documentSearched({
                  ...parsed,
                  page: 1,
                  pageSize: search.search.pageSize
                })
              );
            } catch (err) {
              console.error(err);
              message.error('Impossibile avviare la ricerca dei documenti.');
            }
          }}
        >
          <FormikForm layout="vertical">
            <OrganizationalAreaInput
              name="area"
              resetOnChange={[
                'categoryIndexId',
                'category',
                'categoryAnyIndex'
              ]}
            />
            <CategoryGeneralSelect
              areaName="area"
              categoryIndexName="categoryIndexId"
              categoryName="category"
              categoryAnyIndexName="categoryAnyIndex"
            />
            <TextInputItem name="subject" label="Oggetto" />
            <RegisterYearSearch />
            <RegisterNumberSearch />
            <DateSearch fieldPrefix="createdAt" label="Data Creazione" />
            <DateSearch fieldPrefix="registeredAt" label="Data Protocollo" />
            <TextInputItem
              name="metadataValue"
              label="Metadati Specifici"
              itemProps={{
                help: 'Cerca nei valori dei metadati associati al documento.'
              }}
            />
            <br />
            <Button htmlType="submit" type={'primary' as any}>
              Cerca
            </Button>{' '}
            <Button onClick={handleReset} icon="close-circle">
              Annulla
            </Button>
          </FormikForm>
        </Formik>
      </ColScroller>
      <ColScroller
        hasTableScroll
        isCard
        span={16}
        side="right"
        spacing="outside"
        className="full-table"
      >
        {/* Tabella dei risultati (64)*/}
        {error && <Alert type="error" message={error.message} />}
        <TableScroll
          {...tableScrollProps}
          bottomSpace={64}
          rowSelection={rowSelection}
          dataSource={(response && response.data.items) ?? []}
          columns={columns}
          loading={tableLoaderSpin(loading)}
          onChange={(pagination, filters, sorter) => {
            dispatch(
              SearchActions.documentSearched({
                ...search.search,
                page: pagination.current ?? search.search.page,
                pageSize: pagination.pageSize ?? search.search.pageSize
              })
            );
          }}
          pagination={{
            size: 'small',
            position: 'bottom',
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: search.search.pageSize,
            current: search.search.page,
            pageSizeOptions: ['5', '10', '20']
          }}
        />
      </ColScroller>
    </RowScroller>
  );
}
