import * as React from 'react';
import { Divider } from 'antd';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { MetadataNotes } from 'client/components/metadata/fields/MetadataNotes';
import { DossierStructure } from '../detail/dossier-structure/DossierStructure';
import { DossierDocuments } from '../sections/DossierDocuments';
import { IDossier } from 'common/schema/Dossier';
import { SchemaMetadata } from 'client/components/metadata/schema/SchemaMetadata';
import { SpecificMetadatasView } from 'client/components/metadata/specific/SpecificMetadatasView';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { SchemaSection } from 'client/components/metadata/schema/SchemaSection';
import { SchemaLabel } from 'client/components/metadata/schema/SchemaLabel';
import { CategoryIndexLabel } from 'client/components/category-index/CategoryIndexLabel';
import { DossierStandardMetadataView } from 'client/components/metadata/content/DossierStandardMetadataView';

export interface DossierContentTabProps {
  dossier: IDossier;
}

/**
 * Mostra il dettaglio dei metadati del contenuto del Fascicolo
 */
export function DossierContentTab(props: DossierContentTabProps) {
  const { dossier } = props;

  if (!dossier) return null;

  return (
    <RowScroller>
      <ColScroller span={10} className="col-left">
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="code"
          label="Codice"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="title"
          label="Descrizione"
        />
        <Divider />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="createdAt"
          label="Data di creazione"
          type="date"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="openedAt"
          label="Data di apertura"
          type="date"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="closedAt"
          label="Data di chiusura"
          type="date"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="expiresAt"
          label="Data di scadenza"
          type="date"
        />
        <Divider />
        <SchemaMetadata
          ns="dossier"
          field="creator"
          value={dossier.creator?.name ?? null}
          label="Descrizione del creatore del fascicolo"
        />
        <SchemaMetadata
          ns="dossier"
          field="owner"
          value={dossier.owner?.name ?? null}
          label="Descrizione del proprietario del fascicolo"
        />
        <Divider />
        <DossierStructure dossier={dossier} />
        <Divider />

        {/* Segue metadati */}
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="organizationUid"
          label="Codice Organizzazione"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="organizationName"
          label="Nome Organizzazione"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="uid"
          label="ID Archivio Corrente"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="areaUid"
          label="Area Organizzativa Omogenea"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="areaName"
          label="Nome A.O.O."
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="uoName"
          label="Nome dell’unità organizzativa"
        />

        <Divider />

        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="category"
          label="Classifica principale"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="categoryTitle"
          label="Descrizione classifica principale"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="categoryIndexUid"
          label="ID Titolario per la classifica principale"
        />
        <CategoryIndexLabel
          uid={dossier.categoryIndexUid}
          areaUid={dossier.areaUid}
        />
        <Divider />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="repertory"
          label="Repertorio fascicolo"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="description"
          label="Descrizione fascicolo"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="state"
          label="Stato del fascicolo (Aperto / Chiuso)"
        />
        <Divider />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="registerUid"
          label="ID del registro in cui è contenuto il fascicolo"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="register"
          label="Registro in cui è contenuto il fascicolo"
        />
        <Divider />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="responsible"
          label="Responsabile (del procedimento)"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="applicant"
          label="Richiedente"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="fiscalCode"
          label="P.IVA/Codice fiscale"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="collocation"
          label="Collocazione (del fascicolo ibrido)"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="confidentialLevel"
          label="Livello di riservatezza"
        />
        <Divider />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="connectedDossier"
          label="Nome Fascicolo collegato"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="connectedDossierDesc"
          label="Descrizione fascicolo collegato"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="connectedDossierNumber"
          label="Numero Fascicolo collegato"
        />
        <Divider />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="typologyUid"
          label="ID Tipologia fascicolo"
        />
        <SchemaMetadata
          ns="dossier"
          source={dossier}
          field="typology"
          label="Tipologia fascicolo"
        />
        <Divider />
        <DossierStandardMetadataView metadata={dossier.standardMetadata} />
        <Divider />
        <SchemaSection ns="document" field="metadata">
          {dossier.metadata && dossier.metadata.length > 0 && (
            <>
              <SchemaLabel
                ns="dossier"
                field="metadata"
                label="Metadata Specifici"
              />
              <SpecificMetadatasView metadata={dossier.metadata} />
            </>
          )}
        </SchemaSection>
        <Divider />
        {/* Note */}
        <SchemaSection ns="dossier" field="notes">
          {dossier.notes && dossier.notes.length > 0 && (
            <MetadataNotes
              label={<SchemaLabel ns="dossier" field="notes" label="Note" />}
              notes={dossier.notes}
            />
          )}
        </SchemaSection>
      </ColScroller>

      <ColScroller
        isCard
        span={14}
        side="right"
        className="full-table"
        hasTableScroll
      >
        <DossierDocuments dossier={dossier} ns="dossier" />
      </ColScroller>
    </RowScroller>
  );
}
