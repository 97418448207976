import * as yup from 'yup';

declare module 'yup' {
  interface NumberSchema {
    /**
     * Permette a un numero di essere una stringa vuota, trattata come
     * `undefined`.
     */
    allowEmptyString(): NumberSchema;
  }
}

yup.addMethod(yup.number, 'allowEmptyString', function (formats, parseStrict) {
  return this.transform((value, originalValue) => {
    return originalValue === '' ? undefined : value;
  });
});
