import React from 'react';
import { Row } from 'antd';
import { Table } from 'client/ui/table/Table';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  ITransmission,
  ITransmissionRecipient,
  IRecipientUser
} from 'common/schema/Transmission';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { useFieldSchemaColumns } from '../schema/useFieldSchemaColumn';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { SchemaSection } from '../schema/SchemaSection';

export interface ITransmissionsDetailsTableProps {
  selectedTransmission: number | null;
  transmissions: ITransmission[];
  ns: SchemaConfigNamespace;
}

export function TransmissionsDetailsTable(
  props: ITransmissionsDetailsTableProps
) {
  const { transmissions } = props;

  const detailColumns = useFieldSchemaColumns<ITransmissionRecipient>(
    props.ns,
    [
      {
        title: 'Descrizione',
        dataIndex: 'description',
        field: 'transmissions.recipients.description',
        ellipsis: true
      },
      {
        title: 'Tipo',
        dataIndex: 'kind',
        field: 'transmissions.recipients.kind'
      },
      {
        title: 'Ragione',
        dataIndex: 'reason',
        field: 'transmissions.recipients.reason',
        ellipsis: true
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        field: 'transmissions.recipients.type',
        width: 50
      },
      // {
      //   title: 'Note',
      //   dataIndex: 'notes',
      //   field: 'transmissions.recipients.notes',
      //   ellipsis: true
      // },
      {
        title: 'Scadenza',
        dataIndex: 'expiresAt',
        field: 'transmissions.recipients.expiresAt',
        width: 100,
        render: expiresAt => <ISODate date={expiresAt} />
      }
      // {
      //   title: 'Proprietario',
      //   dataIndex: 'owner',
      //   field: 'transmissions.recipients.owner'
      // },
      // {
      //   title: 'Priorità',
      //   dataIndex: 'priority',
      //   field: 'transmissions.recipients.priority'
      // }
    ]
  );

  const recipientUserColumns = useFieldSchemaColumns<IRecipientUser>(props.ns, [
    {
      title: 'Utente',
      dataIndex: 'user',
      field: 'transmissions.recipients.users.user',
      ellipsis: true
    },
    {
      title: 'Vista il',
      dataIndex: 'seenAt',
      field: 'transmissions.recipients.users.seenAt',
      render: date => <ISODate date={date} />,
      width: '15%'
    },
    {
      title: 'Acc. il',
      dataIndex: 'acceptedAt',
      field: 'transmissions.recipients.users.acceptedAt',
      render: date => <ISODate date={date} />,
      width: '15%'
    },
    {
      title: 'Rif. il',
      dataIndex: 'rejectedAt',
      field: 'transmissions.recipients.users.rejectedAt',
      render: date => <ISODate date={date} />,
      width: '15%'
    },
    {
      title: 'Data conclusione',
      dataIndex: 'rejectedAt',
      field: 'transmissions.recipients.users.conclusionAt',
      render: date => <ISODate date={date} />,
      width: '15%'
    }
  ]);

  if (props.selectedTransmission === null) return null;

  const transmission = transmissions[props.selectedTransmission];

  return (
    <div className="table-container">
      {/* <div className="table-title">Dettaglio Trasmissione</div> */}
      <SchemaMetadata
        ns="document"
        field="transmissions.sentAt"
        value={transmission.sentAt}
        label="Data di trasmissione ! Data Assegnazione"
        type="date"
      />
      <SchemaMetadata
        ns="document"
        field="transmissions.finishAt"
        value={transmission.finishAt}
        label="Data fine"
        type="date"
      />
      <SchemaMetadata
        ns="document"
        field="transmissions.sender"
        value={transmission.sender}
        label="Descrizione mittente ! Assegnante"
      />
      <SchemaMetadata
        ns="document"
        field="transmissions.rights"
        value={transmission.rights}
        label="Diritti sul documento"
      />
      <SchemaMetadata
        ns="document"
        field="transmissions.generalNotes"
        value={transmission.generalNotes}
        label="Note generali ! Nota assegnazione"
      />
      <Table
        label="Destinatari"
        className="table-secondary-thead"
        columns={detailColumns}
        dataSource={transmission.recipients}
        size="middle"
        pagination={false}
        defaultExpandedRowKeys={['1']}
        expandedRowRender={record => (
          <div>
            {(record.notes || record.owner || record.priority) && (
              <Row type="flex" className="metadata-inline">
                <SchemaMetadata
                  ns="document"
                  field="transmissions.recipients.notes"
                  value={record.notes}
                  label="Note di trasmissione al ricevente"
                />
                <SchemaMetadata
                  ns="document"
                  field="transmissions.recipients.owner"
                  value={record.owner}
                  label="Detentore documento originale"
                />
                <SchemaMetadata
                  ns="document"
                  field="transmissions.recipients.priority"
                  value={record.priority}
                  label="Priorità"
                />
              </Row>
            )}
            <SchemaSection ns="document" field="transmissions.recipients">
              <Table
                className="table-secondary-thead"
                columns={recipientUserColumns}
                dataSource={record.users}
                pagination={false}
                defaultExpandedRowKeys={['1']}
                expandedRowRender={record => (
                  <Row type="flex" className="metadata-inline">
                    <SchemaMetadata
                      ns="document"
                      field="transmissions.recipients.users.removed"
                      label="Trasmissione rifiutata ! Restituzione"
                      value={record.removed}
                    />
                    <SchemaMetadata
                      ns="document"
                      field="transmissions.recipients.users.info"
                      label="Informazioni di dettaglio sull’utente"
                      value={record.info}
                    />
                    <SchemaMetadata
                      ns="document"
                      field="transmissions.recipients.users.rejectedReason"
                      label="Motivo restituzione"
                      value={record.rejectedReason}
                    />
                    <SchemaMetadata
                      ns="document"
                      field="transmissions.recipients.users.registeredAt"
                      label="Data fascicolazione"
                      value={record.registeredAt}
                      type="date"
                    />
                    <SchemaMetadata
                      ns="document"
                      field="transmissions.recipients.users.conclusion"
                      label="Conclusione"
                      value={record.conclusion}
                    />
                    <SchemaMetadata
                      ns="document"
                      field="transmissions.recipients.users.conclusionReason"
                      label="Motivo conclusione"
                      value={record.conclusionReason}
                    />
                  </Row>
                )}
              />
            </SchemaSection>
          </div>
        )}
      />
    </div>
  );
}
