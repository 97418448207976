import { IDiscardFindResult } from 'common/dto/DiscardDto';
import { IStatisticSpaceUsedItemProps } from 'client/ui/statistic/StatisticSpaceUsedItem';
import { IDiscard } from 'common/schema/Discard';
import { DiscardLogic } from 'common/logic/DiscardLogic';

export function getDiscardCountReport(
  discard: IDiscard,
  result: IDiscardFindResult
): IStatisticSpaceUsedItemProps[] {
  return [
    {
      title: 'Fascicoli',
      running: DiscardLogic.isPending(discard),
      number: result.meta?.dossiersDeletedCount ?? 0,
      totalNumber: result.meta?.dossiersCount ?? 0,
      loading: !result.meta,
      progressNumber: true
    },
    {
      title: 'Documenti',
      running: DiscardLogic.isPending(discard),
      number: result.meta?.documentsDeletedCount ?? 0,
      totalNumber: result.meta?.documentsCount ?? 0,
      loading: !result.meta,
      progressNumber: true
    }
  ];
}
