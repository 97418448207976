import React from 'react';
import './MainLayout.scss';

import clx from 'classnames';
import { Layout } from 'antd';
const { Header } = Layout;
/**
 * Menu Deposito -> from '../menu/MainMenu'
 * Menu Gestione -> from 'client/routes/menu/MainMenu'
 * Commentare altra riga - Manteniamo per compatibilità
 */
import { MainMenu } from '../menu/MainMenu';
// import { MainMenu } from 'client/routes/menu/MainMenu';
import { TopHeader } from './TopHeader';
import { StaticFooter } from './StaticFooter';

export interface MainLayoutProps {
  children: React.ReactNode;
  hasMenu?: boolean;
  fixedHeader?: boolean;
}

/**
 * Layout principale dell'applicativo.
 * Contiene la divisione del menu principale, il contenuto e il footer.
 */
export function MainLayout(props: MainLayoutProps) {
  return (
    <Layout
      className={clx('main-layout', {
        'has-menu': props.hasMenu,
        'fixed-header': props.fixedHeader
      })}
    >
      <Header
        className={clx({
          'has-menu': props.hasMenu,
          'fixed-header': props.fixedHeader
        })}
      >
        <TopHeader />
        {props.hasMenu && <MainMenu />}
      </Header>
      {props.children}
      <StaticFooter />
    </Layout>
  );
}
