import { IGrant, GrantStatus } from 'common/schema/Grant';
import { assertNever } from 'server/utils/typings/assertNever';
import { uniq } from 'lodash';
import moment from 'moment';

export class GrantLogic {
  static canTransitionTo(grant: IGrant, to: GrantStatus) {
    return GrantLogic.getTransitions(grant).includes(to);
  }

  static isApproved(grant: IGrant | undefined) {
    return (
      grant &&
      grant.status === GrantStatus.Active &&
      moment(grant.expiresAt).isSameOrAfter(moment())
    );
  }

  static getTransitions(grant: IGrant | undefined) {
    return uniq([
      ...(grant?.status ? [grant.status] : []),
      ...GrantLogic.getNextTransitions(grant)
    ]);
  }

  static getNextTransitions(grant: IGrant | undefined) {
    // Se non esiste può essere solo impostato a Draft
    if (!grant) return [GrantStatus.Draft];

    switch (grant.status) {
      case GrantStatus.Draft:
        return [GrantStatus.Active, GrantStatus.Declined];

      case GrantStatus.Active:
        return [GrantStatus.Expired];

      case GrantStatus.Expired:
      case GrantStatus.Declined:
        return [];

      case GrantStatus.Request:
        return [GrantStatus.Draft];
    }
    assertNever(grant.status);
  }
}
