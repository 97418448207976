import * as React from 'react';
import { VisibilityTable } from 'client/components/metadata/tabs/VisibilityTable';
import { IDocument } from 'common/schema/Document';
import { Scrollable } from 'client/ui/scroller/Scrollable';

export interface IDocumentVisibilityTabProps {
  document: IDocument;
}

export function DocumentVisibilityTab(props: IDocumentVisibilityTabProps) {
  const { document } = props;

  if (!document) return null;

  return (
    <Scrollable hasTableScroll>
      <VisibilityTable visibilities={document.visibilities} ns="document" />
    </Scrollable>
  );
}
