import * as React from 'react';
import moment from 'moment';
import { MetadataType, IMetadata } from 'common/schema/Metadata';

export interface ISpecificMetadataValueViewProps {
  metadata: IMetadata;
  value: any;
}

/**
 * Mostra un **singolo** valore di un Metadato.
 */
export function SpecificMetadataValueView(
  props: ISpecificMetadataValueViewProps
) {
  const { metadata, value } = props;
  switch (metadata.type) {
    case MetadataType.Number:
    case MetadataType.String:
      return value;

    case MetadataType.Currency:
      return value ? `${value.amount} ${value.currency}` : '';

    case MetadataType.Date:
      return value ? moment(value, moment.ISO_8601).format('DD/MM/YYYY') : '';
  }
}
