import { Reducer } from 'redux';
import { createAction, ActionTypes } from 'client/core/store/actions';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'client/core/store/RootReducer';
import { IUser } from 'common/schema/User';
import { PermissionsType } from 'common/permissions/Permissions';
import {
  PermissionLogic,
  IPermissionRequestOptions
} from 'common/logic/PermissionLogic';
import { IAuthDomain } from 'common/schema/AuthDomain';
import { boolean } from 'yup';
import { IRoleBinding } from 'common/schema/RoleBinding';

export const AuthActions = {
  loginSucceed: createAction('@auth/loginSucceed', (nonce: string) => ({
    nonce
  })),
  tokenRefreshed: createAction('@auth/tokenRefreshed', (nonce: string) => ({
    nonce
  })),
  logout: createAction(
    '@auth/logout',
    (options: { isRedirect: boolean } = { isRedirect: false }) => ({
      ...options
    })
  ),
  tokenExpired: createAction('@auth/tokenExpired'),
  currentLoaded: createAction('@auth/currentLoaded', (current: IUser) => ({
    current
  }))
};

const IS_LOGGED_KEY = 'ark_isLogged';

export interface AuthState {
  isLogged: boolean;
  /**
   * Viene fornito un nonce randomico ogni volta che viene effettuato un nuovo
   * login (chiamata ad access token).
   */
  nonce: string;
  isTokenExpired: boolean;
  current: IUser | null;
}

/**
 * Carichiamo i dati iniziali dal `sessionStorage` in caso siano
 * presenti.
 */
function initialStateFromLocalStorage() {
  const isLogged = window.localStorage.getItem(IS_LOGGED_KEY);
  return { isLogged: isLogged != null };
}

export const AuthReducer: Reducer<
  AuthState,
  ActionTypes<typeof AuthActions>
> = (
  state = {
    isLogged: false,
    nonce: 'first-load',
    isTokenExpired: false,
    current: null,
    ...initialStateFromLocalStorage()
  },
  action
) => {
  switch (action.type) {
    /**
     * Al login, salviamo il token.
     */
    case '@auth/loginSucceed':
      window.localStorage.setItem(IS_LOGGED_KEY, 'true');
      return {
        ...state,
        isLogged: true,
        nonce: action.payload.nonce,
        isTokenExpired: false,
        current: null
      };

    /**
     * Quando il token viene refreshato correttamente, non reimposto il
     * `current` perché è ancora valido (almeno parzialmente) il precedente.
     */
    case '@auth/tokenRefreshed':
      window.localStorage.setItem(IS_LOGGED_KEY, 'true');
      return {
        ...state,
        isLogged: true,
        nonce: action.payload.nonce,
        isTokenExpired: false
      };

    /**
     * Utente corrente caricato. Quando questa richiesta ha esito positivo,
     * vuol dire che l'utente è autorizzato quindi possiamo impostare il token.
     */
    case '@auth/currentLoaded':
      window.localStorage.setItem(IS_LOGGED_KEY, 'true');
      return {
        ...state,
        isLogged: true,
        isTokenExpired: false,
        current: action.payload.current
      };

    /**
     * In caso di scadenza del token lo registriamo.
     */
    case '@auth/tokenExpired':
      return { ...state, isTokenExpired: true };

    /**
     * Rimozione del token dalla sessione.
     */
    case '@auth/logout':
      window.localStorage.removeItem(IS_LOGGED_KEY);
      return {
        ...state,
        // Manteniamo `isLogged` a true in caso di redirect, in quanto in
        // in quel caso il logout deve essere gestito dallo IAM, e se invece
        // cambiassimo `isLogged` verrebbe fornito un nuovo accessToken
        // dalla strategy (i.e. OpenAM)
        isLogged: action.payload.isRedirect ? true : false,
        nonce: 'logout',
        isTokenExpired: false,
        current: null
      };

    default:
      return state;
  }
};

/**
 * Hook per sapere se si è loggati.
 */
export const useAuth = () =>
  useSelector(
    (state: RootState) => ({
      logged: state.auth.isLogged,
      nonce: state.auth.nonce
    }),
    shallowEqual
  );

export const useCurrentUser = () =>
  useSelector((state: RootState) => state.auth.current, shallowEqual);

/**
 * Hook per controllare i permessi
 */
export const usePermissions = () => {
  const user = useCurrentUser();
  return {
    has(
      permission: PermissionsType | PermissionsType[],
      options: IPermissionRequestOptions = {}
    ) {
      return PermissionLogic.has(user, permission, options);
    },
    domain(
      permission: PermissionsType | PermissionsType[],
      domain: IAuthDomain,
      options?: { extend?: boolean }
    ) {
      return PermissionLogic.has(user, permission, {
        domain,
        useHierarchy: !!options?.extend
      });
    },
    canEditUser(target: IUser) {
      return PermissionLogic.canEditUser(target, user);
    },
    canEditBinding(binding: IRoleBinding) {
      return PermissionLogic.canEditBinding(binding, user);
    }
  };
};
