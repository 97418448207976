import * as React from 'react';
import { FormItem } from 'client/ui/form/FormItem';
import {
  DiscardPolicyRuleField,
  DiscardPolicyRuleOperator,
  DiscardPolicyRuleType
} from 'common/schema/DiscardPolicyRule';
import { TextInput } from 'client/ui/form/input/TextInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { OrganizationalAreaInput } from 'client/components/search/form/organizational-area-input/OrganizationalAreaInput';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { OrganizationalAreaApi } from 'client/components/admin/organizations/OrganizationalAreaApi';
import { IOrganizationalArea } from 'common/schema/OrganizationalArea';

export interface IDiscardPolicyRuleValueInputProps {
  name: string;
  index: number;
  operator: DiscardPolicyRuleOperator | undefined;
  ruleField: DiscardPolicyRuleField | undefined;
}

export function DiscardPolicyRuleValueInput(
  props: IDiscardPolicyRuleValueInputProps
) {
  const { index, name, ruleField, operator } = props;

  return (
    <FormItem
      name={`rules.${index}.value`}
      label={index === 0 && 'Valore'}
      colon={false}
    >
      {renderField(ruleField, operator, index, name)}
    </FormItem>
  );
}

function renderField(
  ruleField: DiscardPolicyRuleField | undefined,
  operator: DiscardPolicyRuleOperator | undefined,
  index: number,
  name: string
) {
  if (!ruleField) return <TextInput name={name} type="text" disabled />;

  const withoutOperator = operator == null;

  switch (ruleField.type) {
    case DiscardPolicyRuleType.String:
      return (
        <TextInput
          // style={{ width: 200 }}
          name={name}
          type="text"
          disabled={withoutOperator}
        />
      );

    case DiscardPolicyRuleType.Area:
      return (
        <AsyncSelectInput
          name={name}
          query={{
            apiFn: OrganizationalAreaApi.listSelectable,
            options: (formik, value) => ({
              data: { query: { name: value, page: 1, pageSize: 30 } }
            })
          }}
          optionTransform={(area: IOrganizationalArea) => ({
            value: area.uid,
            label: `${area.uid} - ${area.name}`
          })}
          showSearch
          refreshOnSearch
          responseTransform={data => data.items}
          responseMeta={data => ({
            shown: data.items.length,
            total: data.meta.total
          })}
          // style={{ width: 200 }}
          disabled={withoutOperator}
          mode={
            operator === DiscardPolicyRuleOperator.In ? 'multiple' : 'default'
          }
        />
      );

    case DiscardPolicyRuleType.Date: {
      return (
        <DatePickerInput
          name={name}
          // style={{ width: 200 }}
          type={operator == DiscardPolicyRuleOperator.Before ? 'to' : 'from'}
          allowClear={false}
          disabled={withoutOperator}
        />
      );
    }
  }
}
