import * as React from 'react';
import clx from 'classnames';
import { Icon, Row, Button } from 'antd';
import { AttachmentIcon } from 'client/components/document/attachment/AttachmentIcon';
import { ISODate } from 'client/ui/display/date/ISODate';
import { IAttachment, AttachmentKind } from 'common/schema/Attachment';
import { TableScroll } from 'client/ui/table/TableScroll';
import { useFieldSchemaColumns } from '../schema/useFieldSchemaColumn';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { SchemaSection } from '../schema/SchemaSection';
import { AttachmentsVersionsTable } from './AttachmentsVersionsTable';
import { orderBy } from 'lodash';
import {
  AttachmentPreview,
  previewAttachment
} from 'client/components/document/attachment/AttachmentPreview';

export interface IAttachmentsTableProps {
  attachments: IAttachment[];
  selected?: AttachmentPreview | null;
  onSelect?: (attachment: AttachmentPreview) => void;
  ns: SchemaConfigNamespace;
}

/**
 * Elenco degli allegati.
 */
export function AttachmentsTable(props: IAttachmentsTableProps) {
  function handleSelect(attachment: IAttachment) {
    if (props.onSelect) props.onSelect(previewAttachment(attachment));
  }

  const attachments = orderBy(props.attachments, ['kind', 'uid'], ['desc']);

  const columns = useFieldSchemaColumns<IAttachment>(props.ns, [
    {
      title: '',
      dataIndex: 'fileName',
      key: 'fileName',
      width: 30,
      render: (_, record) => (
        <AttachmentIcon fileName={record.fileName} kind={record.kind} />
      )
    },
    {
      title: 'Descrizione',
      dataIndex: 'title',
      field: 'attachments.title',
      ellipsis: true,
      render: (_, record) => (
        <span
          className={clx('attachment-title', {
            principal: record.kind == AttachmentKind.Principal
          })}
        >
          {record.title ? record.title : record.fileName}
        </span>
      )
    },
    {
      title: 'Data creazione',
      dataIndex: 'createdAt',
      field: 'attachments.createdAt',
      width: 110,
      render: date => <ISODate date={date} />
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      className: 'arrow',
      width: 40,
      render: (_, record) => (
        <Button
          className="td-button-link"
          type="link"
          icon="right-circle"
          onClick={e => handleSelect(record)}
        />
      )
    }
  ]);

  return (
    <TableScroll
      // bottomSpace={54}
      // label="Allegati"
      rowKey="id"
      columns={columns}
      dataSource={attachments}
      size="middle"
      pagination={false}
      rowClassName={row =>
        row === props.selected?.item ? 'ant-table-row-selected' : ''
      }
      expandedRowRender={record => (
        <Row type="flex" className="metadata-inline">
          <SchemaMetadata
            ns="document"
            field="attachments.fileName"
            value={record.fileName}
            label="Nome del file"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.title"
            value={record.title}
            label="Titolo"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.kind"
            value={record.kind}
            label="Tipo (Principale o Allegato)"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.uid"
            value={record.uid}
            label="ID dell’allegato"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.documentId"
            value={record.documentId}
            label="ID Documento"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.code"
            value={record.code}
            label="Codice"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.detailType"
            value={record.detailType}
            label="Indica se è una ricevuta PEC / interoperabile"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.collocation"
            value={record.collocation}
            label="Collocazione"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.hasFile"
            value={record.hasFile}
            label="Specifica se c’è il file"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.pagesCount"
            value={record.pagesCount}
            label="Numero pagine"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.hash"
            value={record.hash}
            label="hash del file"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.bytes"
            value={record.bytes}
            label="DImensione file"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.areaUid"
            value={record.areaUid}
            label="ID Area"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.organizationUid"
            value={record.organizationUid}
            label="ID Organizzazione"
          />
          <SchemaSection ns="document" field="attachments.versions">
            {record.versions && record.versions.length > 0 && (
              <AttachmentsVersionsTable
                selected={props.selected}
                onSelect={props.onSelect}
                attachment={record}
                versions={record.versions}
                ns={props.ns}
              />
            )}
          </SchemaSection>
        </Row>
      )}
    />
  );
}
