import * as React from 'react';
import clx from 'classnames';
import { Tag } from 'antd';
import './GrantStatusTag.scss';
import { assertNever } from 'server/utils/typings/assertNever';
import { GrantStatus, IGrant } from 'common/schema/Grant';
import moment from 'moment';

function mapGrantToTagProps(
  grant: IGrant | null | undefined,
  status: GrantStatus
) {
  switch (status) {
    case GrantStatus.Request:
      return { children: 'Richiesto', color: 'purple' };
    case GrantStatus.Draft:
      return { children: 'In lavorazione', color: 'blue' };
    case GrantStatus.Active:
      if (grant && moment(grant.expiresAt).isBefore(moment())) {
        return { children: 'Scaduto' };
      }
      return { children: 'Autorizzato', color: 'green' };
    case GrantStatus.Expired:
      return { children: 'Scaduto' };
    case GrantStatus.Declined:
      return { children: 'Respinto', color: 'red' };
  }
  assertNever(status);
}

export interface GrantStatusTagProps {
  grant?: IGrant;
  status?: GrantStatus;
}

/**
 * Mostra lo Stato di una richiesta già stilizzato
 */

export function GrantStatusTag(props: GrantStatusTagProps) {
  if (!props.grant && !props.status) return null;
  const tagProps = mapGrantToTagProps(
    props.grant,
    props.grant?.status ?? props.status!
  );

  return <Tag {...tagProps} />;
}
