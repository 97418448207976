import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    /**
     * Permette di fornire un ref a un array esterno (i.e. context) per validare
     * se il valore è contenuto in quell'array.
     */
    oneOfRefArray(arrayRef: yup.Ref): StringSchema;
  }
}

yup.addMethod(yup.mixed, 'oneOfRefArray', function (arrayRef) {
  return this.test({
    message: 'Il campo ${path} non rientra fra le opzioni disponibili.',
    name: 'oneOfRefArray',
    exclusive: true,
    params: { arrayRef },
    test(value) {
      return (this.resolve(arrayRef) || []).includes(value);
    }
  });
});
