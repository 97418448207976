import React from 'react';
import { Row, Col } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';

export const LoginLayout: React.SFC = props => (
  <PageLayout hasScrollable>
    <Row type="flex" justify="center" align="middle" style={{ height: '100%' }}>
      <Col xs={20} md={8}>
        {props.children}
      </Col>
    </Row>
  </PageLayout>
);
