import React, { useState } from 'react';
import clx from 'classnames';
import { Typography, Row, Col, Card, Statistic, Progress, Icon } from 'antd';
import './StatisticCC.scss';
import { StatisticProps } from 'antd/lib/statistic/Statistic';
import { ISODate } from '../display/date/ISODate';

export interface StatisticCCProps extends Omit<StatisticProps, 'value'> {
  value: number | string | Date;
  dateFormat?: string;
}

/**
 * Elemento base Statistiche personalizzato
 * che permette anche di visualizzare le date
 */
export function StatisticCC(props: StatisticCCProps) {
  const { value, dateFormat, ...otherProps } = props;
  let valueProps: StatisticProps = {};
  if (value instanceof Date) {
    valueProps.valueRender = () => <ISODate date={value} format={dateFormat} />;
  } else {
    valueProps.value = value;
  }

  return (
    <Statistic
      className="statistic-cc"
      groupSeparator="."
      decimalSeparator=","
      {...otherProps}
      {...valueProps}
    />
  );
}
