import React from 'react';
import { Row } from 'antd';
import { CardDashboard } from 'client/ui/card/CardDashboard';
import moment from 'moment';
import { IconVector } from 'client/ui/icon/IconVector';
import { ColCardStatisticCC } from 'client/ui/statistic/ColCardStatisticCC';
import { IDashboardDiscardsData } from 'common/schema/Dashboard';

export interface DashboardDiscardDetailProps {
  data: IDashboardDiscardsData;
}

/**
 * Elemento per mostrare i dati degli scarti nella dashboard
 *
 */
export function DashboardDiscardDetail(props: DashboardDiscardDetailProps) {
  const { data } = props;
  return (
    <CardDashboard
      title={
        <span>
          <IconVector image="discard" /> Scarti
        </span>
      }
    >
      <Row gutter={24}>
        <ColCardStatisticCC
          span={6}
          title="Completati"
          value={data.completed}
        />
        <ColCardStatisticCC span={6} title="Proposte" value={data.proposed} />
        <ColCardStatisticCC span={6} title="In Corso" value={data.running} />
        <ColCardStatisticCC
          span={6}
          title="Ultimo Scarto"
          value={moment(data.lastDiscard).toDate()}
        />
      </Row>
    </CardDashboard>
  );
}
