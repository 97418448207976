import { IDiscardPolicy } from './DiscardPolicy';
import { IUser } from './User';
import { IDiscardHistory } from './DiscardHistory';

export enum DiscardState {
  /** Ricerca e selezione di documenti e fascicoli */
  Search = 'search',
  /** Proposta di scarto */
  Proposal = 'proposal',
  /** Esecuzione dello scarto */
  Remove = 'remove',
  /** Completato */
  Complete = 'complete',
  /** Fallito */
  Fail = 'fail',
  /** Outdated */
  Outdated = 'outdated'
}

export enum DiscardProcessingState {
  Queued = 'queued',
  Running = 'running',
  Stalled = 'stalled',
  Completed = 'completed',
  Failed = 'failed'
}

export enum DiscardItemState {
  Prepared = 'P',
  Running = 'R',
  Discarded = 'D'
}

export interface IDiscard {
  id: number;
  state: DiscardState;
  processing: DiscardProcessingState;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  // attachmentPath?: string;
  attachmentPath?: string | null;
  attachmentFileName?: string | null;
  history: IDiscardHistory[];
  policy: IDiscardPolicy;
  authorId?: number;
  author?: IUser;
}
