import * as React from 'react';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { TableGrantsList } from './TableGrantsList';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

export interface GrantsListPageProps {}

export function GrantsListPage(props: GrantsListPageProps) {
  return (
    <PageLayout
      hasScrollable
      fullTable
      hasMenu
      title={
        <CustomCardTitle
          image="grant"
          title="Permessi Temporanei di Consultazione"
        />
      }
      extra={
        <Link to="/app/grants/edit/create">
          <Button type="primary">Nuovo Permesso</Button>
        </Link>
      }
    >
      <TableGrantsList />
    </PageLayout>
  );
}
