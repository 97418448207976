import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Icon, Button, message, Col, Row } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { Formik } from 'formik';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { OrganizationalAreaApi } from '../organizations/OrganizationalAreaApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { IOrganizationalArea } from 'common/schema/OrganizationalArea';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { FormikForm } from 'client/ui/form/FormikForm';
import { OrganizationApi } from '../organizations/OrganizationApi';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageCard } from 'client/ui/layout/PageCard';

interface Params {
  organizationId?: string;
  id?: string;
}

export interface OrganizationalAreaEditProps
  extends RouteComponentProps<Params> {}

/**
 * Componente per modificare un'area organizzativa
 */
export function OrganizationalAreaEdit(props: OrganizationalAreaEditProps) {
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const organizationId = props.match.params.organizationId
    ? parseInt(props.match.params.organizationId, 10)
    : null;

  const organization = useApiQuery(OrganizationApi.findOrganization, {
    skip: organizationId == null,
    data: {
      id: organizationId!
    }
  });
  const isOrganizationLoaded = organization.response || organizationId == null;

  const { response, error, loading, refetch } = useApiQuery(
    OrganizationalAreaApi.findOrganizationalArea,
    {
      skip: !id,
      data: {
        id: id!
      }
    }
  );

  const update = useApiMutation(
    OrganizationalAreaApi.updateOrganizationalArea,
    { data: { id: id! } }
  );
  const create = useApiMutation(
    OrganizationalAreaApi.createOrganizationalArea,
    {}
  );

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare l'Area Organizzativa"
        description={error.message}
      />
    );

  if (id && (!response?.data || !isOrganizationLoaded)) return <LoadingPage />;

  const pageTitle = response?.data.uid
    ? response?.data.uid + ' - ' + response?.data.name
    : 'Nuova Area Organizzativa';

  return (
    <PageLayout hasMenu hasScrollable excludeCard>
      <Formik<IOrganizationalArea>
        initialValues={
          response && response.data
            ? response.data
            : ({ code: '', name: '' } as any)
        }
        onSubmit={async (values, helpers) => {
          try {
            if (id) {
              await update({
                data: { id: id!, data: values }
              });
            } else {
              if (!organizationId) {
                throw new Error('Organizzazione mancante.');
              }

              const result = await create({
                data: {
                  data: {
                    ...values,
                    parentId: organization.response!.data.domain.id
                  }
                }
              });
              // props.history.push(
              //   `/app/admin/organizational-areas/${result.data!.id}`
              // );
              props.history.push(`/app/admin/organizations/${organizationId}`);
            }
            refetch();
            message.success('Area Organizzativa aggiornata con successo.');
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante l'aggiornamento dell'Area Organizzativa."
            );
          }
        }}
      >
        {({ dirty, submitForm, isSubmitting }) => (
          <PageCard
            hasScrollable
            loading={loading || isSubmitting}
            title={
              <CustomCardTitle
                icon="bank"
                titleLabel="Area Organizzativa:"
                title={pageTitle}
              />
            }
            extra={
              <Col>
                <Button
                  htmlType="submit"
                  onClick={submitForm}
                  disabled={loading || isSubmitting}
                  type="primary"
                >
                  Salva
                </Button>
              </Col>
            }
          >
            <Scrollable spacing="inside">
              <FormikForm layout="vertical">
                <TextInputItem
                  name="uid"
                  prefix={<Icon type="bank" />}
                  label="Codice (UID)"
                  placeholder="Codice"
                />
                <TextInputItem name="name" label="Nome" placeholder="Nome" />
                <Row gutter={16}>
                  <Col span={12}>
                    <TextInputItem
                      name="instanceUid"
                      label="UID Istanza"
                      placeholder="UID"
                    />
                  </Col>
                  <Col span={12}>
                    <TextInputItem name="instanceName" label="Nome Istanza" />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <TextInputItem
                      name="clusterUid"
                      label="UID Raggruppamento Tecnico"
                      placeholder="UID"
                    />
                  </Col>
                  <Col span={12}>
                    <TextInputItem
                      name="clusterName"
                      label="Nome Raggruppamento Tecnico"
                    />
                  </Col>
                </Row>
              </FormikForm>
            </Scrollable>
          </PageCard>
        )}
      </Formik>
    </PageLayout>
  );
}
