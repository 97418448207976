import { yup } from 'common/validation/initYup';
import { createDataTransferObject } from 'common/validation/dto/createDataTransferObject';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';

export const userEditValidator = yup
  .object({
    login: yup.string().required(),
    email: yup.string().email(),
    serial: yup.string(),
    name: yup.string().required(),
    enableAccessKey: yup.boolean().default(false),
    accessKey: yup.string().notRequired().nullable().min(8)
  })
  .noUnknown();

export type IUserEditDto = yup.InferType<typeof userEditValidator>;

export const userCreateValidator = userEditValidator.clone().shape({
  accessKey: yup.string().notRequired().min(8) // TODO Gestire required se è enableAccessKey
});

export type IUserCreateDto = yup.InferType<typeof userCreateValidator>;

// Elenco utenti
export const usersQueryDto = createDataTransferObject(
  yup.object({
    page: yup.number().required().integer().min(1),
    pageSize: yup.number().integer().min(5).max(50).default(20),
    name: yup.string().notRequired(),
    login: yup.string().notRequired(),
    serial: yup.string().notRequired(),
    orderBy: yup
      .string()
      .oneOf(['name', 'id', 'serial', 'login'])
      .notRequired()
      .default('name'),
    orderDirection: yup
      .string<ColumnSortDirections>()
      .oneOf(Object.values(ColumnSortDirections) as any)
      .notRequired()
      .default(ColumnSortDirections.ascend)
  })
);

export type IUsersQueryDto = yup.InferType<typeof usersQueryDto['validator']>;
