import * as React from 'react';
import moment from 'moment';
import { Typography, Tag } from 'antd';
const { Text } = Typography;
import './MetadataNote.scss';
import { IUserNote } from 'common/schema/UserNote';
import { CcTooltip } from 'client/ui/tooltip/CcTooltip';

export interface MetadataNoteProps {
  note: IUserNote;
}

export function MetadataNote(props: MetadataNoteProps) {
  const formattedDate = props.note.date
    ? moment(props.note.date).format('DD/MM/YYYY')
    : null;

  const tagMaxLength = 25;
  const isLongTag =
    props.note.visibility && props.note.visibility.length > tagMaxLength;

  return (
    <div className="metadata-note">
      {props.note.user && (
        <Text type="secondary" className="note-user">
          {props.note.user}
        </Text>
      )}
      {props.note.date && (
        <Text type="secondary" className="note-date">
          {formattedDate}
        </Text>
      )}
      {isLongTag && props.note.visibility ? (
        <CcTooltip title={props.note.visibility}>
          <Tag className="note-tag">
            {props.note.visibility.slice(0, tagMaxLength) + '...'}
          </Tag>
        </CcTooltip>
      ) : (
        props.note.visibility && (
          <Tag className="note-tag">{props.note.visibility}</Tag>
        )
      )}
      {props.note.text && <Text className="note-text">{props.note.text}</Text>}
    </div>
  );
}
