import { useEffect } from 'react';
import { useState } from 'react';
import { CategoryIndexApi } from '../CategoryIndexApi';
import { CategoryApi } from 'client/components/category/CategoryApi';
import { ICategoryIndex } from 'common/schema/CategoryIndex';
import { ICategory } from 'common/schema/Category';

/**
 * Hook per la gestione di un titolario con caricamento asincrono.
 */
export function useCategoryTree(id: number | undefined) {
  const [index, setIndex] = useState(null as ICategoryIndex | null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetch() {
      if (!id) return;
      try {
        setLoading(true);
        const response = await CategoryIndexApi.findIndex({ id });
        setIndex(response.data);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, [id]);

  async function loadCategory(category: ICategory) {
    try {
      category.loading = true;
      const response = await CategoryApi.findCategory({ id: category.id });
      category.children = response.data.children;
      setIndex({ ...index } as any);
    } catch (e) {
      setError(e);
    } finally {
      category.loading = false;
    }
  }

  return { loadCategory, index, loading, error };
}
