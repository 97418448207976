import React, { useState } from 'react';
import clx from 'classnames';
import { Typography, Row, Col, Card, Statistic, Progress, Icon } from 'antd';
const { Text, Paragraph } = Typography;
import './StatisticSpaceUsedItem.scss';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { StatisticItem } from './StatisticItem';

export interface IStatisticSpaceUsedItemProps {
  title: string;
  number: number;
  totalNumber?: number;
  running?: boolean;
  space?: number;
  unit?: string;
  loading?: boolean;
  percent?: number;
  progress?: number;
  progressNumber?: boolean;
}
export interface StatisticSpaceUsedItemProps {
  data: IStatisticSpaceUsedItemProps;
  type?: 'card' | 'compact';
  column?: number;
  totalProgress?: number;
  dataLabel?: string;
}

/**
 * Elemento per mostrare le statistiche del numero di elementi considerati
 * (documenti, fascicoli o file)
 */
export function StatisticSpaceUsedItem(props: StatisticSpaceUsedItemProps) {
  const isCompact = props.type == 'compact';

  return (
    <Col
      span={props.column ? 24 / props.column : 8}
      className="statistic-space-used-item"
    >
      <Card size={isCompact ? 'small' : 'default'}>
        {props.data.loading ? (
          <LoaderSpin transparent />
        ) : (
          <StatisticItem {...props} />
        )}
      </Card>
      {props.data.progress != null && (
        <div className="progress-bar-container">
          <Paragraph type="secondary">{props.data.title}</Paragraph>
          <Progress percent={props.data.progress} size="small" />
        </div>
      )}
    </Col>
  );
}
