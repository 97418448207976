import { Form as AntForm } from 'antd';
import React from 'react';
import { FormProps } from 'antd/lib/form';
import { useFormikContext } from 'formik';
import { get, omitBy, isNil } from 'lodash';
import { FormikFormContext, IFormikFormContext } from './FormikFormContext';
// import { hasAsyncErrors } from 'client/core/validation/asyncValidationErrors';

interface Props extends FormProps, IFormikFormContext {}

const emptyHandleSubmit = (e: React.FormEvent<any>) => e.preventDefault();

/**
 * Wrapper per il Form di antd per integrarlo con Formik
 */
export function FormikForm(props: Props) {
  // const { formik, ...layoutProps } = props;
  const formik = useFormikContext();

  return (
    <AntForm
      onSubmit={
        formik.handleSubmit
        // hasAsyncErrors(formik) ? emptyHandleSubmit : formik.handleSubmit
      }
      onReset={formik.handleReset}
      {...props}
    >
      <FormikFormContext.Provider
        value={{
          hideSuccess: props.hideSuccess,
          helpInTooltips: props.helpInTooltips
        }}
      >
        {props.children}
      </FormikFormContext.Provider>
    </AntForm>
  );
}
