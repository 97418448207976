import { useEffect, useCallback, useState, useRef } from 'react';
import { useFormikContext, getIn, FormikContextType } from 'formik';
import { isEqual } from 'lodash';

/**
 * Riconosce un cambiamento fra un insieme di input di Formik, e chiama
 * la callback quando si verifica una differenza.
 *
 * @param onChange
 * @param sourceFields
 */
export function useFormikFieldChange(
  onChange: (formik: FormikContextType<any>, values: any[]) => void,
  sourceFields: string[]
) {
  const formik = useFormikContext<any>();

  const previousValues = useRef<any[]>([]);
  const handleChange = useCallback(onChange, []);

  useEffect(() => {
    const values = sourceFields.map(field => getIn(formik.values, field));
    if (!isEqual(values, previousValues.current)) {
      if (formik.dirty) {
        // console.log('different', values, previousValues.current, formik.dirty);
        handleChange(formik, values);
      }

      // Aggiorniamo solamente i valori (per di un `enableReinitialize` sono
      // cambiati, ma il form non è "cambiato")
      previousValues.current = values;
    }
  }, [formik.values]);
}
