import * as React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { useField, useFormikContext, FormikValues } from 'formik';
import { dateInputUtils } from './dateInputUtils';

export interface DatePickerInputProps<T extends FormikValues>
  extends DatePickerProps {
  name: keyof T & string;
  type: 'from' | 'to';
}

/**
 * Input per cercare su una data.
 */
export function DatePickerInput<T extends FormikValues>(
  props: DatePickerInputProps<T>
) {
  const { name, type, ...otherProps } = props;
  const [field, meta] = useField(name);
  const formik = useFormikContext<T>();

  return (
    <DatePicker
      {...otherProps}
      value={dateInputUtils.toMoment(type, field.value)}
      onOpenChange={status => {
        if (!status) formik.setFieldTouched(name, true);
      }}
      onChange={(date, raw) => {
        // console.log(
        //   'datepicker',
        //   date,
        //   raw,
        //   dateInputUtils.toString(type, date)
        // );
        formik.setFieldValue(name, dateInputUtils.toString(type, date));
      }}
    />
  );
}
