import * as React from 'react';
import { Tag, Icon } from 'antd';
import {
  IDiscard,
  DiscardState,
  DiscardProcessingState
} from 'common/schema/Discard';
import { TagProps } from 'antd/lib/tag';
import { assertNever } from 'server/utils/typings/assertNever';
import './DiscardStatusTag.scss';
import { toPairs } from 'lodash';

function mapDiscardToTagProps(discardState: DiscardState): TagProps {
  switch (discardState) {
    case DiscardState.Search:
      return { children: 'Caricamento', color: 'blue' };
    case DiscardState.Proposal:
      return { children: 'Proposta', color: 'purple' };
    case DiscardState.Complete:
      return { children: 'Completato', color: 'green' };
    case DiscardState.Remove:
      return { children: 'Esecuzione', color: 'blue' };
    case DiscardState.Fail:
      return { children: 'Fallito', color: 'red' };
    case DiscardState.Outdated:
      return { children: 'Obsoleto', color: 'orange' };
  }
  assertNever(discardState);
}

export const DiscardStateOptions = toPairs(DiscardState).map(
  ([key, value]) => ({
    label: <Tag {...mapDiscardToTagProps(value)} />,
    value: value
  })
);

function mapDiscardProcessToTagProps(discard: IDiscard): TagProps {
  // static processing state
  if (discard.state == DiscardState.Proposal) {
    return { color: 'purple', children: <Icon type="file" /> };
  }
  if (discard.state == DiscardState.Fail) {
    return { color: 'red', children: <Icon type="warning" /> };
  }
  if (discard.state == DiscardState.Complete) {
    return { color: 'green', children: <Icon type="check" /> };
  }
  if (discard.state == DiscardState.Outdated) {
    return { color: 'orange', children: <Icon type="exclamation-circle" /> };
  }
  // dinamic processing state
  switch (discard.processing) {
    case DiscardProcessingState.Completed:
      return { color: 'green', children: <Icon type="check" /> };
    case DiscardProcessingState.Failed:
      return {
        color: 'red',
        children: (
          <>
            <Icon type="warning" /> Fallito
          </>
        )
      };
    case DiscardProcessingState.Queued:
      return {
        color: 'geekblue',
        children: (
          <>
            <Icon type="clock-circle" /> In Coda
          </>
        )
      };
    case DiscardProcessingState.Running:
      return {
        color: 'blue',
        children: (
          <>
            <Icon type="sync" spin /> In Corso
          </>
        )
      };
    case DiscardProcessingState.Stalled:
      return {
        color: 'cyan',
        children: (
          <>
            <Icon type="pause-circle" /> In Stallo
          </>
        )
      };
  }
}

export interface DiscardStatusTagProps {
  discard: IDiscard;
}

/**
 * Mostra lo Stato di uno Scarto
 *
 */
export function DiscardStatusTag(props: DiscardStatusTagProps) {
  const tagStatusProps = mapDiscardToTagProps(props.discard.state);
  const tagProgressProps = mapDiscardProcessToTagProps(props.discard);

  return (
    <>
      <Tag className="left-side" {...tagStatusProps} />
      <Tag className="right-side" {...tagProgressProps} />
    </>
  );
}
