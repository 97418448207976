import React from 'react';
import clx from 'classnames';
import './MetadataText.scss';
import { Col, Typography } from 'antd';
const { Text } = Typography;

export interface MetadataTextProps {
  label?: React.ReactNode;
  text?: React.ReactNode;
  // type?: 'disabled' | 'mark' | 'underline' | 'delete' | 'strong';
  className?: string;
  removeColon?: boolean;
  hideIfEmpty?: boolean;
}

export function MetadataText(props: MetadataTextProps) {
  if (props.hideIfEmpty && props.text == null) return null;

  return (
    <Col className={clx(props.className, 'metadata-text')}>
      {props.label && (
        <Text strong className="label">
          {props.label}
          {props.removeColon ? '' : ':'}
        </Text>
      )}
      {props.text && <Text className="text">{props.text}</Text>}
    </Col>
  );
}
