import React from 'react';
import { Icon } from 'antd';
import './CustomCardExtra.scss';

export interface CustomCardExtraProps {
  showStatus?: boolean;
  statusOnline?: boolean;
  right?: React.ReactNode;
}

/**
 * Elemento per gestire la parte superiore destra dell'intestazione Pagina (Pulsanti o altri elementi)
 * verrà inserito nella proprietà Extra della Card di Ant Dedign che si trova in PageCard
 */
export function CustomCardExtra(props: CustomCardExtraProps) {
  return (
    <div className="custom-card-extra-col-right">
      {props.showStatus && (
        <div className="title-status">
          status:{' '}
          <Icon type={props.statusOnline ? 'check-circle' : 'close-circle'} />
        </div>
      )}
      {props.right && <div className="right-content">{props.right}</div>}
    </div>
  );
}
