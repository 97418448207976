import * as React from 'react';
import { Row, Button, Col, Divider, message, Drawer } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import './DiscardPolicyEditPage.scss';
import { RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DiscardPolicyApi } from '../DiscardPolicyApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { Formik } from 'formik';
import { DiscardPolicyRuleInput } from '../rules/DiscardPolicyRuleInput';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { TextAreaInputItem } from 'client/ui/form/input/TextAreaInputItem';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import {
  discardPolicyValidator,
  IDiscardPolicyCreateDto
} from 'common/dto/DiscardPolicyDto';
import { DiscardPolicyStartButton } from './DiscardPolicyStartButton';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { useState } from 'react';
import { DiscardPolicyAnalyze } from '../analyze/DiscardPolicyAnalyze';
import { PageCard } from 'client/ui/layout/PageCard';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { DiscardPolicyTarget } from 'common/schema/DiscardPolicy';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { useModuleConfigOption } from 'client/components/config/ClientConfigModule';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { PermissionLogic } from 'common/logic/PermissionLogic';
import { Roles } from 'common/permissions/Roles';
import { DiscardRuleLogic } from 'common/logic/DiscardRuleLogic';

interface Params {
  id: string;
}

export interface DiscardPolicyEditPageProps
  extends RouteComponentProps<Params> {}

/**
 * Pagina di visualizzazione, modifica e creazione della Singola policy
 * di scarto.
 */
export function DiscardPolicyEditPage(props: DiscardPolicyEditPageProps) {
  // stato per apertura Drawer
  const [analyzing, setAnalyzing] = useState(false);

  // Otteniamo l'ID (o `create` per una nuova policy)
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const user = useCurrentUser();
  const isUserRoot = PermissionLogic.hasRootRole(user, Roles.Archivist.name);
  const initialRules = isUserRoot ? [{}] : [];

  // Gestione tipologia di scarto (documenti non fascicolati)
  const hasDocumentsTarget = useModuleConfigOption(
    'discard-documents',
    'enableDiscardDocuments'
  );

  const { response, error, loading, refetch } = useApiQuery(
    DiscardPolicyApi.find,
    {
      skip: id == null,
      data: {
        id: id!
      }
    }
  );

  // Modifica
  const create = useApiMutation(DiscardPolicyApi.create, {
    invalidates: [DiscardPolicyApi.find]
  });
  const update = useApiMutation(DiscardPolicyApi.update, {
    invalidates: [DiscardPolicyApi.find]
  });

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare la Policy"
        description={error.message}
      />
    );

  if (id && !response?.data) return <LoadingPage />;

  const policy = response?.data;

  return (
    <PageLayout
      hasScrollable
      hasMenu
      excludeCard
      className="discard-policy-edit-page"
    >
      <Formik<IDiscardPolicyCreateDto>
        initialValues={DiscardRuleLogic.getDiscardPolicyInitialValue(
          policy,
          user
        )}
        validate={schemaToValidator(discardPolicyValidator)}
        enableReinitialize
        onSubmit={async (rawValues, helpers) => {
          try {
            const values = await discardPolicyValidator.validate(rawValues, {
              abortEarly: false
            });

            if (id) {
              await update({
                data: { id: id!, input: values }
              });
            } else {
              const result = await create({
                data: { input: { ...values } }
              });
              props.history.push(
                `/app/discard-policies/edit/${result.data!.id}`
              );
            }
            // refetch();
            message.success('Policy aggiornata con successo. ');
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante l'aggiornamento della policy."
            );
          }
        }}
      >
        {({ dirty, submitForm, isSubmitting }) => (
          <PageCard
            hasScrollable
            loading={loading}
            title={
              <CustomCardTitle
                tag={policy ? policy.id.toString() : 'Nuova'}
                tagTheme="filled"
                title={policy ? policy.name : 'Nuova Policy di Scarto'}
              />
            }
            extra={
              <CustomCardExtra
                right={
                  <>
                    <DiscardPolicyStartButton
                      policy={policy}
                      disabled={dirty || !policy || isSubmitting}
                    />
                    <Button
                      htmlType="submit"
                      onClick={submitForm}
                      disabled={isSubmitting}
                      type="primary"
                    >
                      Salva Policy
                    </Button>
                  </>
                }
              />
            }
          >
            <LoaderSpin spinning={loading} hasScroll transparent>
              <RowScroller>
                <ColScroller span={8} side="left" spacing="inside">
                  <FormikForm layout="horizontal">
                    <TextInputItem
                      name="name"
                      label="Nome della Policy"
                      placeholder="Nome della Policy di Scarto"
                    />
                    {hasDocumentsTarget && (
                      <SelectInputItem
                        name="target"
                        label="Tipologia di Scarto"
                        options={[
                          {
                            label: 'Fascicoli',
                            value: DiscardPolicyTarget.Dossiers
                          },
                          {
                            label: 'Documenti non Fascicolati',
                            value: DiscardPolicyTarget.Documents
                          }
                        ]}
                      />
                    )}
                    <TextAreaInputItem
                      name="description"
                      label="Descrizione"
                      placeholder="Descrizione"
                      autosize={{ minRows: 4, maxRows: 8 }}
                    />
                  </FormikForm>
                </ColScroller>
                <ColScroller
                  span={16}
                  isCard
                  side="right"
                  spacing="outside"
                  className="discard-policy-rule-card"
                >
                  <Divider>Regole di Selezione</Divider>
                  <FormikForm>
                    {/* Lista input condizioni di scarto */}
                    <DiscardPolicyRuleInput />

                    {/* RIGA FINALE  per il pulsante Prova Selezione (che apre la Modal) */}
                    <Row
                      type="flex"
                      gutter={4}
                      justify="end"
                      className="rule-final-button-row"
                    >
                      <Col>
                        <Button
                          type="primary"
                          disabled={dirty || !policy}
                          onClick={() => {
                            setAnalyzing(true);
                          }}
                        >
                          Prova Selezione
                        </Button>
                      </Col>
                    </Row>
                  </FormikForm>
                </ColScroller>
              </RowScroller>
            </LoaderSpin>
          </PageCard>
        )}
      </Formik>
      <Drawer
        destroyOnClose
        className="analyze-policy-drawer"
        title={
          <Row type="flex" justify="space-between">
            <Col>
              Policy: {policy?.name ? ' | ' : ''}Prova Selezione{' '}
              {policy?.target === DiscardPolicyTarget.Documents
                ? 'Documenti'
                : 'Fascicoli'}
            </Col>
            <Col>
              <Button
                type="primary"
                ghost
                size="small"
                onClick={() => {
                  setAnalyzing(false);
                }}
              >
                Torna alla Policy
              </Button>
            </Col>
          </Row>
        }
        placement="bottom"
        closable={false}
        visible={analyzing}
      >
        <DiscardPolicyAnalyze policy={policy} id={id} />
      </Drawer>
    </PageLayout>
  );
}
