import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import { IEvent } from 'common/schema/Event';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { useFieldSchemaColumns } from '../schema/useFieldSchemaColumn';
import { TableScroll } from 'client/ui/table/TableScroll';

export interface IEventTableProps {
  ns: SchemaConfigNamespace;
  events: IEvent[];
}

export function EventTable(props: IEventTableProps) {
  const columns = useFieldSchemaColumns<IEvent>(props.ns, [
    {
      title: 'Data',
      field: 'events.createdAt',
      dataIndex: 'createdAt',
      width: 180,
      render: date => <ISODate format="DD/MM/YYYY HH:mm:ss" date={date} />
    },
    {
      title: 'ID Archivio Corrente',
      field: 'events.uid',
      dataIndex: 'uid',
      width: 100,
      ellipsis: true
    },
    {
      title: 'Produttore',
      field: 'events.actor',
      dataIndex: 'actor',
      width: '25%',
      ellipsis: true
    },
    {
      title: 'Evento',
      field: 'events.event',
      dataIndex: 'event',
      width: '45%'
      // ellipsis: true
    }
  ]);

  return <TableScroll columns={columns} dataSource={props.events} />;
}
