import React from 'react';
import moment from 'moment';

import { Layout, Typography, Icon, Row, Col } from 'antd';
const { Text } = Typography;
import './TopHeader.scss';
import { UserSubMenu } from '../menu/UserSubMenu';
import { LiveDate } from './live-date/LiveDate';
import { resolvePublicPath } from 'client/core/appConfig';
import { useThemeConfig } from 'client/components/config/ClientConfigModule';

// const LogoArma = require('./image/logo-head-carabinieri-C-DEP.svg');
const LogoImg = require('./image/logo-space.png');
// const LogoMiur = require('./image/logo_header_miur.png');

export interface TopHeaderProps {}

/**
 * Fascia in alto della pagina che contiene il logo
 * il nome e il ruolo dell'utente ed eventualmente il nome della UID
 */
export function TopHeader(props: TopHeaderProps) {
  const theme = useThemeConfig();

  return (
    <div className="top-header">
      <Row type="flex" align="middle" className="top-name-row">
        <Col>
          <Text strong>{theme.labels.headerText}</Text>
        </Col>
        <Col className="col-user">
          <UserSubMenu />
        </Col>
        <div className="logo">
          <img src={resolvePublicPath(LogoImg)} alt="C-Dep" />
        </div>
        <div className="today-date-wrapper">
          <Text className="today-date">
            <LiveDate />
          </Text>
        </div>
      </Row>
    </div>
  );
}
