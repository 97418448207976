import * as React from 'react';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DiscardPolicyApi } from '../DiscardPolicyApi';
import { useState } from 'react';
import { IDiscardPolicyAnalyzeQueryDto } from 'common/dto/DiscardPolicyAnalyzeDto';
import { ColumnProps } from 'antd/lib/table';
import { IDossier } from 'common/schema/Dossier';
import { ISODate } from 'client/ui/display/date/ISODate';
import { TableScroll } from 'client/ui/table/TableScroll';
import { Alert } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import { IDocument } from 'common/schema/Document';
import {
  DiscardPolicyTarget,
  IDiscardPolicy
} from 'common/schema/DiscardPolicy';

export interface DiscardPolicyAnalyzeProps {
  id: number | null;
  policy: IDiscardPolicy | undefined;
}

/**
 * Pagina di visualizzazione del risulato
 * delle Regole di Selezione della Policy di Scarto.
 */
export function DiscardPolicyAnalyze(props: DiscardPolicyAnalyzeProps) {
  const { id, policy } = props;
  const [recordsQuery, setRecordsQuery] = useState<
    IDiscardPolicyAnalyzeQueryDto
  >({
    page: 1,
    size: 20
  });

  const { response, loading, error } = useApiQuery(
    DiscardPolicyApi.analyzeRecords,
    {
      skip: !id,
      data: { id: id!, query: recordsQuery }
    }
  );

  if (!id || !policy) return null;

  if (error) {
    return (
      <Alert
        type="error"
        message="Impossibile caricare la selezione per la Policy"
        description={getNetworkErrorMessage(error)}
      />
    );
  }

  // if (!response?.data) {
  //   return <LoaderSpin transparent full />;
  // }
  const records = response?.data;

  // Colonne fascicoli o documenti
  const columns: ColumnProps<IDossier | IDocument>[] = [
    {
      key: 'areaUid',
      dataIndex: 'areaUid',
      title: 'A.O.O.',
      width: 160
    },
    ...(policy.target === DiscardPolicyTarget.Dossiers
      ? [
          {
            key: 'code',
            dataIndex: 'code',
            title: 'Codice'
          },
          {
            key: 'title',
            dataIndex: 'title',
            title: 'Descrizione',
            width: '50%',
            ellipsis: true
          }
        ]
      : []),
    ...(policy.target === DiscardPolicyTarget.Documents
      ? [
          {
            dataIndex: 'registerYear',
            title: 'Anno',
            width: 60,
            ellipsis: true
          },
          {
            dataIndex: 'registerNumber',
            title: 'Protocollo',
            width: 80,
            ellipsis: true
          },
          {
            dataIndex: 'subject',
            title: 'Oggetto',
            ellipsis: true
          }
        ]
      : []),
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Data di creazione',
      render: date => <ISODate date={date} />
    }
  ];

  return (
    <Scrollable isCard className="full-table" hasTableScroll>
      <TableScroll
        // label="Dettaglio Fascicoli"
        bottomSpace={64}
        topSpace={100}
        loading={tableLoaderSpin(loading || !response?.data)}
        dataSource={records?.items ?? []}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          setRecordsQuery({
            ...recordsQuery,
            page: pagination.current || recordsQuery.page,
            size: pagination.pageSize || recordsQuery.size
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: records?.meta.total,
          pageSize: recordsQuery.size,
          current: recordsQuery.page,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </Scrollable>
  );
}
