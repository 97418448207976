import * as React from 'react';
import { Icon, Row, Button } from 'antd';
import { AttachmentIcon } from 'client/components/document/attachment/AttachmentIcon';
import { ISODate } from 'client/ui/display/date/ISODate';
import { Table } from 'client/ui/table/Table';
import { IAttachment } from 'common/schema/Attachment';
import { useFieldSchemaColumns } from '../schema/useFieldSchemaColumn';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { SchemaMetadata } from '../schema/SchemaMetadata';
import { IAttachmentVersion } from 'common/schema/AttachmentVersion';
import {
  AttachmentPreview,
  previewAttachmentVersion
} from 'client/components/document/attachment/AttachmentPreview';

export interface IAttachmentsVersionsTableProps {
  attachment: IAttachment;
  versions: IAttachmentVersion[];
  selected?: AttachmentPreview | null;
  onSelect?: (attachment: AttachmentPreview) => void;
  ns: SchemaConfigNamespace;
}

/**
 * Elenco delle versioni degli allegati.
 */
export function AttachmentsVersionsTable(
  props: IAttachmentsVersionsTableProps
) {
  function handleSelect(version: IAttachmentVersion) {
    if (props.onSelect)
      props.onSelect(previewAttachmentVersion(props.attachment, version));
  }

  const columns = useFieldSchemaColumns<IAttachmentVersion>(props.ns, [
    {
      title: '',
      dataIndex: 'fileName',
      key: 'fileName',
      width: 30,
      render: (_, record) => <AttachmentIcon fileName={record.fileName} />
    },
    {
      title: 'Titolo',
      dataIndex: 'title',
      field: 'attachments.versions.title',
      ellipsis: true
    },
    {
      title: 'Data versione',
      dataIndex: 'date',
      field: 'attachments.versions.date',
      width: 110,
      render: date => <ISODate date={date} />
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      className: 'arrow',
      width: 40,
      render: (_, version) => (
        <Button
          className="td-button-link"
          type="link"
          icon="right-circle"
          onClick={e => handleSelect(version)}
        />
      )
    }
  ]);

  return (
    <Table
      // label={
      //   <SchemaLabel
      //     ns="document"
      //     field="attachments.versions"
      //     label="Versioni (lista)"
      //   />
      // }
      columns={columns}
      dataSource={props.versions}
      size="middle"
      pagination={false}
      rowClassName={row =>
        row === props.selected?.item ? 'ant-table-row-selected' : ''
      }
      expandedRowRender={record => (
        <Row type="flex" className="metadata-inline">
          <SchemaMetadata
            ns="document"
            field="attachments.versions.uid"
            value={record.uid}
            label="ID Versione"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.description"
            value={record.description}
            label="Descrizione versione"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.reason"
            value={record.reason}
            label="Motivazione versione"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.author"
            value={record.author}
            label="Autore versione"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.fileName"
            value={record.fileName}
            label="Nome del file"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.createdAt"
            value={record.createdAt}
            label="Data creazione"
            type="date"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.hasFile"
            value={record.hasFile}
            label="Specifica se c’è il file"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.pagesCount"
            value={record.pagesCount}
            label="Numero pagine"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.hash"
            value={record.hash}
            label="hash del file"
          />
          <SchemaMetadata
            ns="document"
            field="attachments.versions.bytes"
            value={record.bytes}
            label="DImensione file"
          />
        </Row>
      )}
    />
  );
}
