export enum ActorType {
  Role = 'Role',
  User = 'User',
  Office = 'Office',
  Other = 'Other'
}

/**
 * Attore del Sistema
 */
export interface IActor {
  name?: string;
  uid?: number;
  email?: string;
  role?: string;
  aoo?: string;
  uo?: string;
  code?: string;
  type?: ActorType;
}
