import * as React from 'react';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DepositApi } from '../../DepositApi';
import { useState } from 'react';
import { displayAttachmentSize } from 'client/components/document/attachment/displayAttachmentSize';
import { IDeposit } from 'common/schema/Deposit';
import { TableScroll } from 'client/ui/table/TableScroll';
import { IDepositAttachmentsQueryDto } from 'common/dto/DepositAttachmentsQueryDto';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  createColumnFilter,
  FilterInputType,
  getFilter
} from 'client/ui/table/TableColumnFilter';
import { IAttachment } from 'common/schema/Attachment';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';

export interface TableDepositAttachmentsListProps {
  deposit: IDeposit;
  label?: React.ReactNode;
}

/**
 * Tabella che mostra l'elenco degli allegati contenuti in un versamento
 * TODO: Gestire errori
 */
export function TableDepositAttachmentsList(
  props: TableDepositAttachmentsListProps
) {
  const { deposit } = props;

  const [query, setQuery] = useState<IDepositAttachmentsQueryDto>({
    page: 1,
    pageSize: 20
  });

  const { response, loading, error } = useApiQuery(DepositApi.listAttachments, {
    data: {
      id: deposit.id,
      query
    }
  });

  const columns: ColumnProps<any>[] = [
    {
      title: 'UID',
      dataIndex: 'uid',
      width: 80,
      sorter: true
    },
    {
      title: 'Titolo',
      dataIndex: 'title',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      title: 'Nome del File',
      dataIndex: 'fileName',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      title: 'Dimensioni',
      dataIndex: 'bytes',
      align: 'right',
      width: 130,
      sorter: true,
      render: bytes => displayAttachmentSize(bytes)
    }
  ];

  return (
    <TableScroll
      label={props.label}
      bottomSpace={64}
      loading={tableLoaderSpin(loading || !response)}
      columns={columns}
      dataSource={response?.data.items}
      onChange={(pagination, filters, sorter) => {
        const { field, order } = sorter as SorterResult<IAttachment>;

        const title = getFilter<string>(filters, 'title');
        const filename = getFilter<string>(filters, 'fileName');

        setQuery({
          ...query,
          title,
          filename,
          orderBy: order ? field?.toString() : 'id',
          orderByDirection: order
            ? ColumnSortDirections[order]
            : ColumnSortDirections.descend,
          page: pagination.current || query.page,
          pageSize: pagination.pageSize || query.pageSize
        });
      }}
      pagination={{
        pageSize: query.pageSize,
        current: query.page,
        total: response?.data.meta.total
      }}
    />
  );
}
