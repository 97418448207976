import * as React from 'react';
import { IDiscard } from 'common/schema/Discard';
import { TableScroll } from 'client/ui/table/TableScroll';
import { Button, Alert, message } from 'antd';
import { ColumnProps, TableRowSelection, SorterResult } from 'antd/lib/table';
import { IDiscardDocument } from 'common/schema/DiscardDocument';
import { IDiscardDocumentsQueryDto } from 'common/dto/DiscardQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DiscardApi } from '../../DiscardApi';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { useState } from 'react';
import { useApiMutationState } from 'client/core/network/hooks/useApiMutation';
import { DiscardLogic } from 'common/logic/DiscardLogic';
import { DiscardItemBadge } from '../../detail/item-badge/DiscardItemBadge';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  FilterInputType,
  createColumnFilter,
  getFilter
} from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';

export interface IDiscardDocumentsTableProps {
  discard: IDiscard;
  /**
   * Modalità di modifica
   */
  isEditable?: boolean;
  /**
   * Per mantenere le impostazioni in caso di abilitazione del FooterMenu
   */
  hasFooterMenu?: boolean;
}

/**
 * Elenco di documenti contenuti nello scarto.
 */
export function DiscardDocumentsTable(props: IDiscardDocumentsTableProps) {
  const { discard, isEditable } = props;
  const [query, setQuery] = useState<IDiscardDocumentsQueryDto>({
    page: 1,
    size: 20
  });

  const isLoadingContent = DiscardLogic.isLoadingContent(discard);

  const { response, loading, error } = useApiQuery(DiscardApi.findDocuments, {
    skip: isLoadingContent,
    data: { id: discard.id, query }
  });

  // Selezione dei documenti
  const [selected, setSelected] = useState<IDiscardDocument[]>([]);
  const rowSelection: TableRowSelection<IDiscardDocument> = {
    selectedRowKeys: selected?.map(d => String(d.id)),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    }
  };

  // Rimozione fascicoli dallo scarto
  const [remove, { loading: removeLoading }] = useApiMutationState(
    DiscardApi.removeDocuments,
    {
      data: { id: discard.id },
      invalidates: [DiscardApi.findDocuments]
    }
  );
  const handleRemove = async () => {
    await remove({
      data: { input: { documentIds: selected.map(d => d.documentId) } }
    });
    message.success(
      'I documenti selezionati sono stati eliminati dalla proposta di scarto'
    );
  };

  const columns: ColumnProps<IDiscardDocument>[] = [
    {
      dataIndex: 'areaUid',
      title: 'A.O.O.',
      width: 120,
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      dataIndex: 'registerYear',
      title: 'Anno',
      width: 85,
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      dataIndex: 'registerNumber',
      title: 'Protocollo',
      width: 110,
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      dataIndex: 'subject',
      title: 'Oggetto',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      dataIndex: 'state',
      title: 'Stato',
      render: (state, item) => <DiscardItemBadge state={state} />,
      width: 100,
      ellipsis: true
    }
  ];

  const documents = response?.data.items;

  return (
    <>
      {error && <Alert type="error" message={getNetworkErrorMessage(error)} />}
      <TableScroll
        bottomSpace={props.hasFooterMenu ? 102 : 64}
        rowKey={record => String(record.id)}
        loading={tableLoaderSpin(loading || isLoadingContent)}
        dataSource={documents ?? []}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          const { field, order } = sorter as SorterResult<IDiscardDocument>;

          const areaUid = getFilter<string>(filters, 'areaUid');
          const registerYear = getFilter<string>(filters, 'registerYear');
          const registerNumber = getFilter<string>(filters, 'registerNumber');
          const subject = getFilter<string>(filters, 'subject');

          setQuery({
            ...query,
            areaUid,
            registerYear,
            registerNumber,
            subject,
            orderBy: order ? field?.toString() : 'id',
            orderByDirection: order
              ? ColumnSortDirections[order]
              : ColumnSortDirections.descend,
            page: pagination.current || query.page,
            size: pagination.pageSize || query.size
          });
        }}
        pagination={{
          size: 'small',
          position: 'bottom',
          showSizeChanger: true,
          total: response?.data.meta?.total,
          pageSize: query.size,
          current: query.page,
          pageSizeOptions: ['5', '10', '20']
        }}
        // label={
        //   editable
        //     ? 'Seleziona i Fascicoli che non devono essere Scartati'
        //     : 'Elenco Fascicoli'
        // }
        rowSelection={isEditable ? rowSelection : undefined}
        bottomLeft={
          isEditable && (
            <ButtonDeleteConfirm
              placement="top"
              title="Sei sicuro di voler escludere i Documenti selezionati da questo Scarto?"
              okText="Escludi selezionati dallo Scarto"
              onConfirm={handleRemove}
              loading={removeLoading}
            />
          )
        }
      />
    </>
  );
}
