import { PermissionsType } from 'common/permissions/Permissions';
import { IUser } from 'common/schema/User';
import { Roles, RoleName } from 'common/permissions/Roles';
import { IAuthDomain } from 'common/schema/AuthDomain';
import { AuthDomainLogic } from './AuthDomainLogic';
import { number } from 'yup';
import { RolesLogic } from './RolesLogic';
import { IRoleBinding } from 'common/schema/RoleBinding';

export interface IPermissionRequest {
  permission: PermissionsType | PermissionsType[];
  forRoot?: boolean;
  domain?: IAuthDomain;
  /**
   * Se vero, autorizza un utente anche ai sottodomini dei domini per cui è autorizzato.
   * e.g. Un utente autorizzato per il dominio con mpat 1.2 sarà autorizzato anche per 1.2.4, 1.2.8, 1.2.X
   */
  useHierarchy?: boolean;
}

export type IPermissionRequestOptions = Omit<IPermissionRequest, 'permission'>;

export class PermissionLogic {
  /**
   * Controlla se un utente dispone di un ruolo per il dominio root
   */
  static hasRootRole(user: IUser | null, role: RoleName) {
    return (
      user?.bindings?.some(
        binding =>
          binding.role === role && AuthDomainLogic.isRoot(binding.domain)
      ) ?? false
    );
  }

  static hasRole(user: IUser | null, role: RoleName): boolean {
    return user?.bindings?.some(binding => binding.role === role) ?? false;
  }

  /**
   * Controlla se un utente dispone del permesso per un qualsiasi dominio oppure
   * esplicitamente per il dominio radice.
   */
  static has(
    user: IUser | null,
    permission: PermissionsType | PermissionsType[],
    options: IPermissionRequestOptions = {}
  ) {
    if (user == null) return false;

    // Check sul dominio radice
    if (options.forRoot) {
      const rootBinding = user.bindings?.find(binding =>
        AuthDomainLogic.isRoot(binding.domain)
      );
      if (!rootBinding) return false;
      return Roles[rootBinding.role].hasPermission(permission);
    }

    // Check sul dominio e su tutti i suoi sottodomini
    if (options.domain && options.useHierarchy) {
      // Path dei domini dell'albero dalla root
      const domains = options.domain.mpath?.split('.');
      if (!domains) return false;
      // Per ogni dominio, se l'utente ha accesso ad almeno 1 con il giusto permesso,
      // allora si considera autorizzato
      for (let domain of domains) {
        const binding = user.bindings?.find(
          binding => binding.domain.id === Number(domain)
        );
        if (binding && Roles[binding.role].hasPermission(permission)) {
          return true;
        }
      }
      // Se l'utente non risulta autorizzato a nessun dominio nel mpath, ritorna falso
      return false;
    }

    // Check su un dominio specifico
    if (options.domain) {
      const binding = user.bindings?.find(
        binding => binding.domain.id === options.domain?.id
      );
      if (!binding) return false;
      return Roles[binding.role].hasPermission(permission);
    }

    // Check su un dominio qualsiasi (any)
    return user.bindings?.some(binding =>
      Roles[binding.role].hasPermission(permission)
    );
  }

  /**
   * Ritorna vero se l'utente `editor` può modificare il binding.
   */
  static canEditBinding(binding: IRoleBinding, editor: IUser | null) {
    if (!editor) return false;

    // Un utente può editare un binding solo se:
    // - Dato il dominio, ha accesso al ruolo richiesto in quel dominio
    // - Ha un ruolo che concede il permesso di modifica in quel dominio
    return (
      RolesLogic.availableRolesOptionsForDomain(editor, binding.domain).some(
        role => role.value === binding.role
      ) &&
      this.has(editor, 'users.write', {
        domain: binding.domain,
        useHierarchy: true
      })
    );
  }

  /**
   * Ritorna vero se l'utente `editor` ha l'autorizzazione alla modifica dell'utente `user`.
   * È possibile solo se tutti i binding di `user` sono un subset accessibile ai bindings di `editor`.
   */
  static canEditUser(user: IUser, editor: IUser | null) {
    if (!editor) return false;
    // Un utente A può editare un altro utente B solo se A può modificare tutti i binding di B.
    return user.bindings?.every(binding =>
      this.canEditBinding(binding, editor)
    );
  }
}
