import { DepositState } from 'common/schema/Deposit';
import { DepositStateTag } from './DepositStateTag';
import React from 'react';
import { toPairs } from 'lodash';

export const DepositStateOptions = toPairs(DepositState).map(
  ([key, value]) => ({
    label: <DepositStateTag state={value} />,
    value: value
  })
);
