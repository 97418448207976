import * as React from 'react';
import { AttachmentPreviewFrame } from '../attachment/AttachmentPreviewFrame';
import { AttachmentKind, IAttachment } from 'common/schema/Attachment';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { AttachmentDownloadButton } from '../attachment/AttachmentDownloadButton';
import { AttachmentTypeLabel } from '../attachment/AttachmentTypeLabel';
import { AttachmentPreview } from '../attachment/AttachmentPreview';

export interface IAttachmentColumnProps {
  attachment: AttachmentPreview | null;
}

/**
 * Mostra la colonna destra in cui è visualizzato il Pdf,
 * compreso di pulsante di download e indicazione tipo allegato (principale | allegato)
 *
 */
export function AttachmentColumn(props: IAttachmentColumnProps) {
  const { attachment } = props;

  return (
    <>
      <ColScroller pdfViewer isCard span={14} side="right" hasTableScroll>
        <AttachmentPreviewFrame attachment={attachment} />
      </ColScroller>
      <AttachmentDownloadButton attachment={attachment} />
      <AttachmentTypeLabel attachment={attachment} />
    </>
  );
}
