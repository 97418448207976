import * as React from 'react';
import { MetadataText } from 'client/ui/metadata/MetadataText';
import { SpecificMetadataValueView } from './SpecificMetadataValueView';
import { IMetadata } from 'common/schema/Metadata';

export interface ISpecificMetadataViewProps {
  metadata: IMetadata;
}

export function SpecificMetadataView(props: ISpecificMetadataViewProps) {
  const { metadata } = props;

  const text =
    metadata.list || Array.isArray(metadata.value) ? (
      <>
        {metadata.value.map((value: any, key: any) => (
          <span className="specific-metadata--item" key={key}>
            <SpecificMetadataValueView metadata={metadata} value={value} />
          </span>
        ))}
      </>
    ) : (
      <SpecificMetadataValueView metadata={metadata} value={metadata.value} />
    );

  return <MetadataText label={metadata.label || metadata.name} text={text} />;
}
