import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'client/core/store/RootReducer';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';

/**
 * Configurazione di un campo a partire dal file di configurazione
 */
export const useFieldSchema = (ns: SchemaConfigNamespace, field: string) =>
  useSelector(
    (state: RootState) =>
      state.config.config?.schema[ns].fields.find(f => f.name === field),
    shallowEqual
  );
