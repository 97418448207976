import * as React from 'react';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { useFieldSchema } from './useFieldSchema';
import moment from 'moment';
import { Icon } from 'antd';

export interface ISchemaValueProps {
  /**
   * Specifica se è per il documento o per il fascicolo
   */
  ns: SchemaConfigNamespace;
  /**
   * Campo da visualizzare
   */
  field: string;
  /**
   * Dato fornito direttamente
   */
  value: any;
  /**
   * Tipo di dato
   */
  type?: 'string' | 'date' | 'number' | 'boolean';
}

/**
 * Rendering esclusivamente del **valore** (e non del label) di un
 * metadato dello schema.
 */
export function SchemaValue(props: ISchemaValueProps) {
  const { field } = props;
  const fieldSchema = useFieldSchema(props.ns, field);

  if (!fieldSchema?.enable) {
    return null;
  }

  // TODO Supportare anche `source`?
  const originalValue = props.value;

  const option = fieldSchema?.options?.find(o => o.value === originalValue);
  const value = option?.label ?? originalValue;
  const type = props.type ?? typeof value;

  if (value == null) return null;

  switch (type) {
    case 'date':
      return value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : null;
    case 'boolean':
      return props.value ? (
        <>
          <Icon type="check-circle" className="value-true" /> Si
        </>
      ) : (
        <>
          <Icon type="close-circle" className="value-false" /> No
        </>
      );
    case 'number':
    case 'string':
    default:
      return value;
  }
}
