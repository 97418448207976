import * as React from 'react';
import { DepositContentType } from './DepositContentType';
import { ColumnProps } from 'antd/lib/table';
import { IDeposit } from 'common/schema/Deposit';
import { TableScroll } from 'client/ui/table/TableScroll';
import { Table } from 'client/ui/table/Table';

export interface IDepositContentTypeSelectTableProps {
  deposit: IDeposit;
  selected: DepositContentType;
  onChange: (type: DepositContentType) => void;
}

/**
 * Tabella per visualizzare i numeri del Versamento.
 */
export function DepositContentTypeSelectTable(
  props: IDepositContentTypeSelectTableProps
) {
  const { deposit } = props;

  const types = [
    {
      type: DepositContentType.Dossiers,
      title: 'Fascicoli',
      count: deposit.dossiersCount,
      received: deposit.dossiersReceived
    },
    {
      type: DepositContentType.Documents,
      title: 'Documenti',
      count: deposit.documentsCount,
      received: deposit.documentsReceived
    }
  ];

  const columns: ColumnProps<ArrayItem<typeof types>>[] = [
    {
      title: 'Tipo',
      dataIndex: 'title',
      width: '40%'
    },
    {
      title: 'Totale Policy',
      dataIndex: 'count',
      align: 'center'
    },
    {
      title: 'Versati',
      dataIndex: 'received',
      align: 'right'
    },
    {
      title: '',
      dataIndex: 'actions',
      align: 'right',
      width: 20
    }
  ];

  return (
    <Table
      // bottomSpace={54}
      columns={columns}
      dataSource={types}
      rowKey="type"
      pagination={false}
    />
  );
}
