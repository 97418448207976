import { yup } from 'common/validation/initYup';
import { RoleName, Roles } from 'common/permissions/Roles';

export interface IUserRolesAddDto {
  role: RoleName;
  domainId: number;
}

export const userRolesAddDtoValidator = yup.object({
  role: yup.string().required().oneOf(Object.keys(Roles)).label('Ruolo'),
  domainId: yup.number().required().label('Dominio')
});

export interface IUserRoleDeleteQueryDto {
  domainId: number;
}

export const userRolesDeleteQueryDtoValidator = yup.object({
  domainId: yup.number().required().label('Dominio')
});
