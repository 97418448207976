export enum DepositContentType {
  Documents = 'Documents',
  Dossiers = 'Dossiers',
  Attachments = 'Attachments'
}

export const DepositContentTypeTitle = {
  [DepositContentType.Documents]: 'Documenti',
  [DepositContentType.Dossiers]: 'Fascicoli',
  [DepositContentType.Attachments]: 'File'
};
