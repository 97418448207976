import { apiClient } from 'client/core/network/apiClient';
import { IDiscardPoliciesQueryDto } from 'common/dto/DiscardPoliciesQueryDto';
import { IDiscardPolicy } from 'common/schema/DiscardPolicy';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IDiscardPolicyAnalyzeQueryDto } from 'common/dto/DiscardPolicyAnalyzeDto';
import { IDossier } from 'common/schema/Dossier';
import { IDiscard } from 'common/schema/Discard';
import { IDiscardPolicyCreateDto } from 'common/dto/DiscardPolicyDto';
import { IDocument } from 'common/schema/Document';

export const DiscardPolicyApi = {
  list: (opts: { query: IDiscardPoliciesQueryDto }) =>
    apiClient.get<PaginatedResult<IDiscardPolicy>>(`/discard-policies`, {
      params: opts.query
    }),
  find: (opts: { id: number }) =>
    apiClient.get<IDiscardPolicy>(`/discard-policies/${opts.id}`),
  delete: (opts: { id: number }) =>
    apiClient.delete(`/discard-policies/${opts.id}`),
  create: (opts: { input: IDiscardPolicyCreateDto }) =>
    apiClient.post<IDiscardPolicy>(`/discard-policies`, opts.input),
  update: (opts: { id: number; input: IDiscardPolicyCreateDto }) =>
    apiClient.put<IDiscardPolicy>(`/discard-policies/${opts.id}`, opts.input),

  // Analyze impact
  analyzeRecords: (opts: {
    id: number;
    query: IDiscardPolicyAnalyzeQueryDto;
  }) =>
    apiClient.get<PaginatedResult<IDossier | IDocument>>(
      `/discard-policies/${opts.id}/actions/analyze/records`,
      { params: opts.query }
    ),

  // Avvio
  start: (opts: { id: number }) =>
    apiClient.post<IDiscard>(`/discard-policies/${opts.id}/actions/start`)
};
