import React from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import { TextAreaProps } from 'antd/lib/input';
import TextArea from 'antd/lib/input/TextArea';

// interface Props<T extends FormikValues> extends Omit<TextAreaProps, 'name'> {
//   name: keyof T & string;
// }
export interface TextAreaInputProps<T extends FormikValues>
  extends Omit<TextAreaProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input area di testo.
 */
export function TextAreaInput<T extends FormikValues>(
  props: TextAreaInputProps<T>
) {
  const { name } = props;
  const [field, meta] = useField(name);
  const formik = useFormikContext<T>();

  return <TextArea {...props} {...field} />;
}
