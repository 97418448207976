import { apiClient } from 'client/core/network/apiClient';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { IDossier } from 'common/schema/Dossier';
import { IDiscard } from 'common/schema/Discard';
import {
  IDiscardQueryDto,
  IDiscardDossiersQueryDto,
  IDiscardDocumentsQueryDto,
  IDiscardDossiersRemoveDto,
  IDiscardDocumentsRemoveDto
} from 'common/dto/DiscardQueryDto';
import { IDocument } from 'common/schema/Document';
import { IDiscardDossier } from 'common/schema/DiscardDossier';
import { IDiscardDocument } from 'common/schema/DiscardDocument';
import { IDiscardFindResult, IUpdateDiscardDto } from 'common/dto/DiscardDto';

export const DiscardApi = {
  list: (opts: { query: IDiscardQueryDto }) =>
    apiClient.get<PaginatedResult<IDiscard>>(`/discards`, {
      params: opts.query
    }),

  find: (opts: { id: number }) =>
    apiClient.get<IDiscardFindResult>(`/discards/${opts.id}`),

  update: (opts: { id: number; input: IUpdateDiscardDto }) =>
    apiClient.put<IDiscard>(`/discards/${opts.id}`, opts.input),

  remove: (opts: { id: number }) => apiClient.delete(`/discards/${opts.id}`),

  uploadAttachment: (opts: { id: number; data: FormData }) =>
    apiClient.post<IDiscard>(`/discards/${opts.id}/attachment`, opts.data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  removeAttachment: (opts: { id: number }) =>
    apiClient.delete<IDiscard>(`/discards/${opts.id}/attachment`),

  start: (opts: { id: number }) =>
    apiClient.post<{}>(`/discards/${opts.id}/actions/start`),
  retry: (opts: { id: number }) =>
    apiClient.post<{}>(`/discards/${opts.id}/actions/retry`),

  findDossiers: (opts: { id: number; query: IDiscardDossiersQueryDto }) =>
    apiClient.get<PaginatedResult<IDiscardDossier>>(
      `/discards/${opts.id}/dossiers`,
      {
        params: opts.query
      }
    ),

  removeDossiers: (opts: { id: number; input: IDiscardDossiersRemoveDto }) =>
    apiClient.post<{}>(
      `/discards/${opts.id}/dossiers/actions/remove`,
      opts.input
    ),

  findDocuments: (opts: { id: number; query: IDiscardDocumentsQueryDto }) =>
    apiClient.get<PaginatedResult<IDiscardDocument>>(
      `/discards/${opts.id}/documents`,
      {
        params: opts.query
      }
    ),

  removeDocuments: (opts: { id: number; input: IDiscardDocumentsRemoveDto }) =>
    apiClient.post<{}>(
      `/discards/${opts.id}/documents/actions/remove`,
      opts.input
    )
};
