import * as React from 'react';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { RouteComponentProps } from 'react-router-dom';
import { DossierSearch } from './DossierSearch';

export interface IDossierSearchPageProps extends RouteComponentProps {}

export function DossierSearchPage(props: IDossierSearchPageProps) {
  return (
    <PageLayout
      hasScrollable
      hasMenu
      title={<CustomCardTitle icon="folder" title="Ricerca Fascicoli" />}
    >
      <DossierSearch />
    </PageLayout>
  );
}
