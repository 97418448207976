import {
  IDiscard,
  DiscardProcessingState,
  DiscardState
} from 'common/schema/Discard';
import { IDiscardFindResultMeta } from 'common/dto/DiscardDto';

export class DiscardLogic {
  /**
   * Controlla se uno scarto è in caricamento.
   */
  static isLoadingContent(discard: IDiscard) {
    return (
      discard.state === DiscardState.Search &&
      discard.processing === DiscardProcessingState.Running
    );
  }

  /**
   * Controlla se uno scarto non è ancora in uno stato definitivo
   */
  static isPending(discard: IDiscard) {
    return (
      discard.processing === DiscardProcessingState.Running ||
      discard.processing === DiscardProcessingState.Queued
    );
  }

  static isRetryable(discard: IDiscard) {
    return (
      discard.state !== DiscardState.Complete &&
      discard.processing !== DiscardProcessingState.Running &&
      discard.processing !== DiscardProcessingState.Queued &&
      discard.processing !== DiscardProcessingState.Completed
    );
  }

  /**
   * Uno scarto è eliminabile solo se è in uno stato non esecutivo
   */
  static isEditableOrRemovable(discard: IDiscard | undefined) {
    if (!discard) return false;
    return (
      [DiscardState.Proposal, DiscardState.Outdated].includes(discard.state) &&
      ![DiscardProcessingState.Queued, DiscardProcessingState.Running].includes(
        discard.processing
      )
    );
  }

  /**
   * E' possibile avviare solo una proposta di scarto completata.
   */
  static isStartable(discard: IDiscard) {
    return (
      DiscardState.Proposal === discard.state &&
      DiscardProcessingState.Completed === discard.processing
    );
  }

  /**
   * Controlla se uno scarto è ricaricabile, lato UI.
   */
  static shouldRefresh(discard: IDiscard | undefined) {
    if (!discard) return false;
    return (
      discard.processing === DiscardProcessingState.Queued ||
      discard.processing === DiscardProcessingState.Running
    );
  }

  /**
   * Ottiene il progress di scarto
   */
  static totalProgress(discard: IDiscard, meta?: IDiscardFindResultMeta) {
    if (discard.state !== DiscardState.Remove || !meta) return undefined;
    const total = meta.documentsCount + meta.dossiersCount;
    if (total === 0) return undefined;
    return Math.round(
      ((meta.documentsDeletedCount + meta.dossiersDeletedCount) / total) * 100
    );
  }
}
