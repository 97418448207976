import React from 'react';
import { Row, Col, Alert, Card } from 'antd';
import { CardDashboard } from 'client/ui/card/CardDashboard';
import { StatisticSpaceUsedItem } from 'client/ui/statistic/StatisticSpaceUsedItem';
import { IconVector } from 'client/ui/icon/IconVector';
import { IDashboardSystemData } from 'common/schema/Dashboard';

export interface DashboardDepositDetailProps {
  data: IDashboardSystemData;
}

/**
 * Elemento per mostrare i dati generali dell'archivio di deposito nella dashboard
 *
 */
export function DashboardSystemDetail(props: DashboardDepositDetailProps) {
  const { data } = props;
  return (
    <CardDashboard
      title={
        <span>
          <IconVector image="home" /> Archivio
        </span>
      }
    >
      <Row gutter={12}>
        <StatisticSpaceUsedItem
          data={{
            title: 'Fascicoli',
            number: data.dossiers
          }}
          type="compact"
          key={1}
          dataLabel="Totale"
          column={3}
        />
        <StatisticSpaceUsedItem
          data={{
            title: 'Documenti',
            number: data.documents
          }}
          type="compact"
          key={2}
          dataLabel="Totale"
          column={3}
        />
        <StatisticSpaceUsedItem
          data={{
            title: 'Allegati',
            number: data.attachments
          }}
          type="compact"
          key={3}
          dataLabel="Totale"
          column={3}
        />
      </Row>
    </CardDashboard>
  );
}
