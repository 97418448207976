import { joinPath } from './network/joinPath';

const window$ = window as any;

export const appConfig = {
  publicPath: window$.ARK_PUBLIC_PATH || process.env.ARK_PUBLIC_PATH
};

/**
 * Permette di utilizzare URL importati da webpack anche in caso di
 * sottocartelle.
 */
export function resolvePublicPath(path: string) {
  return joinPath([appConfig.publicPath, path]);
}

/**
 * Permette di ottenere il link alle API risolvendo sottocartelle e URL
 * delle API.
 */
export function resolveApiPath(path: string) {
  return joinPath([appConfig.publicPath, process.env.REST_URL, path]);
}
