import * as React from 'react';
import { Input, Row, Col, Select } from 'antd';
import { TextInput } from 'client/ui/form/input/TextInput';
import { useState } from 'react';
import { FormItem } from 'client/ui/form/FormItem';
import { useFormikContext } from 'formik';
import { useTypeField } from 'client/ui/form/hooks/useTypeField';

export interface IRegisterNumberSearchProps {}

const RegisterNumberSearchTypes = [
  { value: 'single', label: 'Singolo', inputNames: ['registerNumber'] },
  {
    value: 'range',
    label: 'Intervallo',
    inputNames: ['registerNumberRangeFrom', 'registerNumberRangeTo']
  }
];

export function RegisterNumberSearch(props: IRegisterNumberSearchProps) {
  const [searchTypeValue, setSearchTypeValue] = useTypeField(
    'registerNumber',
    'single'
  );
  const formik = useFormikContext<any>();
  const searchType = RegisterNumberSearchTypes.find(
    t => t.value === searchTypeValue
  )!;

  const changeSearchType = (value: string) => {
    searchType.inputNames.forEach(name =>
      formik.setFieldValue(name, undefined)
    );
    setSearchTypeValue(value);
  };

  return (
    <FormItem names={searchType.inputNames} label="Num. Protocollo">
      <Input.Group compact>
        <Select
          style={{ width: '30%' }}
          value={searchTypeValue}
          onChange={changeSearchType}
        >
          {RegisterNumberSearchTypes.map(type => (
            <Select.Option key={type.value} value={type.value}>
              {type.label}
            </Select.Option>
          ))}
        </Select>

        {searchType.value === 'single' && (
          <TextInput style={{ width: '70%' }} name="registerNumber" />
        )}
        {searchType.value === 'range' && (
          <>
            <TextInput
              style={{ width: '30%' }}
              name="registerNumberRangeFrom"
              placeholder="Da"
              type="number"
            />
            <Input
              style={{
                width: '10%',
                borderLeft: 0,
                pointerEvents: 'none',
                backgroundColor: '#fff'
              }}
              placeholder="~"
              disabled
            />
            <TextInput
              name="registerNumberRangeTo"
              style={{ width: '30%', borderLeft: 0 }}
              placeholder="A"
              type="number"
            />
          </>
        )}
      </Input.Group>
    </FormItem>
  );
}
