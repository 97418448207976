import * as React from 'react';
import { Typography } from 'antd';
const { Text } = Typography;
import { ISODate } from 'client/ui/display/date/ISODate';
import { Icon } from 'antd';
import {
  IVisibility,
  VisibilityActorType,
  VisibilityPermission
} from 'common/schema/Visibility';
import { TableScroll } from 'client/ui/table/TableScroll';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { useFieldSchemaColumns } from '../schema/useFieldSchemaColumn';
import { IActor, ActorType } from 'common/schema/Actor';
import { MetadataText } from 'client/ui/metadata/MetadataText';

export interface IVisibilityTableProps {
  visibilities: IVisibility[];
  ns: SchemaConfigNamespace;
}

function getIconFromActorType(type: ActorType | null) {
  switch (type) {
    case ActorType.User:
      return 'user';
    case ActorType.Role:
      return 'team';
    case ActorType.Office:
      return 'bank';
    case ActorType.Other:
    default:
      return 'setting';
  }
}

function renderUser(user: IActor) {
  return (
    <MetadataText
      text={
        <>
          <Icon
            style={{ fontSize: '18px' }}
            type={getIconFromActorType(user.type ? user.type : null)}
          />{' '}
          {user.name}
        </>
      }
    />
  );
}

export function VisibilityTable(props: IVisibilityTableProps) {
  const columns = useFieldSchemaColumns<IVisibility>(props.ns, [
    {
      title: 'Tipo',
      dataIndex: 'type',
      field: 'visibilities.type',
      width: 50,
      render: (_, record) => (
        <Icon
          style={{ fontSize: '18px' }}
          type={record.type === VisibilityActorType.Role ? 'team' : 'user'}
        />
      )
    },
    {
      title: 'Ruolo/Utente',
      dataIndex: 'actor',
      field: 'visibilities.actor',
      ellipsis: true
    },
    {
      title: 'ACL',
      dataIndex: 'aclName',
      field: 'visibilities.aclName',
      width: 160,
      ellipsis: true
    },
    {
      title: 'Uffici/Utenti ACL',
      dataIndex: 'actor.role',
      field: 'visibilities.actors',
      render: (_, record) =>
        record.actors.map((actor, key) => (
          <Text key={key}>
            <Icon
              style={{ fontSize: '18px' }}
              type={getIconFromActorType(actor.type ? actor.type : null)}
            />{' '}
            {actor.name}
            {key + 1 != record.actors.length && ', '}
          </Text>
        ))
    },
    {
      title: 'Motivo',
      dataIndex: 'reason',
      field: 'visibilities.reason',
      width: 120,
      ellipsis: true
    },
    {
      title: 'Diritti',
      dataIndex: 'permissions',
      field: 'visibilities.permissions',
      width: 130,
      render: (_, record) =>
        record.permissions === VisibilityPermission.Read
          ? 'Lettura'
          : 'Lettura/Scrittura'
    },
    {
      title: 'Data Inizio',
      dataIndex: 'createdAt',
      field: 'visibilities.createdAt',
      width: 100,
      render: date => <ISODate date={date} />
    },
    {
      title: 'Data Fine',
      dataIndex: 'expiresAt',
      field: 'visibilities.expiresAt',
      width: 100,
      render: date => <ISODate date={date} />
    },
    {
      title: 'Note',
      dataIndex: 'notes',
      field: 'visibilities.notes',
      ellipsis: true
    }
  ]);
  return <TableScroll columns={columns} dataSource={props.visibilities} />;
}
