import { DiscardPolicyRule } from './DiscardPolicyRule';

/**
 * Specifica se la policy di scarto è ricorrente o one-off.
 */
export enum DiscardPolicyType {
  Once = 'once',
  Recurrent = 'recurrent'
}

/**
 * Specifica se la policy di scarto è sui fascicoli o sui documenti non
 * fascicolati.
 */
export enum DiscardPolicyTarget {
  Dossiers = 'dossiers',
  Documents = 'documents'
}

export interface IDiscardPolicy {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  type: DiscardPolicyType;
  target: DiscardPolicyTarget;
  name: string;
  description?: string;
  laws?: string;
  beginsAt?: Date;
  rules: DiscardPolicyRule[];
}

export enum OrderableDiscardPolicyFields {
  Id = 'id',
  Name = 'name',
  CreatedAt = 'createdAt'
}
