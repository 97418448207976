import * as React from 'react';
import { Formik, useFormikContext } from 'formik';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { UserApi } from '../UserApi';
import { FormItem, AntFormItem } from 'client/ui/form/FormItem';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { RoleApi } from '../../roles/RoleApi';
import { AuthDomainApi } from '../../auth-domains/AuthDomainApi';
import { displayAuthDomain } from '../../auth-domains/AuthDomainDisplay';
import { Button, Row, Col } from 'antd';
import { IUser } from 'common/schema/User';
import { IRoleBinding } from 'common/schema/RoleBinding';
import {
  userRolesAddDtoValidator,
  IUserRolesAddDto
} from 'common/dto/UserRolesDto';
import { FormikForm } from 'client/ui/form/FormikForm';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { useAvailableRoleOptions } from 'client/components/config/ClientConfigModule';
import { UserRoleSelectInput } from '../input/UserRoleSelectInput';
import { IAuthDomain } from 'common/schema/AuthDomain';
import { DomainSelectInput } from './DomainSelectInput';

export interface IUserRoleDialogProps {
  user: IUser;
  binding?: IRoleBinding;
}

export function UserRoleDialog(props: IUserRoleDialogProps) {
  const addUserRole = useApiMutation(UserApi.addUserRole, {
    data: { userId: props.user.id },
    invalidates: [UserApi.findUserRoles, UserApi.findUser]
  });

  return (
    <Formik<IUserRolesAddDto>
      initialValues={props.binding || ({} as any)}
      validate={schemaToValidator(userRolesAddDtoValidator as any)}
      onSubmit={async (values, helpers) => {
        const result = await addUserRole({ data: values });
      }}
    >
      <FormikForm layout="vertical" style={{ padding: '24px 0' }}>
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <DomainSelectInput />
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <UserRoleSelectInput />
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <AntFormItem style={{ paddingTop: '20px' }}>
              <Button htmlType="submit" type="primary" block>
                Aggiungi Ruolo
              </Button>
            </AntFormItem>
          </Col>
        </Row>
      </FormikForm>
    </Formik>
  );
}
