import { apiClient } from 'client/core/network/apiClient';
import { IDepositsQueryDto } from 'common/dto/DepositsQueryDto';
import { IDeposit } from 'common/schema/Deposit';
import { IDepositDocumentsQueryDto } from 'common/dto/DepositDocumentsQueryDto';
import { IDocument } from 'common/schema/Document';
import { IDepositDossiersQueryDto } from 'common/dto/DepositDossiersQueryDto';
import { IDossier } from 'common/schema/Dossier';
import { IDepositAttachmentsQueryDto } from 'common/dto/DepositAttachmentsQueryDto';
import { IAttachment } from 'common/schema/Attachment';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';

export const DepositApi = {
  list: (opts: { query: IDepositsQueryDto }) =>
    apiClient.get<PaginatedResult<IDeposit>>(`/deposits`, {
      params: opts.query
    }),
  find: (opts: { id: number }) =>
    apiClient.get<IDeposit>(`/deposits/${opts.id}`),
  listDocuments: (opts: { id: number; query: IDepositDocumentsQueryDto }) =>
    apiClient.get<PaginatedResult<IDocument>>(
      `/deposits/${opts.id}/documents`,
      {
        params: opts.query
      }
    ),
  listDossiers: (opts: { id: number; query: IDepositDossiersQueryDto }) =>
    apiClient.get<PaginatedResult<IDossier>>(`/deposits/${opts.id}/dossiers`, {
      params: opts.query
    }),
  listAttachments: (opts: { id: number; query: IDepositAttachmentsQueryDto }) =>
    apiClient.get<PaginatedResult<IAttachment>>(
      `/deposits/${opts.id}/attachments`,
      {
        params: opts.query
      }
    )
};
