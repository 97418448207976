import * as React from 'react';
import { Row, Col, Statistic } from 'antd';
import { DepositStateTag } from './DepositStateTag';
import './DepositReport.scss';
import { IDeposit } from 'common/schema/Deposit';

export interface DepositReportProps {
  deposit: IDeposit;
}

/**
 * Mostra lo numeri totali degli elementi del versamento
 */
export function DepositReport(props: DepositReportProps) {
  const { deposit } = props;

  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className="deposit-report"
    >
      <Col>
        <Statistic
          title="Fascicoli"
          value={deposit.dossiersReceived}
          groupSeparator="."
          suffix={<DepositStateTag compact state={deposit.state} />}
        />
      </Col>
      <Col>
        <Statistic
          title="Documenti"
          value={deposit.documentsReceived}
          groupSeparator="."
          suffix={<DepositStateTag compact state={deposit.state} />}
        />
      </Col>
    </Row>
  );
}
