import * as React from 'react';
import { Icon, Row, Button, Typography } from 'antd';
const { Text } = Typography;
import { Link } from 'react-router-dom';
import { IDossierDocument, IDossier } from 'common/schema/Dossier';
import { TableScroll } from 'client/ui/table/TableScroll';
import { useFieldSchemaColumns } from 'client/components/metadata/schema/useFieldSchemaColumn';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { SchemaMetadata } from 'client/components/metadata/schema/SchemaMetadata';
import './DossierDocuments.scss';
import { DossierLogic } from 'common/logic/DossierLogic';
import clx from 'classnames';

export interface IDossierDocumentsProps {
  dossier: IDossier;
  ns: SchemaConfigNamespace;
}

/**
 * Elenco dei documenti contenuti in un fascicolo.
 */
export function DossierDocuments(props: IDossierDocumentsProps) {
  const { dossier } = props;

  const isDossierComplete = DossierLogic.isDossierComplete(props.dossier);

  const columns = useFieldSchemaColumns<IDossierDocument>(props.ns, [
    {
      title: 'Registro',
      dataIndex: 'register',
      width: '20%',
      ellipsis: true
    },
    {
      title: 'Protocollo',
      dataIndex: 'registerNumber',
      width: 120,
      ellipsis: true
    },
    {
      title: 'Oggetto',
      dataIndex: 'subject',
      ellipsis: true
    },
    {
      title: '',
      align: 'right',
      width: 40,
      render: (_, row) => (
        <Link to={`/app/documents/${dossier.areaUid}/${row.uid}`}>
          <Button className="td-button-link" type="link" icon="right-circle" />
        </Link>
      )
    }
  ]);

  return (
    <TableScroll
      className={clx('dossier-document-table', {
        incomplete: !isDossierComplete
      })}
      bottomSpace={isDossierComplete ? 58 : 96}
      dataSource={dossier.documents}
      rowKey="uid"
      columns={columns}
      pagination={false}
      footer={() =>
        isDossierComplete ? (
          false
        ) : (
          <>
            <Text ellipsis>
              Attenzione, Fascicolo parziale. Alcuni documenti di questo
              fascicolo non sono stati versati.
            </Text>
          </>
        )
      }
      expandedRowRender={record => (
        <Row type="flex" className="metadata-inline">
          <SchemaMetadata
            ns="dossier"
            field="documents.subject"
            value={record.subject}
            label="Oggetto"
          />
          <SchemaMetadata
            ns="dossier"
            field="documents.uid"
            value={record.uid}
            label="ID d’istanza del documento"
          />
          <SchemaMetadata
            ns="dossier"
            field="documents.registerUid"
            value={record.registerUid}
            label="ID del registro"
          />
        </Row>
      )}
    />
  );
}
