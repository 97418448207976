import * as React from 'react';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { Row, Col, message, Tabs, Typography } from 'antd';
const { TabPane } = Tabs;
const { Text } = Typography;
import { GrantExtra } from '../common/GrantExtra';
import { FormikForm } from 'client/ui/form/FormikForm';
import { Formik, FormikProps } from 'formik';
import './GrantEditPage.scss';
import { RouteComponentProps } from 'react-router';
import { GrantInput } from './GrantInput';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { GrantApi } from '../GrantApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { GrantDocumentInput } from './GrantsDocumentInput';
import { GrantDossierInput } from './GrantsDossierInput';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import { ISODate } from 'client/ui/display/date/ISODate';
import { GrantStatusTag } from '../detail/GrantStatusTag';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { grantValidator, IGrantDto } from 'common/dto/GrantDto';
import { PageCard } from 'client/ui/layout/PageCard';
import { LabeledTextGroup } from 'client/ui/labeled-text/LabeledTextGroup';
import { GrantRequestsTable } from '../relations/GrantRequestsTable';
import { GrantStatus } from 'common/schema/Grant';

interface Params {
  id: string;
}

export interface GrantEditPageProps extends RouteComponentProps<Params> {}

export function GrantEditPage(props: GrantEditPageProps) {
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const { response, error, loading, refetch } = useApiQuery(
    GrantApi.findGrant,
    {
      skip: id == null,
      data: {
        id: id!
      }
    }
  );

  const create = useApiMutation(GrantApi.createGrant, {
    invalidates: [GrantApi.findGrant]
  });
  const update = useApiMutation(GrantApi.updateGrant, {
    invalidates: [GrantApi.findGrant]
  });

  const documents = response?.data.documents;
  const dossiers = response?.data.dossiers;

  const description = response?.data.description;
  let title = id
    ? id + (description ? ' | ' + description : '')
    : 'Nuovo Permesso';
  //title = title.length > 30 ? title.slice(0, 30) + '...' : title;

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare il Permesso Temporaneo di Visualizzazione"
        description={error.message}
        showIcon
      />
    );
  if (id && !response?.data) return <LoadingPage />;

  return (
    <PageLayout hasScrollable hasMenu excludeCard className="grant-edit-page">
      <Formik<IGrantDto>
        initialValues={
          response && response.data
            ? response.data
            : ({ status: GrantStatus.Draft } as any)
        }
        enableReinitialize
        validate={schemaToValidator(grantValidator)}
        onSubmit={async (rawValues, helpers) => {
          try {
            const values = await grantValidator.validate(rawValues);

            if (id) {
              await update({
                data: { id: id!, input: values }
              });
              message.success('Permesso aggiornato con successo. ');
            } else {
              const result = await create({
                data: { input: { ...values } }
              });
              message.success('Permesso creato con successo. ');
              props.history.push(`/app/grants/edit/${result.data!.id}`);
            }
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante l'aggiornamento del permesso."
            );
          }
        }}
      >
        {({ dirty, submitForm, isSubmitting }) => (
          <PageCard
            hasScrollable
            loading={loading || isSubmitting}
            title={
              <CustomCardTitle
                ellipses={true}
                ellipsesWidth="75%"
                image="grant"
                titleLabel="Permesso Temporaneo:"
                title={title}
              />
            }
            extra={
              <GrantExtra
                submit={submitForm}
                loading={loading || isSubmitting}
              />
            }
          >
            <FormikForm layout="vertical" style={{ height: '100%' }}>
              <RowScroller>
                <ColScroller span={8} side="left" spacing="inside">
                  {id && (
                    <>
                      <LabeledTextGroup inline>
                        <LabeledText
                          label="Data Richiesta"
                          text={<ISODate date={response?.data.createdAt} />}
                        />
                        <LabeledText label="ID" text={id} />
                        {/* <LabeledText
                        label="Stato"
                        text={<GrantStatusTag grant={response?.data} />}
                        style={{ minWidth: '150px' }}
                      /> */}
                      </LabeledTextGroup>
                      <LabeledTextGroup inline>
                        <LabeledText
                          label="Stato"
                          text={<GrantStatusTag grant={response?.data} />}
                          style={{ minWidth: '150px' }}
                        />
                        {response?.data.approvedAt && (
                          <LabeledText
                            label="Data Approvazione"
                            text={<ISODate date={response?.data.approvedAt} />}
                          />
                        )}
                        {response?.data.declinedAt && (
                          <LabeledText
                            label="Data Respingimento"
                            text={<ISODate date={response?.data.declinedAt} />}
                          />
                        )}
                      </LabeledTextGroup>
                    </>
                  )}
                  <GrantInput grant={response?.data} />
                </ColScroller>
                <ColScroller span={16} forceHeight hasTableScroll>
                  <Tabs type="card" className="tabs-content-scrollable">
                    {/* Elenco dei fascicoli */}
                    <TabPane
                      className="spacing-tablescroll full-table"
                      key="dossiers"
                      tab={`Elenco Fascicoli ${
                        dossiers ? `(${dossiers.length})` : ''
                      }`}
                    >
                      <GrantDossierInput />
                    </TabPane>
                    {/* Elenco dei documenti */}
                    <TabPane
                      className="spacing-tablescroll full-table"
                      key="document"
                      tab={`Elenco Documenti ${
                        documents ? `(${documents.length})` : ''
                      }`}
                    >
                      <GrantDocumentInput />
                    </TabPane>
                    {/* Dettaglio Richiesta */}
                    <TabPane
                      className="spacing-tablescroll full-table"
                      key="request"
                      tab="Dettaglio Richiesta"
                    >
                      <GrantRequestsTable
                        requests={response?.data.requests ?? []}
                        bottomSpace={58}
                      />
                    </TabPane>
                  </Tabs>
                </ColScroller>
              </RowScroller>
            </FormikForm>
          </PageCard>
        )}
      </Formik>
    </PageLayout>
  );
}
