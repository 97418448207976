import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, message, Row, Col } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { Formik } from 'formik';
import { pick } from 'lodash';
import { OrganizationAreas } from './OrganizationAreas';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { OrganizationApi } from './OrganizationApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { IOrganization } from 'common/schema/Organization';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageCard } from 'client/ui/layout/PageCard';
import { Link } from 'react-router-dom';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';

interface Params {
  id: string;
}

export interface OrganizationEditProps extends RouteComponentProps<Params> {}

/**
 * Componente per modificare un'area organizzativa
 */
export function OrganizationEdit(props: OrganizationEditProps) {
  const id =
    props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const { response, error, loading, refetch } = useApiQuery(
    OrganizationApi.findOrganization,
    {
      skip: !id,
      data: {
        id: id!
      }
    }
  );

  const update = useApiMutation(OrganizationApi.updateOrganization, {
    data: { id: id! }
  });

  const create = useApiMutation(OrganizationApi.createOrganization, {});

  if (error)
    return (
      <AlertPage
        type="error"
        message="Impossibile caricare l'Organizzazione"
        description={error.message}
      />
    );

  if (id && !response?.data) return <LoadingPage />;

  return (
    <PageLayout
      hasMenu
      hasScrollable
      excludeCard
      title={
        <CustomCardTitle
          icon="cluster"
          title={id ? 'Modifica Organizzazione' : 'Nuova Organizzazione'}
        />
      }
    >
      <Formik<IOrganization>
        initialValues={
          response && response.data
            ? pick(response.data, ['name', 'uid'])
            : ({ name: '' } as any)
        }
        enableReinitialize
        onSubmit={async (values, helpers) => {
          try {
            if (id) {
              await update({
                data: { id: id!, data: values }
              });
            } else {
              const result = await create({
                data: { data: values }
              });
              console.log(result.data);
              props.history.push(`/app/admin/organizations/${result.data!.id}`);
            }
            refetch();
            message.success('Organizzazione aggiornata con successo.');
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante l'aggiornamento dell'Organizzazione."
            );
          }
        }}
      >
        {({ dirty, submitForm, isSubmitting }) => (
          <PageCard
            hasScrollable
            loading={isSubmitting || loading}
            title={
              <CustomCardTitle
                icon="cluster"
                titleLabel={id ? 'Modifica Organizzazione:' : 'Organizzazione:'}
                title={id ? response?.data.name : 'Nuova Organizzazione'}
              />
            }
            extra={
              <CustomCardExtra
                right={
                  <>
                    {id && (
                      <Link to={`/app/admin/organizations/${id}/areas/create`}>
                        <Button disabled={isSubmitting || loading}>
                          Aggiungi A.O.O.
                        </Button>
                      </Link>
                    )}
                    <Button
                      htmlType="submit"
                      onClick={submitForm}
                      disabled={isSubmitting || loading}
                      type="primary"
                    >
                      Salva Organizzazione
                    </Button>
                  </>
                }
              />
            }
          >
            <Scrollable spacing="inside">
              <FormikForm layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <TextInputItem
                      name="name"
                      label="Nome"
                      placeholder="Nome"
                    />
                  </Col>
                  <Col span={12}>
                    <TextInputItem name="uid" label="UID" placeholder="UID" />
                  </Col>
                </Row>
              </FormikForm>
              <OrganizationAreas organizationId={id} />
            </Scrollable>
          </PageCard>
        )}
      </Formik>
    </PageLayout>
  );
}
