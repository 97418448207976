import React from 'react';
import { Row } from 'antd';
import { CardDashboard } from 'client/ui/card/CardDashboard';
import moment from 'moment';
import { IconVector } from 'client/ui/icon/IconVector';
import { ColCardStatisticCC } from 'client/ui/statistic/ColCardStatisticCC';
import { IDashboardDepositsData } from 'common/schema/Dashboard';

export interface DashboardDepositDetailProps {
  data: IDashboardDepositsData;
}

/**
 * Elemento per mostrare i dati dei versamenti nella dashboard
 *
 */
export function DashboardDepositDetail(props: DashboardDepositDetailProps) {
  const { data } = props;
  return (
    <CardDashboard
      title={
        <span>
          <IconVector image="deposit" /> Versamenti
        </span>
      }
    >
      <Row gutter={24}>
        <ColCardStatisticCC
          span={8}
          title="Completati"
          value={data.completed}
        />
        <ColCardStatisticCC span={8} title="In Corso" value={data.running} />
        <ColCardStatisticCC
          span={8}
          title="Ultimo Versamento"
          value={moment(props.data.lastDeposit).toDate()}
        />
      </Row>
    </CardDashboard>
  );
}
