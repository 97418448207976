import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Descriptions, Typography } from 'antd';
const { Paragraph } = Typography;
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { UserApi } from './UserApi';
import { UserDetailRoles } from './relations/UserDetailRoles';
import { Link } from 'react-router-dom';
import { UserCreateServiceToken } from './detail/UserCreateServiceToken';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { usePermissions } from 'client/components/auth/AuthModule';
import { LoginLabelText } from 'client/ui/label/LoginLabelText';

interface Params {
  id: string;
}

export interface UserDetailProps extends RouteComponentProps<Params> {}

/**
 * Pagina di dettaglio di un singolo utente.
 */
export function UserDetail(props: UserDetailProps) {
  const id = parseInt(props.match.params.id, 10);
  const { response, error, loading } = useApiQuery(UserApi.findUser, {
    data: { id }
  });
  const permissions = usePermissions();

  if (error) return <AlertPage type="error" message={error.message} showIcon />;

  const user = response?.data;

  if (!user) return <LoadingPage />;

  return (
    <PageLayout
      hasMenu
      hasScrollable
      loading={loading || !response}
      title={
        <CustomCardTitle icon="user" titleLabel="Utente:" title={user?.name} />
      }
      extra={
        <Link to={`/app/admin/users/edit/${id}`}>
          <Button
            type="primary"
            disabled={
              loading ||
              !response ||
              !response.data ||
              !user ||
              !permissions.canEditUser(user)
            }
          >
            Modifica
          </Button>
        </Link>
      }
    >
      <Scrollable spacing="inside">
        <Descriptions>
          <Descriptions.Item label="Nome">{user.name}</Descriptions.Item>
          <Descriptions.Item label={<LoginLabelText />}>
            {user.login}
          </Descriptions.Item>
          {user.serial && (
            <Descriptions.Item label="Matricola">
              {user.serial}
            </Descriptions.Item>
          )}
        </Descriptions>
        {user && (
          <Paragraph>
            <UserCreateServiceToken user={user} />
          </Paragraph>
        )}
        <UserDetailRoles user={user} id={id} />
      </Scrollable>
    </PageLayout>
  );
}
