import * as React from 'react';
import { Icon, Button, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { TableScroll } from 'client/ui/table/TableScroll';
import { GrantStatusTag } from '../detail/GrantStatusTag';
import { IGrant } from 'common/schema/Grant';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { GrantApi } from '../GrantApi';
import { ISODate } from 'client/ui/display/date/ISODate';
import { useState } from 'react';
import { IGrantsQueryDto } from 'common/dto/GrantsQueryDto';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { GrantStatusOptions } from '../detail/GrantOptions';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { TableButtonDetailText } from 'client/ui/label/TableButtonDetailText';

export interface TableGrantsListProps {}

export function TableGrantsList(props: TableGrantsListProps) {
  const [query, setQuery] = useState<IGrantsQueryDto>({
    page: 1,
    pageSize: 20,
    orderBy: 'id',
    orderByDirection: ColumnSortDirections.descend
  });
  const { response, error, loading } = useApiQuery(GrantApi.findGrants, {
    data: { query }
  });

  const deleteOne = useApiMutation(GrantApi.deleteGrant, {
    invalidates: [GrantApi.findGrants]
  });

  const handleDelete = (id: number) => async (e: any) => {
    try {
      await deleteOne({ data: { id } });
      message.success(`Il permesso è stato eliminato.`);
    } catch (err) {
      console.error(err);
      message.error(
        `Si è verificato un errore durante l'eliminazione del permesso.`
      );
    }
  };

  const columns: ColumnProps<IGrant>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      sorter: true
    },
    {
      title: 'Utente',
      dataIndex: 'user.name',
      key: 'user.name',
      width: 140,
      ellipsis: true
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      ...createColumnFilter(FilterInputType.Text)
    },
    {
      title: 'Stato',
      dataIndex: 'status',
      key: 'status',
      width: 90,
      render: (_, record) => <GrantStatusTag grant={record} />,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: GrantStatusOptions
      })
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 90,
      render: (_, record) => <ISODate date={record.createdAt.toString()} />,
      sorter: true,
      ...createColumnFilter(FilterInputType.Date)
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      width: 280,
      render: (_, record) => (
        <div className="table-actions-column">
          <Link to={`/app/grants/edit/${record.id}`}>
            <Button size="small" type="primary">
              <Icon type="edit" /> Modifica
            </Button>
          </Link>
          <Link to={`/app/grants/view/${record.id}`}>
            <Button size="small">
              <Icon type="eye" /> <TableButtonDetailText />
            </Button>
          </Link>
          <ButtonDeleteConfirm
            title="Sei sicuro di voler eliminare il permesso?"
            onConfirm={handleDelete(record.id)}
          />
        </div>
      )
    }
  ];

  if (error)
    return (
      <AlertPage
        message="Impossibile caricare l'elenco dei permessi temporanei"
        description={error.message}
        type="error"
        showIcon
      />
    );

  // if (loading || !response || !response.data)
  //   return <LoadingPage full transparent />;

  return (
    <TableScroll
      loading={tableLoaderSpin(loading || !response)}
      columns={columns}
      dataSource={response?.data.items ?? []}
      size="middle"
      className="table-grant-list"
      onChange={(pagination, filters, sorter) => {
        setQuery({
          ...query,
          page: pagination.current || query.page,
          pageSize: pagination.pageSize || query.pageSize,
          statuses: filters.status ?? [],
          description:
            filters.description && filters.description.length > 0
              ? filters.description[0]
              : undefined,
          createdAt:
            filters.createdAt && filters.createdAt.length > 0
              ? filters.createdAt[0]
              : undefined,
          orderBy: sorter.field,
          orderByDirection: sorter.order
            ? ColumnSortDirections[sorter.order]
            : undefined
        });
      }}
      pagination={{
        size: 'small',
        position: 'bottom',
        showSizeChanger: true,
        total: response?.data.meta.total,
        pageSize: query.pageSize,
        current: query.page,
        pageSizeOptions: ['5', '10', '20', '30', '40']
      }}
    />
  );
}
