import React from 'react';
import { Modal, Typography } from 'antd';

const { Text } = Typography;

export function openMassiveImportBagModal(errors: string[]) {
  Modal.warn({
    title: 'Importazione eseguita. Si sono verificati alcuni errori:',
    width: 500,
    content: (
      <ul>
        {errors.map(error => (
          <li>
            <Text type="secondary">{error}</Text>
          </li>
        ))}
      </ul>
    )
  });
}
