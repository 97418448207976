import React, { useState } from 'react';
import clx from 'classnames';
import { Typography, Row, Col, Card, Statistic, Progress, Icon } from 'antd';
const { Text, Paragraph } = Typography;
import './StatisticItem.scss';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { IStatisticSpaceUsedItemProps } from './StatisticSpaceUsedItem';

export interface StatisticItemProps {
  data: IStatisticSpaceUsedItemProps;
  type?: 'card' | 'compact';
  column?: number;
  dataLabel?: string;

  /**
   * Aggiunto per eliminare il titolo nella dashboard,
   * quando i dati vengono visualizzati nella stessa Card
   */
  hideTitle?: boolean;
}

/**
 * Elemento per mostrare le statistiche del numero di elementi considerati
 * (documenti, fascicoli o file)
 */
export function StatisticItem(props: StatisticItemProps) {
  const spaceTitle = props.data.unit
    ? 'Spazio occupato (' + props.data.unit + ')'
    : 'Spazio occupato (Mb)';
  const isCompact = props.type == 'compact';
  const label = props.dataLabel ?? 'Versati';
  const labelNumber =
    props.data.running && props.data.progressNumber ? (
      <>
        <Icon spin type="sync" style={{ color: '#569bc4' }} />
        {props.data.totalNumber ? label + '/Totale' : label}
      </>
    ) : props.data.totalNumber ? (
      label + '/Totale'
    ) : (
      label
    );
  const valueNumber = props.data.totalNumber
    ? props.data.number + '/' + props.data.totalNumber
    : props.data.number;

  return (
    <div className="statistic-item">
      <Paragraph className="statistic-title" strong>
        {props.data.title}
      </Paragraph>
      {props.data.percent && (
        <Progress percent={props.data.percent} size="small" />
      )}
      {isCompact ? (
        <div className="type-compact">
          <Statistic
            groupSeparator="."
            decimalSeparator=","
            title={labelNumber}
            value={valueNumber}
          />
          {props.data.space && (
            <Statistic
              groupSeparator="."
              decimalSeparator=","
              title={spaceTitle}
              value={props.data.space}
            />
          )}
        </div>
      ) : (
        <>
          <Statistic
            groupSeparator="."
            decimalSeparator=","
            title={labelNumber}
            value={valueNumber}
          />
          {props.data.space && (
            <Statistic
              groupSeparator="."
              decimalSeparator=","
              title={spaceTitle}
              value={props.data.space}
            />
          )}
        </>
      )}
    </div>
  );
}
