import React, { useCallback } from 'react';
import { SchemaConfigNamespace } from 'common/config/ISchemaConfig';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'client/core/store/RootReducer';
import { ColumnProps } from 'antd/lib/table';
import { SchemaLabel } from './SchemaLabel';
import { SchemaValue } from './SchemaValue';

export interface SchemaColumn<T> extends ColumnProps<T> {
  /**
   * Calcola automaticamente il title e la key e mostra la colonna
   * solo se presente
   */
  field?: string;
  /**
   * Tipo di dato
   */
  fieldType?: 'string' | 'date' | 'number' | 'boolean';
}

/**
 * Permette di mostrare delle colonne condizionalmente in base
 * alla definizione dello schema.
 *
 * Per utilizzarlo, semplicemente chiamarlo con argomento le colonne,
 * aggiungendo ad ognuna la proprietà `field` i.e. `field: "events.createdAt"`.
 * E' possibile omettere `key` e lasciare solo `field`.
 *
 *    const columns = useFieldSchemaColumns<IEvent>('document', [
 *      { field: 'createdAt' }
 *    ]);
 */
export function useFieldSchemaColumns<T>(
  ns: SchemaConfigNamespace,
  columns: SchemaColumn<T>[]
) {
  const fields = useSelector(
    (state: RootState) => state.config.config?.schema[ns].fields,
    shallowEqual
  );

  return columns
    .map(column => {
      // Colonna non derivante dallo schema
      if (!column.field) return column;

      // Colonna dello schema, se non è presente o disabilitata
      const fieldSchema = fields?.find(f => f.name === column.field);
      if (!fieldSchema?.enable) return null;

      return {
        render: (value: any) => (
          <SchemaValue
            ns={ns}
            field={column.field!}
            type={column.fieldType}
            value={value}
          />
        ),
        ...column,
        key: column.key ?? column.field,
        title: (
          <SchemaLabel
            ns={ns}
            field={column.field!}
            label={column.title ?? ''}
          />
        )
      };
    })
    .filter((c): c is SchemaColumn<T> => c != null);
}
