import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Tabs, Empty } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { DossierHeader } from './sections/DossierHeader';
import { PageTabsContainer } from 'client/ui/layout/PageTabsContainer';
import { DossierContentTab } from './tabs/DossierContentTab';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DossierApi } from './DossierApi';
import { DossierVisibilityTab } from './tabs/DossierVisibilityTab';
import { DossierTransmissionTab } from './tabs/DossierTransmissionTab';
import { DossierEventTab } from './tabs/DossierEventTab';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { useFieldSchema } from '../metadata/schema/useFieldSchema';
import { SchemaLabel } from '../metadata/schema/SchemaLabel';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
const { TabPane } = Tabs;

type Params = {
  areaUid: string;
  uid: string;
};

export interface DossierPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di visualizzazione del singolo fascicolo. Contiene i dati generali
 * mostrati come intestazione e i documenti contenuti, con le tab di dettaglio.
 *
 * Qui effettuiamo la query alle API per ottenere i dettagli del fascicolo.
 */
export function DossierPage(props: DossierPageProps) {
  const { areaUid, uid } = props.match.params;

  const { response, error, loading } = useApiQuery(DossierApi.findUidDossier, {
    data: { areaUid, uid }
  });

  const showDossierTransmissions = useFieldSchema('dossier', 'transmissions');

  if (error)
    return <AlertPage type="error" message={getNetworkErrorMessage(error)} />;
  if (loading || !response?.data) return <LoadingPage />;

  const dossier = response.data;

  return (
    <PageLayout hasMenu hasScrollable excludeCard>
      <PageTabsContainer header={<DossierHeader dossier={dossier} />} isDossier>
        <TabPane
          tab={<SchemaLabel ns="dossier" field="content" label="Contenuto" />}
          key="content"
          className="page-tabs-panel"
        >
          <DossierContentTab dossier={dossier} />
        </TabPane>

        {showDossierTransmissions && (
          <TabPane
            // tab="Trasmissioni"
            tab={
              <SchemaLabel
                ns="dossier"
                field="transmissions"
                label="Trasmissioni"
              />
            }
            key="transmissions"
            className="page-tabs-panel"
          >
            <DossierTransmissionTab dossier={dossier} />
          </TabPane>
        )}

        <TabPane
          tab={
            <SchemaLabel ns="dossier" field="visibilities" label="Visibilità" />
          }
          key="visibilities"
          className="page-tabs-panel"
        >
          <DossierVisibilityTab dossier={dossier} />
        </TabPane>

        <TabPane
          tab={<SchemaLabel ns="dossier" field="events" label="Eventi" />}
          key="events"
          className="page-tabs-panel"
        >
          <DossierEventTab dossier={dossier} />
        </TabPane>
      </PageTabsContainer>
    </PageLayout>
  );
}
