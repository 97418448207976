import { IAttachment } from 'common/schema/Attachment';
import { resolveApiPath } from 'client/core/appConfig';
import { AttachmentPreview } from './AttachmentPreview';

/**
 * Ottiene l'URL di un Attachment per la visualizzazione o il download.
 */
export function getAttachmentUrl(attachment: AttachmentPreview) {
  return resolveApiPath(
    attachment.type === 'attachment'
      ? `/attachments/${attachment.item.id}/actions/download/${
          attachment.item.fileName ?? 'attachment'
        }`
      : `/attachments/${attachment.attachment.id}/versions/${
          attachment.item.uid
        }/actions/download/${attachment.item.fileName ?? 'attachment'}`
  );
}
