import React from 'react';
import { IDossier, IDossierDocument } from 'common/schema/Dossier';
import { Tree } from 'antd';
const { TreeNode } = Tree;

export interface IDossierStructureDocumentNode {
  type: 'document';
  uid: number;
}

export function getDocumentStructureNode(dossier: IDossier, uid: number) {
  const document = dossier.documents.find(d => d.uid === uid);

  return (
    <TreeNode
      title={document?.subject ?? '<Documento Mancante>'}
      key={`document-${uid}`}
      isLeaf
      data={{ type: 'document', uid } as IDossierStructureDocumentNode}
    />
  );
}
