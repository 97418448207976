import { IAuthDomain } from 'common/schema/AuthDomain';

export class AuthDomainLogic {
  static toRegex(domain: IAuthDomain) {
    return new RegExp('^' + (domain.mpath ?? '') + '(.*)');
  }

  static isRoot(domain: IAuthDomain) {
    return domain.parentId == null;
  }

  /**
   * Ritorna vero se, nell'organigramma, il dominio `subDomain` è un sottodominio di `domain`,
   * ovvero se nel percorso dalla root a `subdomain` (`mpath`) è presente `domain`
   */
  static isSubDomain(domain: IAuthDomain, subDomain: IAuthDomain): boolean {
    if (!subDomain.mpath) return false;
    return subDomain.mpath
      .split('.')
      .some(domainId => Number(domainId) === domain.id);
  }
}
